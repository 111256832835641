<template>
  <div class="filter-box d-flex">
    <v-menu right buttom :close-on-content-click="false" ref="filterMenu">
      <template v-slot:activator="{ on, attrs }">
        <v-icon class="v-data-table-header__filter-icon" size="15" v-on="on" v-bind="attrs">mdi-tune</v-icon><!--mdi-filter-->
      </template>
      <v-list max-width="300">
        <div style="padding: 0 15px;" v-if="title === 'Status'">Show Statuses That Are:</div>
        <div style="padding: 0 15px;" v-else-if="title === 'Name' || title === 'Email'">Show {{title}}s That:</div>
        <div style="padding: 0 15px;" v-else-if="title === 'Next Visit' || title === 'Last Visit'">Show {{title}} by Date Range:</div>
        <div style="padding: 0 15px;" v-else-if="title === 'Phone'">Show Phone Numbers That:</div>
        <div v-if="type !== 'Date' && type !== 'Enum'" style="padding: 0 15px;">
          <Select outlined :items="items" :value="items[0]" @change="setType" />
        </div>
        <v-list-item style="margin-top: -20px">
          <v-list-item-content>
            <v-list-item-subtitle>
              <v-form
                class="py-4"
                ref="form"
                v-model="valid"
                lazy-validation
                :id="`filter-box-${fieldName}`"
                :style="fieldName === 'status' ? 'height: 200px;overflow-x: auto' : ''">
                <template v-if="type === 'Number' && searchType == 4"><!-- && form.search.searchType == 4-->
                  <div class="d-flex flex-column">
                    <Input class="mr-2" outlined  placeholder="from" radius="8" :rules="[notEmtpy]" @input="setFrom" @enter="filter"/>
                    <Input outlined placeholder="to" radius="8" :rules="[notEmtpy]" @input="setTo" @enter="filter"/>
                  </div>
                </template>
                <template v-if="type === 'Date'">
                  <div class="d-flex flex-column">
                    <div class="label">From</div>
                    <datepicker @date:update="rangeSearch.from = $event, setTypeDate('from', $event)"
                                :value="rangeSearch.from"/>
                  </div>
                  <div class="d-flex flex-column">
                    <div class="label">To</div>
                    <datepicker @date:update="rangeSearch.to = $event, setTypeDate('to', $event)"
                                :value="rangeSearch.to"/>
                  </div>
                </template>
                <template v-if="type === 'Enum'">
                  <v-checkbox
                    style="margin-top: 0px"
                    :multiple="true"
                    v-model="Statuses"
                    v-for="status in getStatuses"
                    :key="status.statusCode"
                    :label="status.statusName"
                    :value="status.statusCode"
                    @change="applyStatuses"
                  ></v-checkbox>
                </template>
                <template v-if="type !== 'Date' && type !== 'Enum' && searchType !== 4">
                  <Input outlined placeholder="Some value" radius="8" :rules="[notEmtpy]" @input="form.search.value=$event" @enter="filter"/>
                </template>
              </v-form>
            </v-list-item-subtitle>
            <v-list-item-subtitle class="d-flex">
              <Button dark class="mr-2" btn-bg="dark-green" :styles="{ 'border-radius': '8px' }" @click.native="filter">Filter</Button>
              <Button
                dark
                outlined
                color="#008C89"
                :styles="{ 'border-radius': '8px' }" @click.native="reset">Clear</Button>
            </v-list-item-subtitle>
          </v-list-item-content>
        </v-list-item>
      </v-list>
    </v-menu>
  </div>
</template>

<script>
import Select from '@/components/elements/Select'
import Input from '@/components/elements/Input'
import Button from '@/components/elements/Button'
import datepicker from '@/components/elements/datepicker'
import Validate from '@/mixins/Validation'
import Mapping from '@/mixins/Mapping'

const searchTypeEnum = ['StartsWith', 'Contains', 'EndsWith', 'Equals', 'MultipleRange']

export default {
  name: 'filter-box',
  props: {
    headers: {
      type: Array
    },
    fieldName: {
      type: String
    },
    type: {
      type: String
    },
    title: {
      type: String
    }
  },
  mixins: [Validate, Mapping],
  components: { Select, Input, Button, datepicker },
  data () {
    return {
      testItems: ['Foo', 'Bar', 'Fizz', 'Buzz', 'Buzz1', 'Buzz2', 'Buzz3', 'Buzz4 '],
      searchType: '',
      form: {
        /* type: this.type,
        name: this.fieldName, // "string",
        search: {
          searchType: '', // "StartsWith",
          value: ''
        },
        searchable: true */
        // new from SM-632
        /* Statuses: [] */
        // new from SM-632
      },
      search: {
        searchType: '', // "StartsWith",
        value: ''
      },
      rangeSearch: {
        type: this.type,
        data: this.fieldName,
        from: '',
        to: ''
      },
      Statuses: []
    }
  },
  methods: {
    filter () {
      if (this.validate()) {
        this.$emit('update:filter-box', this.form)
        this.$refs.filterMenu.save()
      }
    },
    reset () {
      this.$emit('reset:filter-box', this.fieldName)
      this.$refs.form.reset()
      this.$refs.filterMenu.save()
    },
    setType ($event) {
      const searchType = this._.upperFirst(this._.camelCase($event))
      const serchIndex = searchTypeEnum.findIndex(el => el === searchType)
      this.searchType = serchIndex
      this.form = {
        type: this.type,
        name: this.fieldName,
        searchable: true,
        search: {
          ...this.search,
          searchType: serchIndex
        }
      }
      if (this.$refs?.form?.resetValidation) {
        this.$refs.form.resetValidation()
      }
    },
    setTypeDate (typeKey, value) {
      if (this.type === 'Date') {
        this.form = {
          rangeSearch: { ...this.rangeSearch }
        }
      }
    },
    applyStatuses () {
      this.form = {
        name: 'Status',
        type: 'Enum',
        constraints: [...this.Statuses]
      }
    },
    setFrom ($event) {
      const value = this.form.search.value
      let tmpVal = ''
      if (value.includes('|')) {
        tmpVal = value.split('|').unshift()
      }
      this.form.search.value = `${$event}|${tmpVal}`
    },
    setTo ($event) {
      const value = this.form.search.value
      let tmpVal = ''

      if (value.includes('|')) {
        tmpVal = value.split('|').pop()
      }
      this.form.search.value = `${tmpVal}|${$event}`
    }
  },
  computed: {
    items () {
      let slctItems = []
      switch (this.type) {
        case 'String':
          slctItems = ['Starts With', 'Contains', 'Ends With', 'Equals']
          break
        case 'Number':
          slctItems = ['Contains', 'Equals', 'Multiple Range']
          break
      }

      return slctItems
    }
  }
}
</script>

<style lang="scss" scoped>
  .filter-box {}
  ::v-deep .v-input {
    .v-input__control {
      .v-text-field__details {
        display: none!important;
      }
      .v-messages {
        display: none!important;
      }
    }
  }
</style>
