<template>
  <div class="pagination">
    <v-pagination v-bind="$props" v-on="paginationListeners"></v-pagination>
  </div>
</template>

<script>
export default {
  name: 'pagination',
  props: {
    length: [Number, String],
    totalVisible: [Number, String],
    value: {
      type: Number,
      default: 1
    }
  },
  computed: {
    paginationListeners: function () {
      var vm = this
      return Object.assign({},
        this.$listeners,
        {
          input: function (event) {
            vm.$emit('input', event)
          }
        }
      )
    }
  }
}
</script>

<style lang="scss" scoped>
  .pagination {
    ::v-deep .v-pagination {
      &__navigation {
        box-shadow: unset !important;
      }
      &__item {
        background-color: transparent;
        color: #718096 !important;
        box-shadow: unset !important;
        &--active {
          background-color: #E4ECF7 !important;
          border-radius: 8px !important;
          color: #425466 !important;
        }
      }
    }
  }
</style>
