<template>
  <div class="supplement">
    <div class="main-row">
      <div class="protocols__total d-flex align-center mr-10"><span class="num mr-2">{{total}}</span> Supplements</div>
      <div class="d-flex align-center flex-grow-1 justify-end">
        <div class="mr-4" style="display: flex;align-items: center;gap: 20px;">
          <div style="display: flex;align-items: center;gap: 10px;">
            <div style="width: 10px;height: 10px;border-radius: 50%;background: #EDEDED;"></div>
            <span>Discontinued</span>
          </div>
          <div style="display: flex;align-items: center;gap: 10px;">
            <div style="width: 10px;height: 10px;border-radius: 50%;background: #E3F7EA;"></div>
            <span>Default</span>
          </div>
          <div style="display: flex;align-items: center;gap: 10px;">
            <div style="width: 10px;height: 10px;border-radius: 50%;background: #F7FEEC;border: 1px solid #dcd1d1;"></div>
            <span>Customized</span>
          </div>
        </div>
        <Input class="mr-2"
               style="margin-top: 17px"
               outlined
               radius="8"
               placeholder="Search"
               @input="inputSearch"
               :value="searchedText"
               @enter="getSupplements(query)"
        >
          <div slot="append" style="cursor: pointer" @click="getSupplements(query)">
            <v-icon>mdi-magnify</v-icon>
          </div>
        </Input>
        <Button btn-bg="dark-green"
                class="mr-2"
                @click.native="refresh"
                :styles="{'border-radius': '8px'}"
        >
                <span style="color: #FFFFFF">
                  Refresh
                </span>
        </Button>
        <Button btn-bg="dark-green" :styles="{'border-radius': '8px'}"
                @click.native="TOGGLE_DIALOG({ name: 'addSupplementDialog', isOpen: true, title: 'Add supplement', afterAction: { name: 'supplements/getSupplements', protocol: query } })">
                      <span style="color: #FFFFFF">
                        <v-icon>mdi-plus</v-icon>
                        Add supplement
                      </span>
        </Button>
      </div>
    </div>
    <div class="main-row" style="position:relative;">
      <Table
        :headers="headers"
        :items="itemsSupplements"
        class="supplement-table"
        ref="supplementTable"
        @update:option="optionTableData($event, 'asc')"
        @filterBox="filterBox"
        @resetFilter="resetFilter"
        hideDefaultFooter>

        <template v-slot:body>
          <template v-for="(item, index) in itemsSupplements">
            <tr
              :key="item.id"
              class="supplement-table__row"
              @mouseover="rowIsHovered = item.id"
              @mouseleave="rowIsHovered = false"
              :class="{
                'expand-row': expandedRows.includes(item.id),
                'is-discontinued': item.discontinued,
                'is-default': (item.isDefault && !item.discontinued && !item.isCustomized),
                'is-customized': item.isCustomized || (item.isCustomized && item.isDefault) || (item.isCustomized && !item.isDefault)
              }"
            >
              <td class="name px-3 pt-1 pb-1" @click="toggleRow(item.id)"><!--:style="{'width': (item.supplementName.length * 8) + 'px'}"-->
                <div class="d-flex align-center width100 height100 justify-space-between">
                  <div class="name d-flex align-center">
                    <span class="font-weight-bold" v-if="!searchedText">{{item.name }}</span>
                    <span class="font-weight-bold" v-else v-html="highlightSearchedText(item.name, searchedText)"></span>
                    <v-tooltip top v-if="item.vegetarian">
                      <template v-slot:activator="{ on, attrs }">
                        <v-icon color="success" v-bind="attrs" v-on="on">mdi-leaf</v-icon>
                      </template>
                      <span>Is Vegetarian</span>
                    </v-tooltip>
                    <v-tooltip top v-if="item.glutenFree" >
                      <template v-slot:activator="{ on, attrs }">
                        <v-icon color="warning" v-bind="attrs" v-on="on">mdi-barley</v-icon>
                      </template>
                      <span>Is Gluten Free Dietary</span>
                    </v-tooltip>
                    <v-tooltip top v-if="item.discontinued">
                      <template v-slot:activator="{ on, attrs }">
                        <v-icon v-bind="attrs"
                                v-on="on" color="#F16063">mdi-alert-circle</v-icon>
                      </template>
                      <span>Supplements is discontinued</span>
                    </v-tooltip>
                    <v-tooltip top v-if="item.warningLowBP" >
                      <template v-slot:activator="{ on, attrs }">
                        <span v-bind="attrs" v-on="on" style="padding-left: 3px">LBP</span>
                      </template>
                      <span>Warning Low BP</span>
                    </v-tooltip>
                    <v-tooltip top v-if="item.warningHighBP" >
                      <template v-slot:activator="{ on, attrs }">
                        <span v-bind="attrs" v-on="on" style="padding-left: 3px">HBP</span>
                      </template>
                      <span>Warning High BP</span>
                    </v-tooltip>
                    <v-tooltip top v-if="item.warningBP" >
                      <template v-slot:activator="{ on, attrs }">
                        <span v-bind="attrs" v-on="on" style="padding-left: 3px">BP</span>
                      </template>
                      <span>Warning BP</span>
                    </v-tooltip>
                  </div>
                  <div>
                    <v-icon v-if="!expandedRows.includes(item.id)">mdi-chevron-down</v-icon>
                    <v-icon v-else>mdi-chevron-up</v-icon>
                  </div><!--v-show="rowIsHovered === item.id"-->
                </div>
              </td>
              <td class="name px-3 pt-1 pb-1" @click="toggleRow(item.id)"><!--:style="{'width': (item.supplementName.length * 8) + 'px'}"-->
                <div class="d-flex align-center width100 height100 justify-space-between">
                  <div class="name d-flex align-center">
                    <span class="font-weight-bold">{{item.brandName ? item.brandName : ''}}</span>
                  </div>
                </div>
              </td>
              <td class="name px-3 pt-1 pb-1"><!--:style="{'width': (item.supplementName.length * 8) + 'px'}"-->
                <v-menu
                  :close-on-content-click="true"
                  offset-x
                  max-width="50px"
                >
                  <template v-slot:activator="{ on, attrs }">
                    <div class="d-flex align-center justify-center width100 height100"
                         v-bind="attrs"
                         v-on="on">
                      <span class="font-weight-bold">{{item.dosageUponArising ? item.dosageUponArising : '-'}}</span>
                    </div>
                  </template>
                  <dosage-control
                    :dosages="calculateDosageValue(item.dosageUponArising)"
                    :itemRow="item"
                    :propertyKey="'dosageUponArising'"
                    @update="updateSupplementDosage"
                  />
                </v-menu>
              </td>
              <td class="name px-3 pt-1 pb-1"><!--:style="{'width': (item.supplementName.length * 8) + 'px'}"-->
                <v-menu
                  :close-on-content-click="true"
                  offset-x
                  max-width="50px"
                >
                  <template v-slot:activator="{ on, attrs }">
                    <div class="d-flex align-center justify-center width100 height100"
                         v-bind="attrs"
                         v-on="on">
                      <span class="font-weight-bold">{{item.dosageBreakfast ? item.dosageBreakfast : '-'}}</span>
                    </div>
                  </template>
                  <dosage-control
                    :dosages="calculateDosageValue(item.dosageBreakfast)"
                    :itemRow="item"
                    :propertyKey="'dosageBreakfast'"
                    @update="updateSupplementDosage"
                  />
                </v-menu>
              </td>
              <td class="name px-3 pt-1 pb-1"><!--:style="{'width': (item.supplementName.length * 8) + 'px'}"-->
                <v-menu
                  :close-on-content-click="true"
                  offset-x
                  max-width="50px"
                >
                  <template v-slot:activator="{ on, attrs }">
                    <div class="d-flex align-center justify-center width100 height100"
                         v-bind="attrs"
                         v-on="on">
                      <span class="font-weight-bold">{{item.dosage10am ? item.dosage10am : '-'}}</span>
                    </div>
                  </template>
                  <dosage-control
                    :dosages="calculateDosageValue(item.dosage10am)"
                    :itemRow="item"
                    :propertyKey="'dosage10am'"
                    @update="updateSupplementDosage"
                  />
                </v-menu>
              </td>
              <td class="name px-3 pt-1 pb-1"><!--:style="{'width': (item.supplementName.length * 8) + 'px'}"-->
                <v-menu
                  :close-on-content-click="true"
                  offset-x
                  max-width="50px"
                >
                  <template v-slot:activator="{ on, attrs }">
                    <div class="d-flex align-center justify-center width100 height100"
                         v-bind="attrs"
                         v-on="on">
                      <span class="font-weight-bold">{{item.dosageLunch ? item.dosageLunch : '-'}}</span>
                    </div>
                  </template>
                  <dosage-control
                    :dosages="calculateDosageValue(item.dosageLunch)"
                    :itemRow="item"
                    :propertyKey="'dosageLunch'"
                    @update="updateSupplementDosage"
                  />
                </v-menu>
              </td>
              <td class="name px-3 pt-1 pb-1"><!--:style="{'width': (item.supplementName.length * 8) + 'px'}"-->
                <v-menu
                  :close-on-content-click="true"
                  offset-x
                  max-width="50px"
                >
                  <template v-slot:activator="{ on, attrs }">
                    <div class="d-flex align-center justify-center width100 height100"
                         v-bind="attrs"
                         v-on="on">
                      <span class="font-weight-bold">{{item.dosage3pm ? item.dosage3pm : '-'}}</span>
                    </div>
                  </template>
                  <dosage-control
                    :dosages="calculateDosageValue(item.dosage3pm)"
                    :itemRow="item"
                    :propertyKey="'dosage3pm'"
                    @update="updateSupplementDosage"
                  />
                </v-menu>
              </td>
              <td class="name px-3 pt-1 pb-1"><!--:style="{'width': (item.supplementName.length * 8) + 'px'}"-->
                <v-menu
                  :close-on-content-click="true"
                  offset-x
                  max-width="50px"
                >
                  <template v-slot:activator="{ on, attrs }">
                    <div class="d-flex align-center justify-center width100 height100"
                         v-bind="attrs"
                         v-on="on">
                      <span class="font-weight-bold">{{item.dosageDinner ? item.dosageDinner : '-'}}</span>
                    </div>
                  </template>
                  <dosage-control
                    :dosages="calculateDosageValue(item.dosageDinner)"
                    :itemRow="item"
                    :propertyKey="'dosageDinner'"
                    @update="updateSupplementDosage"
                  />
                </v-menu>
              </td>
              <td class="name px-3 pt-1 pb-1"><!--:style="{'width': (item.supplementName.length * 8) + 'px'}"-->
                <v-menu
                  :close-on-content-click="true"
                  offset-x
                  max-width="50px"
                >
                  <template v-slot:activator="{ on, attrs }">
                    <div class="d-flex align-center justify-center width100 height100"
                         v-bind="attrs"
                         v-on="on">
                      <span class="font-weight-bold">{{item.dosage7pm ? item.dosage7pm : '-'}}</span>
                    </div>
                  </template>
                  <dosage-control
                    :dosages="calculateDosageValue(item.dosage7pm)"
                    :itemRow="item"
                    :propertyKey="'dosage7pm'"
                    @update="updateSupplementDosage"
                  />
                </v-menu>
              </td>
              <td class="name px-3 pt-1 pb-1"><!--:style="{'width': (item.supplementName.length * 8) + 'px'}"-->
                <v-menu
                  :close-on-content-click="true"
                  offset-x
                  max-width="50px"
                >
                  <template v-slot:activator="{ on, attrs }">
                    <div class="d-flex align-center justify-center width100 height100"
                         v-bind="attrs"
                         v-on="on">
                      <span class="font-weight-bold">{{item.dosageBeforeSleep ? item.dosageBeforeSleep : '-'}}</span>
                    </div>
                  </template>
                  <dosage-control
                    :dosages="calculateDosageValue(item.dosageBeforeSleep)"
                    :itemRow="item"
                    :propertyKey="'dosageBeforeSleep'"
                    @update="updateSupplementDosage"
                  />
                </v-menu>
              </td>
              <td class="notes px-3 pt-1 pb-1" @click.stop>
                <div class="d-flex justify-center align-center width100 height100">
                  <actions :items="actions"
                           v-if="item.userDefined"
                           @action="runAction"
                           :item="item"
                  />
                  <actions :items="globalActions"
                           v-else
                           @action="runAction"
                           :item="item"
                  />
                </div>
              </td>
            </tr>
            <td colspan="100%"
                class="product-supplement"
                :key="index + item.id"
                v-if="expandedRows.includes(item.id)">
              <v-card elevation="2">
                <Table
                  :headers="headersProduct"
                  hideDefaultFooter>

                  <template v-slot:body>
                    <template v-for="sup in item.products.$values">
                      <tr
                        :key="sup.id"
                        :class="{'is-discontinued': sup.discontinued}"
                        class="custom-result-table__dynamic"
                      >
                        <td class="pa-1"><!--:style="{'width': (item.supplementName.length * 8) + 'px'}"-->
                          <div class="d-flex align-center justify-center">
                            <div class="d-flex align-center">
                              {{sup.productNumber}}
                              <v-tooltip top v-if="sup.discontinued">
                                <template v-slot:activator="{ on, attrs }">
                                  <v-icon v-bind="attrs"
                                          v-on="on" color="#F16063" style="padding-left: 3px">mdi-alert-circle</v-icon>
                                </template>
                                <span>The Product is discontinued</span>
                              </v-tooltip>
                            </div>
                          </div>
                        </td>
                        <td class="pa-1">
                          <div class="d-flex align-center justify-center">
                            <div class="d-flex">
                              {{sup.quantity}}
                            </div>
                          </div>
                        </td>
                        <td class="pa-1">
                          <div class="d-flex align-center justify-center">
                            <div class="d-flex">
                              {{sup.quantityTypeName}}
                            </div>
                          </div>
                        </td>
<!--                        <td class="pa-1">
                          <div class="d-flex align-center justify-center">
                            <div class="d-flex">
                              {{sup.discontinued}}
                            </div>
                          </div>
                        </td>-->
                        <td class="pa-1">
                          <div class="d-flex align-center justify-center">
                            <div class="d-flex">
                              {{sup.dosesPerBottle}}
                            </div>
                          </div>
                        </td>
                        <td class="pa-1">
                          <div class="d-flex align-center justify-center">
                            <div class="d-flex">
                              {{sup.dosageSuffixName}}
                            </div>
                          </div>
                        </td>
                        <td class="pa-1">
                          <div class="d-flex align-center justify-center">
                            <div class="d-flex">
                              ${{sup.cost}}
                            </div>
                          </div>
                        </td>
                        <td class="pa-1">
                          <div class="d-flex align-center justify-center">
                            <div class="d-flex">
                              ${{sup.listPrice}}
                            </div>
                          </div>
                        </td>
                        <td class="pa-1">
                          <div class="d-flex align-center justify-center">
                            <div class="d-flex">
                              {{sup.overrideListPrice ? '$ ' + sup.overrideListPrice : '-'}}
                            </div>
                          </div>
                        </td>
                        <td class="pa-1" @click.stop>
                          <div class="d-flex align-center width100 height100">
                            <actions :items="productActions"
                                     @action="runAction"
                                     :item="sup"
                                     :additionalParams="item"
                            />
                          </div>
                        </td>
                      </tr>
                    </template>
                    <tr v-if="item.userDefined">
                      <td colspan="7" style="text-align: end;padding: 10px;">
                        <Button btn-bg="dark-green" :styles="{'border-radius': '8px'}"
                                @click.native="addProduct(item)">
                          <span style="color: #FFFFFF">
                            <v-icon>mdi-plus</v-icon>
                            Add product
                          </span>
                        </Button>
                      </td>
                    </tr>
                  </template>

                </Table>
              </v-card>
            </td>
          </template>
        </template>
        <template v-slot:footer>
          <div class="footer pl-5 d-flex align-center">
            <div class="row-per-page d-flex align-center flex-grow-1">
              <span class="row-per-page__lable mr-4">Rows per page: </span>
              <Select class="row-per-page__list" radius="8" color="#B5B5BD" :items="rowPerPageItems" :value="rowPerPageItems[2]" v-model="currentPageSize" @change="changeItermPerPage" />
            </div>
            <div class="flex-grow-2">
              <pagination v-if="pageCountSupplements" :value="page" :length="pageCountSupplements" :totalVisible="7" @input="changePagination" />
            </div>
            <div class="d-flex align-center justify-end flex-grow-1 mr-6" style="column-gap: 1rem">
              <v-switch class="py-0 my-0" color="#008C89" inset label="Default Supplements" @change="showOnlyDefaultSupplement" />
              <v-switch class="py-0 my-0" color="#008C89" inset label="Show Only My" @change="showMySupplements" />
              <v-switch class="py-0 my-0" color="#008C89" inset label="Show discontinued" @change="showDiscontinuedSupplements" />
            </div>
          </div>
        </template>
      </Table>
      <div v-if="loading" class="loading d-flex justify-center px-2 py-2 align-center">
        <v-progress-circular
          indeterminate
          color="#008C89"
        ></v-progress-circular>
      </div>
    </div>
    <confirm v-if="dialogs.confirm.isOpen" :query="query"/>
    <add-supplement-dialog :componentFrom="'tab-supplement'" v-if="dialogs.addSupplementDialog.isOpen || dialogs.editSupplementDialog.isOpen" :query="query" />
    <add-supplement-product v-if="dialogs.addSupplementProduct.isOpen || dialogs.editSupplementProduct.isOpen" :query="query" />
    <edit-global-supplement-dialog v-if="dialogs.editGlobalSupplementDialog.isOpen" :query="query"/>
  </div>
</template>

<script>
import { mapActions, mapMutations, mapState } from 'vuex'
import Pagination from '@/mixins/Pagination'
import Input from '@/components/elements/Input'
import Button from '@/components/elements/Button'
import Table from '@/components/elements/Table'
import pagination from '@/components/layouts/pagination'
import Select from '@/components/elements/Select'
import actions from '@/components/layouts/actions'
import confirm from '@/components/layouts/dialogs/confirm-dialog'
import AddSupplementDialog from '@/components/layouts/dialogs/add-supplement-dialog'
import EditGlobalSupplementDialog from '@/components/layouts/dialogs/edit-global-supplement-dialog'
import AddSupplementProduct from '@/components/layouts/dialogs/add-supplement-product'
import DosageControl from '@/components/elements/dosage-control'

const GET_ALL_SUPPLEMENTS = 'getSupplements'
const IS_SLOTABLE = ['name', 'notes', 'active']

export default {
  name: 'tab-supplements',
  components: { AddSupplementProduct, Input, Button, Table, pagination, Select, actions, confirm, AddSupplementDialog, EditGlobalSupplementDialog, DosageControl },
  mixins: [Pagination],
  data: () => ({
    searchedText: '',
    rowIsHovered: false,
    itemsPerPage: 15,
    page: 1,
    expandedRows: [],
    isStartCreateNewSupProduct: false,
    loading: true,
    currentPageSize: 15,
  }),
  async mounted () {
    this.appSettings()
    this.loading = true
    // this.getSupplements(this.query)
    await this.getBrands()
  },
  methods: {
    ...mapMutations('dialogs', ['TOGGLE_DIALOG']),
    ...mapMutations('supplements', ['UPDATE_DATA']),
    ...mapMutations('supplements', ['SET_DATA']),
    ...mapActions('supplements', ['getSupplements', 'clearDosageCustomization', 'updateSupplement']),
    ...mapActions('brands', ['getBrands']),
    ...mapActions(['appSettings']),
    filterBox ($event) {
      const columns = []
      columns.push($event)
      this.addUrlParam({ columns })
      this.getSupplements(this.query)
    },
    resetFilter ($event) {
      const query = Object.assign({}, this.query)
      const columns = query.columns.filter(el => el.name !== $event)
      this.addUrlParam({ columns })
      this.getSupplements(this.query)
    },
    refresh () {
      // const query = Object.assign({}, this.query)
      this.searchedText = ''
      delete this.query.search
      delete this.query.columns
      this.page = 1
      this.query.PageSize = this.currentPageSize
      this.query.PageIndex = 0
      this.addUrlParam({ ...this.query })
      this.$nextTick(() => {
        this.getSupplements(this.query)
      })
    },
    goToSettings () {
      if (this.$route.name === 'Settings') {
        this.TOGGLE_DIALOG({ name: 'trialSubscriptionDialog', isOpen: false, root: true })
      } else {
        this.TOGGLE_DIALOG({ name: 'trialSubscriptionDialog', isOpen: false, root: true })
        this.$router.push({ name: 'Settings' })
      }
    },
    inputSearch ($event) {
      this.query.PageIndex = 0
      this.query.PageSize = this.currentPageSize
      this.searchedText = $event
      this.addUrlParam({ search: { searchType: 1, value: $event } })
      this.page = 1
    },
    highlightSearchedText (currentText, searchedText) {
      if (currentText.toLowerCase().includes(searchedText.toLowerCase())) {
        const newStr = currentText.toLowerCase().replace(searchedText.toLowerCase(), `<span style="color: #7cdbc1;">${searchedText}</span>`)
        return newStr
      }
      return currentText
    },
    dynamicSupplementNameMaxWidth (array) {
      let countPixel = 0
      for (let i = 0; i < array.length; i++) {
        countPixel += array[i] ? 13 : 0
      }
      return countPixel
    },
    toggleRow (id) {
      const index = this.expandedRows.indexOf(id)
      if (index > -1) {
        this.expandedRows.splice(index, 1)
      } else {
        this.expandedRows = []
        this.expandedRows.push(id)
      }
    },
    calculateDosageValue(val) {
      let result = []
      if ((val - 2) >= 1) {
       for(let i = val - 2; i <= val + 3; i++) {
         result.push({id: i,active: false,value: i,text: `${i}`})
       }
        result.push({
          id: 6,
          active: false,
          value: null,
          text: 'x'
        })
      } else {
        result = [
          {
            id: 0,
            active: false,
            value: 0.5,
            text: '0.5'
          },
          {
            id: 1,
            active: false,
            value: 1,
            text: '1'
          },
          {
            id: 2,
            active: false,
            value: 2,
            text: '2'
          },
          {
            id: 3,
            active: true,
            value: 3,
            text: '3'
          },
          {
            id: 4,
            active: false,
            value: 4,
            text: '4'
          },
          {
            id: 5,
            active: false,
            value: 5,
            text: '5'
          },
          {
            id: 6,
            active: false,
            value: null,
            text: 'x'
          }
        ]
      }
      return result
    },
    async updateSupplementDosage ({ data, keyProp }) {
      const payload = {
        id: data.form.id,
        name: data.form.name,
        dosageUponArising: data.form.dosageUponArising ? data.form.dosageUponArising : 0,
        dosageBreakfast: data.form.dosageBreakfast ? data.form.dosageBreakfast : 0,
        dosage10am: data.form.dosage10am ? data.form.dosage10am : 0,
        dosageLunch: data.form.dosageLunch ? data.form.dosageLunch : 0,
        dosage3pm: data.form.dosage3pm ? data.form.dosage3pm : 0,
        dosageDinner: data.form.dosageDinner ? data.form.dosageDinner : 0,
        dosage7pm: data.form.dosage7pm ? data.form.dosage7pm: 0,
        dosageBeforeSleep: data.form.dosageBeforeSleep ? data.form.dosageBeforeSleep : 0,
        isCustomized: data.form.isCustomized
      }

      const result = await this.updateSupplement(payload)

      this.updateDataStore(result, data.form.id)
    },

    updateDataStore (result, id) {
      const payload = {
        dosageUponArising: result.record.dosageUponArising,
        dosageBreakfast: result.record.dosageBreakfast,
        dosage10am: result.record.dosage10am,
        dosageLunch: result.record.dosageLunch,
        dosage3pm: result.record.dosage3pm,
        dosageDinner: result.record.dosageDinner,
        dosage7pm: result.record.dosage7pm,
        dosageBeforeSleep: result.record.dosageBeforeSleep,
        isCustomized: result.record.isCustomized
      }
      this.UPDATE_DATA({payload, id})
    },
    runAction (val) {
      val.params.action(val.id, val)
      // this.editResult(val.id)
    },
    getQuantityBySupplementProductId (resultProducts, productId) {
      const product = resultProducts.find(item => item.supplementProductId === productId)
      return product && product.quantity ? product.quantity : '0'
    },
    changePagination ($event) {
      this.page = $event
      this.addUrlParam({
        PageIndex: $event === 0 ? 0 : $event - 1,
        PageSize: this.itemsPerPage
      })
      this.getSupplements(this.query)
    },
    changeItermPerPage ($event) {
      this.itemsPerPage = $event
      this.currentPageSize = $event
      if ($event !== this.query.PageSize) {
        this.addUrlParam({
          PageIndex: 0, // this.page,
          PageSize: $event === 'All' ? '-1' : $event
        })
        this.query.PageIndex = 0
        this.getSupplements(this.query)
        this.page = 1
      }
    },
    showDiscontinuedSupplements ($event) {
      this.query.PageIndex = 0
      this.query.PageSize = this.currentPageSize
      this.addUrlParam({ discontinued: $event })
      this.page = 1
      this.getSupplements(this.query)
    },
    showMySupplements ($event) {
      this.query.PageIndex = 0
      this.query.PageSize = this.currentPageSize
      this.addUrlParam({ onlyMy: $event })
      this.page = 1
      this.getSupplements(this.query)
    },
    showOnlyDefaultSupplement ($event) {
      this.query.PageIndex = 0
      this.query.PageSize = this.currentPageSize
      this.addUrlParam({ onlyDefault: $event })
      this.page = 1
      this.getSupplements(this.query)
    },
    async deleteSupplement (id) {
      await this.TOGGLE_DIALOG({
        id,
        name: 'confirm',
        isOpen: true,
        description: 'Are you sure you want to delete the supplement?',
        action: 'supplements/deleteSupplement',
        afterAction: {
          name: 'supplements/getSupplements',
          protocol: this.query
        },
        title: 'Confirm deleting the supplement'
      })
    },
    editSupplementDialog (id, data) {
      this.TOGGLE_DIALOG({ id, name: 'editSupplementDialog', isOpen: true, title: 'Edit supplement' })
    },
    editGlobalSupplementDialog (id, data) {
      this.TOGGLE_DIALOG({ id, name: 'editGlobalSupplementDialog', isOpen: true, title: 'Edit Supplement Notes', data: data.data })
    },
    async showConfirmDialog (id) {
      await this.TOGGLE_DIALOG({
        id,
        name: 'confirm',
        isOpen: true,
        description: 'Are you sure you want clear dosage customization?',
        action: 'supplements/clearDosageCustomization',
        afterAction: {
          name: 'supplements/getSupplements',
          protocol: this.query
        },
        title: 'Confirm clearing dosage'
      })
    },
    addProduct (data) {
      const { id } = data
      this.TOGGLE_DIALOG({
        id,
        name: 'addSupplementProduct',
        isOpen: true,
        title: 'Add product',
        data: data,
        action: 'supplements/addProduct',
        afterAction: {
          name: 'supplements/getSupplements',
          protocol: this.query
        }
      })
    },
    editProduct (id, data) {
      this.TOGGLE_DIALOG({
        id: data.data.supplementId,
        name: 'editSupplementProduct',
        isOpen: true,
        title: 'Edit product',
        data: data,
        action: 'supplements/updateProduct',
        afterAction: {
          name: 'supplements/getSupplements',
          protocol: this.query
        }
      })
    },
    async deleteProduct (id, data) {
      await this.TOGGLE_DIALOG({
        id,
        name: 'confirm',
        isOpen: true,
        description: 'Are you sure you want to delete the product?',
        action: 'supplements/deleteProduct',
        afterAction: {
          name: 'supplements/getSupplements',
          protocol: this.query
        },
        title: 'Confirm deleting the product'
      })
    }
  },
  computed: {
    ...mapState('supplements', {
      headers (state) {
        const headers = Object.assign([], state.supplements?.headers?.$values)
        headers.push({ text: 'Actions', value: 'actions', isSlotable: true, width: '1%', sortable: false })
        return headers?.map(el => ({
          ...this._.omit(el, ['skip', '$id']),
          isSlotable: IS_SLOTABLE.includes(el.value),
          width: (this._.isNumber(el.width)) ? el.width + '%' : el.width
        }))
      },
      itemsSupplements (state) {
        const items = Object.assign([], state.supplements?.data?.$values)
        return items
      }
    }),
    /* headersProduct () {
      return [
        {
          text: 'Product Name',
          value: 'productName',
          isSlotable: true,
          isReplacingSpace: 0
        },
        {
          text: 'Size',
          value: 'size',
          isSlotable: true,
          isReplacingSpace: 0
        },
        {
          text: 'Unit price',
          value: 'unitPrice',
          isSlotable: true,
          isReplacingSpace: 0
        },
        {
          text: 'Q-TY',
          value: 'quantity',
          isSlotable: true,
          isReplacingSpace: 0
        },
        {
          text: 'Total price',
          value: 'totalPrice',
          isSlotable: true,
          isReplacingSpace: 0
        }
      ]
    }, */
    headersProduct () {
      return [
        {
          text: 'Product Number',
          value: 'productNumber',
          isSlotable: true,
          isReplacingSpace: 0
        },
        {
          text: 'Quantity',
          value: 'quantity',
          isSlotable: true,
          isReplacingSpace: 0
        },
        {
          text: 'Quantity Type Name ',
          value: 'quantityTypeName',
          isSlotable: true,
          isReplacingSpace: 0
        },
        /* {
          text: 'Discontinued',
          value: 'discontinued',
          isSlotable: true,
          isReplacingSpace: 0
        }, */
        {
          text: 'Doses per bottle',
          value: 'dosesPerBottle',
          isSlotable: true,
          isReplacingSpace: 0
        },
        {
          text: 'Dosage Suffix Name',
          value: 'dosageSuffixName',
          isSlotable: true,
          isReplacingSpace: 0
        },
        {
          text: 'Cost',
          value: 'cost',
          isSlotable: true,
          isReplacingSpace: 0
        },
        {
          text: 'List Price',
          value: 'listPrice',
          isSlotable: true,
          isReplacingSpace: 0
        },
        {
          text: 'Override List Price',
          value: 'overrideListPrice',
          isSlotable: true,
          isReplacingSpace: 0
        },
        {
          text: 'Actions',
          value: 'actions',
          isSlotable: true,
          width: '1%',
          sortable: false
        }
      ]
    },
    pageCountSupplements () {
      // const division = this.total > 100 ? 100 : this.itemsPerPage
      return (this.itemsPerPage) ? Math.ceil(this.total / this.itemsPerPage) : 1 // (this.itemsPerPage) ? Math.ceil(this.total / division) : 1
    },
    rowPerPageItems: () => ([5, 10, 15, 20, 'All']),
    actions () {
      return [
        { icon: 'mdi-pencil', color: 'primary', name: 'Edit', action: this.editSupplementDialog },
        { icon: 'mdi-trash-can-outline', color: 'red', name: 'Delete', action: this.deleteSupplement }
      ]
    },
    globalActions () {
      return [
        { icon: 'mdi-pencil', color: 'primary', name: 'Edit', action: this.editGlobalSupplementDialog },
        { icon: 'mdi-cancel', color: '#cab653', name: 'Clear dosage customization', action: this.showConfirmDialog }
      ]
    },
    productActions () {
      return [
        { icon: 'mdi-pencil', color: 'primary', name: 'Edit', action: this.editProduct, higherRights: true },
        { icon: 'mdi-trash-can-outline', color: 'red', name: 'Delete', action: this.deleteProduct, higherRights: false }
      ]
    },
    supplements () {
      return this.$store.state?.supplements?.supplements
    },
    getAllFunc: () => GET_ALL_SUPPLEMENTS,
    dialogs () {
      return this.$store.state.dialogs
    },
    total (state) {
      return state.supplements?.pagination?.total
    },
    supplementsListIsLoaded () {
      return this.$store.state.supplements?.supplementsListIsLoaded
    },
  },
  beforeDestroy () {
    this.SET_DATA(['supplementsListIsLoaded', false])
  },
  watch: {
    supplementsListIsLoaded (val) {
      if (val) {
        this.$refs.supplementTable.$el.classList.add('paddingBottom')
        this.loading = false
      } else {
        this.loading = true
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.supplement {
  margin-top: -15px;
  .loading {
    height: 100%;
    min-height: 100px;
    position: absolute;
    width: 100%;
    top: 0;
    background: rgb(213 213 213 / 50%);
    border-radius: 16px;
  }
  ::v-deep .v-dialog {
    overflow: hidden;
    //height: 300px!important;
    .v-card {
      padding: 0!important;
      height: 685px;
      &__text {
        padding: 0 0 0!important;
      }
      &__actions {
        padding-top: 0!important;
      }
      .v-input {
        &__control {
          .v-messages {
            display: none!important;
          }
        }
      }
    }
  }
  ::v-deep .confirm-dialog {
    .v-dialog {
      overflow: hidden;
      height: 220px!important;
      .v-card {
        &__actions {
          div {
            margin-top: 10px;
          }
        }
      }
    }
  }
  .protocols {
    &__total {
      font-size: 20px;
      font-weight: 600;
      span.num {
        color: $dark-green;
      }
    }
  }
  .label {
    font-size: 14px;
    font-weight: 500;
    color: #425466
  }
  .main-row {
    display: flex;
    flex-wrap: wrap;
    width: 100%;
  }
  .title {
    font-weight: 600;
    font-size: 28px;
    text-align: center;
    color: #27272E;
  }
  .supplement-table {
    width: 100%;
    ::v-deep .v-card {
      height: auto!important;
    }
    ::v-deep .v-data-table-header th + th {
      border-left:thin solid #0000001f !important;
    }
    ::v-deep th {
      height: 38px!important;
    }
    ::v-deep td {
      border-left:thin solid #0000001f !important;
      border-bottom:thin solid #0000001f !important;
    }
    &__row {
      cursor: pointer;
      .name, .notes {}
    }
    &__row:hover {
      background-color: #008c8921!important;
    }
    &__row:last-child {
      td {
        border-bottom:thin solid #0000001f
      }
    }
    &__row.is-discontinued {
      background-color: #EDEDED!important;
    }
    &__row.is-default {
      background-color: #E3F7EA!important;
    }
    &__row.is-customized {
      background-color: #F7FEEC!important;
    }
    .product-supplement {
      padding: 10px 0;
      ::v-deep .v-card {
        background: #EDF2F7;
        padding: 16px;
        .table {
          padding: 0px!important;
          .v-data-table .v-data-table__wrapper {
            table {
              .v-data-table-header th {
                //div {
                //  margin-left: 50%;
                //  transform: translateX(-50%);
                //}
              }
              td + td {
                border-left:thin solid #0000001f;
              }
              td {
                border-bottom:thin solid #0000001f;
              }
              tr:last-child {
                td {
                  border-bottom:unset!important;
                }
              }
              tr.is-discontinued {
                background-color: #eaeaea!important;
              }
              .sub-row {
                .table {
                  padding: 0!important;
                }
                th {
                  padding-top: 15px;
                  padding-bottom: 15px;
                }
                tr:last-child {
                  td {
                    border-bottom: unset;
                  }
                }
                td {
                  //border-bottom: unset;
                }
              }
            }
          }
        }
      }
    }
  }
  .footer {
    height: 60px;
    ::v-deep .v-input {
      .v-messages {
        //display: none;
      }
    }
    .row-per-page {
      font-size: 14px;
      color: #718096;
      &__list {
        width: 60px;
      }
      ::v-deep .v-menu__content {
        min-width: 62px !important;
        .v-list-item {
          &__title {
            display: flex;
            justify-content: center;
          }
        }
      }
      ::v-deep .v-input {
        padding-top: 0px!important;
        margin-top: 0px!important;
        &__slot {
          margin: 0 !important;
        }
        &__append-inner {
          margin-left: 0;
          padding-left: 0;
          margin-top: 2px !important;
        }
      }
      ::v-deep .v-select {
        input {
          display: none;
        }
        &__selection {
          font-size: 14px;
          color: #718096;
        }
        &__selections {
          flex: unset !important;
          line-height: 17px;
          margin-left: 5px;
        }
      }
      .select {
        ::v-deep .v-input {
          &__control {
            .v-text-field__details {
              display: none!important;
            }
          }
        }
      }
    }
    ::v-deep .v-input {
      &__slot {
        margin: 0 !important;
      }
    }
  }
  .paddingBottom {
    padding-bottom: 200px!important;
  }
}
</style>
