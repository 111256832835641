<template>
  <div class="breadcrumbs-layout ml-4">
    <div class="breadcrumbs-block" :class="{ 'disabled-link': $route.name === item.name }" v-for="item in breadcrumbs" :key="item.path" @click="goTo(item)">
      {{item.name}}
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex'

export default {
  name: 'Breadcrumbs',
  data: () => ({
    breadcrumbs: []
  }),
  watch: {
    $route () {
      this.getRoute()
    }
  },
  methods: {
    getRoute () {
      this.breadcrumbs = this.$route.matched
      this.breadcrumbs.map(item => {
        if (item.name === this.$route.name) {
          item.systemCustomProp = this.$route
        }
      })
    },
    goTo (item) {
      if (!this.subscriptionFeatures.expired) {
        if (this.$route.name !== item.name) {
          if (item.systemCustomProp) {
            this.$router.push({ path: item.systemCustomProp.path, query: this.$route.query })
          } else {
            this.$router.push({ path: item.path ? item.path : '/', query: this.$route.query })
          }
        }
      }
    }
  },
  created () {
    this.getRoute()
  },
  computed: {
    ...mapState({
      subscriptionFeatures: state => state.subscription
    })
  }
}
</script>

<style scoped lang="scss">
.breadcrumbs-layout {
  display: flex;
  flex-wrap: wrap;
  column-gap: 1rem;
  justify-content: flex-start;
  align-items: center;
  width: 100%;
  .breadcrumbs-block {
    background: #f7fafc;
    color: #425466;
    padding: 8px 15px;
    border-radius: 8px;
    font-weight: 600;
    font-size: 12px;
    line-height: 12px;
    cursor: pointer;
  }
  .disabled-link {
    color: #aaacbe;
    cursor: default;
  }
}
</style>
