<template>
  <div class="image-cropper" @wheel="handlerScroll">
    <img :src="src" ref="img" alt="img" style="width: 100%; height: 100%;object-fit: cover;">
  </div>
</template>

<script>
import Cropper from 'cropperjs'
import throttle from 'lodash/throttle'

export default {
  name: 'ImageCropper',
  props: {
    src: {
      type: String
    },
    avatarSize: [Number, String]
  },
  data: () => ({
    cropper: {},
    destination: {},
    img: {},
    currentSize: 0
  }),
  mounted () {
    this.img = this.$refs.img
    this.cropper = new Cropper(this.img, {
      aspectRatio: 1,
      minCropBoxWidth: 256,
      minCropBoxHeight: 256,
      viewMode: 3,
      dragMode: 'move',
      background: false,
      zoomOnTouch: false,
      zoomOnWheel: false,
      cropBoxMovable: false,
      cropBoxResizable: false,
      crop: () => {
        const canvas = this.cropper.getCroppedCanvas()
        this.destination = canvas.toDataURL('image/png')
        this.$emit('resultAvatar', this.destination)
      }
    })
  },
  methods: {
    handlerScroll (e) {
      if (e.deltaY < 0) {
        console.log('scrolling up')
        if (this.currentSize < 2) {
          this.throttleDownUpdateSize()
        }
      } else if (e.deltaY > 0) {
        console.log('scrolling down')
        if (this.currentSize > 0) {
          this.throttleUpUpdateSize()
        }
      }
    },
    throttleDownUpdateSize: throttle(function () {
      this.updateSizeDown()
    }, 1000),
    throttleUpUpdateSize: throttle(function () {
      this.updateSizeUp()
    }, 1000),
    updateSizeDown () {
      this.currentSize = this.currentSize + 0.5
      this.cropper.zoom(0.5)
      this.$emit('updateAvatarSize', this.currentSize)
    },
    updateSizeUp () {
      this.currentSize = this.currentSize - 0.5
      this.cropper.zoom(-0.5)
      this.$emit('updateAvatarSize', this.currentSize)
    }
  },
  watch: {
    src (val) {
      if (val) {
        this.cropper.replace(val)
      }
    },
    'cropper.canvasData.width' (val) {
      console.log(val)
    },
    avatarSize (val) {
      if (val > this.currentSize) {
        this.cropper.zoom(0.5)
        this.currentSize = val
      }
      if (this.currentSize > val) {
        this.cropper.zoom(-0.5)
        this.currentSize = val
      }
    }
  },
  beforeDestroy () {
    this.cropper.destroy()
  }
}
</script>

<style scoped lang="scss">
.image-cropper {
  width: 256px;
  height: 256px;
  object-fit: fill;
}
</style>
