<template>
  <div class="drawer">
    <v-navigation-drawer v-model="mini" app permanent :class="{collapseMenu: !mini}"><!--v-bind="$props" :class="{collapseMenu: !mini}" :mini-variant.sync="mini" expand-on-hover-->

      <v-list-item link>
        <v-list-item-title class="drawer__logo d-flex justify-center">
          <Img v-if="!mini" :src="getImgWithExt(['images', 'logo_drawer_short', 'svg'])" width="40" height="40" />
          <template v-else>
            <template v-if="subscriptionFeatures.productName && subscriptionFeatures.productName.includes('Reports Only')">
              <Img :src="getImgWithExt(['images', 'logo_drawer_reports-only', 'svg'])" width="156" />
            </template>
            <template v-else-if="subscriptionFeatures.productName && subscriptionFeatures.productName.includes('Survey Only')">
              <Img :src="getImgWithExt(['images', 'logo_drawer_survey-only', 'svg'])" width="156" />
            </template>
            <template v-else-if="subscriptionFeatures.productName && subscriptionFeatures.productName.includes('Patient Portal')">
              <Img :src="getImgWithExt(['images', 'logo_drawer_complete', 'svg'])" width="156" />
            </template>
            <template v-else>
              <Img :src="getImgWithExt(['images', 'logo_drawer', 'svg'])" width="156" />
            </template>
          </template>
        </v-list-item-title>
      </v-list-item>

      <v-list nav dense>
        <template v-for="(item, i) in filteredItemsOnlyAuth"><!--items-->
          <v-list-item
            :key="i" @click="goToByLink(item)"
            :class="{'active-tab': $route.name && (item.name.toLowerCase() === $route.name.toLowerCase())}"
            :disabled="subscriptionFeatures.expired && item.name !== 'Collapse'"><!-- v-if="isVisible(item)"-->
            <v-list-item-icon class="align-self-center">
              <template v-if="item.name === 'Patients' && !mini">
                <v-badge
                  style="display: flex"
                  color="#7cdbc1"
                  v-if="totalCountPatients && totalCountPatients.pagination"
                  :value="totalCountPatients.pagination.total"
                  :content="totalCountPatients.pagination.total"
                  overlap
                  offset-x="10"
                  offset-y="25"
                >
                  <v-icon color="#A0AEC0" :class="{rotateIcon: !item.link && !mini}">{{ item.icon }}</v-icon>
                </v-badge>
                <v-icon v-else color="#A0AEC0" :class="{rotateIcon: !item.link && !mini}">{{ item.icon }}</v-icon>
              </template>
              <template v-else>
                <v-icon color="#A0AEC0" :class="{rotateIcon: !item.link && !mini}">{{ item.icon }}</v-icon>
              </template>
            </v-list-item-icon>
            <template v-if="item.name === 'Patients'">
              <v-list-item-title style="font-size: 14px; color: #425466" :class="{hideMenuTitles: !mini}">
                {{item.name}}
                <span v-if="totalCountPatients && totalCountPatients.pagination" class="count-patients"> {{totalCountPatients.pagination.total}}</span>
              </v-list-item-title>
            </template>
            <template v-else>
              <v-list-item-title style="font-size: 14px; color: #425466" :class="{hideMenuTitles: !mini}">
                {{item.name}}
              </v-list-item-title>
            </template>
          </v-list-item>
        </template>
<!--        <v-list-item style="cursor: pointer" v-if="userProfile.userType === 'ClinicAdmin'" @click="openTrialSubscriptions">
          <v-list-item-icon class="align-self-center">
            <v-icon>mdi-account-cash</v-icon>
          </v-list-item-icon>
          <v-list-item-title style="font-size: 14px" class="bold&#45;&#45;text" :class="{hideMenuTitles: !mini}">
            Trial
          </v-list-item-title>
        </v-list-item>-->
      </v-list>

    </v-navigation-drawer>
  </div>
</template>

<script>
import { mapMutations, mapState } from 'vuex'

import Image from '@/mixins/Image'
import Img from '@/components/elements/Img'

export default {
  name: 'drawer',
  mixins: [Image],
  props: {
    items: {
      type: Array
    }
  },
  components: {
    Img
  },
  data: () => ({
    mini: true
  }),
  mounted () {
    const { successPayment, tab, modal } = this.$route.query
    if (this.userProfile.firstLogin) {
      this.$router.push('/settings')
    } else if (!this.userProfile.firstLogin && !successPayment && !tab && !this.isAuth) { // orig code !this.userProfile.firstLogin && !successPayment && !this.isAuth
      this.$router.push({ name: 'Home' })
      // new code
    } else if (this.isAuth && tab) {
      if (!modal) {
        this.$router.push(`/settings?tab=${tab}`)
      }
      if (modal) {
        this.$router.push(`/settings?tab=${tab}&modal=${modal}`)
      }
      // new code
    } else if (this.isAuth && !successPayment && sessionStorage.getItem('selectedPage') && this.$route.path !== sessionStorage.getItem('selectedPage')) {
      this.$router.push(sessionStorage.getItem('selectedPage'))
    } else if (this.isAuth && (successPayment === '1' || successPayment === '0')) {
      this.$router.push(`/settings?successPayment=${successPayment}`)
    }
    /* if (sessionStorage.getItem('selectedPage') && this.$route.path !== sessionStorage.getItem('selectedPage')) {
        this.$router.push(sessionStorage.getItem('selectedPage'))
      } */
  },
  methods: {
    ...mapMutations('dialogs', ['TOGGLE_DIALOG']),
    isVisible (item) {
      return ((!item.onlyAuth) || (item.onlyAuth === this.isAuth))
    },
    goToByLink (item) {
      if (item.name === 'User Guide') {
        window.open(item.link, '_blank')
        return
      }
      if (!this.subscriptionFeatures.expired) {
        if (item.link && item.link !== this.$route.path) {
          this.$router.push(item.link)
          this.saveCurrentPage(item.link)
        }
      }
      if (!item.link) {
        this.toggleCollapseMenu()
      }
    },
    saveCurrentPage (page) {
      sessionStorage.setItem('selectedPage', page)
    },
    toggleCollapseMenu () {
      this.mini = !this.mini
      this.$emit('toggleDrawer', this.mini)
    },
    openTrialSubscriptions () {
      console.log('openTrial')
      this.TOGGLE_DIALOG({ name: 'trialSubscriptionDialog', isOpen: true, root: true })
    }
  },
  computed: {
    ...mapState({
      isAuth: state => state.isAuth,
      totalCountPatients: state => state.patients.patients,
      userProfile: state => state.userProfile,
      subscriptionFeatures: state => state.subscription
    }),
    filteredItemsOnlyAuth () {
      return this.items.filter(item => {
        return ((!item.onlyAuth) || (item.onlyAuth === this.isAuth))
      })
    }
  }
}
</script>

<style lang="scss" scoped>
  .drawer {
    ::v-deep .v-navigation-drawer {
      width: 180px!important;
      &__content {
        overflow-y: hidden;
        > .v-list-item {
          padding: 0;
          margin: 0;
          cursor: pointer;
          height: 90px;
          max-height: 90px;
        }
        .v-list {
          padding: 0 !important;
          margin: 0 !important;
          .v-list-item {
            height: 54px;
            max-height: 54px;
            margin: 0 !important;
            padding: 0 !important;
            position: relative;
            &:hover {
              &::after {
                content: '' !important;
                position: absolute !important;
                left: 0 !important;
                height: 100% !important;
                width: 2px !important;
                background-color: $dark-green !important;
              }
            }
            &--active {
              color: $dark-green;
              &::after {
                content: '' !important;
                position: absolute !important;
                left: 0 !important;
                height: 100% !important;
                width: 2px !important;
                background-color: $dark-green !important;
              }
              .v-list-item__title {
                color: $dark-green !important;
                font-weight: 500;
              }
              &::before {
                opacity: 0;
              }
            }
            &__icon {
              padding: 0 !important;
              margin: 0 !important;
              width: 90px;
              height: 100%;
              justify-content: center;
            }
          }
          .v-list-item.active-tab {
            .v-list-item__icon {
              .v-icon {
                color: $dark-green;
              }
            }
            .v-list-item__title {
              color: $dark-green;
            }
          }
        }
      }
    }
    .count-patients {
      background: #7cdbc1;
      border-radius: 50%;
      padding: 2px 6px;
      display: inline-block;
      color: white;
    }
    .collapseMenu {
      width: 56px !important;
    }
    .hideMenuTitles {
      position: absolute !important;
      height: 1px;
      width: 1px;
      overflow: hidden;
      clip: rect(1px, 1px, 1px, 1px);
      white-space: nowrap;
      display: initial;
    }
    .rotateIcon {
      transform: rotateZ(180deg);
    }
    .active-tab {
      background-color: #008c8933;
      ::v-deep .v-list-item__icon {
        .v-icon {
          color: #008C89!important;
        }
      }
    }
  }
</style>
