import Vue from 'vue'

const state = () => ({
  icons: null,
})

const getters = {}

const actions = {
 async getStaticImages ({ commit }, params) {
    const result = await Vue.$http.get(`/organization/avatars?target=${params.id}`)


    if (result?.status === 200) {
      console.log(result);

      commit('SET_DATA', ['icons', result.data.data.$values])

      return result.data.data.$values
    }
  },
}

const mutations = {
  SET_DATA: (state, payload) => {
    const [property, value] = payload
    Vue.set(state, property, value)
  }
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}
