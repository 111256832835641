<template>
  <div class="view-patient">
    <!--<v-container v-if="patient" class="ml-4"></v-container>-->
    <v-row v-if="patient" class="ml-4">
      <v-col cols="9">
        <card :isDisabled="!patient.active" class="custom-card">
          <template v-slot:body>
            <v-row>
              <v-col cols="3" class="px-0 py-0">
                <div class="d-flex flex-column align-center">
                  <div class="view-patient__image">
                    <v-avatar size="70" v-if="patient.profileAvatar">
                      <v-img :src="patient.profileAvatar" alt="profile-avatar" />
                    </v-avatar>
                  </div>
                  <div class="view-patient__fullname py-2">
                    {{patient.firstName}} {{patient.lastName}}
                  </div>
                  <div class="view-patient__joined">
                    Joined {{$moment(patient.createdOn).format('MM/DD/YYYY')}}
                  </div>
                  <Button @click.native="sendEmailToPatient(patient.email)" class="mt-2" outlined color="#718096" height="36" :styles="{ 'border-radius': '8px' }">
                    <v-icon class="mr-2">mdi-email-outline</v-icon>Send Email
                  </Button>
                  <div class="additional-info-icons">
                    <v-tooltip top v-if="patient.vegetarian">
                      <template v-slot:activator="{ on, attrs }">
                        <div v-bind="attrs" v-on="on" class="mr-1 mt-1" style="width: 25px">
                          <Img :src="getImgWithExt(['icons', 'vegan_icon', 'svg'])" />
                        </div>
                      </template>
                      <span>Patient is Vegetarian</span>
                    </v-tooltip>
                    <v-tooltip top v-if="patient.glutenFree" >
                      <template v-slot:activator="{ on, attrs }">
                        <div v-bind="attrs" v-on="on" class="mr-1 mt-1" style="width: 25px">
                          <Img :src="getImgWithExt(['icons', 'gluten_icon', 'svg'])" />
                        </div>
                      </template>
                      <span>Patient is Gluten Free Dietary</span>
                    </v-tooltip>
                    <v-tooltip top v-if="patient.registerPortal" >
                      <template v-slot:activator="{ on, attrs }">
                        <div v-bind="attrs" v-on="on" class="mr-1 mt-1" style="width: 25px">
                          <Img :src="getImgWithExt(['icons', 'portal_regestreted_icon', 'svg'])" />
                        </div>
                      </template>
                      <span>Patient is registered on Patient Portal</span>
                    </v-tooltip>
                    <v-tooltip top v-if="patient.lead" >
                      <template v-slot:activator="{ on, attrs }">
                        <div v-bind="attrs" v-on="on" class="mt-1" style="width: 25px">
                          <v-icon color="primary">mdi-human-queue</v-icon>
                        </div>
                      </template>
                      <span>Patient has leads</span>
                    </v-tooltip>
                  </div>
                  <div class="patient-link" v-if="patient.portal">
                    <v-row class="mt-0">
                      <v-col>
                        <a style="text-decoration: none; color: #008c89" :href="`${patient.domain}/redirect/${patient.id}/${patient.token}`" target="_blank">Login to Patient Portal</a>
                      </v-col>
                    </v-row>
                  </div>
                </div>
              </v-col>
              <v-col cols="9">
                <v-container fluid>
                  <v-row style="column-gap: 0.4rem;">
                    <v-col class="px-0 py-0">
                      <div class="label">Phone number</div>
                      <div class="text">{{patient.homePhone}}</div>
                    </v-col>
                    <v-col class="px-0 py-0">
                      <div class="label">E-mail</div>
                      <div class="text">{{patient.email}}</div>
                    </v-col>
                    <v-col class="px-0 py-0" style="text-align: center;">
                      <Button
                        btn-bg="dark-green"
                        v-if="patient.statusCode === 'NeedSurvey' || patient.statusCode === 'NeedReeval'"
                        @click.native.stop="TOGGLE_DIALOG({ id: $route.params.id, name: 'addVisit', title:'Add new evaluation date', isOpen: true, data: patient })"
                        :styles="{ 'border-radius': '8px' }"
                      >
                        <v-icon style="color:#FFFFFF;">mdi-plus</v-icon>
                        <span style="color: #FFFFFF">Evaluation</span>
                      </Button>
                    </v-col>
                    <v-col class="px-0 py-0">
                      <Button color="#E4ECF7" :styles="{ 'border-radius': '8px' }" @click.native="edit">
                        <v-icon class="mr-2" color="#505780">mdi-pencil-outline</v-icon>Edit
                      </Button>
                    </v-col>
                  </v-row>
                  <v-row class="mt-0">
                    <v-col class="my-2"><v-divider /></v-col>
                  </v-row>
                  <v-row class="mt-0">
                    <v-col class="px-0 py-0">
                      <div class="label">Gender</div>
                      <div class="text">{{patient.sex}}</div>
                    </v-col>
                    <v-col class="px-0 py-0">
                      <div class="label">Weight</div>
                      <div class="text">{{patient.weight}} lb</div>
                    </v-col>
                    <v-col class="px-0 py-0">
                      <div class="label">Height</div>
                      <div class="text">{{patient.height}} in</div>
                    </v-col>
                    <v-col class="px-0 py-0">
                      <div class="label">Birthday</div>
                      <div class="text">{{patient.birthDate ? $moment(patient.birthDate).format('MM/DD/YYYY') : '-'}} {{patient.birthDate ? '(age: ' + ($moment().diff(patient.birthDate, 'years')) + ' yo)' : ''}}</div>
                    </v-col>
                  </v-row>
                  <v-row class="mt-0">
                    <v-col class="my-2"><v-divider /></v-col>
                  </v-row>
                  <v-row class="mt-0">
                    <v-col class="px-0 py-0">
                      <div class="label">Street address</div>
                      <div class="text" style="min-width: 210px">{{patient.line1}}</div>
                    </v-col>
                    <v-col class="px-0 py-0">
                      <div class="label">City</div>
                      <div class="text">{{patient.city}}</div>
                    </v-col>
                    <v-col class="px-0 py-0">
                      <div class="label">State</div>
                      <div class="text">{{patient.state}}</div>
                    </v-col>
                    <v-col class="px-0 py-0">
                      <div class="label">Zip Code</div>
                      <div class="text">{{patient.zip}}</div>
                    </v-col>
                  </v-row>
                </v-container>
              </v-col>
            </v-row>
            <!--              <v-row>
                            <v-col>
                              <v-expansion-panels tile flat>
                                <v-expansion-panel>
                                  <v-expansion-panel-header>
                                    <div class="d-flex justify-end label">Full information</div>
                                  </v-expansion-panel-header>
                                  <v-expansion-panel-content>
                                    <v-row no-gutters>
                                      <v-col class="px-0 py-0">
                                        <div class="label">Street address</div>
                                        <div class="text">{{patient.line1}}</div>
                                      </v-col>
                                      <v-col class="px-0 py-0">
                                        <div class="label">City</div>
                                        <div class="text">{{patient.city}}</div>
                                      </v-col>
                                      <v-col class="px-0 py-0">
                                        <div class="label">Zip Code</div>
                                        <div class="text">{{patient.zip}}</div>
                                      </v-col>
                                      <v-col class="px-0 py-0">
                                        <div class="label">State</div>
                                        <div class="text">{{patient.state}}</div>
                                      </v-col>
                                    </v-row>
                                  </v-expansion-panel-content>
                                </v-expansion-panel>
                              </v-expansion-panels>
                            </v-col>
                          </v-row>-->
          </template>
        </card>
        <card :isDisabled="!patient.active" v-if="patient.visits" class="mt-4">
          <template v-slot:body>
            <Tabs :items="tabs"
                  @update:tab="tab"
                  :tab-index="tabIndex"
                  left
                  :isDisabled="!patient.active"
                  :withoutPadding="tabs[componentInd].component === 'chat' || tabs[componentInd].component === 'reports' || tabs[componentInd].component === 'history' ? 'chat-without-padding' : ''">
              <template v-slot:sliderColor>
                <v-tabs-slider color="#008C89"></v-tabs-slider>
              </template>
              <template v-slot:[`${tabs[componentInd].component}`]>
                <component :patient="patient" :is="tabs[componentInd].component"></component>
              </template>
            </Tabs>
            <!--              <div class="visits-list">
                            <v-timeline dense>
                              <v-timeline-item color="success" v-for="(visit, key) in patient.visits.$values" :key="key">
                                <card>
                                  <template v-slot:body>
                                    <v-row>
                                      <v-col class="px-2 py-2" cols="2">
                                        <div class="visits-list__date">{{$moment(visit.date).format('MMM DD, YYYY')}}</div>
                                        <div class="visits-list__doctor">Visit to Dr. {{visit.doctorFullName}}</div>
                                      </v-col>
                                      <v-col class="px-2 py-2" cols="1"><v-divider vertical /></v-col>
                                      <v-col class="px-2 py-2" cols="6">
                                        <div class="label">Notes</div>
                                        <div class="visits-list__note">{{visit.notes}}</div>
                                      </v-col>
                                      <v-col class="px-2 py-2" cols="1"><v-divider vertical /></v-col>
                                      <v-col class="px-2 py-2" cols="2">
                                        <div class="visits-list__navs d-flex align-center">
                                          <v-icon color="#4FAA6E" class="mr-2 file" @click.native="navToSurvey(visit.id)">mdi-file-outline</v-icon>
                                          <v-icon color="#668BFF" class="briefcase" @click.native="navToResult(visit.id)">mdi-briefcase-plus</v-icon>
                                        </div>
                                      </v-col>
                                    </v-row>
                                  </template>
                                </card>
                              </v-timeline-item>
                            </v-timeline>
                          </div>-->
          </template>
        </card>
      </v-col>
      <v-col cols="3">
        <v-container>
          <v-row>
            <v-col class="py-0 mb-4">
              <card :isDisabled="!patient.active">
                <template v-slot:body>
                  <div class="text-h6 font-weight-bold black--text mb-4" style="position: relative">Last Notes
                    <div class="last-visit-date">{{patient.lastVisitDate ? $moment(patient.lastVisitDate).format('LL') : ''}}</div>
                  </div>
                  <div>{{patient.lastNotes}}</div>
                </template>
              </card>
            </v-col>
          </v-row>
          <v-row>
            <v-col>
              <card :isDisabled="!patient.active">
                <template v-slot:body>
                  <div class="text-h6 font-weight-bold black--text mb-4" style="position: relative">
                    5 Main Complaints
                    <div class="last-visit-date">{{patient.lastVisitDate ? $moment(patient.lastVisitDate).format('LL') : ''}}</div>
                  </div>
                  <div v-if="patient.surveyComplaints && patient.surveyComplaints.$values">{{patient.surveyComplaints.$values.join(',')}}</div>
                </template>
              </card>
            </v-col>
          </v-row>
          <v-row>
            <v-col>
              <card :isDisabled="!patient.active">
                <template v-slot:body>
                  <div class="text-h6 font-weight-bold black--text mb-4" style="position: relative">Last Surveys</div>
                  <template v-if="patient.lastResults && patient.lastResults.$values">
                    <div v-for="survey in patient.lastResults.$values" :key="survey.$id" @click="openReport('/patients/visit', survey.visitId)">
                      <v-tooltip left color="white">
                        <template v-slot:activator="{ on, attrs }">
                          <div class="link-block" color="primary" v-bind="attrs" v-on="on">
                            <div class="label">
                              <!--                        <img :src="getImgWithExt(['icons', 'bottle-sup', 'svg'])"/>-->
                              <v-icon color="#718096">mdi-badge-account-outline</v-icon>
                              <div class="label__name">{{$moment(survey.visitDate).format('ll')}}</div>
                            </div>
                            <div class="arrow">
                              <v-icon>mdi-chevron-right</v-icon>
                            </div>
                          </div>
                        </template>
                        <v-card flat class="card">
                          <v-card-title>
                            <div class="card-header">
                              <div class="card-header__title">Survey Details</div>
                              <div class="card-header__date">{{$moment(survey.visitDate).format('l')}}</div>
                            </div>
                          </v-card-title>
                          <v-card-text>
                            <div class="card-body">
                              <span class="card-body__title">Notes:</span>
                              <span class="card-body__text">{{survey.visitNotes || '-'}}</span>
                            </div>
                            <div class="card-body">
                              <span class="card-body__title">Complaints:</span>
                              <span class="card-body__text" v-if="survey.surveyComplaints && survey.surveyComplaints.$values">{{survey.surveyComplaints.$values.join(',') || '-'}}</span>
                            </div>
                          </v-card-text>
                        </v-card>
                      </v-tooltip>
                    </div>
                  </template>
                </template>
              </card>
            </v-col>
          </v-row>
        </v-container>
      </v-col>
    </v-row>
    <add-patient v-if="dialogs.editPatient.isOpen" @showPopup="showPopup"/>
    <add-visit :query="query" v-if="dialogs.addVisit.isOpen" @showPopup="showPopup" />
    <div class="custom-alert">
      <v-alert
        color="#7ac1fb"
        dismissible
        type="info"
        v-model="alertIsOpen"
      >
        <span style="font-size: 14px;">{{popupMessage}}</span>
        <router-link v-if="newPatientLink" :to="`patients/${newPatientLink.id}`">
          <span style="text-decoration: none;font-size: 14px; font-weight: 700;color: black;">Patient Details</span>
        </router-link>
        <router-link v-if="redirectToNewVisit" :to="`visit/${redirectToNewVisit}`">
          <span style="text-decoration: none;font-size: 14px; font-weight: 700;color: black;">View Evaluation</span>
        </router-link>
      </v-alert>
    </div>
  </div>
</template>

<script>
import { mapActions, mapState, mapMutations } from 'vuex'
import Button from '@/components/elements/Button'
import card from '@/components/layouts/card'
import addPatient from '@/components/layouts/dialogs/add-patient'

import Pagination from '@/mixins/Pagination'
import Tabs from '@/components/layouts/Tabs'
import history from '@/views/patients/history'
import chat from '@/views/patients/chat'
// import simulate from '@/views/patients/simulate'
import events from '@/views/patients/events'
import reports from '@/views/patients/reports'
import Image from '@/mixins/Image'
import addVisit from '@/components/layouts/dialogs/add-visit'
import Img from '@/components/elements/Img'

const SURVEY_TAB_INDEX = 0
const RESULT_TAB_INDEX = 2

export default {
  name: 'view-patient',
  mixins: [Pagination, Image],
  components: { Button, addPatient, card, Tabs, history, chat, reports, addVisit, events, Img }, // simulate
  data: () => ({
    patient: null,
    componentInd: 0,
    tabIndex: 0,
    popupMessage: null,
    newPatientLink: null,
    redirectToNewVisit: null,
    alertIsOpen: false,
    availableTabs: [
      { title: 'evaluations', component: 'history' },
      { title: 'forms', component: 'reports' },
      { title: 'events', component: 'events' }
      // { title: 'simulate', component: 'simulate' }
    ]
  }),
  async mounted () {
    const { id } = this.$route?.params
    if (id) {
      this.addUrlParam({ includeVisitsHistory: true })
      await this.getPatient({ id, urlParams: this.query })

      this.patient = Object.assign({}, this.$store.state.patients.patient)

      document.title = `Patient ${this.patient.lastName} ${this.patient.firstName} - Maestro Practitioner Portal`
      if (this.patient.registerPortal && this.subscriptionFeatures.features.$values.includes('Portal')) {
        this.availableTabs.unshift({
          title: 'chat',
          component: 'chat'
        })
      }
      this.SET_PAGE_SETTINGS({ title: `${this.patient.firstName} ${this.patient.lastName}` })
    }
  },
  destroyed () {
    this.SET_PAGE_SETTINGS(null)
    this.alertIsOpen = false
  },
  methods: {
    ...mapActions('patients', ['getPatient']),
    ...mapMutations(['SET_PAGE_SETTINGS']),
    ...mapMutations('dialogs', ['TOGGLE_DIALOG']),
    async showPopup (res) {
      this.alertIsOpen = true
      this.newPatientLink = res.type === 'add-patient' ? res.data : null
      if (res.type === 'edit-patient') {
        this.popupMessage = `Patient ${res.data.firstName} ${res.data.lastName} was updated successfully.`
        this.addUrlParam({ includeVisitsHistory: true })
        await this.getPatient({ id: this.$route.params.id, urlParams: this.query })
        this.patient = Object.assign({}, this.$store.state.patients.patient)
        this.TOGGLE_DIALOG({ name: 'editPatient', isOpen: false })
      }
      if (res.type === 'add-patient') {
        this.TOGGLE_DIALOG({ name: 'addPatient', isOpen: false })
      }
      if (res.type === 'add-visit') {
        this.TOGGLE_DIALOG({ name: 'addVisit', isOpen: false })
        if (res.data.record.redirectToVisit) {
          this.alertIsOpen = false
          sessionStorage.setItem('selectedPage', `/patients/visit/${res.data.record.id}`)
          this.$router.push({ name: 'View Evaluation', params: { id: res.data.record.id } })
        } else {
          this.popupMessage = `Evaluation date was added. Now you can `
          this.redirectToNewVisit = res.data.record.id
          await this.getPatient({ id: res.data.record.patientId, urlParams: this.query })
        }
      }
    },
    edit ($event) {
      $event.preventDefault()
      const { id } = this.$route?.params
      if (id) {
        this.TOGGLE_DIALOG({ id, name: 'editPatient', isOpen: true, title: 'Edit patient' })
      }
    },
    navToResult (id) {
      this.$router.push({ path: `/visit/${id}`, query: { tabIndex: RESULT_TAB_INDEX } })
    },
    navToSurvey (id) {
      this.$router.push({ path: `/visit/${id}`, query: { tabIndex: SURVEY_TAB_INDEX } })
    },
    tab ($event) {
      this.componentInd = $event
    },
    sendEmailToPatient (email) {
      if (email) {
        window.open('mailto:' + email)
      }
    },
    openReport (path, id) {
      this.$router.push({ path: `${path}/${id}`, query: { tabIndex: 2 } })
    }
  },
  computed: {
    ...mapState({
      // patient: state => state.patients.patient,
      subscriptionFeatures: state => state.subscription,
    }),
    dialogs () {
      return this.$store.state.dialogs
    },
    tabs () {
      return this.availableTabs
    }
  }
}
</script>

<style lang="scss" scoped>
  .w100 {
    width: 100%;
  }
  .label {
    font-size: 14px;
    font-weight: 500;
    color: #425466;
  }
  .text {
    font-weight: 600;
    font-size: 14px;
    line-height: 23px;
  }
  .view-patient {
    position: relative;
    background-color: #F7FAFC;
    .last-visit-date {
      font-size: 11px;
      font-weight: 300;
      color: #00000099;
      right: -10px;
      top: -25px;
      position: absolute;
    }
    .visits-list {
      background: #E9EEF3;
      border-radius: 16px;
      padding-right: 30px;
      &__date {
        font-weight: 500;
        font-size: 16px;
        line-height: 24px;
        color: #425466;
      }
      &__doctor {
        font-size: 12px;
        line-height: 20px;
        color: #718096;
      }
      &__note {
        font-weight: 600;
        font-size: 12px;
        line-height: 20px;
        color: #425466;
      }
      &__navs {
        height: 100%;
        .v-icon {
          border-radius: 6px;
          padding: 4px;
        }
        .file {
          background: rgba(115, 217, 149, 0.2);
          border-radius: 10px;
        }
        .briefcase {
          background: rgba(208, 220, 255, 0.5);
          border-radius: 10px;
        }
      }
    }
    ::v-deep .v-expansion-panel-header {
      .v-icon {
        margin-left: 6px;
        border-radius: 6px;
        border: 1px solid #A0AEC0;
      }
    }
    &__fullname {
      font-size: 20px;
      font-weight: 600;
      color: #16192C;
    }
    &__joined {
      font-weight: 600;
      font-size: 12px;
      line-height: 16px;
      color: #718096;
    }
    .v-icon {
      cursor: pointer;
    }
    .custom-alert {
      max-width: 300px;
      position: absolute;
      top: -50px;
      right: 20px;
      z-index: 100;
    }
    .link-block {
      margin-bottom: 7px;
      align-items: center;
      width: 100%;
      padding: 18px 20px;
      background: #F7FAFC;
      display: flex;
      justify-content: space-between;
      border-radius: 8px;
      cursor: pointer;
      .label {
        display: flex;
        align-items: center;
        column-gap: 0.5rem;
        justify-content: space-between;
        &__name {
          font-weight: 600;
          font-size: 14px;
          color: #425466;
        }
      }
    }
    .additional-info-icons {
      display: flex;
      i {
        padding-right: 3px!important;
      }
    }
    .patient-link {}
    ::v-deep .custom-card {
      .v-sheet {
        padding-left: 0!important;
      }
    }
  }
  .view-patient.blur {
    filter: blur(2px);
  }
  .v-tooltip__content.white {
    padding: 0!important;
    opacity: 1!important;
    border: 1px solid grey!important;
    border-radius: 10px!important;
  }
  ::v-deep {
    .card {
      border-radius: 10px!important;
      .v-card__title {
        background: #edf2f7;
        min-width: 250px;
      }
    }
    .card-header {
      display: flex;
      justify-content: space-between;
      width: 100%;
      &__title {
        font-size: 15px;
        font-weight: bold;
        color: black;
      }
      &__date {
        font-size: 15px;
        font-weight: bold;
        color: grey;
      }
    }
    .card-body {
      &__title {
        font-size: 15px;
        font-weight: bold;
        color: grey;
      }
      &__text {
        color: black;
        font-size: 13px;
      }
    }
  }
</style>
