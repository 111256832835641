<template>
  <div class="notes-crg">
    <Dialog style="width: 80%">
      <template slot="title">
        <div class="title">
          {{modalCrgNotes.title}}
        </div>
      </template>

      <template slot="body">
        <v-row>
          <v-col cols="8" class="px-0">
            <div class="description" v-html="modalCrgNotes.data.supplementCrgNotes"></div>
          </v-col>
          <v-col cols="4" class="pa-0">
            <v-expansion-panels flat v-model="computedPanel" multiple>
              <v-expansion-panel>
                <v-expansion-panel-header>
                  My Notes
                </v-expansion-panel-header>
                <v-expansion-panel-content color="#EDF2F7">
                  <Textarea dense auto-grow rows="1" rows-height="4" outlined @input:text-area="modalCrgNotes.data.clinicianNotes=$event" :value="modalCrgNotes.data.clinicianNotes" />
                </v-expansion-panel-content>
              </v-expansion-panel>
              <v-expansion-panel>
                <v-expansion-panel-header>
                  Warnings
                </v-expansion-panel-header>
                <v-expansion-panel-content color="#EDF2F7">
                  <Textarea dense auto-grow rows="1" rows-height="4" outlined @input:text-area="modalCrgNotes.data.warnings=$event" :value="modalCrgNotes.data.warnings" />
                </v-expansion-panel-content>
              </v-expansion-panel>
              <v-expansion-panel>
                <v-expansion-panel-header>
                  Patient Notes ({{isSpanish ? 'Spanish' : 'English'}})
                </v-expansion-panel-header>
                <v-expansion-panel-content color="#EDF2F7">
                  <Textarea dense auto-grow rows="1" rows-height="4" outlined @input:text-area="modalCrgNotes.data[isSpanish ? 'patientNotesSpanish' : 'patientNotes']=$event" :value="modalCrgNotes.data[isSpanish ? 'patientNotesSpanish' : 'patientNotes']" />
                </v-expansion-panel-content>
              </v-expansion-panel>
              <v-expansion-panel>
                <v-expansion-panel-header>
                  Short Description ({{isSpanish ? 'Spanish' : 'English'}})
                </v-expansion-panel-header>
                <v-expansion-panel-content color="#EDF2F7">
                  <Textarea dense auto-grow rows="1" rows-height="4" outlined @input:text-area="modalCrgNotes.data[isSpanish ? 'supplementDescriptionSpanish' : 'supplementDescription']=$event" :value="modalCrgNotes.data[isSpanish ? 'supplementDescriptionSpanish' : 'supplementDescription']" />
                </v-expansion-panel-content>
              </v-expansion-panel>
            </v-expansion-panels>
            <v-switch color="#008C89" inset label="Show in Spanish" @change="showInSpanish" />
          </v-col>
        </v-row>
      </template>

      <template slot="actions">
        <div class="d-flex justify-end w-100 pb-4 px-4">
          <Button
            dark
            outlined
            color="grey"
            @click.native="close"
            :styles="{ 'border-radius': '8px' }"
          >
            <span style="color: #425466">Close</span>
          </Button>
        </div>
      </template>
    </Dialog>
  </div>
</template>

<script>
import { mapMutations, mapState } from 'vuex'
import Dialog from '@/components/layouts/dialog'
import Button from '@/components/elements/Button'
import Textarea from '@/components/elements/Textarea'

export default {
  name: 'modal-crg-notes',
  components: { Dialog, Button, Textarea },
  data: () => ({
    isSpanish: false
  }),
  methods: {
    ...mapMutations('dialogs', ['TOGGLE_DIALOG']),
    showInSpanish ($event) {
      this.isSpanish = $event
    },
    close () {
      this.TOGGLE_DIALOG({ name: 'modalCrgNotes', isOpen: false })
    }
  },
  computed: {
    ...mapState({
      modalCrgNotes: state => state.dialogs.modalCrgNotes
    }),
    computedPanel () {
      return [0, 1, 2, 3]
    }
  }
}
</script>

<style lang="scss" scoped>
.w-100 {
  width: 100%;
}
.notes-crg {
  ::v-deep .v-expansion-panels {
    .v-expansion-panel:not(.v-item--active) {
      .v-expansion-panel-header {
        padding: 16px 10px !important;
      }
      v-expansion-panel-header--active {
        padding: 16px 10px !important;
      }
      .v-expansion-panel-content {
        border-radius: 8px;
      }
    }
    .v-expansion-panel {
      margin-top: 2px;
      .v-expansion-panel-header {
        padding: 5px 10px !important;
        min-height: 37px;
      }
      v-expansion-panel-header--active {
        padding: 5px 10px !important;
        min-height: 37px;
      }
      .v-expansion-panel-content {
        border-radius: 8px;
      }
    }
  }
  ::v-deep .v-card {
    &__title {
      background: #edf2f7;
      padding: 10px 16px!important;
      justify-content: center!important;
      margin-bottom: 10px!important;
    }
    &__text {
      overflow-x: hidden;
      overflow-y: scroll;
      height: 70vh;
    }
  }
  ::v-deep .v-dialog {
    overflow-y: hidden!important;
    max-height: unset!important;
  }
  ::v-deep .v-dialog__container {
    .v-card {
      &__title {
        //background: #edf2f7;
        padding: 25px 35px 17px 26px !important;
        justify-content: center!important;
        position: relative;
        .image {
          width: 100%;
          text-align: center;
        }
        .title {
          font-family: 'Poppins';
          font-weight: 600;
          font-size: 16px;
          text-align: center;
          color: #27272E;
        }
        .v-icon {
          display: none;
          position: absolute;
          right: 15px;
          top: 25px;
        }
      }
      &__text {
        .description {
          font-family: 'Poppins';
          padding: 0 35px;
          font-weight: 400;
          font-size: 14px;
          text-align: center;
          color: #425466;
        }
      }
    }
  }
}
</style>
