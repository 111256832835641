<template>
	<div class="reports">
		<v-row no-gutters style="gap: 15px">
			<v-col cols="3" style="max-width: 24%">
				<v-card class="px-2 py-1">
					<v-card-title class="report-label">Clinician Reports</v-card-title>
					<v-card-text class="btn-group-report">
						<div
							ref="clinicReport"
							class="btn-report"
							@click.prevent="
								getReport(
									'clinicReport',
									'ClinicianFinalReport',
									'ClinicReport'
								)
							"
						>
							<v-checkbox
								color="#008C89"
								label="Clinician Report"
								v-model="clinicReport"
								@click="
									selectReport(
										$event,
										'ClinicianFinalReport',
										clinicReport,
										() =>
											getReport(
												'clinicReport',
												'ClinicianFinalReport',
												'ClinicReport'
											)
									)
								"
							></v-checkbox>
						</div>
						<div
							ref="clinicianWarnings"
							class="btn-report"
							@click.prevent="
								getReport(
									'clinicianWarnings',
									'ClinicianWarnings',
									'ClinicianWarnings'
								)
							"
						>
							<v-checkbox
								color="#008C89"
								label="Clinician Warnings"
								v-model="clinicianWarnings"
								@click="
									selectReport(
										$event,
										'ClinicianWarnings',
										clinicianWarnings,
										() =>
											getReport(
												'clinicianWarnings',
												'ClinicianWarnings',
												'ClinicianWarnings'
											)
									)
								"
							></v-checkbox>
						</div>
						<div
							v-if="isSubscriptionIncludesBlood"
							ref="clinicianBloodPanelResults"
							class="btn-report"
							@click.prevent="
								getReport(
									'clinicianBloodPanelResults',
									'BloodPanelClinician',
									'ClinicianBloodPanelResults'
								)
							"
						>
							<v-checkbox
								color="#008C89"
								label="Clinician Blood Panel Results"
								v-model="clinicianBloodPanelResults"
								@click="
									selectReport(
										$event,
										'BloodPanelClinician',
										clinicianBloodPanelResults,
										() =>
											getReport(
												'clinicianBloodPanelResults',
												'BloodPanelClinician',
												'ClinicianBloodPanelResults'
											)
									)
								"
							>
							</v-checkbox>
						</div>
					</v-card-text>
				</v-card>
				<br />
				<v-card class="px-2 py-1">
					<v-card-title class="report-label">Patient Reports</v-card-title>
					<v-card-text class="btn-group-report">
						<div
							ref="supplementSchedule"
							class="btn-report"
							@click.prevent="
								getReport(
									'supplementSchedule',
									'PatientSchedule',
									'SupplementSchedule'
								)
							"
						>
							<v-checkbox
								color="#008C89"
								label="Supplement Schedule"
								v-model="supplementSchedule"
								@click="
									selectReport(
										$event,
										'PatientSchedule',
										supplementSchedule,
										() =>
											getReport(
												'supplementSchedule',
												'PatientSchedule',
												'SupplementSchedule'
											)
									)
								"
							></v-checkbox>
						</div>
						<div
							ref="groupDescription"
							class="btn-report custom disable-label"
							@click.prevent="
								getReport(
									'groupDescription',
									'PatientGroupsInfo',
									'PatientGroupsInfo'
								)
							"
						>
							<v-checkbox
								color="#008C89"
								label="Group Description"
								v-model="localGroupDescription"
								@click="
									selectReport(
										$event,
										'PatientGroupsInfo',
										localGroupDescription,
										() =>
											getReport(
												'groupDescription',
												'PatientGroupsInfo',
												'PatientGroupsInfo'
											)
									)
								"
							></v-checkbox>

							<div class="wrapper-group-description-report">
								<div class="group-description-report">
									<v-select
										:items="items"
										:value="items[0]"
										class="custom-select"
										v-model="groupDescription"
										solo
									></v-select>
								</div>
							</div>
						</div>
						<div
							ref="surveyScoreSheet"
							class="btn-report"
							@click.prevent="
								getReport(
									'surveyScoreSheet',
									'PatientSurveyScoreSheet',
									'SurveyScoreSheet'
								)
							"
						>
							<v-checkbox
								color="#008C89"
								label="Survey Score Sheet"
								v-model="localSurveyScoreSheet"
								@click="
									selectReport(
										$event,
										'PatientSurveyScoreSheet',
										localSurveyScoreSheet,
										() =>
											getReport(
												'surveyScoreSheet',
												'PatientSurveyScoreSheet',
												'SurveyScoreSheet'
											)
									)
								"
							></v-checkbox>
						</div>

						<div
							ref="supplementCostAndQuantities"
							class="btn-report"
							@click.prevent="
								getReport(
									'supplementCostAndQuantities',
									'PatientSurveyCostsAndQuantities',
									'SupplementCostAndQuantities'
								)
							"
						>
							<v-checkbox
								color="#008C89"
								label="Costs and Quantities"
								v-model="supplementCostAndQuantities"
								@click="
									selectReport(
										$event,
										'PatientSurveyCostsAndQuantities',
										supplementCostAndQuantities,
										() =>
											getReport(
												'supplementCostAndQuantities',
												'PatientSurveyCostsAndQuantities',
												'SupplementCostAndQuantities'
											)
									)
								"
							></v-checkbox>
						</div>
						<div
							ref="surveyFormSummary"
							class="btn-report"
							@click.prevent="
								getReport(
									'surveyFormSummary',
									'PatientSurveyFormSummary',
									'SurveyFormSummary'
								)
							"
						>
							<v-checkbox
								color="#008C89"
								label="Survey Form Summary"
								v-model="surveyFormSummary"
								@click="
									selectReport(
										$event,
										'PatientSurveyFormSummary',
										surveyFormSummary,
										() =>
											getReport(
												'surveyFormSummary',
												'PatientSurveyFormSummary',
												'SurveyFormSummary'
											)
									)
								"
							></v-checkbox>
						</div>
						<div
							ref="surveyComparisonChart"
							class="btn-report"
							@click.prevent="
								getReport(
									'surveyComparisonChart',
									'SurveyComparisonChart',
									'SurveyComparisonChart'
								)
							"
						>
							<v-checkbox
								color="#008C89"
								label="Survey Comparison Chart"
								v-model="surveyComparisonChart"
								@click="
									selectReport(
										$event,
										'SurveyComparisonChart',
										surveyComparisonChart,
										() =>
											getReport(
												'surveyComparisonChart',
												'SurveyComparisonChart',
												'SurveyComparisonChart'
											)
									)
								"
							></v-checkbox>
						</div>
						<div
							v-if="isSubscriptionIncludesBlood"
							ref="bloodPanelResults"
							class="btn-report"
							@click.prevent="
								getReport(
									'bloodPanelResults',
									'BloodPanelPatient',
									'BloodPanelResults'
								)
							"
						>
							<v-checkbox
								color="#008C89"
								label="Blood Panel Results"
								v-model="bloodPanelResults"
								@click="
									selectReport(
										$event,
										'BloodPanelPatient',
										bloodPanelResults,
										() =>
											getReport(
												'bloodPanelResults',
												'BloodPanelPatient',
												'BloodPanelResults'
											)
									)
								"
							></v-checkbox>
						</div>
						<div
							v-if="isSubscriptionIncludesBlood"
							ref="bloodPanelResultsWithAboveBelowOptimal"
							class="btn-report"
							@click.prevent="
								getReport(
									'bloodPanelResultsWithAboveBelowOptimal',
									'BloodPanelPatientOptimals',
									'BloodPanelResultsWithAboveBelowOptimal'
								)
							"
						>
							<v-checkbox
								color="#008C89"
								label="Blood Panel Results w/ Optimals"
								v-model="bloodPanelResultsWithAboveBelowOptimal"
								@click="
									selectReport(
										$event,
										'BloodPanelPatientOptimals',
										bloodPanelResultsWithAboveBelowOptimal,
										() =>
											getReport(
												'bloodPanelResultsWithAboveBelowOptimal',
												'BloodPanelPatientOptimals',
												'BloodPanelResultsWithAboveBelowOptimal'
											)
									)
								"
							></v-checkbox>
						</div>
						<div
							v-if="isSubscriptionIncludesBlood"
							ref="bloodPanelCompare"
							class="btn-report"
							@click.prevent="
								getReport(
									'bloodPanelCompare',
									'BloodPanelComparison',
									'BloodPanelCompare'
								)
							"
						>
							<v-checkbox
								color="#008C89"
								label="Blood Panel Compare"
								v-model="bloodPanelCompare"
								@click="
									selectReport(
										$event,
										'BloodPanelComparison',
										bloodPanelCompare,
										() =>
											getReport(
												'bloodPanelCompare',
												'BloodPanelComparison',
												'BloodPanelCompare'
											)
									)
								"
							></v-checkbox>
						</div>
					</v-card-text>
				</v-card>
			</v-col>
			<v-col cols="9" style="max-width: 74%">
				<v-card style="height: 704px">
					<ReportView
						:disabledSpanish="true"
						:activeLanguage="activeLanguage"
						@switchLanguage="switchLanguage"
						@sendSurveyFormToPatient="sendToPatientEmail"
						v-if="
							dialogs.openReportPdfView &&
							dialogs.openReportPdfView.isOpen &&
							initialDoc
						"
						:initialDoc="initialDoc"
						:isMultipleReports="!!arrayOfSelectedReports.length"
						@downloadMultipleReports="downloadMultipleReports"
					/>
					<div v-else style="text-align: center">
						<h3 class="pt-10" style="color: rgba(0, 0, 0, 0.6)">
							Please select report
						</h3>
					</div>
					<div
						v-if="loading"
						class="loading d-flex justify-center px-2 py-2 align-center"
					>
						<v-progress-circular
							indeterminate
							color="#008C89"
						></v-progress-circular>
					</div>
				</v-card>
			</v-col>
		</v-row>
		<div class="custom-alert">
			<v-alert :color="!isInvalidEmail ? '#7ac1fb' : 'rgb(207, 70, 85)'" dismissible type="info" v-model="alertIsOpen">
				<span style="font-size: 14px">{{ popupMessage }}</span>
			</v-alert>
		</div>

		<ConfirmDialog :callback="emailCallback" v-if="dialogs.confirm.isOpen" />
	</div>
</template>

<script>
import { mapActions, mapMutations, mapState } from 'vuex';
import ReportView from '@/components/layouts/dialogs/reportView';
import ConfirmDialog from '@/components/layouts/dialogs/confirm-dialog';

export default {
	name: 'reports',
	components: { ReportView, ConfirmDialog },
	data: () => ({
		initialDoc: null,
		docForZip: null,
		activeLanguage: 'en',
		items: [1, 2, 3, 4, 5],

		email: null,
		isNoSelectedReportsError: false,
		isInvalidEmail: false,

		localGroupDescription: false,
		localSurveyScoreSheet: false,

		groupDescription: +localStorage.getItem('groupDescription') || 1,
		patientSchedule: null,
		includeCosts: false,
		disabledIncludeCosts: true,
		printInColors: false,
		disabledPrintInColors: true,
		fileUrl: null,
		file: null,
		reportCode: null,
		refLocal: null,
		alertIsOpen: false,
		popupMessage: '',
		loading: false,
		reportSpanish: true,
		selectedReports: [],
		clinicReport: false,
		clinicianWarnings: false,
		clinicianBloodPanelResults: false,

		supplementSchedule: false,
		supplementCostAndQuantities: false,
		surveyFormSummary: false,
		surveyComparisonChart: false,

		bloodPanelResults: false,
		bloodPanelResultsWithAboveBelowOptimal: false,
		bloodPanelCompare: false,
		arrayOfSelectedReports: []
	}),

	mounted() {
		this.email = this.patient.email || null;
		this.reportSpanish = this.patient.spanish;
	},

	methods: {
		...mapMutations('dialogs', ['TOGGLE_DIALOG']),
		...mapActions('reports', [
			'gerReportByCode',
			'sendSurveyForm',
			'sendEmailToPatient',
			'sendEmailToPatientMultiple',
			'downloadZipReports'
		]),
		openReport() {
			this.initialDoc =
				'https://pdftron.s3.amazonaws.com/downloads/pl/demo-annotated.pdf';
			this.TOGGLE_DIALOG({
				name: 'openReportPdfView',
				isOpen: true,
				title: 'Report'
			});
		},

		switchLanguage(lang) {
			if (lang === 'es') {
				this.reportSpanish = true;
			} else {
				this.reportSpanish = false;
			}

			this.activeLanguage = lang;
		},

		async emailCallback() {
			const data = {
				email: this.email,
				entityId: this.$route.params.id,
				reportSpanish: this.reportSpanish,
				appUserId: this.userProfile.userId,
				reportCode: localStorage.getItem('reportCode'),
			};

			if (this.arrayOfSelectedReports.includes('PatientGroupsInfo')) {
				data.surveyGroups = this.groupDescription;
			}


			delete data.appUserId;
			delete data.reportCode;

			const payload = {
				...data,
				reports: this.arrayOfSelectedReports.map((reportCode) => {
					return {
						reportCode,
						entityId: this.$route.params.id,
						surveyGroups: data.surveyGroups,
						reportSpanish: this.reportSpanish,
					};
				})
			};

			const res = await this.sendEmailToPatientMultiple(payload);


			if (res.success) {
				this.alertIsOpen = true;
				this.popupMessage =
					'Survey Form was send successfully to Patient Email';
			}
		},

		validateEmail() {
			const emailRegExp =
				/^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|.(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

			if (!this.email || !String(this.email).toLowerCase().match(emailRegExp)) {
				this.isInvalidEmail = true;
				return false;
			} else {
				this.isInvalidEmail = false;
				return true;
			}
		},

		async sendToPatientEmail() {
			const isValid = this.validateEmail();

			if (!isValid) {
				this.alertIsOpen = true;
				this.popupMessage = 'The user has invalid email';

				return;
			}

			if (
				this.arrayOfSelectedReports.length === 0 ||
				!this.arrayOfSelectedReports
			) {
				this.isNoSelectedReportsError = true;


				return;
			}

			await this.TOGGLE_DIALOG({
				id: this.email,
				name: 'confirm',
				isOpen: true,
				description: 'Are you sure you want to send email?',
				callback: async () => await this.emailCallback(),
				title: 'Confirm sending email'
			});
		},

		async getReport(ref, reportCode, reportLabel) {
			localStorage.setItem('reportCode', reportCode);
			localStorage.setItem(
				'reportName',
				this.upperCaseFirst(this.splitByUpperCase(ref))
			);

			if (!this.$refs[ref].classList.contains('report-active')) {
				this.loading = true;
				Array.from(document.getElementsByClassName('btn-report')).forEach(
					(item) => {
						item.classList.remove('report-active');
					}
				);

				this.$refs[ref].classList.add('report-active');
				if (ref === 'patientSurveyScoreSheet') {
					this.disabledIncludeCosts = false;
				}
				if (ref === 'surveyComparisonChart') {
					this.disabledPrintInColors = false;
				}

				const title = this.upperCaseFirst(this.splitByUpperCase(reportLabel));

				this.reportCode = reportCode;
				this.refLocal = title;

				const payload = {
					email: this.email,
					appUserId: this.userProfile.userId,
					reportCode,
					entityId: this.$route.params.id,
					reportSpanish: this.reportSpanish
				};

				if (
					this.arrayOfSelectedReports.includes('PatientGroupsInfo') ||
					reportCode === 'PatientGroupsInfo'
				) {
					payload.surveyGroups = this.groupDescription;
				}

				if (reportCode === 'PatientSurveyScoreSheet') {
					payload.includeCosts = this.includeCosts;
				}

				const res = await this.gerReportByCode(payload);
				this.file = res;
				this.parseFile(this.file);
				this.initialDoc =
					'https://pdftron.s3.amazonaws.com/downloads/pl/demo-annotated.pdf';
				this.loading = false;

				this.TOGGLE_DIALOG({ name: 'openReportPdfView', isOpen: true, title });
				this.loading = false;
			}
		},

		async selectReport(event, reportCode, value, callback) {
			event.stopPropagation();

			if (value) {
				this.arrayOfSelectedReports.push(reportCode);
			} else {
				this.arrayOfSelectedReports = this.arrayOfSelectedReports.filter(
					(item) => item !== reportCode
				);
			}

			if (this.arrayOfSelectedReports.length > 0 && value) {
				this.isNoSelectedReportsError = false;
			}

			if (callback && value) await callback();
		},

		async downloadMultipleReports() {
			const data = {};

			if (this.arrayOfSelectedReports.includes('PatientGroupsInfo'))
				data.surveyGroups = this.groupDescription;

			const payload = {
				...data,
				email: this.email,
				entityId: this.$route.params.id,
				reportSpanish: this.reportSpanish,
				appUserId: this.userProfile.userId,
				reports: this.arrayOfSelectedReports.map((reportCode) => {
					return {
						...data,
						reportCode: reportCode,
						entityId: this.$route.params.id,
						reportSpanish: this.reportSpanish,
						appUserId: this.userProfile.userId
					};
				})
			};

			await this.downloadZipReports(payload);
		},
		resetReportFields() {
			this.clinicReport = false;
			this.clinicianWarnings = false;
			this.clinicianBloodPanelResults = false;
			this.supplementSchedule = false;
			this.supplementCostAndQuantities = false;
			this.surveyFormSummary = false;
			this.surveyComparisonChart = false;
			this.bloodPanelResults = false;
			this.bloodPanelResultsWithAboveBelowOptimal = false;
			this.bloodPanelCompare = false;
		},
		async parseFile(file) {
			// this.initialDoc = null
			if (file) {
				const reader = new FileReader();
				reader.onloadend = () => {
					this.fileUrl = URL.createObjectURL(file);
					this.initialDoc = this.fileUrl;

					console.log(this.fileUrl);
				};

				reader.readAsDataURL(file);
			} else {
				this.fileUrl = null;
			}
		},
		splitByUpperCase(str) {
			return str.split(/(?=[A-Z])/).join(' ');
		},
		upperCaseFirst(str) {
			return str[0].toUpperCase() + str.slice(1);
		}
	},
	computed: {
		...mapState({
			visit: (state) => state.visits?.visit,
			userProfile: (state) => state.userProfile,
			patient: (state) => state.patients?.patient,
			subscriptionFeatures: (state) => state.subscription
		}),
		dialogs() {
			return this.$store.state.dialogs;
		},

		isSubscriptionIncludesBlood() {
			return this.subscriptionFeatures.features.$values.includes('Blood');
		},

		getIfAnyReportsSelected() {
			return (
				this.arrayOfSelectedReports && this.arrayOfSelectedReports.length > 0
			);
		}
	},
	watch: {
		async groupDescription(val) {
			localStorage.setItem('groupDescription', val);
			const payload = {
				surveyGroups: val,
				reportCode: this.reportCode,
				entityId: this.$route.params.id,
				reportSpanish: this.reportSpanish,
				appUserId: this.userProfile.userId
			};
			const res = await this.gerReportByCode(payload);
			this.file = res;
			this.parseFile(this.file);
			this.TOGGLE_DIALOG({
				name: 'openReportPdfView',
				isOpen: true,
				title: this.refLocal
			});
		},
		async includeCosts(val) {
			const payload = {
				includeCosts: val,
				reportCode: this.reportCode,
				entityId: this.$route.params.id,
				reportSpanish: this.reportSpanish,
				surveyGroups: this.groupDescription,
				appUserId: this.userProfile.userId
			};
			const res = await this.gerReportByCode(payload);
			this.file = res;
			this.parseFile(this.file);
			this.TOGGLE_DIALOG({
				name: 'openReportPdfView',
				isOpen: true,
				title: this.refLocal
			});
		},
		async reportSpanish(val) {
			if (this.reportCode) {
				const payload = {
					reportSpanish: val,
					reportCode: this.reportCode,
					entityId: this.$route.params.id,
					includeCosts: this.includeCosts,
					appUserId: this.userProfile.userId,
					surveyGroups: this.groupDescription
				};

				const res = await this.gerReportByCode(payload);
				this.file = res;
				this.parseFile(this.file);
				this.TOGGLE_DIALOG({
					name: 'openReportPdfView',
					isOpen: true,
					title: this.refLocal
				});
			}
		},
		alertIsOpen(val) {
			const self = this;
			if (val) {
				setTimeout(function () {
					self.alertIsOpen = false;
				}, 5000);
			}
		}
	}
};
</script>

<style lang="scss" scoped>
.reports {
	.loading {
		min-height: 100px;
		position: absolute;
		height: 99%;
		width: 99%;
		top: 4px;
		left: 5px;
		background: rgba(213, 213, 213, 0.5);
		border-radius: 16px;
	}

	padding: 16px !important;
	.language-switcher {
		border: 1px solid #f1f1f1;
		border-radius: 18px;
		padding: 4px;
		margin-bottom: 10px;
		box-shadow: 0px 0px 1px rgba(12, 26, 75, 0.24),
			0px 3px 8px -1px rgba(50, 50, 71, 0.05);

		.button {
			::v-deep .v-btn {
				background-color: rgba(255, 255, 255, 0.64) !important;
				color: black !important;
				font-weight: 600 !important;
			}
		}
		.button.active-language {
			::v-deep .v-btn {
				background-color: #4faa6e !important;
				color: white !important;
				font-weight: 600 !important;
			}
		}
	}
	.report-label {
		font-weight: 600;
		font-size: 14px;
		color: #425466;
		border-bottom: 1px solid #e4ecf7;
	}
	.btn-group-report {
		display: flex;
		flex-direction: column;
		row-gap: 8px;

		.btn-report {
			height: 36px;
			display: flex;
			align-items: center;
			cursor: pointer;

			&:hover {
				background-color: #edf2f7;
				border-radius: 8px;

				.wrapper-group-description-report {
					.group-description-report {
						::v-deep .v-input {
							.v-input__control {
								.v-input__slot {
									background: #cbd5e0 !important;
								}
							}
						}
					}
				}
			}

			::v-deep .v-input {
				margin: 0 !important;
				padding: 0 !important;
				width: 20px;
				white-space: nowrap;
				position: absolute;

				.v-input__control {
					.v-messages {
						display: none !important;
					}
					.v-text-field__details {
						display: none !important;
					}
					.v-input__slot {
						pointer-events: none !important;

						div {
							z-index: 4;
							pointer-events: all !important;
						}

						label {
							pointer-events: none !important;
						}
					}
				}
			}

			.wrapper-group-description-report {
				position: absolute;
				right: 0;
				display: flex;
				justify-content: space-between;
				align-items: center;
				padding: 0 8px;
				margin-right: 3px;

				.group-description-report {
					width: 70px;
					::v-deep .v-input {
						width: 70px !important;
						position: relative !important;

						.v-input__control {
							min-height: 30px !important;

							.v-input__slot {
								background: #edf2f7 !important;
								box-shadow: none !important;
							}
						}
					}
				}
			}
			.wrapper-group-cost-report {
				display: flex;
				justify-content: space-between;
				align-items: center;
				width: 100%;
				padding: 0 10px;
				.group-cost-report {
					width: 40px;
					::v-deep .v-input {
						width: 40px !important;
						position: relative !important;
					}
				}
			}
		}
		.btn-report.custom {
			::v-deep .v-input {
				width: 100% !important;
				white-space: normal !important;
				.v-input__control {
					.v-input__slot {
						label {
							z-index: 3;
							width: 100% !important;
							pointer-events: none !important;
						}
					}
				}
			}
		}
		.btn-report:first-child {
			margin-top: 13px;
		}
		.btn-report.report-active {
			border: 2px solid $dark-green;
			background-color: #edf2f7;
			border-radius: 8px;
		}
	}

	.main-row {
		display: flex;
		flex-wrap: wrap;
		column-gap: 1rem;
		justify-content: space-between;
		row-gap: 1rem;
		.btn-report {
			width: fit-content;
			height: fit-content;
			background: #f7fafc;
			border-radius: 8px;
			padding: 7px;
			border: 2px solid transparent;
			cursor: pointer;
			.custom-select {
				width: 50px;
				height: 21px;
				::v-deep .v-input__control {
					min-height: unset !important;
					.v-text-field__details {
						display: none !important;
					}
					.v-input__slot {
						height: 21px !important;
						background: transparent !important;
						box-shadow: unset !important;
						padding: 0 !important;
					}
				}
			}
			.select-label {
				position: absolute;
				top: -20px;
				right: -50px;
				font-weight: 500;
				font-size: 12px;
				color: #718096;
				width: max-content;
			}
			.checkbox-label {
				position: absolute;
				top: -20px;
				right: -37px;
				font-weight: 500;
				font-size: 12px;
				color: #718096;
				width: max-content;
			}
			.report-title {
				font-weight: 500;
				font-size: 14px;
				color: #425466;
			}
			.bold {
				font-weight: 600;
				padding-left: 5px;
			}
			.check-box-additional {
				margin-left: 20px;
				::v-deep .v-input {
					.v-label {
						font-size: 14px !important;
						color: #425466 !important;
					}
					.v-label.v-label--is-disabled {
						font-size: 14px !important;
						color: grey !important;
					}
				}
			}
			.additional-filters {
				display: flex;
				column-gap: 1rem;
			}
		}
		.btn-report.report-active {
			border: 2px solid $dark-green;
			background: $light-dark-green;
		}
	}

	.custom-alert {
		max-width: 300px;
		position: fixed;
		top: 10px;
		right: 20px;
		z-index: 100;
	}
}
</style>
