<template>
  <div class="confirmation-end-protocol">
    <Dialog width="610px">
      <template slot="title">
        <div class="title">
          {{modalConfirmationEndProtocol.title}}
        </div>
      </template>

      <template slot="body">
        <div>
          <div class="title" style="text-align: center;">What would you like to do with the Evaluation?</div>
          <br>
          <div class="wrapper pl-2 pr-2">
            <div>
              <div style="width: 48%;text-align: center;display: contents;">
                <Button
                  dark
                  color="#008C89"
                  @click.native="handleAction('adjust-protocol')"
                  :styles="{ 'border-radius': '8px' }"
                >
                  <span>Adjust Protocol</span>
                </Button>
              </div>
              <br>
              <div class="pl-2">
                <span class="bold-title">Adjusting Protocol</span> will close this evaluation, placing it into a Closed status, and create a new evaluation with an In Progress status.
                <v-tooltip top :open-on-hover="false" v-model="showInfoAdjustProtocol">
                  <template v-slot:activator="{}">
                    <v-icon @click="onShowInfoAdjustProtocol" color="primary">mdi-information</v-icon>
                  </template>
                  <p style="width: 350px">Adjusting Protocol will close this evaluation, placing it into a Closed status, and create a new evaluation with an In Progress status. Protocol supplements, patient notes and evaluation notes will be duplicated into the new evaluation for your convenience. However, all system generated reminders will be reset. Furthermore, since protocols are based on day's supply, if your patient has already begun utilizing this protocol, any changes may result in the shortages or overages of product for your patient.</p>
                </v-tooltip>
              </div>
            </div>
            <div class="vertical-line"></div>
            <div>
              <div style="width: 48%;text-align: center;display: contents;">
                <Button
                  dark
                  color="#008C89"
                  @click.native="handleAction('end-protocol')"
                  :styles="{ 'border-radius': '8px' }"
                >
                  <span>End Protocol</span>
                </Button>
              </div>
              <br>
              <div class="pl-2">
                <span class="bold-title">End Protocol</span> - Choose this option if you simply want to end this current protocol. This evaluation will change to a Closed status.
              </div>
            </div>
          </div>
        </div>
      </template>

      <template slot="actions">
        <div class="d-flex justify-space-between align-center w-100 pb-4 px-4">
          <Button
            dark
            outlined
            color="grey"
            @click.native="close"
            :styles="{ 'border-radius': '8px' }"
          >
            <span style="color: #425466">Cancel</span>
          </Button>
          <div style="font-size: 13px;">
            <span class="bold-title">Choose an option</span> to continue or <span class="red-title">Close</span> to cancel this request.
          </div>
        </div>
      </template>
    </Dialog>
    <confirm v-if="dialogs.confirm.isOpen" @close="close"/>
  </div>
</template>

<script>
import { mapMutations, mapState } from 'vuex'
import Dialog from '@/components/layouts/dialog'
import Button from '@/components/elements/Button'
import confirm from '@/components/layouts/dialogs/confirm-dialog'

export default {
  name: 'modal-confirmation-end-protocol',
  components: { Dialog, Button, confirm },
  data: () => ({
    showInfoAdjustProtocol: false,
  }),
  methods: {
    ...mapMutations('dialogs', ['TOGGLE_DIALOG']),
    close () {
      this.TOGGLE_DIALOG({ name: 'modalConfirmationEndProtocol', isOpen: false, root: true })
    },
    handleAction(type) {
      this.$emit('request', type)
    },
    onShowInfoAdjustProtocol() {
      this.showInfoAdjustProtocol = !this.showInfoAdjustProtocol
      clearTimeout(this.timerId)
      if (this.showInfoAdjustProtocol) {
        const self = this
        this.$nextTick(() => {
          self.timerId = setTimeout(function () {
            self.showInfoAdjustProtocol = false
          }, 15000)
        })
      }
    }
  },
  computed: {
    ...mapState({
      modalConfirmationEndProtocol: state => state.dialogs.modalConfirmationEndProtocol
    }),
    dialogs () {
      return this.$store.state.dialogs
    }
  }
}
</script>

<style lang="scss" scoped>
.w-100 {
  width: 100%;
}
.confirmation-end-protocol {
  .wrapper {
    display: flex;
    flex-direction: row;

    .vertical-line {
      height: auto;
      width: 1px;
      background: #dfdbdb;
    }
  }
  .bold-title {
    font-weight: 600;
  }
  .red-title {
    color: red;
  }
  ::v-deep .v-card {
    &__title {
      background: #edf2f7;
      padding: 10px 16px!important;
      justify-content: center!important;
      margin-bottom: 10px!important;
    }
  }
  ::v-deep .v-dialog__container {
    .v-card {
      &__title {
        //background: #edf2f7;
        padding: 25px 35px 17px 26px !important;
        justify-content: center!important;
        position: relative;
        .image {
          width: 100%;
          text-align: center;
        }
        .title {
          font-family: 'Poppins';
          font-weight: 600;
          font-size: 16px;
          text-align: center;
          color: #27272E;
        }
        .v-icon {
          display: none;
          position: absolute;
          right: 15px;
          top: 25px;
        }
      }
      &__text {
        .description {
          font-family: 'Poppins';
          padding: 0 35px;
          font-weight: 400;
          font-size: 14px;
          text-align: center;
          color: #425466;
        }
      }
    }
  }
}
</style>
