<template>
  <div id="simple-info-block">
    <v-card class="simple-info-block" @click="openReportView(info.title)">
      <v-card-title class="info-title">{{info.title}}
      
        <div class="show-notes" v-if="info.title.includes('Practitioner')">
          <v-icon @click="openReportView">mdi-eye-outline</v-icon>
        </div>
      </v-card-title>
      <v-card-text>
        <div v-html="info.data"
             style="white-space: pre-wrap;"
             class="info-text"
             @click="startEdit"
             v-if="!isEdit">
        </div>
        <div v-if="isEdit">
          <v-textarea v-model="notes" :auto-grow="true" @blur.stop="stopEdit"></v-textarea><!--@blur.stop="stopEdit"-->
        </div>
      </v-card-text>
      <v-card-actions>
        <div class="action-edit" v-if="!info.disabled">
          <v-icon @click="openDialog">mdi-square-edit-outline</v-icon>
        </div>
      </v-card-actions>
    </v-card>

    <v-dialog 
      v-if="isOpenDialog"
      :value="isOpenDialog"
      width="auto"
      height="auto"
      @click:outside="close"
    >

      <v-card style="padding: 16px; border-radius: 16px; min-width: 350px; width: 40vw;">
        <v-card-title class="px-0 pt-0 d-flex justify-space-between">
          <span>
            {{info.title}}
          </span>
          <v-icon @click.native="close">mdi-close</v-icon>
        </v-card-title>

        <v-card-text style="width: 100%; height: 70%; max-height: 300px; overflow-y: auto; padding: 0 12px; border: 0">
          <v-textarea v-model="notes" :auto-grow="true" @keydown.shift.enter.stop="save"></v-textarea><!--@blur.stop="stopEdit"-->
        </v-card-text>

        <v-card-actions class="justify-end">
          <Button dark
                  btn-bg="dark-green"
                  height="45"
                  :styles="{ 'border-radius': '8px' }"
                  @click.native="save">
            Save
          </Button>
        </v-card-actions>
      </v-card>

    </v-dialog>


    <v-dialog
      v-if="isOpenReportView"
      :value="isOpenReportView"
      width="60%"
      @click:outside="initialDoc = '', loading = false, isOpenReportView = false"
    >
      <div v-if="loading" style="position: absolute;left: 0;right: 0;top: 0;bottom: 0;margin: auto;" class="d-flex justify-center px-2 py-2 align-center">
        <v-progress-circular
          indeterminate
          color="#008C89"
        ></v-progress-circular>
      </div>

      <iframe style="min-height: 600px; height: 85vh;" v-if="initialDoc" :src="initialDoc" />

    </v-dialog>
  </div>

</template>

<script>

import Button from '@/components/elements/Button'
import { mapActions, mapMutations, mapState } from 'vuex'

export default {
  props: {
    info: {
      type: Object
    },
    index: Number
  },
  name: 'simple-info-block',
  components: { Button },
  data: () => ({
    isEdit: false,
    notes: null,
    isOpenDialog: false,
    isOpenReportView: false,

    file: null,
    fileUrl: null,
    initialDoc: null,
    loading: false,
  }),
  computed: {
    ...mapState({
      userProfile: state => state.userProfile,
    }),
  },
  mounted () {
    this.notes = this.info.data
  },
  methods: {
    ...mapMutations('dialogs', ['TOGGLE_DIALOG']),
    ...mapActions('reports', ['gerReportByCode', 'sendSurveyForm', 'sendEmailToPatient', 'sendEmailToPatientMultiple', 'downloadZipReports']),

    startEdit () {
      if (!this.info.disabled) {
        this.notes = this.info.data
        this.isEdit = true
      }
    },
    stopEdit () {
      this.isEdit = false
      this.$emit('updateNote', { notes: this.notes, index: this.index, data: this.info })
    },
    openDialog () {
      this.isOpenDialog = true
      this.notes = this.info.data
    },
    close () {
      this.isOpenDialog = false
    },

    async openReportView (title) {
      if(title.includes('Practitioner')) {
      this.isOpenReportView = true
      this.loading = true
      this.notes = this.info.data

      const payload = {
        appUserId: this.userProfile.userId,
        reportCode: 'UIClinicianNotes',
        entityId: this.$route.params.id,
      }

      const res = await this.gerReportByCode(payload)

      this.file = res
      this.parseFile(this.file)
      this.initialDoc = 'https://pdftron.s3.amazonaws.com/downloads/pl/demo-annotated.pdf'
      this.loading = false
      }
    },

    parseFile (file) {
      if (file) {
        const reader = new FileReader()
        reader.onloadend = () => {
          this.fileUrl = URL.createObjectURL(file)
          this.initialDoc = this.fileUrl
        }
        reader.readAsDataURL(file)
      } else {
        this.fileUrl = null
      }
    },
    closeReportView () {
      this.isOpenReportView = false
    },
    save () {
      this.stopEdit()
      setTimeout(() => {
        this.close()
      }, 0)
    }
  }
}
</script>

<style scoped lang="scss">
#simple-info-block {
  width: 100%;
  display: flex;
  justify-content: end;
  ::v-deep .v-card {
    .v-card__text {
      padding: 0 16px 14px!important;
    }
  }
  ::v-deep .v-card {
    &__title {
        background: #edf2f7;
        padding: 10px 16px!important;
        justify-content: center!important;
        margin-bottom: 10px!important;
      }
    &__text {
      overflow-y: auto;
      max-height: 300px;
    }
  }
}



.show-notes {
  position: absolute;
  right: 10%;
}

@media screen and (max-width: 1650px) {

  .show-notes {
    right: 5%;
  }
    
}

@media screen and (max-width: 1450px) {

  .show-notes {
    right: 0;
  }
    
}

.simple-info-block {
  //padding: 7px!important;
  border-radius: 16px!important;
  width: 90%;
  .info-title {
    font-weight: 600;
    font-size: 20px;
    color: #16192C;
  }
  .info-text {
    font-weight: 400;
    font-size: 14px;
    color: #718096;
  }
  .action-edit {
    position: relative;
    width: 100%;
    ::v-deep .v-icon {
      right: 0;
      bottom: 0;
      position: absolute;
      cursor: pointer;
      color: #A0AEC0;
    }
  }
}
</style>
