<template>
  <v-app>
    <router-view /><!-- :key="$route.path" -->
<!--    <alert v-if="isAlert" v-bind="messages" />-->
<!--    <brand-vendor-selection v-if="brandVendorSelection.isOpen"/>-->
    <trial-subscription v-if="trialSubscriptionDialog.isOpen"/>
    <terms-conditions v-if="dialogTermsConditions.isOpen"/>
    <forcing-subscription v-if="forcingSubscriptionDialog.isOpen"></forcing-subscription>
  </v-app>
</template>

<script>
/* import { mapState } from 'vuex' */
/* import alert from '@/components/layouts/alert' */
import TrialSubscription from '@/components/layouts/dialogs/trial-subscription'
// import BrandVendorSelection from '@/components/layouts/dialogs/brand-vendor-selection.vue'
import { mapState } from 'vuex'
import ForcingSubscription from '@/components/layouts/dialogs/forcing-subscription.vue'
import TermsConditions from '@/components/layouts/dialogs/terms-conditions.vue'
export default {
  name: 'App',
  mounted() {
    this.adjustZoom();
    window.addEventListener('resize', this.adjustZoom);
  },
  methods: {
    adjustZoom() {
      if (window.innerWidth < 1497 && window.innerWidth > 1198) {
        document.body.style.zoom = "80%";
      } else if (window.innerWidth < 1198) {
        document.body.style.zoom = "75%";
      }else {
        document.body.style.zoom = "100%";
      }
    }
  },
  beforeDestroy() {
    window.removeEventListener('resize', this.adjustZoom);
  },
  components: {
    // BrandVendorSelection,
    ForcingSubscription,
    TrialSubscription,
    TermsConditions
    /* alert */
  },
  computed: {
    ...mapState({
      trialSubscriptionDialog: state => state.dialogs.trialSubscriptionDialog,
      forcingSubscriptionDialog: state => state.dialogs.forcingSubscriptionDialog,
      dialogTermsConditions: state => state.dialogs.termAndConditions,
      brandVendorSelection: state => state.dialogs.modalBrandVendorSelection
    })
  }
  /* computed: {
    ...mapState({
      messages: state => state.messages
    }),
    isAlert () {
      return !this._.isEmpty(this.messages)
    }
  } */
}
</script>

<style lang="scss">
  @import '@/styles/main.scss';

  .v-application {
    &--wrap {
      .v-toolbar {
        box-shadow: unset !important;
        border-bottom: 1px solid rgba(0, 0, 0, 0.12) !important;
        .profile {
          &__logout {
            cursor: pointer;
          }
        }
      }
    }
  }

  .grecaptcha-badge { visibility: hidden; }
</style>
