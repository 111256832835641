<template>
  <div class="phone-input">
    <Input v-bind="$props"
      outlined
      :isAutofocus="isAutofocus"
      v-on="inputListeners"
      :placeholder="placeholder ? placeholder : '(XXX) XXX-XXXX'"
      v-mask="mask ? mask : '(###) ###-####'"
      @input="phone=$event">
    </Input>
  </div>
</template>

<script>
import Input from '@/components/elements/Input'

export default {
  name: 'phone-input',
  props: {
    backgroundColor: String,
    className: String,
    value: String,
    label: String,
    radius: [String, Number],
    mask: [String],
    placeholder: [String],
    isAutofocus: {
      type: Boolean,
      default: false
    },
  },
  components: { Input },
  data: () => ({
    phone: ''
  }),
  mounted () {
    this.$set(this.$data, 'phone', this.value)
  },
  computed: {
    inputListeners: function () {
      var vm = this
      return Object.assign({},
        this.$listeners,
        {
          input: function (event) {
            const value = (event?.target) ? event.target.value : event
            vm.$emit('input', value)
          }
        }
      )
    }
  }
}
</script>
