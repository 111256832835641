<template>
  <div class="events-log">
    <div class="main-row justify-center">
      <h2 style="text-align: start;width: 100%;color: #27272E;margin-bottom: 10px">Events Log</h2>
      <Table
        :headers="eventsHeaders"
        @update:option="actionTableData"
        hideDefaultFooter>
        <template v-slot:body>
          <template v-for="item in eventsItems">
            <tr :key="item.id">
              <td>
                <div class="d-flex align-center justify-start py-4">
                  <div class="d-flex">
                    {{$moment(item.createdOn).format('MM/DD/YYYY')}}
                  </div>
                </div>
              </td>
              <td>
                <div class="d-flex align-center justify-start py-4">
                  <div class="d-flex">
                    {{item.message || '-'}}
                  </div>
                </div>
              </td>
            </tr>
          </template>
        </template>
<!--        <template v-slot:item:createdOn={item}>
          <div class="d-flex align-center justify-start">
            <div class="d-flex">
              {{$moment(item.createdOn).format('MM/DD/YYYY')}}
            </div>
          </div>
        </template>
        <template v-slot:item:message={item}>
          <div class="d-flex align-center justify-start">
            <div class="d-flex">
              {{item.message || '-'}}
            </div>
          </div>
        </template>-->

        <template v-slot:footer>
          <div class="footer pl-5 d-flex align-center">
            <div class="row-per-page d-flex align-center flex-grow-1">
              <span class="row-per-page__lable mr-4">Rows per page: </span>
              <Select class="row-per-page__list" radius="8" color="#B5B5BD" :items="rowPerPageItems" :value="rowPerPageItems[1]" @change="changeItermPerPage($event, auditEvents)" />
            </div>
            <div class="flex-grow-2">
              <pagination v-model="page" :value="page" :length="pageCount" :totalVisible="7" @input="changePagination" />
            </div>
            <div class="d-flex align-center justify-end flex-grow-1 mr-6">
            </div>
          </div>
        </template>
      </Table>
    </div>
  </div>
</template>

<script>
import { mapActions, mapState } from 'vuex'
import Table from '@/components/elements/Table'
import Pagination from '@/mixins/Pagination'
import Mapping from '@/mixins/Mapping'
import Select from '@/components/elements/Select'
import pagination from '@/components/layouts/pagination'

export default {
  name: 'events',
  mixins: [Pagination, Mapping],
  components: { Table, Select, pagination },
  data: () => ({
    eventsItems: null
  }),
  async mounted () {
    const { id } = this.$route.params
    const res = await this.getAuditEvents({ id })
    if (res) {
      this.eventsItems = this.auditEvents
      this.setupPagination(this.auditEvents)
    }
  },
  methods: {
    ...mapActions('patients', ['getAuditEvents']),
    actionTableData ($event) {
      if ($event.sortBy.length) {
        this.items = this.items.sort((a, b) => {
          const key = $event.sortBy[0]
          if (key === 'name') {
            if ($event.sortDesc[0]) {
              if (a[key].toLowerCase() < b[key].toLowerCase()) {
                return -1
              }
            } else {
              if (a[key].toLowerCase() > b[key].toLowerCase()) {
                return 1
              }
            }
          } else {
            return $event.sortDesc[0] ? b[key] - a[key] : a[key] - b[key]
          }
        })
      }
    }
  },
  computed: {
    ...mapState({
      // paymentHistory: state => state.subscriptions.paymentHistory
      auditEvents: state => state.patients.auditEvents
    }),
    eventsHeaders () {
      return [
        // {
        //   text: 'Event type',
        //   value: 'eventType',
        //   isSlotable: true,
        //   width: '33%',
        //   isReplacingSpace: 0
        // },
        {
          text: 'Created',
          value: 'createdOn',
          isSlotable: true,
          width: '10%',
          isReplacingSpace: 0
        },
        {
          text: 'Message',
          value: 'message',
          isSlotable: true,
          width: '90%',
          isReplacingSpace: 0
        }
      ]
    },
    rowPerPageItems: () => ([5, 10, 15, 20])
  },
  watch: {
    items (val) {
      this.eventsItems = val
    }
  }
}
</script>

<style scoped lang="scss">
.events-log {
  margin-top: 15px;
  padding-bottom: 180px;
  ::v-deep .v-data-table {
    background-color: #f7fafc;
    td {
      color: #425466;
      border-bottom: thin solid rgba(0,0,0,.12);
      padding: 0 16px;
    }
  }
  .footer {
    height: 60px;
    ::v-deep .v-input {
      .v-messages {
        //display: none;
      }
      &__control {
        .v-text-field__details {
          display: none!important;
        }
      }
    }
    .row-per-page {
      font-size: 14px;
      color: #718096;
      &__list {
        width: 60px;
      }
      ::v-deep .v-menu__content {
        min-width: 62px !important;
        .v-list-item {
          &__title {
            display: flex;
            justify-content: center;
          }
        }
      }
      ::v-deep .v-input {
        &__slot {
          margin: 0 !important;
        }
        &__append-inner {
          margin-left: 0;
          padding-left: 0;
          margin-top: 2px !important;
        }
      }
      ::v-deep .v-select {
        input {
          display: none;
        }
        &__selection {
          font-size: 14px;
          color: #718096;
        }
        &__selections {
          flex: unset !important;
          line-height: 17px;
          margin-left: 5px;
        }
      }
    }
    ::v-deep .v-input {
      &__slot {
        margin: 0 !important;
      }
    }
  }
}
</style>
