<template>
  <div class="textarea">
    <v-textarea v-bind="$props" v-on="textListener" :style="styles" />
  </div>
</template>

<script>
export default {
  name: 'Textarea',
  props: {
    backgroundColor: String,
    color: String,
    clearable: Boolean,
    counter: Boolean,
    hint: String,
    height: [String, Number],
    label: String,
    radius: [String, Number],
    readonly: Boolean,
    outlined: Boolean,
    placeholder: String,
    rules: Array,
    value: String,
    rows: [String, Number],
    rowsHeight: [String, Number],
    dense: {
      type: [String, Boolean]
    },
    autoGrow: {
      type: Boolean
    },
    disabled: Boolean
  },
  computed: {
    textListener: function () {
      var vm = this
      return Object.assign({},
        this.$listeners,
        {
          change: function ($event) {
            vm.$emit('input:text-area', $event)
          }
        }
      )
    },
    styles () {
      return {
        'border-radius': this.radius + 'px'
      }
    }
  }
}
</script>

<style lang="scss" scoped>
  .textarea {
    ::v-deep .v-input {
      fieldset {
        border: none !important;
      }
      textarea {
        color: #7A828A !important;
      }
    }
  }
</style>
