<template>
  <div class="days-supply-dialog">
    <Dialog width="450">
      <template slot="title">
        <div class="title">
          {{daysSupplyDialog.title}}
        </div>
      </template>

      <template slot="body">
        <div class="description">
          Adjust Supply Days with the new value<br>
          Please be aware that Supplement Bottles will be rearranged according to new value
        </div>
        <div style="padding: 0 35px" class="mt-2">
          <div class="main-row supply-info">
            <div class="label">
              Evaluation Date:
            </div>
            <div class="value">
              {{$moment(visit.date).format('MM/DD/YYYY')}}
            </div>
          </div>
          <div class="main-row supply-info">
            <div class="label">
              Days Supply:
            </div>
            <Input
              outlined
              radius="8"
              type="number"
              :min="1"
              @keypress="filterInputData($event, { min: 1, max: 1000 }, 'number')"
              @input="newDaysSupply=$event.replace(/^0/, '1')"
              :value="newDaysSupply"/>
          </div>
        </div>
      </template>

      <template slot="actions">
        <div class="d-flex justify-space-between w-100 pb-4 px-4">
          <Button
            dark
            outlined
            color="grey"
            @click.native="close"
            :styles="{ 'border-radius': '8px' }"
          >
            <span style="color: #425466">Cancel</span>
          </Button>
          <Button
            btn-bg="dark-green"
            :disabled="[0, '0'].includes(newDaysSupply)"
            @click.native="save"
            :styles="{ 'border-radius': '8px' }"
          >
            <span style="color: #FFFFFF">Change</span>
          </Button>
        </div>
      </template>
    </Dialog>
  </div>
</template>

<script>
import { mapState, mapMutations, mapActions } from 'vuex'
import Dialog from '@/components/layouts/dialog'
import Button from '@/components/elements/Button'
import Image from '@/mixins/Image'
import Input from '@/components/elements/Input'
import Number from '@/mixins/Number'

export default {
  name: 'days-supply-dialog',
  props: {
    query: {
      type: Object
    }
  },
  components: { Dialog, Button, Input },
  mixins: [Image, Number],
  data: () => ({
    newDaysSupply: 0
  }),
  mounted () {
    this.newDaysSupply = this.visit.supplyDays
  },
  methods: {
    ...mapActions('visits', ['updateDaysSupply']),
    ...mapActions('results', ['getResults']),
    ...mapMutations('dialogs', ['TOGGLE_DIALOG']),
    ...mapMutations('visits', ['UPDATE_SUPPLY_DAYS']),
    close () {
      this.TOGGLE_DIALOG({ name: 'daysSupplyDialog', isOpen: false })
    },
    async save () {
      if ([0, '0'].includes(this.newDaysSupply)) {
        return
      }
      const data = {
        id: this.$route.params.id,
        data: {
          daysSupply: this.newDaysSupply
        }
      }
      const res = await this.updateDaysSupply(data)
      if (res) {
        this.UPDATE_SUPPLY_DAYS(+this.newDaysSupply)
        this.getResults(this.$route.params.id)
        this.$emit('showPopup')
      }
    }
  },
  computed: {
    ...mapState({
      daysSupplyDialog: state => state.dialogs.daysSupplyDialog,
      visit: state => state.visits?.visit
    })
  }
}
</script>

<style lang="scss" scoped>
.w-100 {
  width: 100%;
}
.days-supply-dialog {
  .main-row {
    display: flex;
    align-items: center;
  }
  .supply-info {
    .label {
      color: black;
      font-size: 14px;
      font-weight: bold;
      margin-right: 5px;
    }
    .value {
      color: black;
      font-size: 14px;
      font-weight: bold;
      margin-right: 5px;
    }
    .input {
      ::v-deep .v-input {
        width: 75px!important;
      }
    }
  }
  ::v-deep .v-card {
    &__title {
      background: #edf2f7;
      padding: 10px 16px!important;
      justify-content: center!important;
      margin-bottom: 10px!important;
    }
  }
  ::v-deep .v-dialog__container {
    .v-card {
      &__title {
        padding: 25px 35px 17px 26px !important;
        justify-content: center!important;
        position: relative;
        .image {
          width: 100%;
          text-align: center;
        }
        .title {
          font-family: 'Poppins';
          font-weight: 600;
          font-size: 16px;
          text-align: center;
          color: #27272E;
        }
        .v-icon {
          display: none;
          position: absolute;
          right: 15px;
          top: 25px;
        }
      }
      &__text {
        .description {
          font-family: 'Poppins';
          padding: 0 35px;
          font-weight: 400;
          font-size: 14px;
          //text-align: center;
          color: #425466;
        }
        .v-input {
          .v-text-field__details {
            display: none;
          }
        }
      }
    }
  }
}
</style>
