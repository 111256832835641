<template>
  <div class="timer">
    00:{{ currentTime | formatTime }}
  </div>
</template>

<script>
export default {
  name: 'timer',
  data: () => ({
    currentTime: '05'
  }),
  filters: {
    formatTime (value) {
      if (value < 5) {
        return '0' + value
      }
      return value
    }
  },
  watch: {
    currentTime: {
      handler (value) {
        if (value > 0) {
          setTimeout(() => {
            this.currentTime--
          }, 1000)
        } else {
          this.$emit('time:up')
        }
      },
      immediate: true
    }
  }
}
</script>

<style lang="scss" scoped>
  .timer {
    // ..
  }
</style>
