<template>
  <div class="terms-conditions">
    <Dialog width="792" :scrollable="false">

      <template slot="title">
        <span>
          Terms and conditions
        </span>
      </template>

      <template slot="body">
        <div class="terms-description">
          <p>Welcome to Maestro Nutrition LLC! We are a subscription-based Software-as-a-Service (SaaS) that provides a Service for healthcare practitioners to survey and evaluate their patients, recommended supplements, and prioritize patients nutritional support needs. These Terms of Use (“Terms”) are a contract between you (the “Members” or “Subscribers”) and Maestro Nutrition LLC (“Maestro Nutrition” or “We”) and govern your access to and use of any Maestro Nutrition and Survey Maestro websites or content (the “Site”), or Systems Survey Maestro and Maestro Nutrition service (the “Service” or “Platform”). Please read these Terms carefully before accessing and/or using the Site and/or Platform.</p>
          <p>THESE TERMS CONTAIN A BINDING ARBITRATION AGREEMENT AND CLASS ACTION WAIVER THAT REQUIRE YOU TO ARBITRATE ALL DISPUTES YOU HAVE WITH CLASS PASS ON AN INDIVIDUAL BASIS. PLEASE SEE SECTION 13 FOR MORE INFORMATION ABOUT THE ARBITRATION AGREEMENT AND CLASS ACTION WAIVER. YOU EXPRESSLY AGREE THAT DISPUTES BETWEEN YOU AND CLASSPASS WILL BE RESOLVED BY BINDING, INDIVIDUAL ARBITRATION, AND YOU HEREBY WAIVE YOUR RIGHT TO PARTICIPATE IN A CLASS ACTION LAWSUIT OR CLASS WIDE ARBITRATION.</p>
          <ol>
            <li><strong>Acceptance of Terms of Use </strong>These Terms of Use, govern your use of the Maestro Nutrition service. By using, the Maestro Nutrition service, you accept and agree to these Terms of Use. If you do not agree to these Terms of Use, do not use the Maestro Nutrition service.</li>
            <li><strong>Changes to Terms of Use. </strong>Maestro Nutrition may, from time to time, change these Terms of Use, including the Privacy Policy. Such revisions shall be effective immediately; provided however, for existing members, such revision shall, unless otherwise stated, be effective 30 days after posting.</li>
            <li><strong>Privacy. </strong>Personally identifiable information is subject to our Privacy Policy, the terms of which are incorporated herein (<a href="http://www.maestronutrition.com/privacy" target="_blank" rel="noopener">http://www.maestronutrition.com/privacy</a>). Please review our Privacy Policy to understand our practices.</li>
            <li><strong>Communication Preferences. </strong>By using the Maestro Nutrition service, you consent to receiving electronic communications from Maestro Nutrition relating to your account. These communications may involve sending emails to your email address provided during registration, or posting communications on the Maestro Nutrition service, or in the “Your Account” page and will include notices about your account (e.g., payment authorizations, change in password or Payment Method, confirmation e-mails and other transactional information) and are part of your relationship with Maestro Nutrition. You agree that any notice, agreements, disclosure, or other communications that we send to you electronically will satisfy any legal communication requirements, including that such communications be in writing. You should maintain copies of electronic communications by printing a paper copy or saving an electronic copy. You also consent to receiving certain other communications from us, such as newsletters about new Maestro Nutrition features and content, special offers, promotional announcements and customer surveys via email or other methods.</li>
            <li>DISCLAIMER: MAESTRO NUTRITION IS NOT A DOCTOR. THE SITE OR SERVICE DOES NOT PROVIDE MEDICAL ADVICE.<br>
              Maestro Nutrition service is an educational tool and is intended to be used by healthcare providers appropriately licensed and/or legally permitted by their state and/or licensure board or entity. Statements made on or within the Site or Service have not been evaluated by the Food and Drug Administration. The adjunctive schedules and products are not intended to diagnose, treat, cure, or prevent disease, nor should they be perceived as a substitute for medical advice, diagnosis, or treatment. The Site and Service are not doctors and do not provide medical advice.</li>
            <li><strong><strong>Membership, Free Trials, Billing and Cancellation</strong></strong>
              <ol>
                <li><strong><strong>Membership</strong></strong>
                  <ol>
                    <li><strong>Ongoing Membership. </strong>Your Maestro Nutrition membership, which may start with a free trial, will continue month-to-month or year-to-year unless and until you cancel your membership, or we terminate it. You must have Internet access and provide us with a current, valid, accepted method of payment (as such may be updated from time to time, “Payment Method”) to use the Maestro Nutrition service. We will bill the monthly membership fee to your Payment Method. You must cancel your membership before it renews each month in order to avoid billing of the next month’s membership fees to your Payment Method.</li>
                    <li><strong>Differing Memberships. </strong>We may offer a number of membership plans, including special promotional plans or memberships with differing conditions and limitations. Any materially different terms from those described in these Terms of Use will be disclosed at your sign-up or in other communications made available to you. You can find specific details regarding your membership with Maestro Nutrition by visiting your account page. We reserve the right to modify, terminate or otherwise amend our offered membership plans.</li>
                  </ol>
                </li>
                <li><strong><strong>Free Trials</strong></strong>
                  <ol>
                    <li>Your Maestro Nutrition membership may start with a free trial. The free trial period of your membership lasts for one month (30 days), or as otherwise specified during sign-up. Free trials may not be combined with any other offers. Free trials are for new and certain former members only. Maestro Nutrition reserves the right, in its absolute discretion, to determine your free trial eligibility.</li>
                    <li>You will receive notices from us that your free trial period is ending and/or if you would like to continue as an official member. Please note that if continuation is not elected and the trial period expires, the account will be formally cancelled and ACCESS TO ALL DATA WILL BE SUSPENDED.</li>
                  </ol>
                </li>
                <li><strong><strong>Billing</strong></strong>
                  <ol>
                    <li><strong>Recurring Billing. </strong>By starting your Maestro Nutrition membership, you authorize us to charge you a monthly or yearly membership fee at the then current rate, and any other charges you may incur in connection with your use of the Maestro Nutrition service to your Payment Method. You acknowledge that the amount billed each may vary from month to month or year-to-year for reasons that may include differing amounts due to promotional offers and/or changing or adding a plan, and you authorize us to charge your Payment Method for such varying amounts, which may be billed monthly or yearly in one or more charges.</li>
                    <li><strong>Price Changes. </strong>We reserve the right to adjust pricing for our service or any components thereof in any manner and at any time as we may determine in our sole and absolute discretion. Except as otherwise expressly provided for in these Terms of Use, any price changes to your service will take effect following email notice to you.</li>
                    <li><strong>Billing Cycle. </strong>The membership fee for our service will be billed at the beginning of the paying portion of your membership and each month or year thereafter unless and until you cancel your membership. We automatically bill your Payment Method each month or year on the calendar day corresponding to the commencement of your paying membership. Membership fees are fully earned upon payment. We reserve the right to change the timing of our billing as indicated below if your Payment Method has not successfully settled. In the event your membership bill date began on a day not contained in a given month, we may bill your Payment Method on a day in the applicable month or such other day as we deem appropriate. For example, if you started your Maestro Nutrition membership or became a paying member on January 31st, your next payment date is likely to be February 28th, and your Payment Method would be billed on that date. Your renewal date may change due to changes in your Membership. We may authorize your Payment Method in anticipation of membership or service-related charges. As used in these Terms of Use, “billing” shall indicate a charge, debit, or other payment clearance, as applicable, against your Payment Method. Unless otherwise stated differently, month or monthly, year or yearly, refers to your billing cycle.</li>
                    <li><strong>No Refunds. </strong>PAYMENTS ARE NONREFUNDABLE AND THERE ARE NO REFUNDS OR CREDITS FOR PARTIALLY USED PERIODS. Following any cancellation, however, you will continue to have access to the service through the end of your current billing period. At any time, and for any reason, we may provide a refund, discount, or other consideration to some or all of our members. The amount and form of such credits, and the decision to provide them, are at our sole and absolute discretion.</li>
                    <li><strong>Payment Methods. </strong>You may edit your Payment Method information within your account or by calling Maestro Nutrition. If a payment is not successfully settled, due to expiration, insufficient funds, or otherwise, and you do not edit your Payment Method information or cancel your account (see, “Cancellation” below), you remain responsible for any uncollected amounts and authorize us to continue billing the Payment Method, as it may be updated. This may result in a change to your payment billing dates. You may also find billing information about your Maestro Nutrition membership by visiting your account. For certain Payment Methods, the issuer of your Payment Method may charge you a foreign transaction fee or other charges. Check with your Payment Method service provider for details.</li>
                  </ol>
                </li>
                <li><strong>Cancellation. </strong>You may cancel your Maestro Nutrition membership at any time, and you will continue to have access to the Maestro Nutrition service through the end of your monthly billing period. WE DO NOT PROVIDE REFUNDS OR CREDITS FOR ANY PARTIAL-MONTH, PARTIAL-YEAR, MEMBERSHIP AND ONCE MEMBERSHIP IS CANCELLED, ACCESS TO CLIENT DATA WILL BE SUSPENDED. To cancel, please call Maestro Nutrition.</li>
              </ol>
            </li>
            <li><strong><strong>Maestro Nutrition Service</strong></strong>
              <ol>
                <li>To become a member, you must be at least 18 and be legally allowed to evaluate, and recommend to patients, supplements according to your state and licensure laws. It is your responsibility to verify your legal abilities prior to becoming a member. The service is not intended for individuals under the age of 18. Maestro Nutrition only recommends certain professional supplements lines; thus, members should verify that they are allowed to obtain reseller accounts with those professional companies. Although Maestro Nutrition does not actively validate these provisions, we reserve the right to terminate any account as we may determine in our sole and absolute discretion.</li>
                <li>The Maestro Nutrition service, and any content viewed through our service, are for your professional use only. During your Maestro Nutrition membership, we grant you a limited, non-exclusive, non-transferable, license to access the Maestro Nutrition. Except for the foregoing limited license, no right, title or interest shall be transferred to you.</li>
                <li>You may utilize the Maestro Nutrition service anywhere you can access the world wide web. It is intended to be used by one healthcare entity, regardless of the number of healthcare practitioners within that entity. Use of the service outside of these parameters is subject for cancellation as we may determine in out sole and absolute discretion.</li>
                <li>We continually update the Maestro Nutrition service. In addition, we continually test various aspects of our service, including our website, user interfaces, service levels, standard features, delivery, and pricing. We reserve the right to, and by using our service you agree that we may, include you in or exclude you from these tests without notice. We reserve the right in our sole and absolute discretion to make changes from time to time and without notice in how we offer and operate our service.</li>
                <li>You agree to use the Maestro Nutrition service, including all features and functionalities associated therewith, in accordance with all applicable laws, rules and regulations. You agree not to archive, download, reproduce, distribute, modify, display, perform, publish, license, create derivative works from, offer for sale, or use (except as explicitly authorized in these Terms of Use) content and information contained on or obtained from or through the Maestro Nutrition service without express written permission from Maestro Nutrition. You also agree not to: circumvent, remove, alter, deactivate, degrade or thwart any of the content protections in the Maestro Nutrition service; use any robot, spider, scraper or other automated means to access the Maestro Nutrition service; decompile, reverse engineer or disassemble any software or other products or processes accessible through the Maestro Nutrition service; insert any code or product or manipulate the content of the Maestro Nutrition service in any way; or, use any data mining, data gathering or extraction method. In addition, you agree not to upload, post, e-mail or otherwise send or transmit any material designed to interrupt, destroy, or limit the functionality of any computer software or hardware or telecommunications equipment associated with the Maestro Nutrition service, including any software viruses or any other computer code, files, or programs.</li>
                <li>Maestro Nutrition is designed to be utilized over the internet via the world wide web. The performance of the service may vary from computer to computer, and device to device, and may be affected by a variety of factors, such as your location, the bandwidth available through and/or speed of your Internet connection. You are responsible for all Internet access charges. Please check with your Internet provider for information on possible Internet data usage charges. WE DO NOT TAKE RESPONSIBILITY OR OTHERWISE MAKE ANY REPRESENTATIONS OR WARRANTIES TO THE PERFORMANCE OF THIS SERVICE, INCLUDING THE CONTINUING COMPATIBILITY OF YOUR ENVIRONMENT WITH OUR SERVICE.</li>
                <li>We may terminate or restrict your use of our service, without compensation or notice if you are, or if we suspect that you are (i) in violation of any of these Terms of Use or (ii) engaged in illegal or improper use of the service.</li>
              </ol>
            </li>
            <li><strong><strong>Passwords &amp; Account Access</strong></strong>
              <ol>
                <li>The member who created the Maestro Nutrition account and whose Payment Method is charged is referred to here as the Account Owner. The Account Owner has access and control over the Maestro Nutrition account. The Account Owner’s control is exercised through use of the Account Owner’s username and password and, therefore, to maintain exclusive control, the Account Owner should not reveal the password to anyone. In addition, if the Account Owner wishes to prohibit others from contacting Maestro Nutrition Customer Service and potentially altering the Account Owner’s control, the Account Owner should not reveal the Payment Method details associated with their account. The Account Owner may add other sub-account users who will have their own usernames and passwords. These users, depending on the permissions afforded to them by the Account Owner, may have access to data up to and including Payment Method details. You are responsible for updating and maintaining the truth and accuracy of the information you provide to us relating to your account.</li>
                <li>BY SHARING THE MAESTRO NUTRITION SERVICE PASSWORD OR ALLOWING OTHERS TO ACCESS YOUR ACCOUNT, THE ACCOUNT OWNER AGREES TO BE RESPONSIBLE FOR ASSURING THAT OTHER MEMBERS COMPLY WITH THE TERMS OF USE AND SUCH ACCOUNT OWNER SHALL BE RESPONSIBLE FOR THE ACTIONS OF THE OTHER MEMBERS.</li>
                <li>You should be mindful of any communication requesting that you submit credit card or other account information. Providing your information in response to these types of communications can result in identity theft. Always access your sensitive account information by going directly to the Maestro Nutrition website and not through a hyperlink in an email or any other electronic communication, even if it looks official. Maestro Nutrition reserves the right to place any account on hold anytime with or without notification to the member in order to protect itself and its partners from what it believes to be fraudulent activity. Maestro Nutrition is not obligated to credit or discount a membership for holds placed on the account by either a representative of Maestro Nutrition or by the automated processes of Maestro Nutrition.</li>
              </ol>
            </li>
            <li><strong><strong>Disclaimers of Warranties and Limitations on Liability</strong></strong>
              <ol>
                <li>THE MAESTRO NUTRITION SERVICE AND ALL CONTENT AND SOFTWARE ASSOCIATED THEREWITH, OR ANY OTHER FEATURES OR FUNCTIONALITIES ASSOCIATED WITH THE MAESTRO NUTRITION SERVICE, ARE PROVIDED “AS IS” AND “AS AVAILABLE” WITH ALL FAULTS AND WITHOUT WARRANTY OF ANY KIND. MAESTRO NUTRITION DOES NOT GUARANTEE, REPRESENT, OR WARRANT THAT YOUR USE OF THE MAESTRO NUTRITION SERVICE WILL BE UNINTERRUPTED OR ERROR-FREE. MAESTRO NUTRITION SPECIFICALLY DISCLAIMS LIABILITY FOR THE USE OF APPLICATIONS AND MAESTRO NUTRITION SOFTWARE (INCLUDING THEIR CONTINUING COMPATIBILITY WITH OUR SERVICE).</li>
                <li>TO THE EXTENT PERMISSIBLE UNDER APPLICABLE LAWS, IN NO EVENT SHALL MAESTRO NUTRITION, OR ITS SUBSIDIARIES OR ANY OF THEIR SHAREHOLDERS, DIRECTORS, OFFICERS, EMPLOYEES OR LICENSORS BE LIABLE (JOINTLY OR SEVERALLY) TO YOU FOR PERSONAL INJURY OR ANY SPECIAL, INCIDENTAL, INDIRECT OR CONSEQUENTIAL DAMAGES OF ANY KIND, OR ANY DAMAGES WHATSOEVER.</li>
                <li>SOME JURISDICTIONS DO NOT ALLOW THE EXCLUSION OF CERTAIN WARRANTIES OR THE LIMITATION OR EXCLUSION OF LIABILITY FOR CERTAIN TYPES OF DAMAGES. THEREFORE, SOME OF THE ABOVE LIMITATIONS IN THIS SECTION MAY NOT APPLY TO YOU.</li>
                <li>NOTHING IN THESE TERMS OF USE SHALL AFFECT ANY NON-WAIVABLE STATUTORY RIGHTS THAT APPLY TO YOU. If any provision or provisions of these Terms of Use shall be held to be invalid, illegal, or unenforceable, the validity, legality and enforceability of the remaining provisions shall remain in full force and effect.</li>
              </ol>
            </li>
            <li><strong><strong>Intellectual Property</strong></strong>
              <ol>
                <li><strong>Copyright. </strong>The Maestro Nutrition service, including all content provided on the Maestro Nutrition service, is protected by United States and international copyright, trade secret or other intellectual property laws and treaties.</li>
                <li><strong>Claims of Copyright Infringement. </strong>If you believe your work has been reproduced or distributed in a way that constitutes copyright infringement or are aware of any infringing material available through the Maestro Nutrition service, please notify us at&nbsp;<a href="mailto:support@maestronutrition.com">support@maestronutrition.com</a>.</li>
              </ol>
            </li>
            <li><strong><strong>Governing Law</strong></strong>
              <ol>
                <li>For all Maestro Nutrition members, these Terms of Use shall be governed by and construed in accordance with the laws of the state of Florida, U.S.A. without regard to conflict of laws provisions.</li>
                <li>You may also be entitled to certain consumer protection rights under the laws of your local jurisdiction.</li>
              </ol>
            </li>
            <li><strong>Use of Information Submitted. </strong>Maestro Nutrition is free to use any comments, information, ideas, concepts, reviews, or techniques or any other material contained in any communication you may send to us (“Feedback”), including responses to questionnaires or through postings to the Maestro Nutrition service, including the Maestro Nutrition website and user interfaces, without further compensation, acknowledgement or payment to you for any purpose whatsoever including, but not limited to, developing, manufacturing and marketing products and creating, modifying or improving the Maestro Nutrition service. In addition, you agree not to enforce any “moral rights” in and to the Feedback, to the extent permitted by applicable law.</li>
            <li><strong>Customer Support. </strong>If you need assistance with your account, please contact Maestro Nutrition via email, the website, or phone. In certain instances, Customer Service may best be able to assist you by using a remote access support tool through which we have full access to your computer. If you do not want us to have this access, you should not consent to support through the remote access tool, and we will assist you through other means. In the event of any conflict between these Terms of Use and information provided by Customer Support or other portions of our website, these Terms of Use will control.</li>
          </ol>
          <p>PLEASE READ THIS SECTION CAREFULLY – IT MAY SIGNIFICANTLY AFFECT YOUR LEGAL RIGHTS, INCLUDING YOUR RIGHT TO FILE A LAWSUIT IN COURT</p>
          <ol start="14">
            <li><strong> Resolving Disputes</strong></li>
          </ol>
          <ol>
            <li style="list-style-type: none;">
              <ol>
                <li><strong>Informal Resolution.</strong>&nbsp;Before filing a claim in connection with Terms or the Services, you agree to try to resolve the dispute informally by contacting Maestro Nutrition. If the dispute is not resolved within 30 days of contact, you or we may bring a formal arbitration proceeding as described below.</li>
                <li><strong>Agreement to Arbitrate.</strong>&nbsp;You and we agree to resolve any claims relating to these Terms or the Services through final and binding arbitration, except as indicated below under “Exceptions to Agreement to Arbitrate” or where prohibited by law. The arbitration will be held in the state Florida, county of Pinellas. You and we agree to submit to the personal jurisdiction of any or state or federal court in the state of Florida to compel arbitration, stay proceedings pending arbitration, or to confirm, modify, vacate or enter judgment on the award entered by the arbitrator.</li>
                <li><strong>Exceptions to Agreement to Arbitrate.</strong>&nbsp;Either you (or we) may assert claims in the small claims court in Pinellas County, Florida, provided the informal dispute resolution described above was unsuccessful and the claim is eligible to be filed. Either party may bring a lawsuit solely for injunctive relief to stop unauthorized use or abuse of the Services or intellectual property infringement (for example, trademark, trade secret, copyright, or patent rights) without first engaging in arbitration or the informal resolution process described above. The agreement to arbitrate does not apply to (a) claims that may not be arbitrated as a matter of law. Arbitration does apply to all other claims relating to these Terms or the Services including (1) the interpretation, formation, performance, or breach of the Terms, (2) whether any part of the Terms is void or voidable, (3) whether a claim is subject to arbitration, and/or (4) whether arbitration has been waived due to litigation conduct.</li>
                <li><strong>Arbitration Procedures.</strong>&nbsp;The arbitration will be conducted by a single arbitrator, governed by the Consumer Arbitration Rules of the American Arbitration Association (“AAA”), (www.adr.org/Rules) unless otherwise agreed to by the parties. You may also obtain the Consumer Arbitration Rules by calling AAA at 1-800-778-7879, TTY 711.</li>
                <li><strong>Arbitration and Attorney’s Fees.</strong> Payment of all filing, administration, and arbitrator costs and expenses imposed by AAA will be governed by the Consumer Arbitration Rules, provided that if you are initiating an arbitration against us and the value of the relief sought is $10,000 or less, then we will advance all filing, administrative and arbitration costs and expenses imposed by AAA (subject to reimbursement if the arbitrator finds the arbitration to be frivolous). You are responsible for all other additional costs that you may incur in the arbitration including attorney’s fees and expert witness costs unless we are otherwise specifically required to pay such fees under applicable law. The decision of the arbitrator will be in writing and binding and conclusive on us and you, and judgment to enforce the decision may be entered by any court of competent jurisdiction. You agree that dispositive motions, including without limitation, motions to dismiss and motions for summary judgment, will be allowed in the arbitration. The arbitrator must follow these rules and can award the same damages and relief as a court, including injunctive or other equitable relief and attorneys’ fees. You and we agree not to seek any attorneys’ fees and expert witness costs unless the arbitrator finds that a claim or defense was frivolous or asserted for an improper purpose. Applicable law may allow the arbitrator to award attorneys’ fees and costs to the prevailing party.Except as explicitly provided in these Terms, if any clause within these arbitration provisions is found to be illegal or unenforceable, that clause will be severed from these arbitration provisions, and the remainder of the arbitration provisions will be given full force and effect. The terms of these arbitration provisions will also apply to any claims asserted by you against any present or future parent or affiliated company of ours arising out of use of the Services.</li>
                <li><strong>No Class Actions</strong>. PLEASE READ THIS SECTION CAREFULLY – IT MAY SIGNIFICANTLY AFFECT YOUR LEGAL RIGHTS
                  <ol>
                    <li>BY AGREEING TO THIS ARBITRATION AGREEMENT, YOU ARE GIVING UP YOUR RIGHT TO GO TO COURT, INCLUDING YOUR RIGHT TO A JURY TRIAL AND TO PARTICIPATE IN A CLASS ACTION. YOU UNDERSTAND THAT BY AGREEING TO THIS ARBITRATION AGREEMENT AND CLASS ACTION WAIVER, YOU MAY ONLY BRING CLAIMS AGAINST US AND OUR RELATED PARTIES IN AN INDIVIDUAL CAPACITY AND NOT AS A PLAINTIFF OR CLASS MEMBER IN ANY PURPORTED CLASS ACTION OR REPRESENTATIVE PROCEEDING. IF YOU DO NOT AGREE TO ARBITRATION, YOU SHOULD OPT OUT OF THIS ARBITRATION PROVISION PER THE TERMS. OTHERWISE, YOU ARE NOT AUTHORIZED TO USE THE ONLINE SERVICES IN ANY WAY.</li>
                    <li>If any court or arbitrator determines that this “No Class Actions” section is void or unenforceable for any reason or that an arbitration can proceed on a class basis, then the arbitration provisions set forth above will be deemed null and void in their entirety and the parties will be deemed to have not agreed to arbitrate disputes.</li>
                  </ol>
                </li>
                <li><strong>Changes to this Section.&nbsp;</strong>We will provide 30 days’ notice of any changes to this section. Amendments will become effective thirty days after we provide such notice. If a court or arbitrator decides that this subsection on “Changes to this Section” is not enforceable or valid, then this subsection will be severed from the section entitled “Resolving Disputes” and the court or arbitrator will apply the first “Resolving Disputes” section in existence after you began using the Online Services.</li>
                <li><strong>Survival.&nbsp;</strong>This “Resolving Disputes” section will survive any termination of your account, enrollment in any program, eligibility for coverage, or the Services.</li>
              </ol>
            </li>
          </ol>
          <ol>
            <li style="list-style-type: none;"></li>
          </ol>
        </div>
      </template>

    </Dialog>
  </div>
</template>

<script>
import Dialog from '@/components/layouts/dialog'

export default {
  name: 'terms-conditions',
  components: { Dialog }
}
</script>

<style scoped lang="scss">
.terms-conditions {
  ::v-deep .v-dialog__content.v-dialog__content--active {
    padding: 50px 0!important;
  }
  ::v-deep .v-dialog__container .v-dialog__content .v-dialog {
    overflow-y: hidden;
    .v-card {
      background: white!important;
    }
    .v-card .v-card__title {
      position: relative;
      padding: 25px 40px 0 !important;

      .v-icon {
        position: absolute;
        cursor: pointer;
        right: 19px;
        top: 15px;
      }
    }

    .v-card {
      &__text {
        overflow-y: scroll;
        height: 90vh;
      }
    }
  }

  .terms-description {
    font-size: 12px;
  }

  .label {
    font-size: 14px;
    font-weight: 500;
    color: #425466
  }
}
</style>
