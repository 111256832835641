var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[(_vm.dialogs.confirm.isOpen)?_c('ConfirmDialog',{attrs:{"callback":this.emailCallback}}):_vm._e(),_c('Dialog',{staticClass:"evaluation_report",attrs:{"width":"800px","styles":{ 'border-radius': '16px' }}},[_c('template',{slot:"title"},[_c('div',[_c('span',{staticStyle:{"font-size":"x-large","color":"black","font-weight":"bold"}},[_vm._v(" Print / Download / Email to Patient ")]),_c('div',{staticClass:"language-switcher"},[_c('Button',{class:{ 'active-language': _vm.activeLanguage === 'es' },attrs:{"block":"","dark":"","tabindex":"3","height":"36"},nativeOn:{"click":function($event){return _vm.switchLanguage('es')}}},[_vm._v(" Spanish ")]),_c('Button',{class:{ 'active-language': _vm.activeLanguage === 'en' },attrs:{"block":"","dark":"","tabindex":"3","height":"36"},nativeOn:{"click":function($event){return _vm.switchLanguage('en')}}},[_vm._v(" English ")])],1)])]),_c('template',{slot:"body"},[_c('div',{staticClass:"reports"},[_c('v-row',{staticStyle:{"gap":"15px","border-bottom":"1px solid #e4ecf7"},attrs:{"no-gutters":""}},[_c('v-col',{staticStyle:{"width":"100%"}},[_c('v-card',{staticClass:"px-2 py-1"},[_c('v-card-title',{staticClass:"report-label"},[_vm._v("Clinician Reports")]),_c('v-card-text',{staticClass:"btn-group-report"},[_c('div',{staticClass:"btn-report"},[_c('v-checkbox',{attrs:{"color":"#008C89","label":"Clinician Report"},on:{"click":function($event){return _vm.selectReport(
												$event,
												'ClinicianFinalReport',
												_vm.clinicReport
											)}},model:{value:(_vm.clinicReport),callback:function ($$v) {_vm.clinicReport=$$v},expression:"clinicReport"}})],1),_c('div',{ref:"clinicianWarnings",staticClass:"btn-report"},[_c('v-checkbox',{attrs:{"id":"clinicianWarnings","color":"#008C89","label":"Clinician Warnings"},on:{"click":function($event){return _vm.selectReport(
												$event,
												'ClinicianWarnings',
												_vm.clinicianWarnings
											)}},model:{value:(_vm.clinicianWarnings),callback:function ($$v) {_vm.clinicianWarnings=$$v},expression:"clinicianWarnings"}})],1),(_vm.isSubscriptionIncludesBlood)?_c('div',{ref:"clinicianBloodPanelResults",staticClass:"btn-report"},[_c('v-checkbox',{attrs:{"color":"#008C89","label":"Clinician Blood Panel Results"},on:{"click":function($event){return _vm.selectReport(
												$event,
												'BloodPanelClinician',
												_vm.clinicianBloodPanelResults
											)}},model:{value:(_vm.clinicianBloodPanelResults),callback:function ($$v) {_vm.clinicianBloodPanelResults=$$v},expression:"clinicianBloodPanelResults"}})],1):_vm._e()])],1)],1),_c('v-col',{staticStyle:{"width":"100%"}},[_c('v-card',{staticClass:"px-2 py-1"},[_c('v-card-title',{staticClass:"report-label"},[_vm._v("Patient Reports")]),_c('v-card-text',{staticClass:"btn-group-report"},[_c('div',{ref:"supplementSchedule",staticClass:"btn-report"},[_c('v-checkbox',{attrs:{"color":"#008C89","label":"Supplement Schedule"},on:{"click":function($event){return _vm.selectReport(
												$event,
												'PatientSchedule',
												_vm.supplementSchedule
											)}},model:{value:(_vm.supplementSchedule),callback:function ($$v) {_vm.supplementSchedule=$$v},expression:"supplementSchedule"}})],1),_c('div',{staticClass:"btn-report custom"},[_c('v-checkbox',{attrs:{"color":"#008C89","label":"Group Description"},on:{"click":function($event){return _vm.selectReport(
												$event,
												'PatientGroupsInfo',
												_vm.localGroupDescription
											)}},model:{value:(_vm.localGroupDescription),callback:function ($$v) {_vm.localGroupDescription=$$v},expression:"localGroupDescription"}}),_c('div',{staticClass:"wrapper-group-description-report"},[_c('div',{staticClass:"group-description-report"},[_c('v-select',{staticClass:"custom-select",attrs:{"disabled":!_vm.getIfAnyReportsSelected,"items":_vm.items,"value":_vm.items[0],"solo":""},model:{value:(_vm.groupDescription),callback:function ($$v) {_vm.groupDescription=$$v},expression:"groupDescription"}})],1)])],1),_c('div',{ref:"surveyScoreSheet",staticClass:"btn-report"},[_c('v-checkbox',{attrs:{"color":"#008C89","label":"Survey Score Sheet"},on:{"click":function($event){return _vm.selectReport(
												$event,
												'PatientSurveyScoreSheet',
												_vm.localSurveyScoreSheet
											)}},model:{value:(_vm.localSurveyScoreSheet),callback:function ($$v) {_vm.localSurveyScoreSheet=$$v},expression:"localSurveyScoreSheet"}})],1),_c('div',{ref:"supplementCostAndQuantities",staticClass:"btn-report"},[_c('v-checkbox',{attrs:{"color":"#008C89","label":"Costs and Quantities"},on:{"click":function($event){return _vm.selectReport(
												$event,
												'PatientSurveyCostsAndQuantities',
												_vm.supplementCostAndQuantities
											)}},model:{value:(_vm.supplementCostAndQuantities),callback:function ($$v) {_vm.supplementCostAndQuantities=$$v},expression:"supplementCostAndQuantities"}})],1),_c('div',{ref:"surveyFormSummary",staticClass:"btn-report"},[_c('v-checkbox',{attrs:{"color":"#008C89","label":"Survey Form Summary"},on:{"click":function($event){return _vm.selectReport(
												$event,
												'PatientSurveyFormSummary',
												_vm.surveyFormSummary
											)}},model:{value:(_vm.surveyFormSummary),callback:function ($$v) {_vm.surveyFormSummary=$$v},expression:"surveyFormSummary"}})],1),_c('div',{ref:"surveyComparisonChart",staticClass:"btn-report"},[_c('v-checkbox',{attrs:{"color":"#008C89","label":"Survey Comparison Chart"},on:{"click":function($event){return _vm.selectReport(
												$event,
												'SurveyComparisonChart',
												_vm.surveyComparisonChart
											)}},model:{value:(_vm.surveyComparisonChart),callback:function ($$v) {_vm.surveyComparisonChart=$$v},expression:"surveyComparisonChart"}})],1),(_vm.isSubscriptionIncludesBlood)?_c('div',{ref:"bloodPanelResults",staticClass:"btn-report"},[_c('v-checkbox',{attrs:{"color":"#008C89","label":"Blood Panel Results"},on:{"click":function($event){return _vm.selectReport(
												$event,
												'BloodPanelPatient',
												_vm.bloodPanelResults
											)}},model:{value:(_vm.bloodPanelResults),callback:function ($$v) {_vm.bloodPanelResults=$$v},expression:"bloodPanelResults"}})],1):_vm._e(),(_vm.isSubscriptionIncludesBlood)?_c('div',{ref:"bloodPanelResultsWithAboveBelowOptimal",staticClass:"btn-report"},[_c('v-checkbox',{attrs:{"color":"#008C89","label":"Blood Panel Results w/ Optimals"},on:{"click":function($event){return _vm.selectReport(
												$event,
												'BloodPanelPatientOptimals',
												_vm.bloodPanelResultsWithAboveBelowOptimal
											)}},model:{value:(_vm.bloodPanelResultsWithAboveBelowOptimal),callback:function ($$v) {_vm.bloodPanelResultsWithAboveBelowOptimal=$$v},expression:"bloodPanelResultsWithAboveBelowOptimal"}})],1):_vm._e(),(_vm.isSubscriptionIncludesBlood)?_c('div',{ref:"bloodPanelCompare",staticClass:"btn-report"},[_c('v-checkbox',{attrs:{"color":"#008C89","label":"Blood Panel Compare"},on:{"click":function($event){return _vm.selectReport(
												$event,
												'BloodPanelComparison',
												_vm.bloodPanelCompare
											)}},model:{value:(_vm.bloodPanelCompare),callback:function ($$v) {_vm.bloodPanelCompare=$$v},expression:"bloodPanelCompare"}})],1):_vm._e()])],1)],1)],1)],1)]),_c('template',{slot:"actions"},[_c('v-row',{staticClass:"d-flex justify-space-between",staticStyle:{"padding":"0 20px","align-items":"center","width":"100%"}},[_c('span',[_vm._v(" "+_vm._s(_vm.getSelectedValue)+" reports selected ")]),_c('Button',{attrs:{"color":"#EBF2FA","styles":{
						padding: '12px',
						'background-color': '#FFFFFF',
						border: '1px solid #EBF2FA'
					}},nativeOn:{"click":function($event){return _vm.downloadFile()}}},[_c('span',{staticStyle:{"color":"#425466","display":"flex","justify-content":"center","align-items":"center","gap":"8px"}},[_c('img',{staticClass:"icon",attrs:{"src":_vm.getImgWithExt(['icons', 'evaluation-download', 'svg']),"alt":"icon"}}),_vm._v(" Download ")])]),_c('Button',{attrs:{"color":"#EBF2FA","styles":{
						padding: '12px',
						'background-color': '#FFFFFF',
						border: '1px solid #EBF2FA'
					}},nativeOn:{"click":function($event){return _vm.print()}}},[_c('span',{staticStyle:{"color":"#425466","display":"flex","justify-content":"center","align-items":"center","gap":"8px"}},[_c('img',{staticClass:"icon",attrs:{"src":_vm.getImgWithExt(['icons', 'evaluation-print', 'svg']),"alt":"icon"}}),_vm._v(" Print ")])]),_c('Button',{attrs:{"color":"#EBF2FA","styles":{
						padding: '12px',
						'background-color': '#FFFFFF',
						border: '1px solid #EBF2FA'
					}},nativeOn:{"click":function($event){return _vm.sendToPatientEmail()}}},[_c('span',{staticStyle:{"color":"#425466","display":"flex","justify-content":"center","align-items":"center","gap":"8px"}},[_c('img',{staticClass:"icon",attrs:{"src":_vm.getImgWithExt(['icons', 'evaluation-email', 'svg']),"alt":"icon"}}),_vm._v(" Email ")])]),_c('span',[_vm._v(" to ")]),_c('form',[_c('Input',{staticStyle:{"max-height":"40px","flex":"1"},attrs:{"outlined":"","autocomplete":"off","placeholder":"email","type":"email","value":_vm.getPatientEmail},on:{"input":function($event){return _vm.onChangeEmail($event)}}})],1)],1),_c('v-row',{staticClass:"clinical-report-selected-warning"},[(_vm.getIfClinicalReportSelected)?_c('div',[_c('img',{staticClass:"icon",attrs:{"src":_vm.getImgWithExt(['icons', 'warning', 'svg']),"alt":"warning"}}),_vm._v(" It is not recommended to send Clinician reports to patients. ")]):_vm._e(),(_vm.getIfEmailValidationError)?_c('div',[_c('img',{staticClass:"icon",attrs:{"src":_vm.getImgWithExt(['icons', 'warning', 'svg']),"alt":"warning"}}),_vm._v(" Please enter valid email. ")]):_vm._e(),(_vm.getIfIsNoSelectedReportsError)?_c('div',[_c('img',{staticClass:"icon",attrs:{"src":_vm.getImgWithExt(['icons', 'warning', 'svg']),"alt":"warning"}}),_vm._v(" Select at least one report to do this. ")]):_vm._e()]),(!_vm.loading)?_c('iframe',{staticClass:"pdf-printer",attrs:{"src":_vm.fileUrl,"width":"100%","id":"pdfDocument-2","height":590}}):_vm._e(),_c('div',{staticClass:"custom-alert"},[_c('v-alert',{attrs:{"color":"#7ac1fb","dismissible":"","type":"info"},model:{value:(_vm.alertIsOpen),callback:function ($$v) {_vm.alertIsOpen=$$v},expression:"alertIsOpen"}},[_c('span',{staticStyle:{"font-size":"14px"}},[_vm._v(_vm._s(_vm.popupMessage))])])],1)],1)],2)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }