<template>
  <div class="trial-subscription">
    <Dialog width="60%" :scrollable="false" @destroyDialog="onCLoseHandler">

      <template slot="title">
<!--        <Img :src="getImgWithExt(['images', 'logo_drawer', 'svg'])" width="170" />-->
        <span>{{subscription.productName}}</span>
      </template>

      <template slot="body">
<!--        <template v-if="userProfile.userType === 'Doctor'">
          <div class="main-row justify-center">
            <span class="title" v-if="subscription.productName === 'Trial'">{{subscription.productName}} Period</span>
            <span class="title" v-else>Current Subscription Plan <span style="color: red;font-weight: 600">{{subscription.productName}}</span></span>
          </div>
          <div class="main-row justify-center">
            <div class="progress-title">
              <template v-if="!subscription.expired">
                You have <span class="days-left">{{subscription.daysLeft}} days</span> left to use this product with full feature availability.
              </template>
              <template v-else>
                <span style="color: red;font-weight: 600">The {{subscription.productName}} subscription is expired!</span>
              </template>
            </div>
          </div>
          <div class="main-row align-center">
            <div class="progress-block">
              <v-progress-linear
                :value="((subscription.totalDays || 30) - subscription.daysLeft) / (30/100)"
                :color="!subscription.expired ? '#008C89' : '#F16063'"
                height="6"
                striped
                :style="{'border-radius': '10px'}"
              >
              </v-progress-linear>
            </div>
            <div class="percent-value" v-if="subscription.productName === 'Trial'">{{(subscription.totalDays || 30) - subscription.daysLeft}}/{{subscription.totalDays || 30}}</div>
          </div>
        </template>
        <template v-else>
          <div class="main-row justify-center">
            <span class="title">{{subscription.productName}} Period</span>
          </div>
          <div class="main-row justify-center">
            <div class="progress-title">
&lt;!&ndash;              <template v-if="!subscription.expired">
                You have <span class="days-left">{{subscription.daysLeft}} days</span> left to use this product with full feature availability.
              </template>&ndash;&gt;
              <template v-if="subscription.expired && subscription.trial">
                <span style="color: red;font-weight: 600">Valued Client, your 30-day trial subscription has ended. Now is the time to make the bold decision to bring a higher level of Nutrition to your practice and elevate the care of your patients. To continue using Maestro Nutrition, please choose the subscription service level that is right for you. You can always make changes at any time.</span>
              </template>
              <template v-if="subscription.expired && !subscription.trial">
                <span style="color: red;font-weight: 600">
                  Valued Maestro Nutrition Client, your subscription has expired effective {{$moment(subscription.endDate).format('MM/DD/YYYY')}}. Please reactivate your subscription by choosing a desired service level and updating your payment information. We appreciate your loyalty and want to continue our partnership in providing Sustainable Health to your patients.
                </span>
              </template>
            </div>
          </div>
&lt;!&ndash;          <div class="main-row align-center">
            <div class="progress-block">
              <v-progress-linear
                :value="((subscription.totalDays || 30) - subscription.daysLeft) / (30/100)"
                :color="!subscription.expired ? '#008C89' : '#F16063'"
                height="6"
                striped
                :style="{'border-radius': '10px'}"
              >
              </v-progress-linear>
            </div>
            <div class="percent-value">{{(subscription.totalDays || 30) - subscription.daysLeft}}/{{subscription.totalDays || 30}}</div>
          </div>&ndash;&gt;
        </template>
        <template v-if="userProfile.userType === 'ClinicAdmin'">
          <div class="main-row subscriptions-variant" v-if="availableProducts.length">
            <template v-for="plan in availableProducts">
              <subscription :plan="plan" :key="plan.$id" :activePaying="activePaying" @switchPaying="switchPaying"/>
            </template>
          </div>
          <div v-else class="loading d-flex justify-center px-2 py-2">
            <v-progress-circular
              indeterminate
              color="#008C89"
            ></v-progress-circular>
          </div>
          <div class="main-row footer justify-center mt-4">
            <div class="information">
              You may pick the Subscription plan from within the <span class="link" @click="goToSettings">Profile and Organization Setup</span>
            </div>
            <div class="information">
              First time users will be automatically redirected here to set important profile data.
            </div>
          </div>
        </template>
        <template v-else>
          <div v-if="subscription.expired && subscription.trial" class="main-row justify-center mt-4" style="font-weight: bold;font-size: 20px;">
            Valued Client, your 30-day trial subscription has ended. Please contact your practice administrator.
          </div>
          <div v-if="subscription.expired && !subscription.trial" class="main-row justify-center mt-4" style="font-weight: bold;font-size: 20px;">
            The Maestro Nutrition subscription has expired. Please contact your practice administrator.
          </div>
        </template>-->
        <div class="px-4">
          <div class="main-row justify-center">
            <span v-if="!currentSubscription.trial && currentSubscription.expired" class="title" style="color: #CF4655;text-align: left">Your subscription has expired effective {{$moment(currentSubscription.endDate).format('MM/DD/YYYY')}}. <br>Resubscribe by choosing the desired subscription and clicking "Access Billing and Subscription".</span>
            <span v-if="currentSubscription.trial && !currentSubscription.expired" class="title">Your trial of {{subscription.productName}} is active. You may subscribe at any time by choosing a desired subscription and clicking "Access Billing and Subscription".</span>
            <span v-if="currentSubscription.trial && currentSubscription.expired" class="title">Your trial of {{currentSubscription.productName}} has ended. You may now subscribe by choosing a desired subscription and clicking "Access Billing and Subscription".</span>
            <span v-if="!currentSubscription.trial && !currentSubscription.expired" class="title">You are currently subscribed to {{currentSubscription.productName}}, billed {{currentSubscription.isMonthly ? 'monthly' : 'annually'}}.</span>
          </div>
          <div class="main-row description">
            <p>Click the link below to access our subscription and billing portal. You will need your account email to receive a one-time access code. Please call or chat with us if you have questions prior to making any changes. Within the portal, you can:</p>
          </div>
          <div class="you-can">
            <div>Make Changes to Your Subscription</div>
            <div>Update Your Payment Method</div>
            <div>Update Your Billing Information</div>
            <div>View Your Invoice History</div>
          </div>
          <div class="main-row description">
            <p>Note: Before making any changes to your subscription level, please refer to the current <a
              @click="openTermConditions"
              style="color: #72767F">Terms and Use</a> as it applies to you. If you are subscribed to a Legacy product and make a change to a different subscription level, you will not be able to return to your legacy status. Please visit our website to see all services currently being offered.</p>
          </div>
          <div v-if="userProfile.userType === 'ClinicAdmin'" class="main-row justify-center pb-3">
            <Button
              btn-bg="dark-green"
              block
              dark
              @click.native="accessBillingAndSubscriptions"
              width="500"
              tabindex="3" height="50">
              Access Billing and Subscriptions
            </Button>
          </div>
        </div>
      </template>

    </Dialog>
  </div>
</template>

<script>
import Dialog from '@/components/layouts/dialog'
import Image from '@/mixins/Image'
// import Subscription from '@/components/elements/subscription'
import { mapActions, mapMutations, mapState } from 'vuex'
import Button from '@/components/elements/Button.vue'

export default {
  name: 'trial-subscription',
  components: { Dialog, Button }, // Subscription
  mixins: [Image],
  data: () => ({
    activePaying: 'month'
  }),
  mounted () {
    this.getAvailableProducts()
    this.getCurrentSubscriptionPlan()
  },
  methods: {
    ...mapActions('subscriptions', ['getAvailableProducts', 'getCurrentSubscriptionPlan', 'createCustomerPortalSession']),
    ...mapMutations('dialogs', ['TOGGLE_DIALOG']),
    switchPaying (val) {
      this.activePaying = val
    },
    goToSettings () {
      if (this.$route.name === 'Settings') {
        this.TOGGLE_DIALOG({ name: 'trialSubscriptionDialog', isOpen: false, root: true })
      } else {
        this.TOGGLE_DIALOG({ name: 'trialSubscriptionDialog', isOpen: false, root: true })
        this.$router.push({ name: 'Settings' })
      }
    },
    onCLoseHandler (val) {
      if (this.subscription.expired) {
        if (this.$route.name !== 'Settings') {
          this.$router.push({ name: 'Settings' })
        }
      }
    },
    async accessBillingAndSubscriptions () {
      const res = await this.createCustomerPortalSession()
      window.open(res.url, '_self').focus()
    },
    openTermConditions () {
      this.TOGGLE_DIALOG({ name: 'termAndConditions', isOpen: true, root: true })
    }
  },
  computed: {
    ...mapState({
      availableProducts: state => state.subscriptions.availableProducts,
      currentSubscription: state => state.subscriptions.currentSubscription
    }),
    subscription () {
      return this.$store.state.subscription
    },
    userProfile () {
      return this.$store.state.userProfile
    }
  }
}
</script>

<style lang="scss" scoped>
.trial-subscription {
  ::v-deep .v-dialog {
    overflow: hidden;
    .v-card {
      overflow-y: scroll;
      //height: 85vh;
      padding: 10px;
      &__title {
        //justify-content: start!important;
        position: relative;
        padding: 25px 40px 0 !important;
        justify-content: center!important;
        .v-icon {
          position: absolute;
          cursor: pointer;
          right: 19px;
          top: 15px;
        }
      }
    }
  }
  .label {
    font-size: 14px;
    font-weight: 500;
    color: #425466
  }
  .main-row {
    display: flex;
    flex-wrap: wrap;
    width: 100%;
  }
  .description {
    p {
      font-weight: bold;
      color: black;
    }
  }
  .you-can {
    padding: 0 10px 10px;
    font-weight: bold;
    color: black;
  }
  .title {
    font-weight: 600;
    font-size: 28px;
    text-align: center;
    color: #27272E;
  }
  .progress-title {
    font-weight: 500;
    font-size: 14px;
    text-align: center;
    color: #27272E;
    .days-left {
      color: #008C89;
      font-weight: 600;
      font-size: 16px;
    }
  }
  .progress-block {
    width: 93%;
  }
  .percent-value {
    text-align: end;
    width: 7%;
    font-weight: 600;
    font-size: 14px;
    color: #27272E;
  }
  .footer {
    flex-wrap: wrap;
    .information {
      width: 100%;
      text-align: center;
      font-weight: 500;
      font-size: 14px;
      color: #7A828A;
      .link {
        cursor: pointer;
        color: #668BFF;
      }
    }
  }
  //::-webkit-scrollbar {
  //  width: 5px;
  //  margin-right: 10px;
  //}
  //::-webkit-scrollbar-track {
  //  background: #c3d2e5;
  //}
  //::-webkit-scrollbar-thumb {
  //  background: red;
  //}
}
</style>
