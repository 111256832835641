import Vue from 'vue'

const state = () => ({
  reports: {},
  reportsSurvey: {}
})

const getters = {}

const actions = {
  async gerReportByCode ({ commit }, params) {
    const result = await Vue.$http.post('Reports/GetByCode', { ...params }, { responseType: 'blob' })
    if (result?.status === 200) {
      commit('SET_DATA', ['reports', result?.data])
      return result?.data
    }
  },
  async gerReportSurveyFormByCode ({ commit }, params) {
    const result = await Vue.$http.post('Reports/GetSurveyForm', { ...params }, { responseType: 'blob' })
    if (result?.status === 200) {
      commit('SET_DATA', ['reportsSurvey', result?.data])
      return result?.data
    }
  },
  async sendSurveyForm ({ commit }, params) {
    const result = await Vue.$http.post('Reports/EmailSurveyForm', { ...params })
    if (result?.status === 200) {
      return result?.data
    }
  },
  async sendEmailToPatient ({ commit }, params) {
    const result = await Vue.$http.post('Reports/EmailToPatient', { ...params })
    if (result?.status === 200) {
      return result?.data
    }
  },
  async sendEmailToPatientMultiple ({ commit }, params) {
    const result = await Vue.$http.post('Reports/EmailToPatientMultiple', { ...params })
    if (result?.status === 200) {
      return result?.data
    }
  },
  async downloadZipReports ({ commit }, params) {
    const result = await Vue.$http.post('Reports/download-zip', { ...params }, { responseType: 'blob' })
    if (result?.status === 200) {
      const blob = new Blob([result.data], { type: 'application/zip' });
      const url = window.URL.createObjectURL(blob);
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('download', 'Reports.zip');
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
      window.URL.revokeObjectURL(url);
    }
  }
}

const mutations = {
  SET_DATA: (state, payload) => {
    const [property, value] = payload
    Vue.set(state, property, value)
  }
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}
