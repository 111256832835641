<template>
  <div class="custom-radio-button d-flex align-center justify-space-between pl-3">
    <v-col cols="4" class="pa-0">
      <div class="custom-radio-button__icon-title d-flex align-center">
        <div class="icon" v-if="iconName">
          <img :src="getImgWithExt(['icons', iconName, 'svg'])" alt="icon" />
        </div>
        <div class="title">
          <span :class="{'ml-4': iconName}" class="title-text">{{title}}</span>
        </div>
      </div>
    </v-col>
    <v-col cols="8" class="pa-0">
      <div class="custom-radio-button__buttons">
        <template v-if="type === 'radio'">
          <div
            v-for="(btn, i) in computedCountBtn" :key="i"
            class="circle d-flex align-center justify-center"
            :class="{'active': conditionForSetColorCircle(btn)}"
            @click="setNewDataValue(btn)"
          >
            <template v-if="type === 'radio'">
              <span class="grey-text" :class="{'green-text': btn === propertyKeyValue}">{{btn}}</span>
            </template>
            <template v-else>
              <svg width="36" height="36" viewBox="0 0 36 36" fill="none" xmlns="http://www.w3.org/2000/svg">
                <circle cx="18" cy="18" r="17" fill="#F5F7F9" stroke="#CBD5E0" stroke-width="2"/>
                <path d="M15 14.5L21 20.5L24 18.5L26 15.5V12.5L23.5 10L20.5 10.5L15 14.5Z" fill="#CBD5E0"/>
                <path d="M25.5002 10.5C24.54 9.54007 23.2379 9.00079 21.8802 9.00079C20.5225 9.00079 19.2203 9.54007 18.2602 10.5L10.5002 18.26C10.0133 18.7328 9.62534 19.2977 9.35877 19.9219C9.09219 20.546 8.95236 21.2169 8.9474 21.8955C8.94244 22.5742 9.07245 23.247 9.32987 23.875C9.5873 24.5029 9.96699 25.0734 10.4469 25.5533C10.9268 26.0332 11.4973 26.4129 12.1252 26.6703C12.7532 26.9277 13.426 27.0578 14.1047 27.0528C14.7833 27.0478 15.4542 26.908 16.0783 26.6414C16.7025 26.3749 17.2674 25.9869 17.7402 25.5L25.5002 17.74C26.4601 16.7799 26.9994 15.4777 26.9994 14.12C26.9994 12.7623 26.4601 11.4602 25.5002 10.5ZM16.3202 24.09C15.7242 24.6533 14.9352 24.9672 14.1152 24.9672C13.2951 24.9672 12.5062 24.6533 11.9102 24.09C11.3282 23.5037 11.0016 22.7111 11.0016 21.885C11.0016 21.0589 11.3282 20.2663 11.9102 19.68L15.0902 16.5L19.5002 20.91L16.3202 24.09ZM24.0902 16.32L20.9102 19.5L16.5002 15.09L19.6802 11.91C20.2712 11.3563 21.0544 11.0542 21.8641 11.0674C22.6739 11.0806 23.4468 11.4081 24.0194 11.9808C24.5921 12.5534 24.9196 13.3263 24.9328 14.1361C24.946 14.9458 24.6439 15.729 24.0902 16.32Z" fill="#CBD5E0"/>
                <path d="M15 14.5L21 20.5L24 18.5L26 15.5V12.5L23.5 10L20.5 10.5L15 14.5Z" fill="#CBD5E0"/>
                <path d="M21.7718 19.9332L14.9436 14.8958L12.2756 17.3211L10.7457 20.586L11.1935 23.5524L14.0385 25.6513L16.9303 24.7092L21.7718 19.9332Z" fill="#CBD5E0"/>
                <path d="M25.5002 10.5C24.54 9.54007 23.2379 9.00079 21.8802 9.00079C20.5225 9.00079 19.2203 9.54007 18.2602 10.5L10.5002 18.26C10.0133 18.7328 9.62534 19.2977 9.35877 19.9219C9.09219 20.546 8.95236 21.2169 8.9474 21.8955C8.94244 22.5742 9.07245 23.247 9.32987 23.875C9.5873 24.5029 9.96699 25.0734 10.4469 25.5533C10.9268 26.0332 11.4973 26.4129 12.1252 26.6703C12.7532 26.9277 13.426 27.0578 14.1047 27.0528C14.7833 27.0478 15.4542 26.908 16.0783 26.6414C16.7025 26.3749 17.2674 25.9869 17.7402 25.5L25.5002 17.74C26.4601 16.7799 26.9994 15.4777 26.9994 14.12C26.9994 12.7623 26.4601 11.4602 25.5002 10.5ZM16.3202 24.09C15.7242 24.6533 14.9352 24.9672 14.1152 24.9672C13.2951 24.9672 12.5062 24.6533 11.9102 24.09C11.3282 23.5037 11.0016 22.7111 11.0016 21.885C11.0016 21.0589 11.3282 20.2663 11.9102 19.68L15.0902 16.5L19.5002 20.91L16.3202 24.09ZM24.0902 16.32L20.9102 19.5L16.5002 15.09L19.6802 11.91C20.2712 11.3563 21.0544 11.0542 21.8641 11.0674C22.6739 11.0806 23.4468 11.4081 24.0194 11.9808C24.5921 12.5534 24.9196 13.3263 24.9328 14.1361C24.946 14.9458 24.6439 15.729 24.0902 16.32Z" fill="#CBD5E0"/>
                <path d="M21 22.5L13.5 15L15 13.5L22.5 21L21 22.5Z" fill="#F5F7F9"/>
              </svg>

            </template>
          </div>
        </template>
        <template v-else>
          <div
            v-for="(tablet, i) in computedCountBtn" :key="i"
            class="circle d-flex align-center justify-center"
            :class="{'active': conditionForSetColorCircle(tablet)}"
            @click="setNewDataValueTablets(tablet)"
          >
            <svg width="23" height="23" viewBox="0 0 23 23" fill="none" xmlns="http://www.w3.org/2000/svg" :class="{'active-tablet': tablet <= propertyKeyValue}">
              <path d="M6.78516 8.15631L14.0142 15.375L10.8441 18.5406C8.84787 20.534 5.61131 20.534 3.61505 18.5406V18.5406C1.6188 16.5472 1.6188 13.3153 3.61505 11.3219L6.78516 8.15631Z" fill="#CBD5E0"/>
              <path d="M15.4551 13.9937L8.226 6.77492L11.3961 3.60935C13.3924 1.61595 16.6289 1.61595 18.6252 3.60935V3.60935C20.6214 5.60275 20.6214 8.83469 18.6252 10.8281L15.4551 13.9937Z" fill="#CBD5E0"/>
            </svg>
          </div>
          <Input
            outlined
            radius="8"
            type="number"
            :min="0"
            @input="setNewDataValueTablets($event)"
            :value="propertyKeyValue"/>
        </template>
      </div>
    </v-col>
  </div>
</template>

<script>
import Image from '@/mixins/Image'
import Input from '@/components/elements/Input'

export default {
  name: 'custom-radio-button',
  mixins: [Image],
  components: {
    Input
  },
  props: {
    iconName: {
      type: String,
      default: null
    },
    title: {
      type: String,
      default: null
    },
    countBtns: {
      type: Number
    },
    propertyKeyValue: {
      type: [String, Number]
    },
    type: {
      type: String,
      default: 'radio'
    },
    waysToColorCircles: {
      type: String,
      default: 'radio'
    }
  },
  methods: {
    setNewDataValue (newValue) {
      this.$emit('setData', newValue)
    },
    setNewDataValueTablets (newValue) {
      if (newValue === this.propertyKeyValue) {
        this.$emit('setData', 0)
      } else {
        this.$emit('setData', +newValue)
      }
    },
    conditionForSetColorCircle (btn) {
      if (this.waysToColorCircles === 'range') {
        return btn <= this.propertyKeyValue
      } else {
        return btn === this.propertyKeyValue
      }
    }
  },
  computed: {
    computedCountBtn () {
      const array = []
      for (let i = 1; i <= this.countBtns; i++) {
        array.push(i)
      }
      return array
    }
  }
}
</script>

<style scoped lang="scss">
.custom-radio-button {
  width: 100%;
  &__icon-title {
    .title-text {
      font-family: Poppins;
      font-style: normal;
      font-weight: 500;
      line-height: 18px;
      font-size: 18px;
      color: #1A202C;
    }
  }
  .custom-radio-button__buttons {
    display: flex;
    .circle {
      width: 36px;
      height: 36px;
      border: 2px solid #CBD5E0;
      border-radius: 50%;
      cursor: pointer;
      margin-left: 7px;
      span {
        font-family: Poppins;
        font-style: normal;
        font-weight: 500;
        font-size: 14px;
        line-height: 18px;
      }
    }
    .circle.active {
      border: 2px solid $dark-green;
      background: $light-dark-green;
    }
    .active-tablet {
      path {
        fill: $dark-green;
      }
    }
    .grey-text {
      color: #CBD5E0;
    }
    .green-text {
      color: $dark-green;
    }
    .input {
      ::v-deep .v-input {
        width: 70px;
        margin-left: 10px;
        .v-input__control {
          .v-input__slot {
            border-color: #CBD5E0 !important;
            .v-text-field__slot {
              input {
                text-align: center !important;
              }
              input[type=number]::-webkit-inner-spin-button,
              input[type=number]::-webkit-outer-spin-button {
                -webkit-appearance: none !important;
                -moz-appearance: none !important;
              }
            }
          }
          .v-text-field__details {
            display: none !important;
          }
        }
      }
    }
  }
}
</style>
