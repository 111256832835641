<template>
  <div class="carousel">
    <v-carousel v-model="currItem" v-bind="items" :height="height" :show-arrows="false" hide-delimiters>
      <v-carousel-item
        v-for="(item,i) in items"
        :key="i"
        :src="item.src"
        reverse-transition="fade-transition"
        transition="fade-transition">
      </v-carousel-item>
    </v-carousel>
    <div class="white--text mt-10">
<!--      <div>0{{currItem + 1}}</div>-->
      <div v-if="currItem !== null" v-html="items[currItem].content"></div>
      <span v-for="(item,i) in items" :key="i" class="mr-2">
        <v-icon color="white" size="12" v-if="currItem==i">mdi-checkbox-blank-circle</v-icon>
        <v-icon color="white" size="12" v-else @click="changeSlide(i)">mdi-checkbox-blank-circle-outline</v-icon>
      </span>
    </div>
  </div>
</template>

<script>
export default {
  name: 'carousel',
  props: {
    items: {
      type: Array,
      require: true
    },
    height: {
      type: [Number, String]
    }
  },
  data: () => ({
    currItem: null,
    interval: null
  }),
  mounted () {
    this.interval = setInterval(() => {
      this.changeSlide(this.currItem)
      this.currItem += 1
      if (this.currItem === 3) {
        this.currItem = 0
      }
    }, 3000)
  },
  beforeDestroy () {
    clearInterval(this.interval)
  },
  methods: {
    changeSlide (index) {
      this.currItem = index
    }
  }
}
</script>

<style lang="scss" scoped>
  .carousel {
    .v-carousel {
      border-radius: 80px;
    }
    .v-icon {
      cursor: pointer;
    }
    ::v-deep .v-window {
      &__container {
        .v-image {
          width: 695px!important;
          border-radius: 80px!important;
        }
      }
    }
  }
</style>
