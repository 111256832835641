<template>
  <div class="datepicker">
    <v-menu
      ref="menu"
      v-model="menu"
      :close-on-content-click="false"
    >

      <template v-slot:activator="{ on }">

        <Input
          v-on="on"
          v-bind="$props"
          readonly
          outlined
          radius="8"
          append-icon="mdi-calendar"
          :label="label"
          :value="formatDate"
        />

      </template>

      <v-date-picker
        no-title
        scrollable
        :show-current=false
        :max="$props.max"
        :min="$props.min"
        @change="changeDate"
        :value="datePickerValue" 
      />

    </v-menu>
  </div>
</template>

<script>

import Input from '@/components/elements/Input'

export default {
  name: 'datepicker',
  props: {
    color: {
      default: '#212b37',
      type: String
    },
    format: {
      type: String
    },
    disabled: {
      type: Boolean
    },
    label: {
      type: String
    },
    max: {
      type: String
    },
    min: {
      type: String
    },
    placeholder: String,
    rules: {
      type: [Array, String, Object]
    },
    'type-picker': {
      type: String
    },
    showCurrent: {
      type: String
    },
    value: {
      type: [String, Date]
    },
    height: { type: [String, Number] }
  },
  components: { Input },
  data: () => ({
    date: null,
    menu: false
  }),
  created () {
    // this.$emit('date:update', this.value)
  },
  computed: {
    

    datePickerValue () {
        return this.$moment(this.date || this.value || new Date()).format('YYYY-MM-DD');
    },

    formatDate () {
      return this.date ? this.$moment(this.date).format('MM/DD/YYYY') : this.value ? this.$moment(this.value).format('MM/DD/YYYY') : ''
    }
  },
  methods: {
    formatForDatePicker(date) {
        return this.$moment(date || new Date()).format('YYYY-MM-DD');

    },

    changeDate ($event) {


      this.$set(this.$data, 'date', $event)
      this.$emit('date:update', $event)
      this.$refs.menu.save()
    }
  }
}
</script>

<style lang="scss" scoped>
  .datepicker {
    // ..
  }
</style>
