<template>
  <div class="upload-avatar">
    <Dialog
      width="auto"
      :styles="{'border-radius': '16px'}">

      
      <template slot="title">
        <div class="upload-avatar__header">
          <div class="upload-avatar__title">
            New avatar
          </div>

          <Img @click="close()" class="upload-avatar__close" :src="getImgWithExt(['icons', 'cross', 'svg'])" />


        </div>
      </template>

      <template slot="body">

        <div class="container">
        
       <v-row class="d-flex justify-center">

        <div class="main-row fluid">
          <template v-if="avatar === null">
              <div class="avatar"
                  @click="openUploadFile('inputAvatar')"
              >
                  <Img class="image-icon" :src="getImgWithExt(['icons', 'upload_avatar', 'svg'])" />

                <div
                  bordered
                  bottom
                  offset-x="27"
                  offset-y="27"
                  class="avatar__avatar-badge"
                >

                  <input id="hiddenUploadInput" ref="inputAvatar" type="file" name="image" @change="uploadFile('inputAvatar', $event)">
                  <v-avatar class="avatar__avatar-modify" >
                    <v-img alt="avatar"></v-img>
                  </v-avatar>
                </div>
              </div>
          </template>

          <template v-else>
            <div class="avatar">
              <ImageCropper class="image-cropper" :src="avatar" :avatarSize="avatarSize" @resultAvatar="getCroppedAvatar" @updateAvatarSize="updateAvatarSize"/>
            </div>

            <v-row>
              <v-col cols="12">
                <v-slider
                  v-model="avatarSize"
                  :max="2"
                  color="#008c89"
                  :step="0.5"
                  label="Size"
                >
                </v-slider>
              </v-col>
            </v-row>
          </template>
      </div>
      </v-row>

      
      <v-row class="d-flex justify-start">

        <div class="default_icons__container">
          <span class="default_icons__title">
            or choose default
          </span>

          <div class="main-row default_icons">
            <v-list
                class="list"
            >
              <v-list-item
                class="list-item"
                v-for="(item, index) in getStaticIcons"
                :key="index"
                :value="index"
              >

               <div class="item-container">
                 
                 <!-- <div v-if="selectedFile === item" class="badge"> </div> -->
                 
                <v-avatar 
                  @click.native.stop="uploadStaticFile('inputAvatar', item)"  
                  class="avatar__avatar-modify clickable"  
                   v-bind:class="{ badge: item === selectedFile}"
                >

                  <v-img
                   class="list-image" :src="item || ''"
                    v-bind:class="{ 'badge-border': item === selectedFile}"
                  >
                  </v-img>        

                </v-avatar>


                <Img v-if="selectedFile === item" class="selected-icon" :src="getImgWithExt(['icons', 'select-avatar', 'svg'])" />   

              </div>  


              </v-list-item>
            </v-list>
          </div>

        </div>
      </v-row>

      </div>
    </template>

      <template slot="actions">
        <v-row class="d-flex justify-end mb-4">
          <Button
            :styles="{'border-radius': '8px', 'margin-left': '5px','border': '1px solid #008c89', 'font-weight': '600'}">
            <span class="ml-2" @click="close">Cancel</span>
          </Button>
          <Button btn-bg="dark-green"
                  @click.native="updateAvatar"
                  :styles="{'border-radius': '8px', 'margin-left': '5px', 'color': 'white'}">
            <span class="ml-2">Update</span>
          </Button>
        </v-row>
      </template>
    </Dialog>
  </div>
</template>

<script>
import Dialog from '@/components/layouts/dialog'
import ImageCropper from '@/components/elements/ImageCropper'
import Button from '@/components/elements/Button'
import { mapMutations } from 'vuex'
import Image from '@/mixins/Image'
import { mapActions, mapState } from 'vuex'

export default {
  props: {
    avatar: {
      type: [String, Object]
    },
    id: String,
    refName: String
  },
  mixins: [Image],
  name: 'UploadAvatar',
  components: { ImageCropper, Dialog, Button },
  data: () => ({
    newAvatar: null,
    selectedFile: null,
    avatarSize: 0,
  }),
  

  async mounted () {
    await this.getStaticImages({id: this.id})

  },

  computed: {
       ...mapState({
      isAuth: state => state.isAuth,
      getStaticIcons: state => {
        return state.defaultIconsState.icons
      }
    }),
  },

  methods: {
    ...mapActions('defaultIconsState', ['getStaticImages']),

    openUploadFile (name) {
        this.$refs[name].click()
    },

    uploadFile (ref, e) {
      this.refName = ref
      const image = e.target.files[0]
      const reader = new FileReader()
      reader.readAsDataURL(image)
      reader.onload = (e) => {
        this.avatar = e.target.result
      }
    },

    uploadStaticFile (name, url) {
      this.selectedFile = url

      this.refName = name
      const image = url
      this.avatar = image
    },

    ...mapMutations('dialogs', ['TOGGLE_DIALOG']),
    getCroppedAvatar (newAvatar) {
      this.newAvatar = newAvatar
    },
    updateAvatarSize (size) {
      this.avatarSize = size
    },
    updateAvatar () {
      this.$emit('selectedNewAvatar', this.newAvatar)
      this.close()
    },
    close () {
      this.TOGGLE_DIALOG({ name: 'uploadAvatar', state: false })
    }
  },
  beforeDestroy () {
    console.log('destroy modal avatar')
    this.$emit('modalClose', this.refName)
    this.newAvatar = null
  }
}
</script>

<style scoped lang="scss">

.item-container {
  position: relative;
  width: 80px;
  height: 80px;
}


.selected-icon {
  position: absolute;

  z-index: 5;
  bottom: 0px;
  right: 0px;
}

.list-image {
  z-index: 3;
}

.badge {
   filter: drop-shadow(2px 2px 25px #008C89);
}

.badge-border {
  border: 2px solid #008C89
}

  .v-badge__badge::after {
    border: 0 !important;
    border-style: none !important;
    border-color: transparent !important;
    background-color:  transparent !important;
  }


  .container {
    min-height: 500px;
  }

  .main-row {
    display: flex;
    flex-wrap: wrap;
    column-gap: 1rem;
    align-items: center;

    width: 100%;
    height: 100%;


    .avatar {
      overflow: visible !important;
      width: 200px;
      min-height: 200px;
      background-color: #f7fafc;
      border-radius: 16px;
      position: relative;
      cursor: pointer;

      .image-cropper {
        width: 200px;
        height: 200px;
      }

      &__avatar-badge {
        ::v-deep .v-badge__wrapper .v-badge__badge {
          width: 100%;
          height: 100%;


          background-color:  transparent !important;
          border: 0 !important;
          border-style: none !important;
          border-color: transparent !important;
        }

   
        #hiddenUploadInput {
          display: none;
        }
      }
      &__avatar-modify  {
        z-index: 0;
        position: relative;

        width: 80px !important;
        height: 80px !important;
      }
    }
    .info-title {
      font-weight: 600;
      font-size: 18px;
      color: #1A202C;
    }
    .reset-logo {
      color: rgb(66, 84, 102);
      cursor: pointer;
      text-decoration: underline;


    }
  }


  .image-icon {
    width: 100%;
    padding: 20px;

    margin: 0 auto;
    position: absolute;
  }

  .default_icons__container {
    width: 100%;
    margin-top: 20px;
    color: #425466 !important;
    font-weight: 500;
    min-width: 450px;
    min-height: 200px;

    .default_icons {
      margin-top: 10px;
      width: 100%;
      position: relative;
      border-radius: 16px;
      background-color: #EDF2F7;

      display: flex;
      flex-direction: row;
      justify-content: center;

      .list {
        width: 100%;
        max-width: 450px;
        
        display: flex;
        flex-wrap: wrap;
        flex-direction: row;
        justify-content: center;

        background-color: #EDF2F7;

        gap: 10px;

        .list-item {
          cursor: pointer;

          min-width: 30%;

          width: 80px;
          height: 80px;

          border-radius: 16px;
          
          text-align: center;

          flex: 0;
        }
      }
      
    }

  }

.upload-avatar {
  width: auto;
  height: auto;

  min-height: 500px;

  ::v-deep .v-dialog {
    overflow-x: hidden !important;
  }
  ::v-deep .v-dialog {
    .v-card {
      min-height: 500px;

      padding: 0!important;
      &__title {
        padding: 5px 10px!important;
        justify-content: center!important;
        // background: #edf2f7;
        position: relative;
      }
      &__text {
        margin-top: 20px!important;
        padding: 0 24px!important;
      }
      &__actions {
        padding: 0 24px!important;
      }
    }
    .v-card .v-icon {
      display: none;
      bottom: 0 !important;
      right: 10px !important;
    }
  }
  

  &__header {
    position: relative;
    width: 100%;
  }

  &__title {
    padding: 35px 0px 0px 20px;
  }

  &__close {

    position: absolute;
    right: 10px;
    top: 15px;

    cursor: pointer;
  }

  &__result {
    font-size: 18px;
    font-weight: 600;
  }

  @media screen and (max-width: 490px) {
  .fluid {
      flex-direction: column !important;
      
    }
  }


}
</style>
