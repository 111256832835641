<div>
  <canvas id="donut"></canvas>
</div>

<script>

import { Doughnut } from 'vue-chartjs'
import Chart from 'chart.js'

export default {
  name: 'chart-doughnut',
  props: {
    dataSet: Object
  },
  extends: Doughnut,
  mounted () {
    Chart.pluginService.register({
      beforeDraw: function (chart) {
        if (chart.config.options.elements.center) {
          const ctx = chart.chart.ctx

          const centerConfig = chart.config.options.elements.center
          const fontStyle = centerConfig.fontStyle || 'Arial'
          const txt = centerConfig.text
          const color = centerConfig.color || '#000'
          const sidePadding = centerConfig.sidePadding || 20
          const sidePaddingCalculated = (sidePadding / 100) * (chart.innerRadius * 2)
          ctx.font = '30px ' + fontStyle
          const stringWidth = ctx.measureText(txt).width
          const elementWidth = (chart.innerRadius * 2) - sidePaddingCalculated
          // Find out how much the font can grow in width.
          const widthRatio = elementWidth / stringWidth
          const newFontSize = Math.floor(30 * widthRatio)
          const elementHeight = (chart.innerRadius * 2)
          // Pick a new font size so it will not be larger than the height of label.
          const fontSizeToUse = Math.min(newFontSize, elementHeight)
          ctx.textAlign = 'center'
          ctx.textBaseline = 'middle'
          const centerX = ((chart.chartArea.left + chart.chartArea.right) / 2)
          const centerY = ((chart.chartArea.top + chart.chartArea.bottom) / 2)
          ctx.font = fontSizeToUse + 'px ' + fontStyle
          ctx.fillStyle = color
          ctx.fillText(txt, centerX, centerY)
        }
      }
    })
    console.log('this.dataSet', this.dataSet)
    const maxValue = this.dataSet.datasets[0].data ? Math.max(...this.dataSet.datasets[0].data) : null
    const indexMaxValue = maxValue ? this.dataSet.datasets[0].data.indexOf(maxValue) : null
    this.renderChart(this.dataSet, {
      elements: {
        center: {
          text: indexMaxValue ? this.dataSet.labels[indexMaxValue] || 'No Data' : 'No Data',
          color: '#27272E', // Default is #000000
          fontStyle: 'Arial', // Default is Arial
          sidePadding: 20 // Defualt is 20 (as a percentage)
        }
      }
    })
  },
  watch: {
    'dataSet' (val) {
      const maxValue = Math.max(...this.dataSet.datasets[0].data)
      const indexMaxValue = this.dataSet.datasets[0].data.indexOf(maxValue)
      this.renderChart(val, {
        elements: {
          center: {
            text: this.dataSet.labels[indexMaxValue] || 'No Data',
            color: '#27272E', // Default is #000000
            fontStyle: 'Arial', // Default is Arial
            sidePadding: 20 // Defualt is 20 (as a percentage)
          }
        }
      })
    }
  }
}
</script>

<style scoped>

</style>
