<template>
  <div class="add-supplement-dialog">
    <Dialog width="1000" scrollable>
      <template slot="title">
        <div>{{dialog.title}}</div>
      </template>
      <template slot="body">
        <v-form ref="form" v-model="valid" lazy-validation id="editGlobalSupplement" class="py-2 pl-3 pr-3">
          <v-row class="d-flex justify-center mt-1" style="font-size: 16px">{{dialog.data.name}}</v-row>
          <v-row class="ml-2 justify-space-between" style="margin-top: 15px">
            <v-col cols="6" style="max-width: 49%">
              <v-row class="mb-3">
                <label style="width: 100%">
                  <span>Short Description (English)</span>
                  <Textarea rows="5" disabled rows-height="2" outlined @input:text-area="form.description=$event" :value="form.description" />
                </label>
              </v-row>
              <v-row class="mb-3">
                <label style="width: 100%">
                  <span>Short Description (Spanish)</span>
                  <Textarea rows="5" disabled rows-height="2" outlined @input:text-area="form.descriptionSpanish=$event" :value="form.descriptionSpanish" />
                </label>
              </v-row>
              <v-row>
                <v-checkbox
                  color="#008C89"
                  style="margin-top: 0px"
                  :value="form.printShortDesc"
                  @change="changePintOnShortDesc"
                  label="Print on Patient Report"/>
              </v-row>
              <v-row>
                <v-switch class="py-0 my-0" color="#008C89" inset label="Default Supplement" :disabled="form.discontinued" :value="form.discontinued ? false : form.isDefault" @change="changeIsDefaultSupplement" />
              </v-row>
            </v-col>
            <v-col cols="6" style="max-width: 49%">
              <v-row class="mb-3">
                <label style="width: 100%">
                  <span>My Short Description (English)</span>
                  <Textarea rows="1" rows-height="1" outlined @input:text-area="form.shortDesc=$event" :value="form.shortDesc" />
                </label>
              </v-row>
              <v-row class="mb-3">
                <label style="width: 100%">
                  <span>My Short Description (Spanish)</span>
                  <Textarea rows="1" rows-height="1" outlined @input:text-area="form.shortDescSpanish=$event" :value="form.shortDescSpanish" />
                </label>
              </v-row>
              <v-row class="mb-3">
                <label style="width: 100%">
                  <span>Patient Note (English)</span>
                  <Textarea rows="1" rows-height="1" outlined @input:text-area="form.patientNotes=$event" :value="form.patientNotes" />
                </label>
              </v-row>
              <v-row class="mb-3">
                <label style="width: 100%">
                  <span>Patient Note (Spanish)</span>
                  <Textarea rows="1" rows-height="1" outlined @input:text-area="form.patientNotesSpanish=$event" :value="form.patientNotesSpanish" />
                </label>
              </v-row>
              <v-row>
                <v-checkbox
                  color="#008C89"
                  style="margin-top: 0px"
                  :value="form.printMyShortDesc"
                  @change="changePintMyShortDesc"
                  label="Print on Patient Report"/>
              </v-row>
            </v-col>
          </v-row>
          <v-row class="justify-end">
            <v-col cols="6" class="py-0">
              <div class="d-flex justify-space-between">
                <Button :styles="{ 'border-radius': '8px', 'border': '1px solid #EDF2F7', 'background': 'white' }"
                        width="99"
                        height="53"
                        @click.native="close">
                  <span style="color: #425466;font-weight: 600;font-size: 14px;">Cancel</span>
                </Button>
                <Button btn-bg="dark-green"
                        dark
                        width="180"
                        height="53"
                        :styles="{ 'border-radius': '8px', 'font-weight': '600' }"
                        @click.native="update">
                  <span>Update</span>
                </Button>
              </div>
            </v-col>
          </v-row>
        </v-form>
      </template>
    </Dialog>
  </div>
</template>

<script>
import { mapState, mapActions, mapMutations } from 'vuex'

import Dialog from '@/components/layouts/dialog'
import Validation from '@/mixins/Validation'

import SearchSelect from '@/components/elements/search-select'
import Button from '@/components/elements/Button'
import Textarea from '@/components/elements/Textarea'
import CustomRadioButton from '@/components/elements/custom-radio-button'

import debounce from 'lodash/debounce'
import Input from '@/components/elements/Input'
import Select from '@/components/elements/Select'

export default {
  name: 'edit-global-supplement-dialog',
  props: {
    query: Object,
    componentFrom: String
  },
  mixins: [Validation],
  components: { Dialog, Button, CustomRadioButton, Textarea, SearchSelect, Input, Select }, // SearchSelect, Input, SpinInput, Textarea, Input
  data: () => ({
    form: {
      clinicianNotes: null,
      description: null,
      descriptionSpanish: null,
      patientNotes: null,
      patientNotesSpanish: null,
      printMyShortDesc: null,
      printShortDesc: null,
      shortDesc: null,
      shortDescSpanish: null,
      isDefault: false
    },
  }),
  async created () {
    const res = await this.getGlobalSupplementsNotes(this.dialog.id)
    this.form = Object.assign({}, res.record)
  },
  methods: {
    ...mapMutations('dialogs', ['TOGGLE_DIALOG']),
    ...mapActions('supplements', ['getGlobalSupplementsNotes', 'updateGlobalSupplementsNotes', 'getSupplements']),
    changePintOnShortDesc($event) {
      this.form.printShortDesc = $event
      this.form.printMyShortDesc = false
    },
    changePintMyShortDesc($event) {
      this.form.printMyShortDesc = $event
      this.form.printShortDesc = false
    },
    changeIsDefaultSupplement($event) {
      this.form.isDefault = $event
    },
    async update () {
      delete this.form.$id
      this.form.isDefault = this.form.isDefault === null ? false : this.form.isDefault
      const res = await this.updateGlobalSupplementsNotes({ id: this.dialog.id, params: this.form })
      if (res) {
        this.getSupplements(this.query)
      }
      this.close()
    },
    close () {
      this.TOGGLE_DIALOG({ name: this.dialog.name, isOpen: false })
    }
  },
  computed: {
    ...mapState({
      dialog (state) {
        const modalName = this._.findKey(state.dialogs, ['isOpen', true])
        return state.dialogs[modalName]
      },
    })
  }
}
</script>

<style lang="scss" scoped>
.add-supplement-dialog {
  ::v-deep .v-dialog {
    overflow-y: hidden!important;
    max-height: unset!important;
    &__container {
      .v-card {
        &__title {
          background: #edf2f7;
          padding: 25px 30px 17px 46px !important;
          justify-content: center!important;
          position: relative;
          .v-icon {
            position: absolute;
            right: 15px;
            top: 25px;
          }
        }
        &__text {
          overflow-y: scroll;
          max-height: 605px; // 595px;
          padding: 16px!important;
          background-color: white!important;
        }
      }
    }
  }
  #editGlobalSupplement {
    .textarea {
      ::v-deep .v-input {
        &__control {
          .v-input__slot {
            background: #edf2f79e;
            border-radius: 8px;
          }
          .v-text-field__details {
            display: none;
          }
        }
      }
    }
  }

  ::v-deep .button {
    .v-btn.v-btn--disabled {
      background-color: #EBF2FA!important;
      color: white!important;
    }
  }
}
</style>
