<template>
  <div class="registration-form">
    <template v-if="!isRegistered">
<!--      <div>Start for free</div>-->
      <div class="registration-form__title mb-0">Create an account</div>
      <div style="text-align: center;margin-right: 70px;margin-top: -10px;font-size: 14px;font-weight: 600;">
        30-Day FREE TRIAL
      </div>
      <v-form ref="form" v-model="valid" lazy-validation id="authForm" autocomplete="off">
        <v-row class="mt-0">
          <v-col class="py-0">
            <div class="label">First Name</div>
            <Input input-class="registration"
                   autocomplete="off"
                   outlined
                   height="46"
                   radius="16"
                   tabindex="0"
                   :isAutofocus="isAutofocus"
                   background-color="#EDF2F7"
                   placeholder="Your first name"
                   :rules="regFirstNameRules"
                   @input="form.firstName=$event"
                   @enter="preLocalRegister"
            />
          </v-col>
        </v-row>

        <v-row class="mt-1">
          <v-col class="py-0">
            <div class="label">Last Name</div>
            <Input input-class="registration"
                   autocomplete="off"
                   outlined
                   height="46"
                   radius="16"
                   tabindex="0"
                   background-color="#EDF2F7"
                   placeholder="Your last name"
                   :rules="regLastNameRules"
                   @input="form.lastName=$event"
                   @enter="preLocalRegister"
            />
          </v-col>
        </v-row>

        <v-row class="mt-1">
          <v-col class="py-0">
            <div class="label">E-mail</div>
            <Input input-class="registration"
                   autocomplete="off"
                   outlined
                   height="46"
                   radius="16"
                   tabindex="0"
                   background-color="#EDF2F7"
                   placeholder="Your Email"
                   :rules="regEmailRules"
                   @input="form.email=$event"
                   @enter="preLocalRegister"
            />
          </v-col>
        </v-row>

        <v-row class="mt-1">
          <v-col class="py-0" style="position: relative">
            <div class="label">Password</div>
            <Input input-class="registration"
                   autocomplete="off"
                   outlined
                   height="46"
                   radius="16"
                   tabindex="0"
                   background-color="#EDF2F7"
                   placeholder="Password"
                   type="password"
                   :rules="regPwdRules"
                   @input="form.password=$event"
                   @focus="showPasswordRequirements = true"
                   @blur="showPasswordRequirements = false"
                   @enter="preLocalRegister"
            /><!--notEmtpy.concat(pwdRules)-->
            <div class="pwd-requirements-prompt">
              <password-requirements
                v-show="showPasswordRequirements"
                :verifiablePassword="form.password"
                :rules="passwordRequirementsRules"
              />
            </div>
          </v-col>
        </v-row>

        <v-row class="mt-1">
          <v-col class="py-0">
            <div class="label">Confirm Password</div>
            <Input input-class="registration"
                   autocomplete="off"
                   outlined
                   height="46"
                   radius="16"
                   tabindex="0"
                   background-color="#EDF2F7"
                   placeholder="Confirm password"
                   type="password"
                   :rules="regCfmPwdRules"
                   @input="form.confirmPassword=$event"
                   @enter="preLocalRegister"
            /><!--notEmtpy.concat(cfmPwdRule)-->
          </v-col>
        </v-row>

        <v-row class="mt-1">
          <v-col class="py-0">
            <div class="registration-form__agree d-flex align-start">
              <checkbox color="#008C89" tabindex="0" :rules="regAgreeRules" @change="form.isAgreedToTerms=$event" />
<!--              <span class="mr-2">Agree with</span>-->
              <a
                @click="openTermConditions"
                style="color: #72767F">Accept Terms of Use</a><!--href="https://surveymaestro.com/contact.php" target="_blank"-->
<!--              <Link color="gray" to="#">Terms end Conditions</Link>-->
            </div>
          </v-col>
        </v-row>

        <v-row class="mt-1">
          <v-col class="py-0">
            <div class="d-flex align-start">
              <!-- <vue-recaptcha
                :sitekey="captchaKey"
                @verify="verify"
                @expired="expired"
                @render="render"
                @error="error"
              ></vue-recaptcha> -->
            </div>
          </v-col>
        </v-row>

        <v-row class="mt-4">
          <v-col><!--class="mt-4"-->
            <div v-if="commonError" style="line-height: 12px;font-size: 12px;color: #ff5252;margin-bottom: 5px">
              {{ commonError }}
            </div>
            <Button btn-bg="dark-green" @click.native="preLocalRegister" height="56" block dark>Sign up</Button>
          </v-col>
        </v-row>

        <v-row class="mt-0">
          <v-col>
            <div class="registration-form__registration-link d-flex justify-center">Already have an account? <Link class="ml-2" to="/sign-in">Sign in</Link></div>
          </v-col>
        </v-row>

      </v-form>
      <div v-if="loading" class="loading d-flex justify-center px-2 py-2 align-center">
        <v-progress-circular
          indeterminate
          color="#008C89"
        ></v-progress-circular>
      </div>
    </template>
    <template v-else>
      <div class="registered">
        <img src="../../../assets/icons/icon-done.svg" alt="Almost done!">
        <h2 class="registered__title">Nice. Almost done!</h2>
        <div class="registered__info">
          <v-row justify="center">
            <v-col class="py-5">
              <p class="registered__info-text">
                We sent a verification email to you. Please
                check your incoming mail
              </p>
            </v-col>
          </v-row>
          <v-row>
            <v-col class="py-0">
              <div class="d-flex justify-center">
                <Button block
                        btn-bg="dark-green"
                        :width="'auto'"
                        :height="56"
                        :styles="{ 'border-radius': '8px' }"
                        @click.native="$router.push('/sign-in')"
                >
                  <span style="color: white">Back to login page</span>
                </Button>
              </div>
            </v-col>
          </v-row>
        </div>
      </div>
    </template>
    <terms-conditions v-if="dialogTermsConditions.isOpen"/>
    <div class="custom-alert">
      <v-alert
        :color="alertColor"
        dismissible
        type="info"
        v-model="alertIsOpen"
      >
        <span style="font-size: 14px;">{{message}}</span>
      </v-alert>
    </div>
  </div>
</template>

<script>
import Validation from '@/mixins/Validation'

// import VueRecaptcha from 'vue-recaptcha'

import Input from '@/components/elements/Input'
import checkbox from '@/components/elements/checkbox'
import Link from '@/components/elements/Link'
import Button from '@/components/elements/Button'
import { mapActions, mapMutations, mapState } from 'vuex'
import { MIN_PASS_LENGTH } from '@/constants/const'
import PasswordRequirements from '@/components/elements/PasswordRequirements'
import TermsConditions from '@/components/layouts/dialogs/terms-conditions'

export default {
  name: 'registration-form',
  mixins: [Validation],
  components: { Input, checkbox, Link, Button, PasswordRequirements, TermsConditions },
  data: () => ({
    form: {
      firstName: '',
      lastName: '',
      email: '',
      password: '',
      confirmPassword: '',
      isAgreedToTerms: false,
      isCaptchaChecked: true
    },
    isRegistered: false,
    showPasswordRequirements: false,
    passwordRequirementsRules: [
      {
        text: `Be at least ${MIN_PASS_LENGTH} characters long`,
        icon: 'mdi-check-circle',
        passedValidation: false
      },
      {
        text: 'Have at least one uppercase',
        icon: 'mdi-check-circle',
        passedValidation: false
      },
      {
        text: 'Have at least one lowercase',
        icon: 'mdi-check-circle',
        passedValidation: false
      },
      {
        text: 'Have at least one number',
        icon: 'mdi-check-circle',
        passedValidation: false
      },
      {
        text: 'Have at least one special characters (!@#)',
        icon: 'mdi-check-circle',
        passedValidation: false
      }
    ],
    commonError: null,
    loading: false,
    alertColor: '#7ac1fb',
    message: '',
    alertIsOpen: false,
    isAutofocus: true,
  }),
  mounted () {
    document.title = 'Registration - Maestro Practitioner Portal'
    this.isAutofocus = false
  },
  methods: {
    ...mapActions(['registration']),
    ...mapMutations('dialogs', ['TOGGLE_DIALOG']),
    preLocalRegister () {
      this.loading = true
      this.setDynamicValidationRules({
        regLastNameRules: [
          v => !!v || 'Last Name is required.'
        ],
        regFirstNameRules: [
          v => !!v || 'First Name is required.'
        ],
        regEmailRules: [
          v => !!v || 'E-mail is required.'
        ],
        regPwdRules: [
          v => !!v || 'Password is required.',
          v => (v && v?.length) >= MIN_PASS_LENGTH || `Min ${MIN_PASS_LENGTH} characters`],
        regCfmPwdRules: [
          v => !!v || 'Confirm password is required.',
          v => (v === this.form.password) || 'This field must match'
        ],
        regAgreeRules: [v => !!v || 'You must agree to continue!']
      })
      this.$nextTick(() => {
        this.register()
      }, 200)
    },
    async register () {
      if (this.validate()) {
        const res = await this.registration(this.form)
        console.log('res', res)
        if (res.status === 404) {
          this.loading = false
          this.alertIsOpen = true
          this.alertColor = '#CF4655'
          this.message = `Occurred some error`
          const self = this
          setTimeout(function () {
            self.alertIsOpen = false
          }, 3000)
        }
        if (res && res.data.success && res.data.validationResults.isValid) {
          this.isRegistered = true
          this.loading = false
        }
        if (!res.data.validationResults.isValid) {
          if (res.data.validationResults?.results[0].name === 'FirstName') {
            this.setDynamicValidationRules({
              regFirstNameRules: [
                v => !!v || 'First Name is required.',
                res.data.validationResults.isValid || res.data.validationResults?.results[0].message
              ]
            })
            this.loading = false
          }
          if (res.data.validationResults?.results[0].name === 'LastName') {
            this.setDynamicValidationRules({
              regLastNameRules: [
                v => !!v || 'Last Name is required.',
                res.data.validationResults.isValid || res.data.validationResults?.results[0].message
              ]
            })
            this.loading = false
          }
          if (res.data.validationResults?.results[0].name === 'Email') {
            this.setDynamicValidationRules({
              regEmailRules: [
                v => !!v || 'E-mail is required.',
                res.data.validationResults.isValid || res.data.validationResults?.results[0].message
              ]
            })
            this.loading = false
          }
          if (res.data.validationResults?.results[0].name === 'Password') {
            this.setDynamicValidationRules({
              regPwdRules: [
                v => !!v || 'Password is required.',
                v => (v && v?.length) >= MIN_PASS_LENGTH || `Min ${MIN_PASS_LENGTH} characters`,
                res.data.validationResults.isValid || res.data.validationResults?.results[0].message
              ]
            })
            this.loading = false
          }
          if (res.data.validationResults?.results[0].name === 'Common') {
            this.commonError = res.data.validationResults?.results[0].message
            this.loading = false
          }
          this.loading = false
        }
      }
    },
    async recaptcha () {
      // (optional) Wait until recaptcha has been loaded.
      await this.$recaptchaLoaded()

      const token = await this.$recaptcha('registration')
      console.log(token)

      // Do stuff with the received token.
    },
    // verify ($event) {
    //   if ($event) {
    //     this.isCaptchaChecked = true
    //   }
    // },
    // expired ($event) {
    //   console.log('expired', $event)
    // },
    // render ($event) {
    //   console.log('render', $event)
    // },
    // error ($event) {
    //   console.log('error', $event)
    // }
    openTermConditions () {
      this.TOGGLE_DIALOG({ name: 'termAndConditions', isOpen: true })
    }
  },
  created () {
    this.recaptcha()
  },
  computed: {
    captchaKey () {
      return process.env.VUE_APP_GOOGLE_API_TOKERN
    },
    ...mapState({
      messages: state => state.messages,
      dialogTermsConditions: state => state.dialogs.termAndConditions
    })
  },
  watch: {
    'form.firstName' (val) {
      if (!val) this.regFirstNameRules = []
      if (val) {
        this.regFirstNameRules = [
          v => !!v || 'First Name is required.'
        ]
      }
    },
    'form.lastName' (val) {
      if (!val) this.regLastNameRules = []
      if (val) {
        this.regLastNameRules = [
          v => !!v || 'Last Name is required.'
        ]
      }
    },
    'form.email' (val) {
      if (!val) this.regEmailRules = []
      if (val) {
        this.regEmailRules = [
          v => !!v || 'E-mail is required.'
        ]
      }
    },
    'form.password' (val) {
      if (!val) this.regPwdRules = []
      if (val) {
        this.regPwdRules = [
          v => !!v || 'Password is required.',
          v => (v && v?.length) >= MIN_PASS_LENGTH || `Min ${MIN_PASS_LENGTH} characters`
        ]
      }
    },
    'form.confirmPassword' (val) {
      if (!val) this.regCfmPwdRules = []
      if (val) {
        this.regCfmPwdRules = [
          v => !!v || 'Confirm password is required.',
          v => (v === this.form.password) || 'This field must match'
        ]
      }
    },
    'form.isAgreedToTerms' (val) {
      if (!val) this.regAgreeRules = [v => !!v || 'You must agree to continue!']
      if (val) this.regAgreeRules = []
    },
    messages (val) {
      if (val && val.length > 0) {
        let errMessages = ''
        for (let i = 0; i < val.length; i++) {
          errMessages += `${val[i].msg} `
        }
        this.regEmailRules = [v => errMessages]
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.custom-alert {
  max-width: 300px;
  position: fixed;
  top: 10px;
  right: 20px;
  z-index: 999;
}
  .loading {
    min-height: 100px;
    position: absolute;
    height: 90%;
    width: 110%;
    top: 50px;
    left: -20px;
    background: rgba(213, 213, 213, 0.5);
    border-radius: 16px;
  }
  .label {
    font-size: 14px;
    font-weight: 500;
    color: #425466;
  }
  .registration-form {
    width: 100%;
    position: relative;
    &__registration-link,
    &__agree {
      font-weight: 500;
      font-size: 14px;
      color: #72767F;
      position: relative;
      ::v-deep .v-messages.error--text {
        position: absolute;
        top: 26px;
      }
    }
    &__title {
      font-weight: 500;
      font-size: 38px;
    }
    .registered {
      &__title {
        font-weight: 500;
        font-size: 36px;
        line-height: 136.02%;
        color: #27272E;
      }
      &__info-text {
        color: #27272E;
        font-size: 20px;
      }
    }
  }
  .pwd-requirements-prompt {
    width: inherit;
    position: absolute;
    z-index: 4;
    right: 2px;// -458px;
    top: 83px;// -75px;
  }
  ::v-deep .v-text-field__details {
    padding-top: 2px !important;
    margin-bottom: 1px;
  }
</style>
