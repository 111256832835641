import Vue from 'vue'

const state = () => ({
  auth: { isOpen: false, name: '' },
  forgot: { isOpen: false, name: '' },
  registration: { isOpen: false, name: '' },
  previewEmailTemplateDialog: { isOpen: false, name: '', title: '', action: '' },
  modalAddNewBrand: { isOpen: false, name: '', title: '' },
  addPatient: { isOpen: false, name: '', title: 'Add Patient' },
  editPatient: { id: 0, isOpen: false, name: '', title: 'Edit patient' },
  addVisit: { isOpen: false, name: '', title: 'Add Evaluation' },
  editVisit: { isOpen: false, name: '', title: 'Edit Evaluation' },
  termAndConditions: { isOpen: false, name: '', title: '' },
  confirm: { isOpen: false, name: '', title: 'Confirm dialog', action: '' },
  addSupplementDialog: { isOpen: false, name: '', title: '' },
  editSupplementDialog: { isOpen: false, name: '', title: '' },
  editProtocolDialog: { isOpen: false, name: '', title: 'Edit protocol' },
  addProtocolDialog: { isOpen: false, name: '', title: 'Add protocol' },
  editProductDialog: { isOpen: false, name: '', title: 'Edit product' },
  addProductDialog: { isOpen: false, name: '', title: 'Add product' },
  editSupplementProduct: { isOpen: false, name: '', title: '' },
  editGlobalSupplementDialog: { isOpen: false, name: '', title: '' },
  addResult: { isOpen: false, name: '', title: 'Add result' },
  editResult: { isOpen: false, name: '', title: 'Edit result' },
  inviteDoctors: { isOpen: false, name: '', title: 'Invite Practitioner' },
  openReportPdfView: { isOpen: false, name: '' },
  invitePatient: { isOpen: false, name: '', title: 'Invite Patient to join Portal' },
  uploadAvatar: { isOpen: false, name: '' },
  evaluationReportsModal: { isOpen: false, name: '' },
  completeVisit: { isOpen: false, name: '', title: 'Complete Evaluation' },
  notesDialog: { isOpen: false, name: '', title: 'Notes' },
  daysSupplyDialog: { isOpen: false, name: '', title: 'Change Evaluation Supply Days' },
  trialSubscriptionDialog: { isOpen: false, name: '', title: '' },
  forcingSubscriptionDialog: { isOpen: false, name: '', title: '' },
  quickEntryDialog: { isOpen: false, name: '', title: '' },
  resultGroupSupplementTotals: { isOpen: false, name: '', title: '' },
  operationsWithDoctorDialog: { isOpen: false, name: '', title: '' },
  dialogShowPreviousProtocol: { isOpen: false, name: '', title: '' },
  dialogShowProtocol: { isOpen: false, name: '', title: '' },
  dialogShowProduct: { isOpen: false, name: '', title: '' },
  addSupplementProduct: { isOpen: false, name: '', title: '' },
  paymentDialog: { isOpen: false, name: '', title: '', action: '' },
  receiptViewForm: { isOpen: false, name: '', title: 'Invoice view', action: '' },
  modalCrgNotes: { isOpen: false, name: '', title: '' },
  modalConfirmationDeleteVisit: { isOpen: false, name: '', title: '' },
  modalConfirmationEndProtocol: { isOpen: false, name: '', title: '' },
  modalBrandVendorSelection: { isOpen: false, name: '', title: '', canCloseManually: false },
})

const getters = {}

const actions = {}

const mutations = {
  TOGGLE_DIALOG: (state, payload) => {

    console.log({state, payload});
    Vue.set(state, payload.name, payload)
  }
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}
