<template>
  <v-card elevation="1" class="simple-info" @click.native="goToPatientsByCode"><!--flat-->
    <v-card-title class="simple-info__title">
      {{title}}
    </v-card-title>
    <v-card-text>
      <div class="main-row">
        <div class="text">
          <slot name="new-patient"></slot>
          <slot name="info"></slot>
        </div>
        <div class="icon">
          <slot name="image"></slot>
        </div>
      </div>
    </v-card-text>
  </v-card>
</template>

<script>
import Image from '@/mixins/Image'

export default {
  name: 'simple-info',
  mixins: [Image],
  props: {
    title: String,
    code: String
  },
  methods: {
    goToPatientsByCode () {
      const index = this.qFilterBtns.findIndex(filter => filter.code === this.code)
      localStorage.setItem('quickFilterIndex', index)
      this.$router.push({ name: 'Patients', query: { quickFilterCode: this.code } })
    }
  },
  computed: {
    qFilterBtns () {
      return this.$store.state.settings?.patients?.quickFilters?.$values
    }
  }
}
</script>

<style scoped lang="scss">
.simple-info {
  margin-bottom: 25px;
  border-radius: 16px;
  cursor: pointer;
  ::v-deep .v-card {
    &__title {
      position: absolute;
    }
    &__text {
      padding-bottom: 0;
    }
  }
  &__title {
    font-weight: 600;
    font-size: 20px;
    color: #16192C;
  }
  .main-row {
    display: flex;
    align-items: center;
    justify-content: space-between;
    .text {
      font-weight: 500;
      font-size: 36px;
      color: #27272E;
    }
    .icon {
      display: flex;
    }
  }
}
</style>
