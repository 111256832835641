<template>
  <div class="table">
    <v-data-table v-bind="$props" :options.sync="options">
      <template v-for="(iter, i) in headers" :slot="`header.${iter.value}`" slot-scope="{item}">
        <div v-if="iter.filterable" :key="`${i}-filterable`" class="d-flex float-left">
          <filter-box :field-name="iter.value" :title="iter.text" :type="iter.type" @update:filter-box="emitFilterBox" @reset:filter-box="emitResetFilter" />
          <span
            class="ml-2"
            :key="i"
            :class="{
              'brown-text': activeFilteredColumn && activeFilteredColumn.name.toLowerCase() === iter.value.toLowerCase()
            }"
          >
            <!-- {{iter.text}} -->
          </span>
          <slot :name="`header:${iter.value}`" :item="item" />
        </div>
        <div v-if="iter.isReplacingSpace" :key="`${i}-isReplacingSpace`" class="d-flex justify-center">
          <div v-html="iter.text" style="text-align: center"></div>
        </div>
        <div v-else-if="(iter.isIcon || iter.customFilter) && !currentHeader" :key="`${i}-custom-filter`" class="d-flex align-center" :class="{ 'cursor-pointer': iter.action }" @click="handleEvent(iter)">
          <div class="justify-start">
            <v-icon style="font-size: 20px">{{iter.icon ? iter.icon : iter.text}}</v-icon>
            <span v-if="iter.text">{{iter.text}}</span>
          </div>
          <div v-if="iter.customFilter" class="d-flex justify-end align-center" id="filter-activator" style="cursor: pointer;" @click.stop="showCustomFilter">
            <img v-if="customFilterIsActive" :src="getImgWithExt(['icons', 'blood-custom-filter-active', 'svg'])" alt="icon" />
            <img v-else :src="getImgWithExt(['icons', 'blood-custom-filter', 'svg'])" alt="icon" />
            <v-menu activator="#filter-activator" offset-y :close-on-content-click="true" v-model="isFilterActive">
              <v-list style="padding: 10px 10px;">
                <v-list-item
                  v-for="(item, index) in customFilterItems"
                  :key="index"
                  :value="item.value"
                  class="filter-list-item"
                  @click="filterData(item)"
                >
                  <v-list-item-title style="display: flex;align-items: center;column-gap: 10px">
                    <img :src="getImgWithExt(['icons', item.icon, 'svg'])" alt="icon" />
                    {{ item.title }}
                  </v-list-item-title>
                </v-list-item>
              </v-list>
            </v-menu>
            <span class="filter-label">{{selectedFilterValue}}</span>
          </div>
        </div>
        <div v-else :key="i" class="d-flex float-left">
          <span v-if="currentHeader?.text !== iter.text">{{iter.text}}</span>
<!--          <slot :name="`header:${iter.value}`" :item="item" />-->
        </div>
        <div :key="`${i}-input`"
             v-if="JSON.stringify(currentHeader) === JSON.stringify(iter)"
             class="d-flex justify-center align-center"
        >
          <Input outlined
                 isAutofocus
                 height="30px"
                 class="search-input"
                 @blur="currentHeader = null"
                 v-model="searchInputValue"
                 @keyup.enter="$emit('searchByInput', searchInputValue), currentHeader = null"
                 :style="{'width': '100%'}"
          >
            <div slot="prepend-inner" style="cursor: pointer">
              <v-icon>{{iter.icon}}</v-icon>
            </div>
          </Input>
          <div v-if="iter.customFilter"
               id="filter-activator-2"
               class="d-flex justify-end align-center"
               style="cursor: pointer;"
               @click.stop="showCustomFilter">
            <img v-if="customFilterIsActive" :src="getImgWithExt(['icons', 'blood-custom-filter-active', 'svg'])" alt="icon" />
            <img v-else :src="getImgWithExt(['icons', 'blood-custom-filter', 'svg'])" alt="icon" />
            <v-menu activator="#filter-activator-2" offset-y :close-on-content-click="true" v-model="isFilterActive">
              <v-list style="padding: 10px 10px;">
                <v-list-item
                  v-for="(item, index) in customFilterItems"
                  :key="index"
                  :value="item.value"
                  class="filter-list-item"
                  @click="filterData(item)"
                >
                  <v-list-item-title style="display: flex;align-items: center;column-gap: 10px">
                    <img :src="getImgWithExt(['icons', item.icon, 'svg'])" alt="icon" />
                    {{ item.title }}
                  </v-list-item-title>
                </v-list-item>
              </v-list>
            </v-menu>
            <span class="filter-label">{{selectedFilterValue}}</span>
          </div>
        </div>
      </template>
      <template v-for="(iter, i) in headers" :slot="`item.${iter.value}`" slot-scope="{item}">
        <slot v-if="iter.isSlotable" :name="`item:${iter.value}`" :item="item"></slot>
        <span v-else :key="i">{{item[iter.value]}}</span>
      </template>

      <template slot="body.append">
        <slot name="body:append"></slot>
      </template>

      <template slot="body">
        <slot name="body"></slot>
      </template>

      <template slot="item.actions" slot-scope="{ item }">
        <actions :items="item.portal && item.active ? actions : item.portal && !item.active ? actionsWithActivate : !item.portal && !item.active ? extendedActionsWithActivate : extendedActions"
          v-on="actionListener"
          :item="item"
        />
      </template>

      <template v-slot:footer="{ item }">
        <slot name="footer" :item="item"></slot>
      </template>
    </v-data-table>
  </div>
</template>

<script>
import Validation from '@/mixins/Validation'
import actions from '@/components/layouts/actions'
import filterBox from '@/components/layouts/filter-box'
import Input from '@/components/elements/Input.vue'
import Image from '@/mixins/Image'

export default {
  name: 'Table',
  props: {
    headers: {
      type: Array
    },
    items: {
      type: Array
    },
    actions: { type: Array },
    actionsWithActivate: { type: Array },
    extendedActions: { type: Array },
    extendedActionsWithActivate: { type: Array },
    deactivatePatient: Object,
    activatePatient: Object,
    serverItemsLength: {
      type: Number
    },
    hideDefaultFooter: {
      type: Boolean
    }
  },
  mixins: [Validation, Image],
  components: { actions, filterBox, Input },
  data: () => ({
    activeFilteredColumn: null,
    currentHeader: null,
    searchInputValue: null,
    selectedFilterValue: 'Groups',
    customFilterIsActive: false,
    isFilterActive: false,
    customFilterItems: [
      { title: 'Show Groups', value: 0, icon: 'blood-show-groups', label: 'Groups' },
      { title: 'Out of Range', value: 1, icon: 'blood-out-of-range', label: 'Out of Range' },
      { title: 'Sort by Name', value: 2, icon: 'blood-sort-by-name', label: 'Sorted by Name' },
    ]
  }),
  computed: {
    options: {
      get () {
        return {}
      },
      set (val) {
        this.$emit('update:option', val)
      }
    },
    actionListener: function () {
      var vm = this
      return Object.assign({},
        this.$listeners,
        {
          action: function (event) {
            vm.$emit('click:action', { action: event.params, id: event.id, data: event.data })
          }
        }
      )
    }
  },
  methods: {
    emitFilterBox (value) {
      if (value.rangeSearch) {
        this.activeFilteredColumn = {
          name: value.rangeSearch.data,
          type: value.rangeSearch.type
        }
      } else {
        this.activeFilteredColumn = value
      }
      this.$emit('filterBox', value)
    },
    emitResetFilter (fieldName) {
      this.activeFilteredColumn = null
      this.$emit('resetFilter', fieldName)
    },
    handleEvent (iter) {
      if (iter.action) {
        this.currentHeader = iter
        console.log('handleEvent')
      }
    },
    showCustomFilter() {
      this.customFilterIsActive = true
    },
    filterData(obj) {
      this.searchInputValue = null
      this.selectedFilterValue = obj.label
      this.$emit('filer:update', obj.value)
    }
  },
  watch: {
    isFilterActive (val) {
      if (!val) {
        this.customFilterIsActive = false
      }
    }
  }
}
</script>

<style lang="scss" scoped>
  .table {

    ::v-deep .v-data-table {
       filter: drop-shadow(0px 10px 20px rgba(113, 128, 150, 0.06));
      &__wrapper {
        .v-data-table-header {
          tr {
            th {
              font-size: 10px;
              font-weight: 600;
              color: #8492A6;
              white-space: nowrap;
            }
          }
        }
        tbody {
          tr {
            &:hover { background-color: transparent !important; cursor: pointer; }
            &:last-child {
              td {
                 border-bottom: thin solid #EDF2F9;
              }
            }
          }
        }
      }
      .v-input {
        .v-input__control {
          .v-text-field__details {
            display: none!important;
          }
        }
      }
    }
    .brown-text {
      color: brown;
    }
    .cursor-pointer {
      cursor: pointer;
    }
  }
  ::v-deep .v-menu__content {
    .v-list {
      .v-list-item:hover {
        background: #f6f6f6!important;
      }
    }
  }
  .filter-label {
    margin-left: 10px;color: black;
    background: #f6f6f6;
    padding: 5px;
    border-radius: 5px;
  }
  .search-input {
    ::v-deep .v-input {
      .v-input__control .v-input__slot .v-text-field__slot input {
        font-weight: 400;
      }
    }
  }
</style>
