import Vue from 'vue'

const state = () => ({
  products: {},
  supplementById: {},
  productsListIsLoaded: false
})

const getters = {}

const actions = {
  async getProducts ({ commit }, params) {
    commit('SET_DATA', ['productsListIsLoaded', false])
    const result = await Vue.$http.post('supplements/clinic-products/get-list', { ...params })
    commit('SET_DATA', ['productsListIsLoaded', true])
    if (result?.status === 200) {
      commit('SET_DATA', ['products', result?.data])
      return result?.data
    }
  },
  async addProduct ({ commit }, params) {
    const result = await Vue.$http.post('supplements/clinic-products', { ...params })
    if (result?.status === 200) {
      return true
    } else {
      return false
    }
  },
  async updateProduct ({ commit }, params) {
    const {id, data} = params
    const result = await Vue.$http.put(`supplements/clinic-products/${id}`, { ...data, id })
    if (result?.status === 200) {
      return true
    } else {
      return false
    }
  },
  async deleteProduct ({ commit }, id) {
    const result = await Vue.$http.delete(`supplements/clinic-products/${id}`)
    if (result?.status === 200) {
      return result?.data
    }
  },
}

const mutations = {
  SET_DATA: (state, payload) => {
    const [property, value] = payload
    Vue.set(state, property, value)
  }
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}
