<template>
  <div class="login-form">
    <div class="login-form__title">Login</div>
    <div class="login-form__sign-up-link d-flex mt-2 mb-10">
      Don’t have an account? <Link to="/sign-up" class="ml-2">Create account</Link>
    </div>
    <v-form ref="form" v-model="valid" lazy-validation id="authForm" autocomplete="off">
      <v-row>
        <v-col class="py-0">
          <div class="label">E-mail</div>
          <Input
            autocomplete="off"
            input-class="login"
            outlined
            height="56"
            radius="16"
            tabindex="1"
            placeholder="Type your email"
            :rules="loginEmailRules"
            @input="form.email = $event"
            @enter="preLocalAuth"
          />
<!--          background-color="#EDF2F7"-->
        </v-col>
      </v-row>
      <v-row>
        <v-col class="py-0">
          <div class="label">Password</div>
          <Input
            autocomplete="off"
            input-class="login"
            outlined
            height="56"
            radius="16"
            tabindex="2"
            placeholder="Type your password"
            type="password"
            :rules="loginPwdRules"
            @input="form.password = $event"
            @enter="preLocalAuth"
          />
<!--          background-color="#EDF2F7"-->
        </v-col>
      </v-row>
      <v-row class="mt-0">
        <v-col class="py-0">
          <div v-if="commonError" style="line-height: 12px;font-size: 12px;color: #ff5252;margin-bottom: 5px;">
            {{ commonError }}
          </div>
          <div class="">
            <Button block dark btn-bg="dark-green" tabindex="3" height="56" @click.native="preLocalAuth">Sign in</Button>
          </div>
        </v-col>
      </v-row>
    </v-form>
    <br>
    <div class="login-form__forgot-link d-flex justify-center">Forgot your password? <Link to="/" class="ml-2" @click.native="openForgot">Reset password</Link></div>
    <div v-if="loading" class="loading d-flex justify-center px-2 py-2 align-center">
      <v-progress-circular
        indeterminate
        color="#008C89"
      ></v-progress-circular>
    </div>
    <forgot-password v-if="dialogs.forgot.isOpen" />
  </div>
</template>

<script>
import { mapActions, mapMutations, mapState } from 'vuex'
import Validation from '@/mixins/Validation'

import Button from '@/components/elements/Button'
import Input from '@/components/elements/Input'
import Link from '@/components/elements/Link'

import ForgotPassword from '@/components/layouts/dialogs/forgot-password'
// import { MIN_PASS_LENGTH } from '@/constants/const'

export default {
  name: 'login-form',
  components: { Button, Input, Link, ForgotPassword },
  mixins: [Validation],
  data: () => ({
    form: {
      email: '',
      password: ''
    },
    commonError: null,
    loading: false
  }),
  mounted () {
    document.title = 'Maestro Practitioner Portal'
  },
  methods: {
    ...mapMutations('dialogs', ['TOGGLE_DIALOG']),
    ...mapActions(['login']),
    ...mapActions('subscriptions', ['getCurrentSubscriptionPlan']),
    preLocalAuth () {
      this.loading = true
      this.commonError = null
      this.setDynamicValidationRules({
        loginEmailRules: [
          v => !!v || 'Email is required'
          // v => (!v || /.+@.+\..+/.test(v)) || 'E-mail must be valid'
        ],
        loginPwdRules: [
          v => !!v || 'Password is required'
          // v => (!v || v?.length >= MIN_PASS_LENGTH) || `Min ${MIN_PASS_LENGTH} characters`
        ]
      })
      this.$nextTick(() => {
        this.auth()
      }, 200)
    },
    async auth () {
      if (this.validate()) {
        const res = await this.login(this.form)
        if (!res.validationResults.isValid) {
          if (res.validationResults?.results[0].name === 'Email') {
            this.setDynamicValidationRules({
              loginEmailRules: [
                v => !!v || 'Email is required',
                // v => (!v || /.+@.+\..+/.test(v)) || 'E-mail must be valid',
                res.validationResults.isValid || res.validationResults?.results[0].message
              ]
            })
          }
          if (res.validationResults?.results[0].name === 'Password') {
            this.setDynamicValidationRules({
              loginPwdRules: [
                v => !!v || 'Password is required',
                // v => (!v || v?.length >= MIN_PASS_LENGTH) || `Min ${MIN_PASS_LENGTH} characters`,
                res.validationResults.isValid || res.validationResults?.results[0].message
              ]
            })
          }
          if (res.validationResults?.results[0].name === 'Common') {
            this.commonError = res.validationResults?.results[0].message
          }
        }
        if (!res.success) {
          this.commonError = res.message
        }
        if (res.validationResults.isValid) {
          this.getCurrentSubscriptionPlan()
        }
        this.loading = false
      }
    },
    openForgot () {
      this.TOGGLE_DIALOG({ name: 'forgot', isOpen: true })
    }
  },
  computed: {
    ...mapState({
      dialogs: state => state.dialogs
    })
  },
  watch: {
    'form.email' (val) {
      if (!val) this.loginEmailRules = []
    },
    'form.password' (val) {
      if (!val) this.loginPwdRules = []
    }
  }
}
</script>

<style lang="scss" scoped>
  .loading {
    min-height: 100px;
    position: absolute;
    height: 75%;
    width: 110%;
    top: 106px;
    left: -20px;
    background: rgba(213, 213, 213, 0.5);
    border-radius: 16px;
  }
  .label {
    font-size: 14px;
    font-weight: 500;
    color: #425466
  }
  .login-form {
    width: 100%;
    position: relative;
    &__title {
      font-weight: 500;
      font-size: 38px;
    }
    &__sign-up-link,
    &__forgot-link {
      font-weight: 400;
      font-size: 16px;
      color: #72767F;
      white-space: nowrap;
    }
  }
  ::v-deep .v-text-field__details {
    padding-top: 5px !important;
  }
</style>
