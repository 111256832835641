<template>
  <div class="add-brand">
    <Dialog style="width: 50%">
      <template slot="title">
        <div class="title">
          {{modalAddNewBrand.title}}
        </div>
      </template>

      <template slot="body">
        <div class="edit-protocol-dialog__form">
          <v-form ref="form" v-model="valid" lazy-validation id="edit-brand" class="pt-4 pb-4 px-4">
            <v-row>
              <v-col class="py-0">
                <div class="label">Name</div>
                <Input outlined
                       radius="8"
                       @input="form.name=$event"
                       :rules="notEmtpy"
                />
              </v-col>
            </v-row>
            <v-row>
              <v-col class="py-0">
                <div class="label">Short abbreviation (2-3 letters)</div>
                <Input outlined
                       radius="8"
                       @input="form.shortName=$event"
                       :rules="notEmtpy"
                />
              </v-col>
            </v-row>
          </v-form>
        </div>
      </template>

      <template slot="actions">
        <div class="d-flex justify-space-between w-100 pb-4 px-4">
          <Button
            dark
            outlined
            color="grey"
            @click.native="close"
            :styles="{ 'border-radius': '8px' }"
          >
            <span style="color: #425466">Cancel</span>
          </Button>
          <Button
            btn-bg="dark-green"
            @click.native="createNewBrand"
            :disabled="!form.name || !form.shortName"
            :styles="{ 'border-radius': '8px' }"
          >
            <span style="color: #FFFFFF">Create</span>
          </Button>
        </div>
      </template>
    </Dialog>
  </div>
</template>

<script>
import { mapActions, mapMutations, mapState } from 'vuex'
import Dialog from '@/components/layouts/dialog'
import Button from '@/components/elements/Button'
import Textarea from '@/components/elements/Textarea'
import Validation from '@/mixins/Validation'
import Input from '@/components/elements/Input.vue'

export default {
  name: 'modal-add-brand',
  components: { Dialog, Button, Input, Textarea },
  mixins: [Validation],
  data: () => ({
    form: {
      name: '',
      shortName: ''
    }
  }),
  methods: {
    ...mapMutations('dialogs', ['TOGGLE_DIALOG']),
    ...mapActions('brands', ['createBrand']),
    close () {
      this.TOGGLE_DIALOG({ name: 'modalAddNewBrand', isOpen: false })
    },
    async createNewBrand () {
      const res = await this.createBrand(this.form)
      if (res && res.brandId) {
        this.$emit('update', res.brandId)
        this.close()
      } else {
        this.$emit('update', false)
        this.close()
      }
    }
  },
  computed: {
    ...mapState({
      modalAddNewBrand: state => state.dialogs.modalAddNewBrand
    })
  }
}
</script>

<style lang="scss" scoped>
.w-100 {
  width: 100%;
}
.add-brand {
  ::v-deep .v-card {
    padding: 0;
    background: white!important;
    height: auto;
    &__title {
      background: #edf2f7;
      padding: 10px 16px!important;
      justify-content: center!important;
    }
  }
  ::v-deep .v-dialog {
    width: 25%;
    height: 40%;
    overflow-y: hidden!important;
    max-height: unset!important;
  }
  ::v-deep .v-dialog__container {
    .v-card {
      &__title {
        padding: 25px 35px 17px 26px !important;
        justify-content: center!important;
        position: relative;
        .title {
          font-family: 'Poppins';
          font-weight: 600;
          font-size: 16px;
          text-align: center;
          color: #27272E;
        }
        .v-icon {
          position: absolute;
          right: 15px;
          top: 25px;
        }
      }
    }
  }
}
</style>
