import _ from 'lodash'

const Pagination = {
  data: () => ({
    query: {},
    pagination: null,
    page: 1,
    pageSize: 5,
    pageCount: 0,
    allItems: [],
    items: [],
    copiedItems: []
  }),
  methods: {
    optionTableData ($event, direction) {
      // First request make here, because mount to trigger earlier.
      // We have to waiting for table pagination params.

      this.addUrlParam({
        PageSize: this.currentPageSize || 15, // $event.page,
        PageIndex: 0, // +localStorage.getItem('patientPaginationPageIndex') || $event.page || 1,
        order: [
          {
            column: 'name',
            direction: direction || 'desc'
          }
        ]
      })
      if ($event.sortBy.length) {
        this.addUrlParam({
          PageSize: this.currentPageSize || 15, // $event.page,
          PageIndex: 0,
          order: [
            {
              column: $event.sortBy[0],
              direction: ($event.sortDesc[0]) ? 'desc' : 'asc'
            }
          ]
        })
      }
      if (this.$route.query.quickFilterCode) {
        const index = this.qFilterBtns.findIndex(filter => filter.code === this.$route.query.quickFilterCode)
        localStorage.setItem('quickFilterIndex', index)
        this.query.PageIndex = 0
        this[this.getAllFunc]({ ...this.query, quickFilterCode: this.$route.query.quickFilterCode })
      } else {
        this[this.getAllFunc]({...this.query, PageSize: this.currentPageSize || 15})
      }
      this.page = 1
      // if (this.qFilterIndex) {
      //   console.log('PAGINATION', this.qFilterBtns)
      //   this[this.getAllFunc]({ ...this.query, quickFilterCode: this.qFilterBtns[this.qFilterIndex].code })
      // } else {
      //   this[this.getAllFunc](this.query)
      // }
    },
    addUrlParam (params) {
      this.$set(this.$data, 'query', { ...this.query, ...params })
    },
    removeUrlParam (param) {
      const query = this.query
      if (query[param]) {
        delete query[param]
        this.$set(this.$data, 'query', query)
      }
    },
    removeAllUrlParam () {
      this.$set(this.$data, 'query', '')
    },
    removeAllUrlParamExept (params) {
      const query = this.query
      for (const key in query) {
        if (key && !params.includes(key)) {
          delete query[key]
        }
      }
      this.$set(this.$data, 'query', query)
    },
    getQuery () {
      return this.query
    },
    setupPagination (allItems) {
      this.allItems = _.chunk(allItems, this.pageSize)
      this.pageCount = _.size(this.allItems)
      this.items = this.allItems[this.page - 1] || this.allItems[0]
      this.copiedItems = this.items
    },
    changeItermPerPage ($event, allItems) {
      this.pageSize = $event
      this.setupPagination(allItems)
    },
    changePagination ($event) {
      this.page = $event
      this.items = this.allItems[this.page - 1] || this.allItems[0]
      this.copiedItems = this.items
    }
  }
}

export default Pagination
