<template>
  <div class="reports">
    <v-row no-gutters>
      <v-col cols="12">
        <v-row no-gutters>
          <div class="report-label">Systems Survey Forms</div>
        </v-row>
        <div class="main-row mt-4">
          <div v-for="item in settingsForm.$values"
               :ref="item.code"
               :key="item.$id"
               class="btn-report"
               @click="getReport(item.code, item.code)"
          >
            <span class="report-title">{{item.name}}</span>
          </div>
<!--          <div ref="surveyForm2PEng" class="btn-report" @click="getReport('surveyForm2PEng', 'surveyForm2PEng')">
            <span class="report-title">Survey Form 2 page</span>
          </div>
          <div ref="surveyForm2PSpa" class="btn-report" @click="getReport('surveyForm2PSpa', 'surveyForm2PSpa')">
            <span class="report-title">Survey Form 2 Spanish</span>
          </div>
          <div ref="surveyForm3PEng" class="btn-report" @click="getReport('surveyForm3PEng', 'surveyForm3PEng')">
            <span class="report-title">Survey Form 3</span>
          </div>
          <div ref="surveyFormNumb" class="btn-report" @click="getReport('surveyFormNumb', 'surveyFormNumb')">
            <span class="report-title">Survey Form Numbers</span>
          </div>-->
        </div>
        <div class="main-row mt-4">
          <div class="check-box-additional">
            <v-checkbox
              style="margin-top: 0;padding-top: 0"
              color="#008C89"
              v-model="includeCurrentPatient"
              :disabled="disabledCheckBox"
              label="Include current Patient"
            ></v-checkbox>
          </div>
          <div class="check-box-additional">
            <v-checkbox
              style="margin-top: 0;padding-top: 0"
              color="#008C89"
              v-model="includeCurrentDoctor"
              :disabled="disabledCheckBox"
              label="Include current Doctor"
            ></v-checkbox>
          </div>
          <div class="check-box-additional">
            <v-checkbox
              style="margin-top: 0;padding-top: 0"
              color="#008C89"
              v-model="includeGroupDescriptions"
              :disabled="disabledCheckBox"
              label="Show Group Name"
            ></v-checkbox>
          </div>
        </div>
      </v-col>
    </v-row>
    <ReportView
      :disabledSpanish="true"
      :activeLanguage="activeLanguage"
      @switchLanguage="switchLanguage"
      @sendSurveyFormToPatient="sendToPatientEmail"
      v-if="dialogs.openReportPdfView && dialogs.openReportPdfView.isOpen && initialDoc"
      :initialDoc="initialDoc"
    />
    <div v-else style="text-align: center;">
      <h3>Please select report</h3>
    </div>
    <div class="custom-alert">
      <v-alert
        color="#7ac1fb"
        dismissible
        type="info"
        v-model="alertIsOpen"
      >
        <span style="font-size: 14px;">{{popupMessage}}</span>
      </v-alert>
    </div>
  </div>
</template>

<script>
import { mapActions, mapMutations, mapState } from 'vuex'
import ReportView from '@/components/layouts/dialogs/reportView'
import Mapping from '@/mixins/Mapping'

export default {
  name: 'reports',
  components: { ReportView },
  mixins: [Mapping],
  data: () => ({
    initialDoc: null,
    activeLanguage: 'en',
    items: [1, 2, 3, 4, 5],
    includeCurrentPatient: false,
    includeCurrentDoctor: false,
    includeGroupDescriptions: false,
    disabledCheckBox: true,
    fileUrl: null,
    file: null,
    alertIsOpen: false,
    popupMessage: ''
  }),
  methods: {
    ...mapActions('patients', ['getPatient']),
    ...mapMutations('dialogs', ['TOGGLE_DIALOG']),
    ...mapActions('reports', ['gerReportSurveyFormByCode', 'sendSurveyForm']),

    openReport () {
      this.initialDoc = 'https://pdftron.s3.amazonaws.com/downloads/pl/demo-annotated.pdf'
      this.TOGGLE_DIALOG({ name: 'openReportPdfView', isOpen: true, title: 'Report' })
    },

    switchLanguage (lang) {
      this.activeLanguage = lang
    },

    
    async getReport (ref, reportCode) {
      if (!this.$refs[ref][0].classList.contains('report-active')) {
        Array.from(document.getElementsByClassName('btn-report')).forEach(item => {
          item.classList.remove('report-active')
        })
        this.$refs[ref][0].classList.add('report-active')
        this.disabledCheckBox = false
        localStorage.setItem('reportCode', reportCode)
        localStorage.setItem('reportName', this.upperCaseFirst(this.splitByUpperCase(ref)))
        const payload = {
          reportCode,
          // entityId: this.$route.params.id,
          patientId: this.$route.params.id,
          includePatient: this.includeCurrentPatient,
          includeDoctor: this.includeCurrentDoctor,
          includeGroup: this.includeGroupDescriptions
        }
        const res = await this.gerReportSurveyFormByCode(payload)
        this.file = res
        this.parseFile(this.file)
        this.TOGGLE_DIALOG({ name: 'openReportPdfView', isOpen: true, title: `${this.getReportNameByCode[ref]}` })
      } /* else {
        localStorage.setItem('reportCode', '')
        localStorage.setItem('reportName', '')
        this.disabledCheckBox = true
        this.$refs[ref][0].classList.remove('report-active')
      } */
    },
    async sendToPatientEmail () {
      const data = {
        reportCode: localStorage.getItem('reportCode'),
        patientId: this.patient.id,
        includePatient: this.includeCurrentPatient,
        includeDoctor: this.includeCurrentDoctor,
        includeGroup: this.includeGroupDescriptions
      }
      const res = await this.sendSurveyForm(data)
      if (res.success) {
        this.alertIsOpen = true
        this.popupMessage = 'Survey Form was send successfully to Patient Email'
      }
    },
    parseFile (file) {
      // this.initialDoc = null
      if (file) {
        const reader = new FileReader()
        reader.onloadend = () => {
          this.fileUrl = URL.createObjectURL(file)
          this.initialDoc = this.fileUrl

          /* const self = this
          setTimeout(function () {
            const element = document.createElement('iframe')
            element.src = self.initialDoc
            element.id = 'print-frame'
            element.style.display = 'none'
            const body = document.getElementsByTagName('body')[0]
            body.appendChild(element)
            element.contentWindow.print()
          }, 1000) */
        }
        reader.readAsDataURL(file)
      } else {
        this.fileUrl = null
      }
    },
    splitByUpperCase (str) {
      return str.split(/(?=[A-Z])/).join(' ')
    },
    upperCaseFirst (str) {
      return str[0].toUpperCase() + str.slice(1)
    }
  },
  watch: {
    async includeCurrentPatient (val) {
      const payload = {
        reportCode: localStorage.getItem('reportCode'),
        // entityId: this.$route.params.id,
        patientId: this.$route.params.id,
        includePatient: val,
        includeDoctor: this.includeCurrentDoctor,
        includeGroup: this.includeGroupDescriptions
      }
      const res = await this.gerReportSurveyFormByCode(payload)
      this.file = res
      this.parseFile(this.file)
      this.TOGGLE_DIALOG({ name: 'openReportPdfView', isOpen: true, title: `${this.upperCaseFirst(this.splitByUpperCase(localStorage.getItem('reportName')))}` })
    },
    async includeCurrentDoctor (val) {
      const payload = {
        reportCode: localStorage.getItem('reportCode'),
        // entityId: this.$route.params.id,
        patientId: this.$route.params.id,
        includePatient: this.includeCurrentPatient,
        includeDoctor: val,
        includeGroup: this.includeGroupDescriptions
      }
      const res = await this.gerReportSurveyFormByCode(payload)
      this.file = res
      this.parseFile(this.file)
      this.TOGGLE_DIALOG({ name: 'openReportPdfView', isOpen: true, title: `${this.upperCaseFirst(this.splitByUpperCase(localStorage.getItem('reportName')))}` })
    },
    async includeGroupDescriptions (val) {
      const payload = {
        reportCode: localStorage.getItem('reportCode'),
        // entityId: this.$route.params.id,
        patientId: this.$route.params.id,
        includePatient: this.includeCurrentPatient,
        includeDoctor: this.includeCurrentDoctor,
        includeGroup: val
      }
      const res = await this.gerReportSurveyFormByCode(payload)
      this.file = res
      this.parseFile(this.file)
      this.TOGGLE_DIALOG({ name: 'openReportPdfView', isOpen: true, title: `${this.upperCaseFirst(this.splitByUpperCase(localStorage.getItem('reportName')))}` })
    },
    alertIsOpen (val) {
      const self = this
      if (val) {
        setTimeout(function () {
          self.alertIsOpen = false
        }, 5000)
      }
    }
  },
  computed: {
    ...mapState({
      settingsForm: state => state.settings.patients.forms,
      patient: state => state.patients.patient
    }),
    dialogs () {
      return this.$store.state.dialogs
    }
  }
}
</script>

<style lang="scss" scoped>
.reports {
  padding: 16px!important;
  .language-switcher {
    border: 1px solid #f1f1f1;
    border-radius: 18px;
    padding: 4px;
    margin-bottom: 10px;
    box-shadow: 0px 0px 1px rgba(12, 26, 75, 0.24), 0px 3px 8px -1px rgba(50, 50, 71, 0.05);

    .button {
      ::v-deep .v-btn {
        background-color: rgba(255, 255, 255, 0.64) !important;
        color: black!important;
        font-weight: 600!important;
      }
    }
    .button.active-language {
      ::v-deep .v-btn {
        background-color: #4faa6e !important;
        color: white!important;
        font-weight: 600!important;
      }
    }
  }
  .report-label {
    font-weight: 600;
    font-size: 20px;
    color: #16192C;
  }

  .main-row {
    display: flex;
    flex-wrap: wrap;
    column-gap: 1rem;
    row-gap: 1rem;
    .btn-report {
      width: fit-content;
      height: fit-content;
      background: #F7FAFC;
      border-radius: 8px;
      padding: 7px;
      border: 2px solid transparent;
      cursor: pointer;
      .custom-select {
        width: 50px;
        height: 21px;
        ::v-deep .v-input__control {
          min-height: unset!important;
          .v-text-field__details {
            display: none!important;
          }
          .v-input__slot {
            height: 21px!important;
            background: transparent!important;
            box-shadow: unset!important;
            padding: 0!important;
          }
        }
      }
      .select-label {
        position: absolute;
        top: -27px;
        right: -50px;
        font-weight: 500;
        font-size: 12px;
        color: #718096;
        width: max-content;
      }
      .report-title {
        font-weight: 500;
        font-size: 14px;
        color: #425466;
      }
      .bold {
        font-weight: 600;
        padding-left: 5px;
      }
      .check-box-additional {
        margin-left: 20px;
        ::v-deep .v-input {
          .v-label {
            font-size: 14px!important;
            color: #425466!important;
          }
          .v-label.v-label--is-disabled {
            font-size: 14px!important;
            color: grey!important;
          }
        }
      }
      .additional-filters {
        display: flex;
        column-gap: 1rem;
      }
    }
    .btn-report.report-active {
      border: 2px solid $dark-green;
      background: $light-dark-green;
    }
  }
  .custom-alert {
    max-width: 300px;
    position: fixed;
    top: 10px;
    right: 20px;
    z-index: 100;
  }
}
</style>
