import Vue from 'vue'

const state = () => ({
  supplements: {},
  supplementById: {},
  productById: {},
  supplementsListIsLoaded: false
})

const getters = {}

const actions = {
  supplementsDropdown ({ _ }, name) {
    return new Promise((resolve) => {
      Vue.$http.get(`/Supplements/SupplementsDropdown?search=${name}`).then(result => {
        resolve(result?.data?.record)
      })
    })
  },
  async getDefaultDosagesBySupplement ({ commit }, id) {
    const result = await Vue.$http.get(`/supplements/${id}/Get`)
    if (result?.status === 200) {
      console.log(result)
      commit('SET_DATA', ['supplementById', result?.data?.record])
      return result?.data
    }
  },
  async getSupplements ({ commit }, params) {
    commit('SET_DATA', ['supplementsListIsLoaded', false])
    const result = await Vue.$http.post('supplements/GetSupplements', { ...params })
    commit('SET_DATA', ['supplementsListIsLoaded', true])
    if (result?.status === 200) {
      commit('SET_DATA', ['supplements', result?.data])
      return result?.data
    }
  },
  async getGlobalSupplementsNotes ({ commit }, id) {
    const result = await Vue.$http.get(`/supplements/${id}/GetNotes`)
    if (result?.status === 200) {
      return result?.data
    }
  },
  async updateGlobalSupplementsNotes ({ commit }, {id, params}) {
    const result = await Vue.$http.put(`/supplements/${id}/UpdateNotes`, {...params})
    if (result?.status === 200) {
      return result?.data
    }
  },
  async addSupplement ({ commit }, params) {
    delete params.id
    const result = await Vue.$http.post('supplements/Add', { ...params })
    if (result?.status === 200) {
      return result?.data
    }
  },
  async clearDosageCustomization ({ commit }, id) {
    const result = await Vue.$http.post(`supplements/${id}/clear-dosage-customization`)
    if (result?.status === 200) {
      return result?.data
    }
  },
  async deleteSupplement ({ commit }, id) {
    const result = await Vue.$http.delete(`supplements/${id}/Delete`)
    if (result?.status === 200) {
      return result?.data
    }
  },
  async updateSupplement ({ commit }, params) {
    const { id } = params
    const result = await Vue.$http.put(`supplements/${id}/Update`, { ...params })
    if (result?.status === 200) {
      return result?.data
    }
  },
  async addProduct ({ commit }, params) {
    const id = params.supplementId
    const result = await Vue.$http.post(`supplements/${id}/Products/Add`, { ...params })
    if (result?.status === 200) {
      return result?.data
    }
  },
  async updateProduct ({ commit }, params) {
    const supplementId = params.id
    delete params.$id
    const result = await Vue.$http.put(`supplements/Products/${supplementId}/Update`, { ...params })
    if (result?.status === 200) {
      return result?.data
    }
  },
  async deleteProduct ({ commit }, id) {
    const result = await Vue.$http.delete(`supplements/Products/${id}/Delete`)
    if (result?.status === 200) {
      return result?.data
    }
  },
  async getProductById ({ commit }, params) {
    const { id } = params
    const result = await Vue.$http.get(`supplements/Products/${id}/Get`, { ...params })
    if (result?.status === 200) {
      commit('SET_DATA', ['productById', result?.data])
      return result?.data
    }
  }
}

const mutations = {
  SET_DATA: (state, payload) => {
    const [property, value] = payload
    Vue.set(state, property, value)
  },
  UPDATE_DATA: (state, {payload, id}) => {
    state.supplements.data.$values.find(supplement => {
      if (supplement.id === id) {
        for (const key in payload) {
          supplement[key] = payload[key]
        }
      }
    })
  },
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}
