import Vue from 'vue'

const state = () => ({
  messages: [],
  supportMessages: [],
  totalCountUnreadMessages: 0
})

const getters = { }

const actions = {
  async getMessages ({ commit, state }, params) {
    const { additionalParam } = params
    const result = await Vue.$http.get(`Chat/Messages${additionalParam}`)
    if (result?.status === 200) {
      const receiveData = result?.data.data.$values.map(item => {
        return Object.assign(item, { customDate: item.createdOn.split('T')[0] }) //  new Date(item.createdOn).setHours(0, 0, 0, 0)
      }).sort((a, b) => new Date(a.createdOn) - new Date(b.createdOn))
      if (state.messages.length > 0) {
        commit('CONCAT_DATA', receiveData)
      } else {
        commit('SET_DATA', ['messages', receiveData])
      }
      return result?.data.record
    }
  },
  async getSupportMessages ({ commit, state }, params) {
    const { additionalParam } = params
    const result = await Vue.$http.get(`Chat/SupportMessages${additionalParam}`)
    if (result?.status === 200) {
      const receiveData = result?.data.data.$values.map(item => {
        return Object.assign(item, { customDate: item.createdOn.split('T')[0] }) //  new Date(item.createdOn).setHours(0, 0, 0, 0)
      }).sort((a, b) => new Date(a.createdOn) - new Date(b.createdOn))
      if (state.messages.length > 0) {
        commit('CONCAT_DATA_SUPPORT_CHAT', receiveData)
      } else {
        commit('SET_DATA', ['supportMessages', receiveData])
      }
      return result?.data.record
    }
  },
  async createMessage ({ commit }, params) {
    const { ...data } = params
    // await Vue.$http.post('Chat/Messages/send-to-admin', { ...data })
    const result = await Vue.$http.post('Chat/Messages/Create', { ...data })
    if (result?.status === 201) {
      commit('PUSH_NEW_MESSAGE', result?.data)
    }
  },
  async createMessageForAdmin ({ commit }, params) {
    const { ...data } = params
    await Vue.$http.post('Chat/Messages/send-to-admin', { ...data })
    // const result = await Vue.$http.post('Chat/Messages/Create', { ...data })
    // if (result?.status === 201) {
    //   commit('PUSH_NEW_MESSAGE', result?.data)
    // }
  },
  async getUnreadCountMessages ({ commit }) {
    const result = await Vue.$http.get('Chat/Messages/UnreadCount')
    if (result?.status === 200) {
      commit('SET_DATA', ['totalCountUnreadMessages', result?.data.record])
    }
  },
  async markAsReadMsg ({ commit }, params) {
    const { IDs } = params
    const result = await Vue.$http.post('Chat/Messages/MarkAsRead', [...IDs])
    if (result?.status === 200) {
      console.log(result?.data)
      // commit('UPDATE_MESSAGE', result?.data)
    }
  }
}

const mutations = {
  SET_DATA: (state, payload) => {
    const [property, value] = payload
    Vue.set(state, property, value)
  },
  CONCAT_DATA: (state, payload) => {
    state.messages = state.messages.concat(payload).map(item => {
      return Object.assign(item, { customDate: item.createdOn.split('T')[0] })
    }).sort((a, b) => new Date(a.createdOn) - new Date(b.createdOn))
  },
  CONCAT_DATA_SUPPORT_CHAT: (state, payload) => {
    state.supportMessages = state.supportMessages.concat(payload).map(item => {
      return Object.assign(item, { customDate: item.createdOn.split('T')[0] })
    }).sort((a, b) => new Date(a.createdOn) - new Date(b.createdOn))
  },
  PUSH_NEW_MESSAGE: (state, payload) => {
    const messageExist = state.messages.find(message => message.id === payload.id)
    if (!messageExist) {
      payload.customDate = payload.createdOn.split('T')[0]
      state.messages.push(payload)
    }
  },
  PUSH_NEW_SUPPORT_MESSAGE: (state, payload) => {
    const messageExist = state.supportMessages.find(message => message.id === payload.id)
    if (!messageExist) {
      payload.customDate = payload.createdOn.split('T')[0]
      state.supportMessages.push(payload)
    }
  },
  UPDATE_MESSAGE: (state, payload) => {
    state.messages = state.messages.map(message => {
      if (message.id === payload.id) {
        return Object.assign(message, { isRead: payload.isRead })
      }
      return message
    })
  },
  UPDATE_TOTAL_COUNT_UNREAD_MESSAGE: (state, payload) => {
    state.totalCountUnreadMessages = payload
  }
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}
