<template>
  <div class="search-select">
    <v-autocomplete v-bind="$props" v-on="selectListeners" attach=".search-select" border-radius="20" />
  </div>
</template>

<script>
export default {
  name: 'search-select',
  props: {
    appendIcon: {
      type: String
    },
    appendOuterIcon: {
      type: String
    },
    attach: {
      type: Boolean
    },
    height: {
      type: [Number, String]
    },
    hideNoData: {
      type: Boolean
    },
    itemText: {
      type: String
    },
    itemValue: {
      type: String
    },
    items: {
      type: Array
    },
    label: {
      type: String
    },
    rules: {
      type: Array
    },
    outlined: {
      type: String
    },
    placeholder: {
      type: String
    },
    returnObject: {
      type: Boolean
    },
    value: {
      type: String
    },
    backgroundColor: {
      type: String
    },
    disabled: {
      type: Boolean
    },
    disableLookup: {
      type: Boolean
    }
  },
  computed: {
    selectListeners: function () {
      var vm = this
      return Object.assign({},
        this.$listeners,
        {
          'update:search-input': function ($event) {
            vm.$emit('update:search', $event)
          },
          change: function ($event) {
            vm.$emit('change:object', $event)
          }
        }
      )
    }
  }
}
</script>

<style lang="scss" scoped>
  .search-select {
    position: relative;
    ::v-deep .v-input {
      &__slot {
        height: 40px !important;
        min-height: 40px !important;
      }
      &__append-inner {
        margin-top: 7px !important;
      }
    }
  }
</style>
