<template>
  <div class="receipt">
    <v-row>
      <v-col cols="9">
        <v-card elevation="2">
          <v-card-text>
            <Table
              :headers="headers"
              hideDefaultFooter>

              <template v-slot:body>
                <template v-for="item in regularProductsItems">
                  <tr
                    :key="item.id"
                    class="custom-receipt-table__dynamic"
                    :class="{
                      'is-default': item.isDefault
                    }"
                  >
                    <td>
                      <div class="d-flex align-center justify-center">
                        <div class="d-flex">
                          <checkbox :class="{'disabled': ['Completed', 'Closed'].includes(visit.status)}" color="#008C89" :inputValue="item.showOnReceipt" @change="updatePreviewReceipt('regularProducts', item, item.resultSupplementProductId, $event), item.showOnReceipt=$event"></checkbox>
                        </div>
                      </div>
                    </td>
                    <td class="pa-0">
                      <div class="d-flex align-center justify-center">
                        <div class="d-flex">
                          <span>{{item.productNumber}}</span>
                        </div>
                      </div>
                    </td>
                    <td class="pa-0">
                      <div class="d-flex align-center justify-center">
                        <div class="d-flex">
                          <span>{{item.quantity}}</span>
                        </div>
                      </div>
                    </td>
                    <td class="pa-0 px-1">
                      <div class="d-flex align-center justify-center">
                        <div class="d-flex">
                          <span>{{item.productName}}</span>
                        </div>
                      </div>
                    </td>
                    <td class="pa-0 px-1">
                      <div class="d-flex align-center justify-center">
                        <div class="d-flex drop-ship" @click="openReceiptView(item)">
                          <span v-if="item.pastReceipt"># {{item.pastReceipt.receiptNumber}} {{item.pastReceipt.orderMethodName}}</span>
                          <span v-else>-</span>
                        </div>
                      </div>
                    </td>
                    <td class="pa-0">
                      <div class="d-flex align-center justify-center">
                        <div class="d-flex">
                          <span>{{item.size}}</span>
                        </div>
                      </div>
                    </td>
                    <td class="pa-0">
                      <div class="d-flex align-center justify-center">
                        <div class="d-flex">
                          <span>${{item.unitPrice.toFixed(2)}}</span>
                        </div>
                      </div>
                    </td>
                    <td class="pa-0">
                      <div class="d-flex align-center justify-center">
                        <div class="d-flex">
                          <span>${{item.totalCost.toFixed(2)}}</span>
                        </div>
                      </div>
                    </td>
                  </tr>
                </template>
              </template>

            </Table>
            <Table
              class="table-total-cost"
              hideDefaultFooter>

              <template v-slot:body>
                <tr class="custom-receipt-table__no-dynamic" v-if="receiptPreview">
                  <td>
                    <span class="font-weight-black" style="color: #8492A6;font-size: 14px;font-weight: bold">
                      Total
                    </span>
                  </td>
                  <td class="total-row" style="padding-left: 82px;">
                    <div class="d-flex">
                      <div>
                        <span  style="color: #8492A6">Daily:</span>
                        <span class="font-weight-bold">
                          {{receiptPreview.totalDailyPrice !== 0 ? '$' + receiptPreview.totalDailyPrice.toFixed(2) : ''}}
                        </span>
                      </div>
                      <div class="pl-4">
                        <span  style="color: #8492A6">Weekly:</span>
                        <span class="font-weight-bold">
                      {{receiptPreview.totalWeeklyPrice !== 0 ? '$' + receiptPreview.totalWeeklyPrice.toFixed(2) : ''}}
                    </span>
                      </div>
                      <div class="pl-4">
                        <span  style="color: #8492A6">Monthly:</span>
                        <span class="font-weight-bold">
                          {{receiptPreview.totalMonthlyPrice !== 0 ? '$' + receiptPreview.totalMonthlyPrice.toFixed(2) : ''}}
                        </span>
                      </div>
                    </div>
                  </td>
                  <td class="total-row" style="text-align: end;display: flex;align-items: center;justify-content: end;">
                    <span  style="color: #8492A6;padding-right: 10px">All Supplements:</span>
                    <span class="font-weight-bold" style="width: 100px;display: block;text-align: center;">
                      {{receiptPreview.totalProductsPrice !== 0 ? '$' + receiptPreview.totalProductsPrice.toFixed(2) : '-'}}
                    </span>
                  </td>
                </tr>
              </template>

            </Table>
          </v-card-text>
        </v-card>
        <br>
        <v-card elevation="2">
          <v-card-text>
            <Table
              :headers="headersClinicProducts"
              hideDefaultFooter>

              <template v-slot:body>
                <template v-for="item in clinicProductsItems">
                  <tr
                    :key="item.id"
                    class="custom-receipt-table__dynamic"
                    :class="{
                      'is-default': item.isDefault
                    }"
                  >
                    <td>
                      <div class="d-flex align-center justify-center">
                        <div class="d-flex align-center">
                          <checkbox :class="{'disabled': ['Completed', 'Closed'].includes(visit.status)}" color="#008C89" :inputValue="item.showOnReceipt" @change="updatePreviewReceipt('clinicProducts', item, item.resultSupplementProductId, $event), item.showOnReceipt=$event"></checkbox>
                          <v-icon v-if="!item.isDefault && item.pastReceipt" style="margin-top: -2px;" @click="deleteClinicProduct(item.resultSupplementProductId)">mdi-trash-can-outline</v-icon>
                        </div>
                      </div>
                    </td>
                    <td class="pa-0">
                      <div class="d-flex align-center justify-center">
                        <div class="d-flex">
                          <span>{{item.productNumber}}</span>
                        </div>
                      </div>
                    </td>
                    <td class="pa-0">
                      <div class="d-flex align-center justify-center">
                        <div class="d-flex align-center justify-center">
                          <span>{{item.quantity}}</span>
                          <div class="d-flex flex-column">
                            <v-icon @click="updateQuantityBottles(1, item, item.resultSupplementProductId)">mdi-chevron-up</v-icon>
                            <v-icon v-show="item.quantity > 1" @click="updateQuantityBottles(-1, item, item.resultSupplementProductId)">mdi-chevron-down</v-icon>
                          </div>
                        </div>
                      </div>
                    </td>
                    <td class="pa-0 px-1">
                      <div class="d-flex align-center justify-center">
                        <div class="d-flex">
                          <span>{{item.productName}}</span>
                        </div>
                      </div>
                    </td>
                    <td class="pa-0 px-1">
                      <div class="d-flex align-center justify-center">
                        <div class="d-flex drop-ship" @click="openReceiptView(item)">
                          <span v-if="item.pastReceipt"># {{item.pastReceipt.receiptNumber}} {{item.pastReceipt.orderMethodName}}</span>
                          <span v-else>-</span>
                        </div>
                      </div>
                    </td>
                    <td class="pa-0">
                      <div class="d-flex align-center justify-center">
                        <div class="d-flex">
                          <span>${{item.unitPrice.toFixed(2)}}</span>
                        </div>
                      </div>
                    </td>
                    <td class="pa-0">
                      <div class="d-flex align-center justify-center">
                        <div class="d-flex">
                          <span>${{item.totalCost.toFixed(2)}}</span>
                        </div>
                      </div>
                    </td>
                  </tr>
                </template>
              </template>

            </Table>
            <Table
              class="table-total-cost"
              hideDefaultFooter>

              <template v-slot:body>
                <tr class="custom-receipt-table__no-dynamic" v-if="receiptPreview">
                  <td>
                    <span class="font-weight-black" style="color: #8492A6;font-size: 14px;font-weight: bold">
                      Total
                    </span>
                  </td>
                  <td class="total-row" style="text-align: end;display: flex;align-items: center;justify-content: end;">
                    <span  style="color: #8492A6;padding-right: 10px">All products:</span>
                    <span class="font-weight-bold" style="width: 100px;display: block;text-align: center;">
                      {{receiptPreview.totalClinicProductsPrice !== 0 ? '$' + receiptPreview.totalClinicProductsPrice.toFixed(2) : ''}}
                    </span>
                  </td>
                </tr>
              </template>

            </Table>
          </v-card-text>
        </v-card>
      </v-col>
      <v-col cols="3">
        <div class="main-row" style="column-gap: 0.3rem;justify-content: space-between">
          <Button btn-bg="dark-green"
                  @click.native="onGenerateReceipts"
                  :styles="{ 'border-radius': '8px', 'border': '1px solid #008C89' }">
            <span style="color: white">Generate</span>
          </Button><!--v-if="['InProtocol', 'NeedProduct', 'ProtocolEnding'].includes(visit.status)"-->
          <Button btn-bg="dark-green"
                  v-if="['InProtocol', 'NeedProduct', 'ProtocolEnding', 'InProgress', 'Reviewed', 'SurveyDue', 'SurveySent', 'InActive', 'NeedSurvey', 'NeedReeval', 'NeedReview'].includes(visit.status)"
                  @click.native="onAddProduct"
                  :styles="{ 'border-radius': '8px', 'border': '1px solid #008C89' }">
            <span style="color: #FFFFFF">
              <v-icon>mdi-plus</v-icon>
              Product
            </span>
          </Button><!--v-if="['InProtocol', 'NeedProduct'].includes(visit.status)"-->
        </div>
        <br>
        <v-card elevation="2" class="options-adn-fees">
          <div class="main-row justify-space-between">
            <div class="logo">
              <Img :src="getImgWithExt(['icons', 'money2', 'svg'])" />
            </div>
            <div class="info-title">
              Options and fees
            </div>
          </div>
          <v-card-text>
            <!--            <div class="main-row justify-space-between" style="width: 85%">-->
            <!--              <span style="color: #27272E">Hide Pricing</span>-->
            <!--              <div class="checkbox-setting mr-3" style="width: 40px;text-align: left">-->
            <!--                <checkbox color="#008C89" :inputValue="hidePricingOnReceipt" @change="hidePricingOnReceipt=$event"></checkbox>-->
            <!--              </div>-->
            <!--            </div>-->
            <!--<div class="main-row mb-1 justify-space-between" style="width: 92%">
                          <span style="color: #27272E">Visit Fee</span>
                          <div class="input-setting mr-2 ml-1">
                            <Input outlined
                                   radius="8"
                                   @keypress="filterInputData($event)"
                                   min="0"
                                   height="25px"
                                   prepend-inner-icon="mdi-currency-usd"
                                   :value="visitFee"
                                   @input="visitFee=+$event"
                            />
                          </div>
                        </div>-->
            <!--<div class="main-row mb-1 justify-space-between" style="width: 92%">
              <span style="color: #27272E">Other Fee</span>
              <div class="input-setting mr-2 ml-1">
                <Input outlined
                       radius="8"
                       @keypress="filterInputData($event)"
                       min="0"
                       height="25px"
                       prepend-inner-icon="mdi-currency-usd"
                       :value="otherFee"
                       @input="otherFee=+$event"
                />
              </div>
            </div>-->
            <div class="main-row mb-1 justify-space-between" v-if="receiptPreview && receiptPreview.salesTaxValue" style="width: 100%">
              <span style="color: #27272E">Sales Tax</span>
              <div class="input-setting mr-2 ml-1" style="width: 70px;text-align: left">
                <span>${{receiptPreview.salesTaxValue.toFixed(2)}}</span>
              </div>
            </div>
            <div class="main-row mb-1 justify-space-between" style="width: 100%">
              <span style="color: #27272E">Shipping Fee</span>
              <div class="input-setting mr-2 ml-1" style="width: 70px;text-align: left">
                <span>${{shippingFee}}</span>
              </div>
            </div>
            <div class="main-row justify-space-between mb-2" style="width:100%" v-if="receiptPreview">
              <span style="color: #27272E">Total price</span>
              <div class="input-setting mr-2 ml-1" style="width: 70px;font-weight: 600;color: #16192C;">
                <span>{{receiptPreview.totalPrice !== 0 ? '$' + receiptPreview.totalPrice.toFixed(2) : ''}}</span>
              </div>
            </div>
            <div style="width: 100%; height: 1px;background-color: #0000001f"></div>
            <div class="main-row mb-1 justify-space-between mt-2" style="width: 100%">
              <span style="color: #27272E">Min. Ship. Fee</span>
              <div class="input-setting mr-2 ml-1" style="width: 70px;text-align: left">
                <span>${{minimumAmountFreeShip}}</span>
              </div>
            </div>
            <div class="main-row justify-space-between" style="width: 100%">
              <span style="color: #27272E">Price Modifier</span>
              <div class="input-setting mr-2 ml-1" style="width: 70px;text-align: left">
                <span>{{priceModifier}}%</span>
              </div>
            </div>
          </v-card-text>
        </v-card>
        <br>
        <v-card elevation="2" class="all-receipts">
          <div class="main-row">
            <div class="logo">
              <Img :src="getImgWithExt(['icons', 'bottle', 'svg'])" />
            </div>
            <div class="info-title">
              Invoices
            </div>
          </div>
          <v-card-text>
            <Table
              hideDefaultFooter>

              <template v-slot:body>
                <template v-for="item in listReceipts">
                  <tr
                    :key="item.id"
                    class="custom-receipt-table__dynamic"
                  >
                    <td>
                      <div class="d-flex align-center justify-center">
                        <div class="d-flex">
                          <span class="drop-ship"># {{item.receiptNumber}}</span>
                        </div>
                      </div>
                    </td>
                    <td class="pa-0" @click="openReceiptView(item)"><!--:class="{'disabled': ['Completed', 'Closed'].includes(visit.status)}"-->
                      <div class="d-flex align-center justify-center">
                        <div class="d-flex" style="max-width: 125px;">
                          <span class="drop-ship">{{item.orderMethodName}}</span>
                        </div>
                      </div>
                    </td>
                    <td>
                      <div class="d-flex align-center justify-center">
                        <div class="d-flex">
                          <span class="drop-ship">{{$moment(item.date).format('MM/DD')}}</span>
                        </div>
                      </div>
                    </td>
                    <td class="pa-0">
                      <div class="d-flex align-center justify-center">
                        <div class="d-flex">
                          <v-icon color="#CF4655"
                                  @click="handlerRemoveReceipt(item.id)"
                                  style="cursor: pointer">
                            mdi-trash-can-outline
                          </v-icon>
                        </div>
                      </div>
                    </td>
                  </tr>
                </template>
              </template>

            </Table>
          </v-card-text>
        </v-card>
        <div class="main-row mt-2" v-if="!['InProtocol', 'NeedProduct'].includes(visit.status)">
<!--          <span>Complete Evaluation to generate Receipt(s)</span>--><!--v-else-->
        </div>
      </v-col>
    </v-row>
    <receipt-view-form v-if="dialogs.receiptViewForm.isOpen" @showPopup="showPopup" />
    <product v-if="dialogs.dialogShowProduct.isOpen"></product>
    <confirm v-if="dialogs.confirm.isOpen" @showPopup="handlerConfirm" />
    <div class="custom-alert">
      <v-alert
        :color="alertColor"
        dismissible
        :type="alertType"
        v-model="alertIsOpen"
      >
        <span style="font-size: 14px;">{{popupMessage}}</span>
      </v-alert>
    </div>
    <div v-if="loading" class="loading d-flex justify-center px-2 py-2 align-center">
      <v-progress-circular
        indeterminate
        color="#008C89"
      ></v-progress-circular>
    </div>
  </div>
</template>

<script>
import { mapActions, mapMutations, mapState } from 'vuex'
import Table from '@/components/elements/Table'
import checkbox from '@/components/elements/checkbox'

import Number from '@/mixins/Number'
import Image from '@/mixins/Image'
import Img from '@/components/elements/Img'
import Button from '@/components/elements/Button'
import Input from '@/components/elements/Input'
import Product from '@/components/layouts/dialogs/product.vue'

export default {
  name: 'receipt',
  mixins: [Number, Image],
  props: {
    orderMethod: {
      type: Object
    },
    calcMethod: {
      type: Object
    },
    isUpdateDaysSupply: {
      type: Boolean,
      default: false
    },
    isUpdateVisit: {
      type: Boolean,
      default: false
    }
  },
  components: {
    Product,
    Table,
    checkbox,
    Img,
    Button,
    Input,
    ReceiptViewForm: () => import('@/components/layouts/dialogs/receipt-view-form.vue'),
    confirm: () => import('@/components/layouts/dialogs/confirm-dialog.vue')
  },
  data: () => ({
    alertIsOpen: false,
    popupMessage: null,
    alertType: 'info',
    alertColor: '#7ac1fb',
    checkedProductIds: [],
    regularProducts: [],
    clinicProducts: [],
    // hidePricingOnReceipt: false,
    visitFee: 0,
    otherFee: 0,
    minimumAmountFreeShip: 0,
    salesTax: 0,
    shippingFee: 0,
    priceModifier: 0,
    loading: true
  }),
  methods: {
    ...mapActions('receipts', ['getReceipts', 'createReceiptPreview', 'generateReceipts', 'deleteReceipt']),
    ...mapActions('results', ['updateSupplementProduct', 'removeSupplementProduct']),
    ...mapMutations('dialogs', ['TOGGLE_DIALOG']),
    showPopup ({ message, type, color }) {
      this.alertType = type
      this.alertColor = color
      this.alertIsOpen = true
      this.popupMessage = `${message}`
      this.TOGGLE_DIALOG({ name: 'receiptViewForm', isOpen: false })
      const self = this
      setTimeout(function () {
        self.alertIsOpen = false
      }, 3000)
    },
    handlerConfirm (res) {
      const { id } = this.$route.params
      if (res) {
        this.getReceipts(this.visit.resultId)
        const resultId = this.visit.resultId
        const orderMethodCode = this.orderMethod.code
        const calcMethodCode = this.calcMethod.code
        // const hidePricingOnReceipt = this.hidePricingOnReceipt
        const visitFee = this.visitFee
        const otherFee = this.otherFee
        const regularProducts = this.regularProductsItems
        const clinicProducts = this.clinicProductsItems
        this.createReceiptPreview({
          form: {
            visitId: id,
            resultId,
            orderMethodCode,
            calcMethodCode,
            // hidePricingOnReceipt,
            visitFee,
            otherFee,
            regularProducts: regularProducts,
            clinicProducts: clinicProducts
          }
        })
      }
    },
    async updatePreviewReceipt (key, object, localId, value) {
      const { id } = this.$route.params
      let regularProducts = []
      let clinicProducts = []
      // this.checkedProductIds.push(localId)
      if (key === 'regularProducts') {
        this.regularProductsItems.map(item => {
          regularProducts.push({...item, showOnReceipt: item.resultSupplementProductId === object.resultSupplementProductId ? value : item.showOnReceipt})
        })
        clinicProducts = this.clinicProductsItems
      }
      if (key === 'clinicProducts') {
        this.clinicProductsItems.map(item => {
          clinicProducts.push({...item, showOnReceipt: item.resultSupplementProductId === object.resultSupplementProductId ? value : item.showOnReceipt})
        })
        regularProducts = this.regularProductsItems
      }
      const resultId = this.visit.resultId
      const orderMethodCode = this.orderMethod.code
      const calcMethodCode = this.calcMethod.code
      // const hidePricingOnReceipt = this.hidePricingOnReceipt
      const visitFee = this.visitFee
      const otherFee = this.otherFee
      const res = await this.createReceiptPreview({
        form: {
          visitId: id,
          resultId,
          orderMethodCode,
          calcMethodCode,
          // hidePricingOnReceipt,
          visitFee,
          otherFee,
          regularProducts: regularProducts,
          clinicProducts: clinicProducts
        }
      })
      if (res) {
        this.visitFee = this.receiptPreview.visitFee
        this.otherFee = this.receiptPreview.otherFee
        this.minimumAmountFreeShip = this.receiptPreview.minimumAmountFreeShip
        this.salesTax = this.receiptPreview.salesTaxValue
        this.shippingFee = this.receiptPreview.shippingFeeValue
        this.priceModifier = this.receiptPreview.priceModifierPercent
        clinicProducts = []
        regularProducts = []
      }
      /* if (value) {
        this.checkedProductIds.push(localId)
        if (key === 'regularProducts') {
          delete object.$id
          this.regularProducts.push(object)
        }
        if (key === 'clinicProducts') {
          delete object.$id
          this.clinicProducts.push(object)
        }
        const resultId = this.visit.resultId
        const orderMethodCode = this.orderMethod.code
        const calcMethodCode = this.calcMethod.code
        // const hidePricingOnReceipt = this.hidePricingOnReceipt
        const visitFee = this.visitFee
        const otherFee = this.otherFee
        const res = await this.createReceiptPreview({
          form: {
            visitId: id,
            resultId,
            orderMethodCode,
            calcMethodCode,
            // hidePricingOnReceipt,
            visitFee,
            otherFee,
            regularProducts: this.regularProducts,
            clinicProducts: this.clinicProducts
          }
        })
        if (res) {
          this.visitFee = this.receiptPreview.visitFee
          this.otherFee = this.receiptPreview.otherFee
          this.minimumAmountFreeShip = this.receiptPreview.minimumAmountFreeShip
          this.salesTax = this.receiptPreview.salesTaxValue
          this.shippingFee = this.receiptPreview.shippingFeeValue
          this.priceModifier = this.receiptPreview.priceModifierPercent
        }
      } else {
        const index = this.checkedProductIds.indexOf(localId)
        this.checkedProductIds.splice(index, 1)
        if (key === 'regularProducts') {
          this.regularProducts.splice(index, 1)
        }
        if (key === 'clinicProducts') {
          this.clinicProducts.splice(index, 1)
        }
        const resultId = this.visit.resultId
        const orderMethodCode = this.orderMethod.code
        const calcMethodCode = this.calcMethod.code
        // const hidePricingOnReceipt = this.hidePricingOnReceipt
        const visitFee = this.visitFee
        const otherFee = this.otherFee
        const res = await this.createReceiptPreview({
          form: {
            visitId: id,
            resultId,
            orderMethodCode,
            calcMethodCode,
            // hidePricingOnReceipt,
            visitFee,
            otherFee,
            regularProducts: this.regularProducts,
            clinicProducts: this.clinicProducts
          }
        })
        if (res) {
          this.visitFee = this.receiptPreview.visitFee
          this.otherFee = this.receiptPreview.otherFee
          this.minimumAmountFreeShip = this.receiptPreview.minimumAmountFreeShip
          this.salesTax = this.receiptPreview.salesTaxValue
          this.shippingFee = this.receiptPreview.shippingFeeValue
          this.priceModifier = this.receiptPreview.priceModifierPercent
        }
      } */
    },
    async onGenerateReceipts () {
      const { id } = this.$route.params
      const resultId = this.visit.resultId
      const orderMethodCode = this.orderMethod.code
      const calcMethodCode = this.calcMethod.code
      // const hidePricingOnReceipt = this.hidePricingOnReceipt
      const visitFee = this.visitFee
      const otherFee = this.otherFee
      this.regularProducts = this.regularProductsItems
      this.clinicProducts = this.clinicProductsItems
      const resGen = await this.generateReceipts({
        form: {
          resultId,
          orderMethodCode,
          calcMethodCode,
          // hidePricingOnReceipt,
          visitFee,
          otherFee,
          regularProducts: this.regularProducts,
          clinicProducts: this.clinicProducts
        }
      })
      if (resGen) {
        await this.$store.dispatch('visits/updateTotalInvoices', { totalInvoices: resGen.totalInvoices })

        this.getReceipts(this.visit.resultId)
        const resultId = this.visit.resultId
        const orderMethodCode = this.orderMethod.code
        const calcMethodCode = this.calcMethod.code
        // const hidePricingOnReceipt = this.hidePricingOnReceipt
        const visitFee = this.visitFee
        const otherFee = this.otherFee
        const res = await this.createReceiptPreview({
          form: {
            visitId: id,
            resultId,
            orderMethodCode,
            calcMethodCode,
            // hidePricingOnReceipt,
            visitFee,
            otherFee,
            regularProducts: this.regularProducts,
            clinicProducts: this.clinicProducts
          }
        })
        if (res) {
          // this.hidePricingOnReceipt = this.receiptPreview.hidePricingOnReceipt
          this.visitFee = this.receiptPreview.visitFee
          this.otherFee = this.receiptPreview.otherFee
          this.minimumAmountFreeShip = this.receiptPreview.minimumAmountFreeShip
          this.salesTax = this.receiptPreview.salesTaxValue
          this.shippingFee = this.receiptPreview.shippingFeeValue
          this.priceModifier = this.receiptPreview.priceModifierPercent
        }
        this.openReceiptView(resGen)
      }
    },
    async deleteClinicProduct (supplementProductId) {
      const response = await this.removeSupplementProduct({ supplementProductId })
      if (response) {
        this.showPopup({message: 'Clinic product was deleted success!', type: 'info', color: '#7ac1fb'})
        const { id } = this.$route.params
        const resultId = this.visit.resultId
        const orderMethodCode = this.visit.receiptOrderTypeCode
        const calcMethodCode = this.visit.calcMethodTypeCode
        const visitFee = null
        const otherFee = null
        const res = await this.createReceiptPreview({
          form: {
            visitId: id,
            resultId,
            otherFee,
            orderMethodCode,
            calcMethodCode,
            // hidePricingOnReceipt,
            visitFee
          }
        })
        if (res) {
          // this.hidePricingOnReceipt = this.receiptPreview.hidePricingOnReceipt
          this.visitFee = this.receiptPreview.visitFee
          this.otherFee = this.receiptPreview.otherFee
          this.minimumAmountFreeShip = this.receiptPreview.minimumAmountFreeShip
          this.salesTax = this.receiptPreview.salesTaxValue
          this.shippingFee = this.receiptPreview.shippingFeeValue
          this.priceModifier = this.receiptPreview.priceModifierPercent
          this.$emit('updateOrderMethod', { code: this.receiptPreview.orderType.code, name: this.receiptPreview.orderType.name })
        }
      } else {
        this.$emit('showPopup', { message: 'Occurred some error', type: 'error', color: '#CF4655' })
      }
    },
    onAddProduct () {
      this.TOGGLE_DIALOG({ name: 'dialogShowProduct', isOpen: true, title: 'Select product' })
    },
    async updateQuantityBottles (val, product, resultSupplementProductId) {
      const res = product.quantity + val
      if (res >= 1) {
        const response = await this.updateSupplementProduct({ supplementProductId: resultSupplementProductId, numberOfBottles: res })
        if (response) {
          const { id } = this.$route.params
          const resultId = this.visit.resultId
          const orderMethodCode = this.visit.receiptOrderTypeCode // this.orderMethod.code
          const calcMethodCode = this.visit.calcMethodTypeCode
          // const hidePricingOnReceipt = this.hidePricingOnReceipt
          const visitFee = null // this.visitFee
          const otherFee = null // this.otherFee
          const res = await this.createReceiptPreview({
            form: {
              visitId: id,
              resultId,
              otherFee,
              orderMethodCode,
              calcMethodCode,
              // hidePricingOnReceipt,
              visitFee
            }
          })
          if (res) {
            // this.hidePricingOnReceipt = this.receiptPreview.hidePricingOnReceipt
            this.visitFee = this.receiptPreview.visitFee
            this.otherFee = this.receiptPreview.otherFee
            this.minimumAmountFreeShip = this.receiptPreview.minimumAmountFreeShip
            this.salesTax = this.receiptPreview.salesTaxValue
            this.shippingFee = this.receiptPreview.shippingFeeValue
            this.priceModifier = this.receiptPreview.priceModifierPercent
            this.$emit('updateOrderMethod', { code: this.receiptPreview.orderType.code, name: this.receiptPreview.orderType.name })
          }
        }
      }
    },
    handlerRemoveReceipt (id) {
      this.TOGGLE_DIALOG({ name: 'confirm', title: 'Please confirm Delete Invoice', isOpen: true, whose: 'confirmationDeleteReceipt', id, resultId: this.visit.resultId,  action: 'receipts/deleteReceipt', })
    },
    openReceiptView (item) {
      const data = item
      if ('pastReceipt' in item) {
        data.id = item.pastReceipt.id
        data.orderMethodName = item.pastReceipt.orderMethodName
        data.orderMethodCode = item.pastReceipt.orderMethodCode
      }
      this.TOGGLE_DIALOG({ name: 'receiptViewForm', title: `${data.orderMethodName} Invoice`, isOpen: true, data: data })
    }
  },
  async mounted () {
    const { id } = this.$route.params
    this.loading = true
    const resData = await this.getReceipts(this.visit.resultId)
    if (resData) {
      this.loading = false
    }
    const resultId = this.visit.resultId
    const orderMethodCode = this.visit.receiptOrderTypeCode // this.orderMethod.code
    const calcMethodCode = this.visit.calcMethodTypeCode
    // const hidePricingOnReceipt = this.hidePricingOnReceipt
    const visitFee = null // this.visitFee
    const otherFee = null // this.otherFee
    const res = await this.createReceiptPreview({
      form: {
        visitId: id,
        resultId,
        otherFee,
        orderMethodCode,
        calcMethodCode,
        // hidePricingOnReceipt,
        visitFee
      }
    })
    if (res) {
      // this.hidePricingOnReceipt = this.receiptPreview.hidePricingOnReceipt
      this.visitFee = this.receiptPreview.visitFee
      this.otherFee = this.receiptPreview.otherFee
      this.minimumAmountFreeShip = this.receiptPreview.minimumAmountFreeShip
      this.salesTax = this.receiptPreview.salesTaxValue
      this.shippingFee = this.receiptPreview.shippingFeeValue
      this.priceModifier = this.receiptPreview.priceModifierPercent
      this.$emit('updateOrderMethod', { code: this.receiptPreview.orderType.code, name: this.receiptPreview.orderType.name })
    }
  },
  computed: {
    ...mapState({
      visit: state => state.visits?.visit
    }),
    ...mapState('receipts', {
      headers (state) {
        const headers = Object.assign([], state?.receiptsPreview?.headers?.$values)
        return headers?.map(el => ({
          ...this._.omit(el, ['skip', '$id', 'width'])
        }))
      },
      regularProductsItems (state) {
        return state?.receiptsPreview?.record?.regularProducts?.$values
      },
      headersClinicProducts (state) {
        const headers = Object.assign([], state?.receiptsPreview?.headers?.$values)
        headers.splice(5, 1)
        return headers?.map(el => {
          if (el.value === 'showOnReceipt') { // showInInvoice
            return {...this._.omit(el, ['skip', '$id', 'width']), text: 'Show / Delete'}
          } else {
            return {...this._.omit(el, ['skip', '$id', 'width']),}
          }
        })
      },
      clinicProductsItems (state) {
        return state?.receiptsPreview?.record?.clinicProducts?.$values
      },
      receiptPreview (state) {
        return state?.receiptsPreview?.record
      },
      listReceipts (state) {
        return state?.listReceipts?.$values
      }
    }),
    actions () {
      return [
        { icon: 'mdi-pencil', color: 'primary', name: 'Edit', action: this.editResult },
        { icon: 'mdi-trash-can-outline', color: 'red', name: 'Delete', action: this.deleteResult }
      ]
    },
    dialogs () {
      return this.$store.state.dialogs
    },
  },
  watch: {
    // async 'orderMethod.code' (val) {
    //   if (val) {
    //     const { id } = this.$route.params
    //     const resultId = this.visit.resultId
    //     // const hidePricingOnReceipt = this.hidePricingOnReceipt
    //     const calcMethodCode = this.calcMethod.code
    //     const visitFee = this.visitFee
    //     const otherFee = this.otherFee
    //     this.regularProducts = this.regularProductsItems
    //     this.clinicProducts = this.clinicProductsItems
    //     const res = await this.createReceiptPreview(
    //       {
    //         form: {
    //           visitId: id,
    //           resultId,
    //           orderMethodCode: val,
    //           calcMethodCode,
    //           // hidePricingOnReceipt,
    //           visitFee,
    //           otherFee,
    //           regularProducts: this.regularProducts,
    //           clinicProducts: this.clinicProducts
    //         }
    //       })
    //     if (res) {
    //       // this.hidePricingOnReceipt = this.receiptPreview.hidePricingOnReceipt
    //       this.visitFee = this.receiptPreview.visitFee
    //       this.otherFee = this.receiptPreview.otherFee
    //       this.minimumAmountFreeShip = this.receiptPreview.minimumAmountFreeShip
    //       this.salesTax = this.receiptPreview.salesTaxValue
    //       this.shippingFee = this.receiptPreview.shippingFeeValue
    //       this.priceModifier = this.receiptPreview.priceModifierPercent
    //     }
    //   }
    // },
    // async 'calcMethod.code' (val) {
    //   if (val) {
    //     const { id } = this.$route.params
    //     const resultId = this.visit.resultId
    //     // const hidePricingOnReceipt = this.hidePricingOnReceipt
    //     const visitFee = this.visitFee
    //     const otherFee = this.otherFee
    //     const orderMethodCode = this.orderMethod.code
    //     const res = await this.createReceiptPreview(
    //       {
    //         form: {
    //           visitId: id,
    //           resultId,
    //           calcMethodCode: val,
    //           orderMethodCode,
    //           // hidePricingOnReceipt,
    //           visitFee,
    //           otherFee
    //         }
    //       })
    //     if (res) {
    //       // this.hidePricingOnReceipt = this.receiptPreview.hidePricingOnReceipt
    //       this.visitFee = this.receiptPreview.visitFee
    //       this.otherFee = this.receiptPreview.otherFee
    //       this.minimumAmountFreeShip = this.receiptPreview.minimumAmountFreeShip
    //       this.salesTax = this.receiptPreview.salesTaxValue
    //       this.shippingFee = this.receiptPreview.shippingFeeValue
    //       this.priceModifier = this.receiptPreview.priceModifierPercent
    //     }
    //   }
    // },
    async hidePricingOnReceipt (val) {
      if (val) {
        const { id } = this.$route.params
        const resultId = this.visit.resultId
        const orderMethodCode = this.orderMethod.code
        const calcMethodCode = this.calcMethod.code
        const visitFee = this.visitFee
        const otherFee = this.otherFee
        const res = await this.createReceiptPreview(
          {
            form: {
              visitId: id,
              resultId,
              // hidePricingOnReceipt: val,
              orderMethodCode,
              calcMethodCode,
              visitFee,
              otherFee
            }
          })
        if (res) {
          // this.hidePricingOnReceipt = this.receiptPreview.hidePricingOnReceipt
          this.visitFee = this.receiptPreview.visitFee
          this.otherFee = this.receiptPreview.otherFee
          this.minimumAmountFreeShip = this.receiptPreview.minimumAmountFreeShip
          this.salesTax = this.receiptPreview.salesTaxValue
          this.shippingFee = this.receiptPreview.shippingFeeValue
          this.priceModifier = this.receiptPreview.priceModifierPercent
        }
      }
    },
    async visitFee (val) {
      if (val) {
        const { id } = this.$route.params
        const resultId = this.visit.resultId
        const orderMethodCode = this.orderMethod.code
        const calcMethodCode = this.calcMethod.code
        // const hidePricingOnReceipt = this.hidePricingOnReceipt
        const otherFee = this.otherFee
        const res = await this.createReceiptPreview(
          {
            form: {
              visitId: id,
              resultId,
              visitFee: val,
              orderMethodCode,
              calcMethodCode,
              // hidePricingOnReceipt,
              otherFee
            }
          })
        if (res) {
          // this.hidePricingOnReceipt = this.receiptPreview.hidePricingOnReceipt
          // this.visitFee = this.receiptPreview.visitFee
          this.otherFee = this.receiptPreview.otherFee
          this.minimumAmountFreeShip = this.receiptPreview.minimumAmountFreeShip
          this.salesTax = this.receiptPreview.salesTaxValue
          this.shippingFee = this.receiptPreview.shippingFeeValue
          this.priceModifier = this.receiptPreview.priceModifierPercent
        }
      }
    },
    async otherFee (val) {
      if (val) {
        const { id } = this.$route.params
        const resultId = this.visit.resultId
        const orderMethodCode = this.orderMethod.code
        const calcMethodCode = this.calcMethod.code
        // const hidePricingOnReceipt = this.hidePricingOnReceipt
        const visitFee = this.visitFee
        const res = await this.createReceiptPreview(
          {
            form: {
              visitId: id,
              resultId,
              otherFee: val,
              orderMethodCode,
              calcMethodCode,
              // hidePricingOnReceipt,
              visitFee
            }
          })
        if (res) {
          // this.hidePricingOnReceipt = this.receiptPreview.hidePricingOnReceipt
          this.visitFee = this.receiptPreview.visitFee
          // this.otherFee = this.receiptPreview.otherFee
          this.minimumAmountFreeShip = this.receiptPreview.minimumAmountFreeShip
          this.salesTax = this.receiptPreview.salesTaxValue
          this.shippingFee = this.receiptPreview.shippingFeeValue
          this.priceModifier = this.receiptPreview.priceModifierPercent
        }
      }
    },
    async 'isUpdateDaysSupply' (val) {
      if (val) {
        const { id } = this.$route.params
        const resultId = this.visit.resultId
        const calcMethodCode = this.calcMethod.code
        const visitFee = this.visitFee
        const otherFee = this.otherFee
        const orderMethodCode = this.orderMethod.code
        const res = await this.createReceiptPreview(
          {
            form: {
              visitId: id,
              resultId,
              calcMethodCode,
              orderMethodCode,
              // hidePricingOnReceipt,
              visitFee,
              otherFee
            }
          })
        if (res) {
          this.visitFee = this.receiptPreview.visitFee
          this.otherFee = this.receiptPreview.otherFee
          this.minimumAmountFreeShip = this.receiptPreview.minimumAmountFreeShip
          this.salesTax = this.receiptPreview.salesTaxValue
          this.shippingFee = this.receiptPreview.shippingFeeValue
          this.priceModifier = this.receiptPreview.priceModifierPercent
        }
        this.$emit('updateIsUpdateDaysSupply', false)
      }
    },
    async 'isUpdateVisit' (val) {
      if (val) {
        const { id } = this.$route.params
        const resultId = this.visit.resultId
        const visitFee = this.visitFee
        const otherFee = this.otherFee
        const orderMethodCode = this.orderMethod.code
        const calcMethodCode = this.calcMethod.code
        const res = await this.createReceiptPreview(
          {
            form: {
              visitId: id,
              resultId,
              visitFee,
              otherFee,
              orderMethodCode,
              calcMethodCode,
            }
          })
        if (res) {
          this.visitFee = this.receiptPreview.visitFee
          this.otherFee = this.receiptPreview.otherFee
          this.minimumAmountFreeShip = this.receiptPreview.minimumAmountFreeShip
          this.salesTax = this.receiptPreview.salesTaxValue
          this.shippingFee = this.receiptPreview.shippingFeeValue
          this.priceModifier = this.receiptPreview.priceModifierPercent
          this.$emit('updateIsUpdateVisit', false)
        }
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.receipt {
  padding-bottom: 50px;
  min-height: 100px;

  .disabled {
    pointer-events: none;
  }

  .loading {
    height: 100%;
    min-height: 100px;
    position: absolute;
    width: 100%;
    top: 0;
    background: rgb(213 213 213 / 50%);
    border-radius: 16px;
  }
  .table {
    padding: 9px 9px 0px!important; // 16px!important;
    overflow-y: scroll;
    max-height: 52vh;
    ::v-deep .v-menu {
      .v-menu__content {
        z-index: 100!important;
      }
    }
  }
  .table.total-table {
    background: #ededed; // #FAFAFB
    border-bottom-left-radius: 16px;
    border-bottom-right-radius: 16px;
    padding-left: 16px!important;
    padding-right: 16px!important;
    padding-top: 0!important;
  }
  .table.table-total-cost {
    /*padding-top: 0px!important;
    bottom: 0;
    position: absolute;
    width: 100%;*/
    border-top: 2px solid #0000001f;
  }
  .v-icon {
    cursor: pointer;
  }
  .table {
    ::v-deep .v-data-table .v-data-table__wrapper .v-data-table-header th {
      padding: 0 4px!important;
      div {
        justify-content: center!important;
        float: unset!important;
      }
    }
    ::v-deep .v-data-table {
      background: transparent!important;
    }
  }
  .custom-alert {
    max-width: 300px;
    position: fixed;
    top: 10px;
    right: 20px;
    z-index: 100;
  }
  .is-default {
    background-color: #E3F7EA;
  }
  .drop-ship {
    color: green;
    cursor: pointer;
  }
  .options-adn-fees.v-card {
    padding: 7px!important;
    .table {
      padding: 0 0 0!important;
    }
  }
  .all-receipts.v-card {
    padding: 7px!important;
    .table {
      padding: 0 0 0!important;
    }
  }
  .main-row {
    display: flex;
    flex-wrap: wrap;
    column-gap: 1rem;
    align-items: center;
    .logo {
      width: 25px;
    }
    .info-title {
      font-weight: 600;
      font-size: 18px;
      color: #16192C;
    }
    .list-of-receipts {
      list-style-type: none;
    }
    .input-setting {
      ::v-deep .v-input {
        width: 55px!important;
        &__control {
          .v-text-field__details {
            display: none!important;
          }
          .v-input__slot {
            padding: 0 1px!important;
          }
          .v-input__prepend-inner {
            width: 16px!important;
            margin-top: -1px!important;
            padding-right: 0!important;
          }
        }
        &__icon {
          width: unset;
          .v-icon {
            font-size: 15px;
          }
        }
        input {
          text-align: center;
          font-size: 15px;
        }
      }
    }
    .checkbox-setting {
      ::v-deep .v-input {
        width: 42px!important;
        &__control {
          .v-text-field__details {
            display: none!important;
          }
          .v-input__slot {
            padding: 0 1px!important;
          }
        }
      }
    }
    .checkbox-setting .checkbox {
      width: 25px;
    }
  }
}
.total-row {
  text-align: center;
}
::v-deep .v-tooltip__content {
  padding: 3px 3px;
}
::v-deep table .v-data-table-header th + th { border-left:thin solid #0000001f !important; }
::v-deep table td + td:not(.total-row) { border-left:thin solid #0000001f !important; }
.custom-receipt-table {
  &__dynamic {
    border-bottom:thin solid #0000001f;
    td {
      padding: 0 4px; // 0 16px;
      border-bottom:thin solid #0000001f;
      height: 52px;
      .width100 {
        width: 100% !important;
      }
      .height100 {
        height: 100% !important;
      }
      div[aria-expanded=true] {
        background-color: #c3e5c399;
      }
      div[aria-expanded=false] {
        background-color: unset;
      }
    }
  }
  &__dynamic:last-child {
    td {
      border-bottom: unset;
    }
  }
  &__no-dynamic {
    border-bottom:thin solid #0000001f;
    td {
      //border-bottom:thin solid #0000001f;
      height: 52px;
      border-right: thin solid transparent;
    }
    td:last-child {
      border-right: unset;
    }
    //td:last-child {
    //  border-bottom: unset;
    //}
  }
}
@keyframes moveXandSpin {
  from {
    transform: translateX(0px) rotate(0);
  }
  to {
    transform: translateX(570px) rotate(720deg);
  }
}
</style>
