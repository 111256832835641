<template>
  <div class="profile">
    <Tabs :items="tabs" @update:tab="tab" :tab-index="tabIndex" left>
      <template v-slot:sliderColor>
        <v-tabs-slider color="#008C89"></v-tabs-slider>
      </template>
      <template v-slot:[`${tabs[componentInd].component}`]>
        <component :elevation="2" :is="tabs[componentInd].component"></component>
      </template>
    </Tabs>
    <div class="custom-alert">
      <v-alert
        color="#CF4655"
        dismissible
        type="info"
        v-model="alertIsOpen"
      >
        <span style="font-size: 14px;">Please, enter "Practitioner profile" and "Organization information" data</span>
      </v-alert>
    </div>
    <payment-dialog v-if="dialogs.paymentDialog.isOpen"/>
  </div>
</template>

<script>
import profilesettings from '@/views/settings/tabs/profileSettings'
import doctorsettings from '@/views/settings/tabs/doctorSettings'
import protocol from '@/views/settings/tabs/tabProtocol'
import settings from '@/views/settings/tabs/tabSettings'
import supplements from '@/views/settings/tabs/tabSupplements'
import products from '@/views/settings/tabs/tabProducts'
import subscriptions from '@/views/settings/tabs/tabSubscriptions'
import leads from '@/views/settings/tabs/tabLeads'
import chat from '@/views/settings/tabs/tabChat'
import Tabs from '@/components/layouts/Tabs'
import PaymentDialog from '@/components/layouts/dialogs/payment-dialog'
import { mapActions, mapMutations, mapState } from 'vuex'

export default {
  name: 'Settings',
  components: { Tabs, profilesettings, doctorsettings, protocol, settings, supplements, products, subscriptions, PaymentDialog, leads, chat },
  data: () => ({
    componentInd: 0,
    tabIndex: 0,
    alertIsOpen: false
  }),
  methods: {
    ...mapMutations('dialogs', ['TOGGLE_DIALOG']),
    ...mapActions(['refreshToken']),
    tab ($event) {
      this.componentInd = $event
    }
  },
  created() {
    let uri = window.location.href.split('?')
    if(uri.length === 2) {
      let vars = uri[1].split('&')
      let getVars = {}
      let tmp = ''
      vars.forEach(function(v) {
        tmp = v.split('=')
        if(tmp.length == 2 || tmp.length >= 2)
          getVars[tmp[0]] = tmp[1]
      })

      const { refresh, successPayment } = getVars
      if (refresh === 'true') {
        this.refreshToken()
      }
      if (refresh === 'false') {
        this.TOGGLE_DIALOG({
          name: 'paymentDialog',
          isOpen: true,
          title: 'Error',
          description: 'Wow. Occurred some error'
        })
      }
      if (successPayment === '1') {
        this.refreshToken()
        this.TOGGLE_DIALOG({
          name: 'paymentDialog',
          isOpen: true,
          title: 'Confirm',
          description: 'Subscription payment was successful!.'
        })
      }
      if (successPayment === '0') {
        this.TOGGLE_DIALOG({
          name: 'paymentDialog',
          isOpen: true,
          title: 'Error',
          description: 'Wow. Occurred some error'
        })
      }
    }
  },
  mounted () {
    document.title = 'Settings - Maestro Practitioner Portal'
    const { tab, modal } = this.$route.query
    const self = this
    if (tab === 'profile-and-organization') {
      setTimeout(() => {
        this.componentInd, this.tabIndex = self.tabs.findIndex(elem => {
          return elem.component === 'profilesettings'
        })
      }, 1000)
    }
    if (tab === 'settings') {
      setTimeout(() => {
        this.componentInd, this.tabIndex = self.tabs.findIndex(elem => {
          return elem.component === 'settings'
        })
      }, 1000)
    }
    if (tab === 'subscriptions') {
      setTimeout(() => {
        this.componentInd, this.tabIndex = self.tabs.findIndex(elem => {
          return elem.component === 'subscriptions'
        })
      }, 1000)
    }
    if (tab === 'practitioners') {
      setTimeout(() => {
        this.componentInd, this.tabIndex = self.tabs.findIndex(elem => {
          return elem.component === 'doctorsettings'
        })
      }, 1000)
      if (modal === 'inviteDoctors') {
        setTimeout(() => {
          this.TOGGLE_DIALOG({ name: 'inviteDoctors', isOpen: true, title: ' Invite Additional Practitioner' })
        }, 1100)
      }
    }
    if (tab === 'protocol') {
      setTimeout(() => {
        this.componentInd, this.tabIndex = self.tabs.findIndex(elem => {
          return elem.component === 'protocol'
        })
      }, 1000)
    }
    if (tab === 'supplements') {
      setTimeout(() => {
        this.componentInd, this.tabIndex = self.tabs.findIndex(elem => {
          return elem.component === 'supplements'
        })
      }, 1000)
    }
    if (tab === 'patient-generator') {
      setTimeout(() => {
        this.componentInd, this.tabIndex = self.tabs.findIndex(elem => {
          return elem.component === 'leads'
        })
      }, 1000)
    }
    if (tab === 'support-chat') {
      setTimeout(() => {
        this.componentInd, this.tabIndex = self.tabs.findIndex(elem => {
          return elem.component === 'chat'
        })
      }, 1000)
    }
  },
  computed: {
    ...mapState({
      organization: state => state.organization,
      subscriptionFeatures: state => state.subscription
    }),
    userProfile () {
      return this.$store.state.userProfile
    },
    dialogs () {
      return this.$store.state.dialogs
    },
    tabs () {
      if (this.subscriptionFeatures.expired && this.userProfile.userType !== 'Doctor') {
        return [{ title: 'My Profile and Organization', component: 'profilesettings' }]
      } else {
        const tabs = [
          { title: 'My Profile and Organization', component: 'profilesettings' },
          { title: 'Settings', component: 'settings' },
          { title: 'Protocols', component: 'protocol' },
          { title: 'Supplements', component: 'supplements' },
          { title: 'Non-Supplements', component: 'products' }
        ]
        if (this.userProfile.userType === 'ClinicAdmin') {
          tabs.push({ title: 'Subscriptions', component: 'subscriptions' })
          tabs.push({ title: 'Practitioners', component: 'doctorsettings' })
          tabs.push({ title: 'Patient Generator', component: 'leads' })
          tabs.push({ title: 'Support Chat', component: 'chat' })
        }
        if (!this.subscriptionFeatures.features.$values.includes('Leads')) {
          const index = tabs.findIndex(elem => {
            return elem.component === 'leads'
          })
          if (index !== -1) {
            tabs.splice(index, 1)
          }
        }
        return tabs
      }
    }
  },
  beforeRouteLeave: function (to, from, next) {
    const self = this
    if (this.organization) {
      next()
    } else {
      this.alertIsOpen = true
      setTimeout(function () {
        self.alertIsOpen = false
      }, 5000)
    }
  }
}
</script>

<style lang="scss" scoped>
  .profile {
    //..
    background: #F7FAFC;
    height: 100%;
    ::v-deep .tabs {
      .v-window .v-card {
        background: #F7FAFC;
      }
    }
    .custom-alert {
      max-width: 300px;
      position: fixed;
      top: 10px;
      right: 20px;
      z-index: 100;
    }
  }
</style>
