<!-- eslint-disable vue/no-multiple-template-root -->
<template>
	<div class="history" v-if="patient">
		<div class="visits-list">
			<v-timeline dense>
				<v-timeline-item
					dot-bg="dark-green"
					v-for="(visit, key) in sortedHistory"
					:key="key"
				>
					<card class="history__list">
						<template v-slot:body>
							<v-row
								class="d-flex"
								style="min-height: 80px; cursor: pointer; padding: 12px"
							>
								<v-col
									class="py-2 date-status p-2"
									cols="2"
									@click="navToSurvey(visit.id)"
								>
									<div class="visits-list__date">
										{{ $moment(visit.date).format('MM/DD/YYYY') }}
									</div>
									<div class="visits-list__doctor">
										<v-tooltip right :open-on-hover="true">
											<template v-slot:activator="{ on, attrs }">
												<div v-bind="attrs" v-on="on">
													<status
														v-if="getStatusCodeName[visit.status]"
														:class="getStatusCodeName[visit.status].class || ''"
														radius="6"
													>
														{{ getStatusCodeName[visit.status].name || '-' }}
													</status>
												</div>
											</template>
											<div
												style="display: block; max-width: 300px"
												v-if="getStatusCodeName[visit.status]"
											>
												{{
													getStatusCodeName[visit.status].description(
														visit.statusDays
													) || '-'
												}}
											</div>
										</v-tooltip>
									</div>
									<!--Evaluation to -->
								</v-col>
								<!-- <v-divider vertical /><v-col class="px-2 py-2" cols="1"><v-divider vertical /></v-col> -->
								<v-col class="info-notes py-2">
									<div class="d-flex">
										<div
											class="d-flex justify-space-between description w-100"
											@click="navToSurvey(visit.id)"
										>
											<div>{{ patient.doctorName }}</div>
											<div class="d-flex more-description">
												<div class="sub-label">
													Supply Days
													<span class="label">{{
														visit.supplyDays || '-'
													}}</span>
												</div>
												<div class="sub-label ml-2">
													Score
													<span class="label">{{
														visit.surveyScore || '-'
													}}</span>
												</div>
											</div>
										</div>

										<div class="d-flex reports">
											<Button
												color="#EBF2FA"
												:styles="{ padding: '2px 4px', height: '26px' }"
												@click.native="toggleModalReports(true, visit.id)"
											>
												<span style="color: #425466"
													>Reports
													<Img
														class="icon"
														:src="
															getImgWithExt(['icons', 'right-arrow', 'svg'])
														"
													/>
												</span>
											</Button>
										</div>
									</div>

									<div class="visits-list__note" @click="navToSurvey(visit.id)">
										<span class="sub-label">Notes </span>
										{{ visit.evaluationNotes || '-' }}
									</div>
								</v-col>
								<!-- <v-divider vertical /><v-col class="px-2 py-2" cols="1"><v-divider vertical /></v-col> -->
								<!-- <v-col class="actions py-2" cols="2">
                </v-col> -->
							</v-row>
						</template>
					</card>
				</v-timeline-item>
			</v-timeline>
		</div>
		<v-dialog
			v-model="showReport"
			width="60%"
			@click:outside="(initialDoc = ''), (loading = false)"
		>
			<div
				v-if="loading"
				style="
					position: absolute;
					left: 0;
					right: 0;
					top: 0;
					bottom: 0;
					margin: auto;
				"
				class="d-flex justify-center px-2 py-2 align-center"
			>
				<v-progress-circular
					indeterminate
					color="#008C89"
				></v-progress-circular>
			</div>
			<ReportView
				:disabledSpanish="true"
				:activeLanguage="'en'"
				:initialDoc="initialDoc"
				:showActionButton="false"
				:frameHeight="'800'"
			/>
		</v-dialog>

		<EvaluationReports v-if="dialogs.evaluationReportsModal.isOpen" />
	</div>
</template>

<script>
import { mapActions, mapMutations, mapState } from 'vuex';
import Pagination from '@/mixins/Pagination';
import card from '@/components/layouts/card';
import Mapping from '@/mixins/Mapping';
import EvaluationReports from '@/components/elements/EvaluationReports';
import status from '@/components/layouts/status.vue';
import Button from '@/components/elements/Button.vue';

import ReportView from '@/components/layouts/dialogs/reportView.vue';
import Image from '@/mixins/Image';

const SURVEY_TAB_INDEX = 0;
const RESULT_TAB_INDEX = 1;

export default {
	name: 'history',
	mixins: [Pagination, Mapping, Image],
	components: {
		card,
		status,
		Button,
		ReportView,
		EvaluationReports
	},
	data: () => ({
		showReport: false,
		fileUrl: null,
		initialDoc: null,
		loading: false,
		selectedVisit: null
	}),
	async created() {
		const { id } = this.$route?.params;
		if (id) {
			this.addUrlParam({ includeVisitsHistory: true });
			// await this.getPatient({ id, urlParams: this.query })
			// this.patient = Object.assign({}, this.$store.state.patients.patient)
			this.SET_PAGE_SETTINGS({
				title: `${this.patient.firstName} ${this.patient.lastName}`
			});
		}
	},
	destroyed() {
		this.SET_PAGE_SETTINGS(null);
	},
	methods: {
		...mapActions('patients', ['getPatient']),
		...mapActions('reports', ['gerReportByCode']),
		...mapMutations('dialogs', ['TOGGLE_DIALOG']),
		...mapMutations(['SET_PAGE_SETTINGS']),

		toggleModalReports(value, visitId) {
			localStorage.setItem('selectedVisitId', visitId);

			this.TOGGLE_DIALOG({
				name: 'evaluationReportsModal',
				isOpen: value,
				visitId
			});
		},

		async getReport(reportCode, id) {
			const payload = {
				appUserId: this.userProfile.userId,
				reportCode,
				entityId: id
			};
			const res = await this.gerReportByCode(payload);
			this.file = res;
			this.parseFile(this.file);
			this.loading = false;
		},
		parseFile(file) {
			if (file) {
				const reader = new FileReader();
				reader.onloadend = () => {
					this.fileUrl = URL.createObjectURL(file);
					this.initialDoc = this.fileUrl;
				};
				reader.readAsDataURL(file);
			} else {
				this.fileUrl = null;
			}
		},
		navToResult(id) {
			this.$router.push({
				path: `visit/${id}`,
				query: { tabIndex: RESULT_TAB_INDEX }
			});
		},
		navToSurvey(id) {
			this.$router.push({
				path: `visit/${id}`,
				query: { tabIndex: SURVEY_TAB_INDEX }
			});
		},
		runAction(val) {
			val.params.action(val.id, val);
		},
		patientSchedule(id) {
			this.showReport = true;
			this.loading = true;
			this.getReport('PatientSchedule', id);
		},
		patientSurveyScoreSheet(id) {
			this.showReport = true;
			this.loading = true;
			this.getReport('PatientSurveyScoreSheet', id);
		},
		clinicianReport(id) {
			this.showReport = true;
			this.loading = true;
			this.getReport('ClinicianFinalReport', id);
		},
		clinicianWarnings(id) {
			this.showReport = true;
			this.loading = true;
			this.getReport('ClinicianWarnings', id);
		}
	},
	computed: {
		...mapState({
			patient: (state) => state.patients.patient,
			userProfile: (state) => state.userProfile
		}),

		dialogs() {
			return this.$store.state.dialogs;
		},

		sortedHistory() {
			if (this.patient.visits && this.patient.visits.$values) {
				// eslint-disable-next-line vue/no-side-effects-in-computed-properties
				return this.patient.visits.$values.sort(function (a, b) {
					return new Date(b.date) - new Date(a.date);
				});
			} else {
				return [];
			}
		},
		actions() {
			return [
				{
					color: 'primary',
					name: 'Patient Schedule',
					action: this.patientSchedule
				},
				{
					color: 'red',
					name: 'Patient Survey Score Sheet',
					action: this.patientSurveyScoreSheet
				},
				{
					color: 'red',
					name: 'Clinician Report',
					action: this.clinicianReport
				},
				{
					color: 'red',
					name: 'Clinician Warnings',
					action: this.clinicianWarnings
				}
			];
		}
	}
};
</script>

<style scoped lang="scss">
.history {
	padding-top: 10px;
	overflow-y: auto;
	.visits-list {
		background: #e9eef3;
		border-radius: 16px;
		padding-right: 30px;
		::v-deep .v-timeline {
			&:before {
				left: calc(33px - 1px) !important;
			}
			.v-timeline-item {
				&[dot-bg='dark-green'] {
					.v-timeline-item__divider {
						.v-timeline-item__dot {
							background-color: $dark-green;
							.v-timeline-item__inner-dot {
								background-color: #e9eef3;
							}
						}
					}
				}
				&__dot {
					width: 28px;
					height: 28px;
				}
				&__inner-dot {
					width: 20px;
					height: 20px;
				}
				&__divider {
					min-width: 66px !important;
				}
				&__body {
					max-width: calc(100% - 66px) !important;
				}
			}
		}
		&__date {
			font-weight: 800;
			font-size: 14px;
			line-height: 24px;
			color: #425466;
		}
		&__doctor {
			font-size: 14px;
			line-height: 20px;
			color: #718096;

			width: 100%;
		}

		&__note {
			font-weight: 600;
			font-size: 12px;
			line-height: 20px;
			color: #425466;
		}
		&__navs {
			height: 100%;
			.v-icon {
				border-radius: 6px;
				padding: 4px;
			}
			.file {
				background: rgba(115, 217, 149, 0.2);
				border-radius: 10px;
			}
			.briefcase {
				background: rgba(208, 220, 255, 0.5);
				border-radius: 10px;
			}
		}
	}
	&__list {
		::v-deep .v-card {
			padding: 12px !important;
		}
		.date-status {
			display: flex;
			flex-direction: column;
			justify-content: flex-start;
			align-items: center;
		}
		.info-notes {
			display: flex;
			flex-direction: column;
			justify-content: flex-start;

			.description {
				width: 100%;
				border-bottom: 1px solid rgba(0, 0, 0, 0.12);
			}

			.reports {
				padding: 0 0 0 20px;
			}

			.label {
				font-weight: 600;
				font-size: 12px;
				line-height: 20px;
				color: #425466;
			}
			.sub-label {
				font-weight: 100;
				font-size: 12px;
				line-height: 20px;
				color: #425466;
			}
		}
		.actions {
			display: flex;
			justify-content: center;
			align-items: center;

			::v-deep .actions {
				.v-btn {
					max-width: 25px;
				}
			}
		}
	}
	::v-deep .status {
		.v-chip {
			cursor: pointer !important;
			background: unset !important;
			color: unset !important;
			justify-content: center !important;

			span {
				width: 100px;
				justify-content: center;
			}
		}
	}
	.need-product {
		background: rgba(255, 237, 227, 0.7) !important;
		color: #f7936f !important;
		border-radius: 6px !important;
		text-align: center !important;
	}
	.in-active {
		background: #edf2f7 !important;
		color: #a0aec0 !important;
		border-radius: 6px !important;
		text-align: center !important;
	}
	.in-progress {
		background: rgba(208, 220, 255, 0.5);
		color: #668bff;
		border-radius: 6px !important;
		text-align: center !important;
	}
	.need-survey {
		background: rgba(255, 237, 227, 0.7) !important;
		color: #f7936f !important;
		border-radius: 6px !important;
		text-align: center !important;
	}
	.need-reeval {
		background: rgba(255, 237, 227, 0.7) !important;
		color: #f7936f !important;
		border-radius: 6px !important;
		text-align: center !important;
	}
	.survey-sent {
		background: rgba(208, 220, 255, 0.5);
		color: #668bff;
		border-radius: 6px !important;
		text-align: center !important;
	}
	.survey-due {
		background: rgba(255, 230, 228, 0.7) !important;
		color: #f16063 !important;
		border-radius: 6px !important;
		text-align: center !important;
	}
	.need-review {
		background: rgba(255, 237, 227, 0.7) !important;
		color: #f7936f !important;
		border-radius: 6px !important;
		text-align: center !important;
	}
	.reviewed {
		background: rgba(208, 220, 255, 0.5) !important;
		color: #668bff !important;
		border-radius: 6px !important;
		text-align: center !important;
	}
	.in-protocol {
		background: rgba(0, 140, 137, 0.16) !important;
		color: #008c89 !important;
		border-radius: 6px !important;
		text-align: center !important;
	}
	.protocol-ending {
		background: #ffe9e2 !important;
		color: #e69376 !important;
		border-radius: 6px !important;
		text-align: center !important;
	}
	.completed {
		background: rgba(141, 186, 106, 0.2) !important;
		color: #8dba6a !important;
		border-radius: 6px !important;
		text-align: center !important;
	}
}
</style>
