<template>
  <div class="payment-dialog">
    <Dialog width="450">
      <template slot="title">
        <div class="image">
          <Img :src="getImgWithExt(['icons', 'confirm-logo-title', 'svg'])" width="50" />
        </div>
        <div class="title">
          {{confirmState.title}}
        </div>
      </template>

      <template slot="body">
        <div class="description" v-html="confirmState.description">
        </div>
      </template>

      <template slot="actions">
        <div class="d-flex justify-end w-100 pb-4 px-4">
          <Button
            btn-bg="dark-green"
            @click.native="confirm"
            :styles="{ 'border-radius': '8px' }"
          >
            <span style="color: #FFFFFF">Ok</span>
          </Button>
        </div>
      </template>
    </Dialog>
  </div>
</template>

<script>
import { mapState, mapMutations, mapActions } from 'vuex'
import Dialog from '@/components/layouts/dialog'
import Button from '@/components/elements/Button'
import Image from '@/mixins/Image'

export default {
  name: 'payment-dialog',
  props: {
    query: {
      type: Object
    }
  },
  components: { Dialog, Button },
  mixins: [Image],
  mounted () {
    this.getCurrentSubscriptionPlan()
    this.refreshToken()
  },
  methods: {
    ...mapMutations('dialogs', ['TOGGLE_DIALOG']),
    ...mapActions('subscriptions', ['getCurrentSubscriptionPlan']),
    ...mapActions(['refreshToken']),
    confirm () {
      const query = Object.assign({}, this.$route.query)
      delete query.refresh
      this.$router.replace({ query })
      this.close()
      console.log(this.$route.query)
    },
    close () {
      this.TOGGLE_DIALOG({ name: 'paymentDialog', isOpen: false })
    }
  },
  computed: {
    ...mapState({
      confirmState: state => state.dialogs.paymentDialog
    })
  }
}
</script>

<style lang="scss" scoped>
.w-100 {
  width: 100%;
}
.payment-dialog {
  //::v-deep {
  //  .v-card__title {
  //    position: relative!important;
  //    .v-icon {
  //      position: absolute!important;
  //      right: 15px;
  //      top: 0;
  //    }
  //  }
  //}
  //.title {
  //  width: 100%;
  //  text-align: center;
  //}
  //.description {
  //  padding: 0 16px;
  //}
  ::v-deep .v-dialog__container {
    .v-card {
      &__title {
        //background: #edf2f7;
        padding: 25px 35px 17px 26px !important;
        justify-content: center!important;
        position: relative;
        .image {
          width: 100%;
          text-align: center;
        }
        .title {
          font-family: 'Poppins';
          font-weight: 600;
          font-size: 16px;
          text-align: center;
          color: #27272E;
        }
        .v-icon {
          display: none;
          position: absolute;
          right: 15px;
          top: 25px;
        }
      }
      &__text {
        .description {
          font-family: 'Poppins';
          font-weight: 400;
          font-size: 14px;
          text-align: center;
          color: #425466;
        }
        .v-input {
          .v-text-field__details {
            display: none;
          }
        }
      }
    }
  }
}
</style>
