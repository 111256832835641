<template>
  <div class="add-result">
    <Dialog width="1000" scrollable>
      <template slot="title">
        <div>{{dialog.title}}</div>
      </template>
      <template slot="body">
        <v-form ref="form" v-model="valid" lazy-validation id="addResult" class="py-2 pl-3 pr-3">
          <v-row>
            <v-col cols="6">
              <v-row class="align-center">
                <v-col cols="8">
                  <div class="d-flex align-center">
                    <v-avatar size="40" v-if="visit.patientAvatar">
                      <v-img :src="'data:image/png;base64,'+visit.patientAvatar" />
                    </v-avatar>
                    <div v-if="dialog.name === 'editResult'">
                      <span class="ml-4 patient-name">Brand: {{dialog.data.brandName || '-'}}</span><!--patientName-->
                    </div>
                  </div>
                </v-col>
              </v-row>
              <v-row class="mt-0">
                <v-col class="pb-0 pt-0">
                  <div v-if="dialog.name !== 'editResult'"  class="add-result__label mb-1" style="margin-top: -29px">Select Supplement <span style="font-weight: normal;">(type first letters)</span></div>
                  <div v-if="dialog.name === 'editResult'" class="ml-2 mt-2 mb-2">
                    <span style="font-style: normal;font-weight: 600;font-size: 20px;">{{searchValue}}</span>
                  </div>
                  <search-select @update:search="searchValue=$event"
                                 @change:object="form.supplementId=$event.id"
                                 placeholder="Enter supplement name"
                                 itemText="name"
                                 itemValue="id"
                                 :value="form.supplementId"
                                 :items="supplements"
                                 returnObject
                                 :rules="notEmtpy"
                                 v-else
                                 :background-color="'#EDF2F7'"
                  />
                </v-col>
              </v-row>
              <v-row class="pl-2 pr-3 mb-2">
                <custom-radio-button
                  :title="'Days Per Week'"
                  :countBtns="7"
                  :waysToColorCircles="'range'"
                  :propertyKeyValue="form.daysPerWeek"
                  @setData="form.daysPerWeek = $event"
                ></custom-radio-button>
              </v-row>
              <v-row class="pl-2 pr-3 mb-2">
                <custom-radio-button
                  :iconName="'upon-arising'"
                  :title="'Upon Arising'"
                  :countBtns="5"
                  :type="'range'"
                  :waysToColorCircles="'range'"
                  :propertyKeyValue="form.dosageUponArising"
                  @setData="form.dosageUponArising = $event"
                ></custom-radio-button>
              </v-row>
              <v-row class="pl-2 pr-3 mb-2">
                <custom-radio-button
                  :iconName="'breakfast'"
                  :title="'Breakfast'"
                  :countBtns="5"
                  :type="'range'"
                  :waysToColorCircles="'range'"
                  :propertyKeyValue="form.dosageBreakfast"
                  @setData="form.dosageBreakfast = $event"
                ></custom-radio-button>
              </v-row>
              <v-row class="pl-2 pr-3 mb-2">
                <custom-radio-button
                  :iconName="'10am'"
                  :title="'10 am'"
                  :countBtns="5"
                  :type="'range'"
                  :waysToColorCircles="'range'"
                  :propertyKeyValue="form.dosage10am"
                  @setData="form.dosage10am = $event"
                ></custom-radio-button>
              </v-row>
              <v-row class="pl-2 pr-3 mb-2">
                <custom-radio-button
                  :iconName="'lunch'"
                  :title="'Lunch'"
                  :countBtns="5"
                  :type="'range'"
                  :waysToColorCircles="'range'"
                  :propertyKeyValue="form.dosageLunch"
                  @setData="form.dosageLunch = $event"
                ></custom-radio-button>
              </v-row>
              <v-row class="pl-2 pr-3 mb-2">
                <custom-radio-button
                  :iconName="'3pm'"
                  :title="'3 pm'"
                  :countBtns="5"
                  :type="'range'"
                  :waysToColorCircles="'range'"
                  :propertyKeyValue="form.dosage3pm"
                  @setData="form.dosage3pm = $event"
                ></custom-radio-button>
              </v-row>
              <v-row class="pl-2 pr-3 mb-2">
                <custom-radio-button
                  :iconName="'dinner'"
                  :title="'Dinner'"
                  :countBtns="5"
                  :type="'range'"
                  :waysToColorCircles="'range'"
                  :propertyKeyValue="form.dosageDinner"
                  @setData="form.dosageDinner = $event"
                ></custom-radio-button>
              </v-row>
              <v-row class="pl-2 pr-3 mb-2">
                <custom-radio-button
                  :iconName="'7pm'"
                  :title="'7 pm'"
                  :countBtns="5"
                  :type="'range'"
                  :waysToColorCircles="'range'"
                  :propertyKeyValue="form.dosage7pm"
                  @setData="form.dosage7pm = $event"
                ></custom-radio-button>
              </v-row>
              <v-row class="pl-2 pr-3 mb-2">
                <custom-radio-button
                  :iconName="'before-sleep'"
                  :title="'Before Sleep'"
                  :countBtns="5"
                  :type="'range'"
                  :waysToColorCircles="'range'"
                  :propertyKeyValue="form.dosageBeforeSleep"
                  @setData="form.dosageBeforeSleep = $event"
                ></custom-radio-button>
              </v-row>
              <v-row class="pl-2 pr-3 mb-2" v-if="form.specialNotes">
                <div class="pl-2 pr-3">
                  <div class="d-flex justify-space-between">
                    <div style="color: #008C89">* Special Notes</div>
                    <div style="font-weight: bold;margin-right: 10px">Total dosages per day
                      {{form.dosage3pm + form.dosage7pm + form.dosage10am + form.dosageBeforeSleep + form.dosageBreakfast + form.dosageDinner + form.dosageLunch + form.dosageUponArising}}
                    </div>
                  </div>
                  <div class="pl-2 pr-3" style="max-height: 110px;overflow-y: auto;">{{form.specialNotes}}</div>
                </div>
              </v-row>
            </v-col>
            <v-col cols="6">
              <div class="d-flex align-center" v-if="dialog.name === 'editResult'">
                <span class="supply-days__title">Supply days</span>
                <span class="supply-days__count ml-1">{{visit.supplyDays || '-'}}</span>
              </div>
              <v-row class="add-result__label justify-center mt-3">Supplement Notes</v-row>
              <v-row class="pl-2 pr-3 mb-2">
                <v-expansion-panels flat v-model="panel" multiple>
                  <v-expansion-panel>
                    <v-expansion-panel-header>
                      Patient Note (English)
                    </v-expansion-panel-header>
                    <v-expansion-panel-content color="#EDF2F7">
                      <Textarea dense auto-grow rows="3" rows-height="5" outlined @input:text-area="form.patientNotes=$event" :value="form.patientNotes" />
                    </v-expansion-panel-content>
                  </v-expansion-panel>
                  <v-expansion-panel>
                    <v-expansion-panel-header>
                      Patient Note (Spanish)
                    </v-expansion-panel-header>
                    <v-expansion-panel-content color="#EDF2F7">
                      <Textarea dense auto-grow rows="3" rows-height="5" outlined @input:text-area="form.patientNotesSpanish=$event" :value="form.patientNotesSpanish" />
                    </v-expansion-panel-content>
                  </v-expansion-panel>
                  <v-expansion-panel>
                    <v-expansion-panel-header>
                      My internal Notes
                    </v-expansion-panel-header>
                    <v-expansion-panel-content>
                      <Textarea rows="3" rows-height="5" dense auto-grow outlined @input:text-area="form.clinicianNotes=$event" :value="form.clinicianNotes" />
                    </v-expansion-panel-content>
                  </v-expansion-panel>
                  <!--<v-expansion-panel>
                    <v-expansion-panel-header>
                      Warnings
                    </v-expansion-panel-header>
                    <v-expansion-panel-content color="#ffede3b3" border-radius="8">
                      <Textarea dense auto-grow outlined color="'red'" :disabled="dialog.name === 'editResult'" @input:text-area="form.warnings=$event" :value="form.warnings" />
                    </v-expansion-panel-content>
                  </v-expansion-panel>-->
                </v-expansion-panels>
              </v-row>
              <v-row>
                <v-col class="py-0">
                  <div class="d-flex justify-space-between">
                    <Button :styles="{ 'border-radius': '8px', 'border': '1px solid #EDF2F7', 'background': 'white' }"
                            width="99"
                            height="53"
                            @click.native="close">
                      <span style="color: #425466;font-weight: 600;font-size: 14px;">Cancel</span>
                    </Button>
                    <Button btn-bg="dark-green"
                            dark
                            width="180"
                            height="53"
                            :styles="{ 'border-radius': '8px', 'font-weight': '600' }"
                            v-if="dialog.name == 'addResult'"
                            @click.native="add">
                      <span>Save</span>
                    </Button>
                    <Button btn-bg="dark-green"
                            dark
                            width="180"
                            height="53"
                            :styles="{ 'border-radius': '8px', 'font-weight': '600' }"
                            v-else
                            @click.native="update">
                      <span>Update</span>
                    </Button>
                    <!--                <Button color="primary mr-2" @click.native="update" v-else>Update</Button>-->
                  </div>
                </v-col>
              </v-row>
            </v-col>
          </v-row>
        </v-form>
      </template>
    </Dialog>
  </div>
</template>

<script>
import { mapState, mapActions, mapMutations } from 'vuex'

import Dialog from '@/components/layouts/dialog'
import Validation from '@/mixins/Validation'

import SearchSelect from '@/components/elements/search-select'
import Button from '@/components/elements/Button'
// import Input from '@/components/elements/Input'
// import SpinInput from '@/components/elements/spin-input'
import Textarea from '@/components/elements/Textarea'
import CustomRadioButton from '@/components/elements/custom-radio-button'

import debounce from 'lodash/debounce'

export default {
  name: 'add-result',
  mixins: [Validation],
  props: {
    orderMethod: {
      type: Object
    }
  },
  components: { Dialog, Button, SearchSelect, CustomRadioButton, Textarea }, // Input, SpinInput, Textarea
  data: () => ({
    panel: [0, 1],
    form: {
      // resultStartedOn: '2021-11-09T11:54:00',
      dosageUponArising: 0,
      dosageBreakfast: 0,
      dosage10am: 0,
      dosageLunch: 0,
      dosage3pm: 0,
      dosageDinner: 0,
      dosage7pm: 0,
      dosageBeforeSleep: 0,
      daysPerWeek: 7,
      supplementId: '',
      // warnings: '',
      specialNotes: '',
      patientNotes: '',
      patientNotesSpanish: '',
      clinicianNotes: ''
    },
    supplements: [],
    searchValue: ''
  }),
  async created () {
    if (this.dialog.name === 'editResult') {
      await this.getResult(this.dialog.id)
      this.form = Object.assign({}, this.result)
    }
  },
  methods: {
    ...mapActions('results', ['addResult', 'getResults', 'getNotes', 'getResult', 'updateResult']),
    ...mapActions('supplements', ['supplementsDropdown', 'getDefaultDosagesBySupplement']),
    ...mapMutations('dialogs', ['TOGGLE_DIALOG']),
    async add () {
      const { id } = this.$route.params
      if (this.validate()) {
        await this.addResult({ id, form: this.form })
        const orderMethod = this.orderMethod.code
        await this.getResults({ id, orderMethod })
        this.getNotes(this.$route.params.id)
      }
    },
    async update () {
      if (this.validate()) {
        await this.updateResult({ id: this.dialog.id, form: this.form })
        const orderMethod = this.orderMethod.code
        await this.getResults({ id: this.$route.params.id, orderMethod })
        this.getNotes(this.$route.params.id)
      }
    },
    close () {
      this.TOGGLE_DIALOG({ name: this.dialog.name, isOpen: false })
    }
  },
  computed: {
    ...mapState({
      visit: state => state.visits?.visit,
      dialog (state) {
        const modalName = this._.findKey(state.dialogs, ['isOpen', true])
        return state.dialogs[modalName]
      },
      patientName: state => {
        return state.results.results.record.patientName
      },
      result: state => state.results?.result
    })
  },
  watch: {
    searchValue: debounce(async function (val) {
      if (val) {
        const result = await this.supplementsDropdown(val)
        this.supplements = result?.$values
      } else {
        this.supplements = []
      }
    }, 1000),
    'form.supplementId': async function (val) {
      if (this.dialog.name !== 'editResult') {
        const result = await this.getDefaultDosagesBySupplement(val)
        Object.assign(this.form, {
          dosageUponArising: result.record.dosageUponArising,
          dosageBreakfast: result.record.dosageBreakfast,
          dosage10am: result.record.dosage10am,
          dosageLunch: result.record.dosageLunch,
          dosage3pm: result.record.dosage3pm,
          dosageDinner: result.record.dosageDinner,
          dosage7pm: result.record.dosage7pm,
          dosageBeforeSleep: result.record.dosageBeforeSleep,
          patientNotes: result.record.patientNotes,
          patientNotesSpanish: result.record.patientNotesSpanish,
          clinicianNotes: result.record.clinicianNotes,
        })
      } else {
        console.log('WATCH ELSE GET SUP searchValue')
        // const result = await this.getDefaultDosagesBySupplement(val)
        // this.searchValue = result.name
      }
    }
  }
}
</script>

<style lang="scss" scoped>
  .add-result {
    ::v-deep .v-dialog {
      overflow-y: hidden!important;
      max-height: unset!important;
      &__container {
        .v-card {
          &__title {
            background: #edf2f7;
            padding: 25px 30px 17px 46px !important;
            justify-content: center!important;
            position: relative;
            .v-icon {
              position: absolute;
              right: 15px;
              top: 25px;
            }
          }
          &__text {
            overflow-y: unset;
            max-height: 595px;
            padding: 16px!important;
          }
        }
      }
    }
    .patient-name {
      font-style: normal;
      font-weight: 600;
      font-size: 20px;
    }
    .supply-days {
      font-style: normal;
      &__title {
        font-weight: normal;
        font-size: 14px;
        line-height: 23px;
        color: #718096;
      }
      &__count {
        font-weight: 600;
        font-size: 14px;
        line-height: 23px;
        color: #425466;
      }
    }
    &__label {
      font-weight: bold;
      line-height: 21px;
      font-size: 16px;
      color: #425466;
    }
    ::v-deep .search-select {
      .v-input {
        .v-input__control {
          .v-input__slot {
            border-radius: 8px !important;
            input {
              padding-left: 8px;
            }
          }
          .v-input__slot:before {
            width: 0 !important;
          }
        }
      }
    }
    ::v-deep .v-expansion-panels {
      .v-expansion-panel:not(.v-item--active) {
        border-bottom: 1px solid #E2E8F0;
        .v-expansion-panel-header {
          padding: 16px 10px !important;
        }
        v-expansion-panel-header--active {
          padding: 16px 10px !important;
        }
        .v-expansion-panel-content {
          border-radius: 8px;
        }
      }
      .v-expansion-panel {
        border-bottom: 1px solid #E2E8F0;
        .v-expansion-panel-header {
          padding: 16px 10px !important;
        }
        v-expansion-panel-header--active {
          padding: 16px 10px !important;
        }
        .v-expansion-panel-content {
          border-radius: 8px;
        }
      }
    }
    &__spin-input {
      ::v-deep .v-text-field__details {
        display: none !important;
      }
    }
  }
</style>
