<template>
  <div class="trial-subscription">
    <div class="main-row justify-start">
      <span v-if="!currentSubscription.trial && currentSubscription.expired" class="title" style="color: red;">Your subscription has expired effective {{$moment(currentSubscription.endDate).format('MM/DD/YYYY')}}. Resubscribe by choosing the desired subscription and clicking Subscribe Now.</span>
      <span v-if="currentSubscription.trial && !currentSubscription.expired" class="title">Your trial of {{subscription.productName}} is active. You may subscribe at any time by choosing a desired subscription and clicking Subscribe Now.</span>
      <span v-if="currentSubscription.trial && currentSubscription.expired" class="title" style="color: red;">Your trail of {{currentSubscription.productName}} has ended. You may now subscribe by choosing a desired subscription and clicking Subscribe Now.</span>
      <span v-if="!currentSubscription.trial && !currentSubscription.expired" class="title">You are currently subscribed to {{currentSubscription.productName}}, billed {{currentSubscription.isMonthly ? 'monthly' : 'annually'}}.</span>
    </div>
    <div class="main-row description">
      <p>Click the link below to access our subscription and billing portal. You will need your account email to receive a one-time access code. Please call or chat with us if you have questions prior to making any changes. Within the portal, you can:</p>
    </div>
    <div class="you-can">
      <div>Make Changes to Your Subscription</div>
      <div>Update Your Payment Method</div>
      <div>Update Your Billing Information</div>
      <div>View Your Invoice History</div>
    </div>
    <div class="main-row description">
      <p>Note: Before making any changes to your subscription level, please refer to the current <a
        @click="openTermConditions"
        style="color: #72767F">Terms and Use</a> as it applies to you. If you are subscribed to a Legacy product and make a change to a different subscription level, you will not be able to return to your legacy status. Please visit our website to see all services currently being offered.</p>
    </div>
    <div class="main-row justify-center pb-3">
      <Button
        btn-bg="dark-green"
        block
        dark
        @click.native="accessBillingAndSubscriptions"
        width="500"
        tabindex="3" height="50">
        Access Billing and Subscriptions
      </Button>
    </div>
<!--    <template v-if="userProfile.userType === 'Doctor'">
      <div class="main-row justify-center">
        <div class="progress-title">
          <template v-if="!subscription.expired">
            You have <span class="days-left">{{subscription.daysLeft}} days</span> left to use this product with full feature availability.
          </template>
          <template v-else>
            <span style="color: red;font-weight: 600">The {{subscription.productName}} subscription is expired!</span>
          </template>
        </div>
      </div>
      <div class="main-row align-center">
        <div class="progress-block">
          <v-progress-linear
            :value="((subscription.totalDays || 30) - subscription.daysLeft) / (30/100)"
            :color="!subscription.expired ? '#008C89' : '#F16063'"
            height="6"
            striped
            :style="{'border-radius': '10px'}"
          >
          </v-progress-linear>
        </div>
        <div class="percent-value" v-if="subscription.productName === 'Trial'">{{(subscription.totalDays || 30) - subscription.daysLeft}}/{{subscription.totalDays || 30}}</div>
      </div>
    </template>
    <template v-else>
      <div class="main-row justify-center">
        <div class="progress-title">
          <template v-if="subscription.expired && subscription.trial">
            <span style="color: red;font-weight: 600">Valued Client, your 30-day trial subscription has ended. Now is the time to make the bold decision to bring a higher level of Nutrition to your practice and elevate the care of your patients. To continue using Maestro Nutrition, please choose the subscription service level that is right for you. You can always make changes at any time.</span>
          </template>
          <template v-if="subscription.expired && !subscription.trial">
                <span style="color: red;font-weight: 600">
                  Valued Maestro Nutrition Client, your subscription has expired effective {{$moment(subscription.endDate).format('MM/DD/YYYY')}}. Please reactivate your subscription by choosing a desired service level and updating your payment information. We appreciate your loyalty and want to continue our partnership in providing Sustainable Health to your patients.
                </span>
          </template>
        </div>
      </div>
    </template>
    <div class="main-row subscriptions-variant"
         :class="{ 'trial': currentSubscription.trial }"
         v-if="availableProducts.length">
      <template v-for="plan in availableProducts">
        <subscription
          :plan="plan"
          :key="plan.$id"
          :activePaying="activePaying"
          :class="{
            'current-subscription': plan.name === currentSubscription.productName && !subscription.expired,
            'current-subscription-expired': plan.name === currentSubscription.productName && subscription.expired,
            'need-to-continue-plan': currentSubscription.needToContinuePlan
          }"
          @switchPaying="switchPaying"/>
      </template>
    </div>
    <div v-else class="loading d-flex justify-center px-2 py-2">
      <v-progress-circular
        indeterminate
        color="#008C89"
      ></v-progress-circular>
    </div>-->
    <div class="main-row justify-center mt-3 payment-history" v-if="!currentSubscription.trial">
      <h2 style="text-align: start;width: 100%;color: #27272E">Payment History</h2>
      <v-card elevation="2" class="mt-4">
        <Table
          :headers="paymentHeaders"
          hideDefaultFooter>

          <template v-slot:body>
            <template v-for="item in paymentHistory">
              <tr
                :key="item.id"
                class="custom-result-table__dynamic"
              >
                <td class="px-3 py-1"><!--:style="{'width': (item.supplementName.length * 8) + 'px'}"-->
                  <div class="d-flex align-center justify-start">
                    <div class="d-flex">
                      {{$moment(item.invoiceCreated).format('MM/DD/YYYY')}}
                    </div>
                  </div>
                </td>
                <td class="px-3 py-1">
                  <div class="d-flex align-center justify-start">
                    <div class="d-flex">
                      {{item.paymentMethod ? capitalizeFirstLetter(item.paymentMethod) : '-'}}
                    </div>
                  </div>
                </td>
                <td class="px-3 py-1">
                  <div class="d-flex align-center justify-start">
                    <div class="d-flex">
<!--                      <status v-if="getStatusCodeName[item.status]"
                              :class="getStatusCodeName[item.status].class"
                              radius="6">
                        {{getStatusCodeName[item.status].name}}
                      </status>-->
                      {{item.status ? capitalizeFirstLetter(item.status) : '-'}}
                    </div>
                  </div>
                </td>
                <td class="px-3 py-1">
                  <div class="d-flex align-center justify-start">
                    <div class="d-flex">
                      {{item.subscription.productName || '-'}}
                    </div>
                  </div>
                </td>
                <td class="px-3 py-1">
                  <div class="d-flex align-center justify-start">
                    <div class="d-flex">
                      <a style="text-decoration: none" :href="item.invoiceUrl" target="_blank">
                        <v-tooltip top>
                          <template v-slot:activator="{ on, attrs }">
                            <v-icon v-bind="attrs" v-on="on" color="info">mdi-information</v-icon>
                          </template>
                          <span>Information link. Click to icon for view</span>
                        </v-tooltip>
                      </a>
                      <v-tooltip top>
                        <template v-slot:activator="{ on, attrs }">
                          <v-icon v-bind="attrs" v-on="on" @click.stop="downloadFile(item.invoicePdf)">mdi-file-pdf-box</v-icon>
                        </template>
                        <span>Click for download pdf file information about invoice</span>
                      </v-tooltip>
                    </div>
                  </div>
                </td>
                <td class="px-3 py-1">
                  <div class="d-flex align-center justify-start">
                    <div class="d-flex">
                      ${{item.amountPaid}}
                    </div>
                  </div>
                </td>
              </tr>
            </template>
          </template>

        </Table>
      </v-card>
    </div>
    <terms-conditions v-if="dialogTermsConditions.isOpen"/>
  </div>
</template>

<script>
// import Subscription from '@/components/elements/subscription'
import Image from '@/mixins/Image'
import Table from '@/components/elements/Table'
// import status from '@/components/layouts/status'
import { mapActions, mapMutations, mapState } from 'vuex'
import Mapping from '@/mixins/Mapping'
import Button from '@/components/elements/Button.vue'
import TermsConditions from '@/components/layouts/dialogs/terms-conditions.vue'

export default {
  name: 'tabSubscriptions',
  components: { Table, Button, TermsConditions }, // status, Subscription
  mixins: [Image, Mapping],
  data: () => ({
    activePaying: 'month',
    mockPaymentHistory: [
      {
        date: new Date(),
        method: 'PayPal',
        status: 'completed',
        amount: 0.12,
        surcharge: 0,
        discount: 1
      },
      {
        date: new Date(),
        method: 'PayPal',
        status: 'canceled',
        amount: 2.12,
        surcharge: 1,
        discount: 0.3
      },
      {
        date: new Date(),
        method: 'PayPal',
        status: 'canceled',
        amount: 0.12,
        surcharge: 0,
        discount: 1
      },
      {
        date: new Date(),
        method: 'PayPal',
        status: 'completed',
        amount: 5.12,
        surcharge: 0.6,
        discount: 9
      }
    ]
  }),
  mounted () {
    this.getAvailableProducts()
    this.getCurrentSubscriptionPlan()
    this.getPaymentHistory()
  },
  methods: {
    ...mapActions('subscriptions', ['createCustomerPortalSession', 'getAvailableProducts', 'getCurrentSubscriptionPlan', 'getPaymentHistory']),
    ...mapMutations('dialogs', ['TOGGLE_DIALOG']),
    ...mapMutations('dialogs', ['TOGGLE_DIALOG']),
    async accessBillingAndSubscriptions () {
      const res = await this.createCustomerPortalSession()
      window.open(res.url, '_self').focus()
    },
    capitalizeFirstLetter (string) {
      return string.charAt(0).toUpperCase() + string.slice(1)
    },
    openTermConditions () {
      this.TOGGLE_DIALOG({ name: 'termAndConditions', isOpen: true })
    },
    async downloadFile (urlFile) {
      const link = document.createElement('a')
      document.getElementsByTagName('body')[0].appendChild(link)
      link.href = urlFile
      link.download = 'Invoice.pdf'
      link.click()
      document.getElementsByTagName('body')[0].removeChild(link)
    },
    switchPaying (val) {
      this.activePaying = val
    },
    goToSettings () {
      if (this.$route.name === 'Settings') {
        this.TOGGLE_DIALOG({ name: 'trialSubscriptionDialog', isOpen: false, root: true })
      } else {
        this.TOGGLE_DIALOG({ name: 'trialSubscriptionDialog', isOpen: false, root: true })
        this.$router.push({ name: 'Settings' })
      }
    },
    onCLoseHandler (val) {
      if (this.subscription.expired) {
        if (this.$route.name !== 'Settings') {
          this.$router.push({ name: 'Settings' })
        }
      }
    }
  },
  computed: {
    ...mapState({
      availableProducts: state => state.subscriptions.availableProducts,
      currentSubscription: state => state.subscriptions.currentSubscription,
      paymentHistory: state => state.subscriptions.paymentHistory,
      dialogTermsConditions: state => state.dialogs.termAndConditions
    }),
    subscription () {
      return this.$store.state.subscription
    },
    paymentHeaders () {
      return [
        {
          text: 'Payment Date',
          value: 'paymentDate',
          isSlotable: false,
          width: '16.6%',
          isReplacingSpace: 0
        },
        {
          text: 'Payment Method',
          value: 'paymentMethod',
          isSlotable: false,
          width: '16.6%',
          isReplacingSpace: 0
        },
        {
          text: 'Status',
          value: 'status',
          width: '16.6%',
          isReplacingSpace: 0
        },
        {
          text: 'Product name',
          value: 'productName',
          width: '16.6%',
          isReplacingSpace: 0
        },
        {
          text: 'Invoice',
          value: 'invoiceUrl',
          width: '16.6%',
          isReplacingSpace: 0
        },
        {
          text: 'Amount',
          value: 'amountm',
          width: '16.6%',
          isReplacingSpace: 0
        }
      ]
    },
    actionsPayment () {
      return [
        { icon: 'mdi-pencil', color: 'primary', name: 'Edit', action: 'someActionMethod' },
        { icon: 'mdi-trash-can-outline', color: 'red', name: 'Delete', action: 'someActionMethod' }
      ]
    },
    userProfile () {
      return this.$store.state.userProfile
    }
  }
}
</script>

<style lang="scss" scoped>
.trial-subscription {
  padding-bottom: 45px;
  /*::v-deep .v-dialog {
    overflow: hidden;
    .v-card {
      overflow-y: scroll;
      height: 80vh;
      padding: 10px;
      &__title {
        justify-content: end!important;
        .v-icon {
          bottom: 0!important;
        }
      }
    }
  }*/
  .label {
    font-size: 14px;
    font-weight: 500;
    color: #425466
  }
  .main-row {
    display: flex;
    flex-wrap: wrap;
    width: 100%;
  }
  .description {
    p {
      font-weight: bold;
      color: black;
    }
  }
  .you-can {
    padding: 0 10px 10px;
    font-weight: bold;
    color: black;
  }
  .title {
    font-weight: 500;
    font-size: 28px;
    text-align: center;
    color: #27272E;
  }
  .current-subscription-name {
    color: $dark-green;
  }
  .progress-title {
    font-weight: 500;
    font-size: 14px;
    text-align: center;
    color: #27272E;
    .days-left {
      color: #008C89;
      font-weight: 600;
      font-size: 16px;
    }
  }
  .progress-block {
    width: 93%;
  }
  .percent-value {
    text-align: end;
    width: 7%;
    font-weight: 600;
    font-size: 14px;
    color: #27272E;
  }
  .footer {
    flex-wrap: wrap;
    .information {
      width: 100%;
      text-align: center;
      font-weight: 500;
      font-size: 14px;
      color: #7A828A;
      .link {
        cursor: pointer;
        color: #668BFF;
      }
    }
  }
  .subscriptions-variant.trial {
    flex-wrap: nowrap!important;
    overflow-x: auto!important;
  }
  .subscriptions-variant {
    .subscription {
      width: 400px!important;
      background-color: #f1f1f161!important;
    }
    .subscription:hover {
      background-color: #FFFFFF!important;
      box-shadow: rgba(0, 0, 0, 0.25) 0px 54px 55px, rgba(0, 0, 0, 0.12) 0px -12px 30px, rgba(0, 0, 0, 0.12) 0px 4px 6px, rgba(0, 0, 0, 0.17) 0px 12px 13px, rgba(0, 0, 0, 0.09) 0px -3px 5px;
    }
    .subscription.current-subscription {
      border: .22rem solid $dark-green!important;
      border-radius: 8px!important;
    }
    .subscription.current-subscription-expired {
      border: .22rem solid #F16063!important;
      border-radius: 8px!important;
    }
    .subscription.need-to-continue-plan {
      border: .22rem solid #F16063!important;
      border-radius: 8px!important;
    }
  }
  .loading {}
  .payment-history {
    ::v-deep .v-card {
      width: 100%!important;
      border-radius: 14px!important;
      .table .v-data-table {
        th {
          text-align: center!important;
        }
        td {
          color: #425466;
        }
      }
    }
    ::v-deep .status {
      .v-chip {
        cursor: pointer!important;
        background: unset!important;
        color: unset!important;
        width: -webkit-fill-available!important;
        justify-content: center!important;
      }
    }
    .completed {
      background: rgba(0, 140, 137, 0.16)!important;
      color: #008C89!important;
      border-radius: 6px!important;
      text-align: center!important;
      padding: 5px 10px!important;
      width: 113px!important;
    }
    .canceled {
      background: rgba(255, 230, 228, 0.7)!important;
      color: #F16063;
      border-radius: 6px!important;
      text-align: center!important;
      padding: 5px 10px!important;
      width: 113px!important;
    }
  }
}
</style>
