<template>
  <div class="profile-organization">
    <v-container fluid v-if="organizationRecord && form.doctor">
      <v-card flat>
        <v-row>
          <v-col cols="6">
            <div class="main-row">
              <div class="avatar">
                <v-badge
                  bordered
                  bottom
                  offset-x="27"
                  offset-y="27"
                  class="avatar__avatar-badge"
                  @click.native.stop="openUploadFile('inputAvatarDoctorProfile')"
                >
                  <input id="hiddenUploadInput" ref="inputAvatarDoctorProfile" type="file" name="image" @change="uploadFile('inputAvatarDoctorProfile', $event)">
                  <template v-slot:badge>
                    <v-icon style="font-size: 17px;top: 1px">mdi-pencil</v-icon>
                  </template>
                  <v-avatar class="avatar__avatar-modify" >
                    <v-img :src="form.doctor.logoFull || form.doctor.logoSmall" alt="avatar"></v-img>
                  </v-avatar>
                </v-badge>
              </div>

              <div class="title_block">
                <div class="info-title">
                  Practitioner Profile
                </div>
              </div>
            </div>
            <br>
            <v-form ref="form" v-model="valid" :readonly="form.doctor.readOnly" lazy-validation id="profileForm" class="pt-4" autocomplete="off">
              <v-row>
                <v-col class="py-0">
                  <div class="label">Prefix</div>
                  <Input outlined
                         autocomplete="off"
                         :isAutofocus="isAutofocus"
                         radius="8"
                         placeholder="Prefix"
                         :value="form.doctor.prefix"
                         @input="form.doctor.prefix=$event"
                  />
                </v-col>
              </v-row>
              <v-row>
                <v-col class="py-0">
                  <div class="label">First name</div>
                  <Input outlined
                         autocomplete="off"
                         radius="8"
                         placeholder="John"
                         :value="form.doctor.firstName"
                         @input="form.doctor.firstName=$event"
                         :rules="notEmtpy"
                  />
                </v-col>
              </v-row>
              <v-row>
                <v-col class="py-0">
                  <div class="label">Last name</div>
                  <Input outlined
                         autocomplete="off"
                         radius="8"
                         placeholder="Walker"
                         :value="form.doctor.lastName"
                         @input="form.doctor.lastName=$event"
                         :rules="notEmtpy"
                  />
                </v-col>
              </v-row>
              <v-row>
                <v-col class="py-0">
                  <div class="label">Title</div>
                  <Input outlined
                         autocomplete="off"
                         radius="8"
                         placeholder="Title"
                         :value="form.doctor.title"
                         @input="form.doctor.title=$event"
                  />
                </v-col>
              </v-row>
              <v-row>
                <v-col class="py-0">
                  <div class="label">Phone</div>
                  <PhoneInput radius="8" @input="form.doctor.phoneNumber=$event" :value="form.doctor.phoneNumber" />
                </v-col>
              </v-row>
              <v-row>
                <v-col class="py-0">
                  <div class="label">Email</div>
                  <Input outlined
                         autocomplete="off"
                         class="flex-grow-1 mr-4"
                         radius="8"
                         readonly
                         placeholder="john.smith@gmail.com"
                         :value="form.doctor.email"
                         :rules="emailRules"
                         @input="form.doctor.email=$event"
                  />
                </v-col>
              </v-row>
            </v-form>
          </v-col>
          <v-col cols="6">
            <div class="main-row">
              <div class="avatar">
                <v-badge
                  bordered
                  bottom
                  offset-x="27"
                  offset-y="27"
                  class="avatar__avatar-badge"
                  @click.native.stop="openUploadFile('inputAvatarOrganization')"
                >
                  <input id="hiddenUploadInput" ref="inputAvatarOrganization" type="file" name="image" @change="uploadFile('inputAvatarOrganization', $event)">
                  <template v-slot:badge>
                    <v-icon style="font-size: 17px;top: 1px">mdi-pencil</v-icon>
                  </template>
                  <v-avatar class="avatar__avatar-modify" >
                    <v-img :src="form.organization.logoFull || form.organization.logoSmall || ''"></v-img>
                  </v-avatar>
                </v-badge>
              </div>
              <div class="title_block">
                <div class="info-title">
                  Organization Information
                </div>

              </div>

            </div>

            <br>
            <v-form ref="form" v-model="valid" :readonly="form.organization.readOnly" lazy-validation id="organizationForm" class="pt-4" autocomplete="off">
              <v-row>
                <v-col class="py-0">
                  <div class="label">Practice name</div>
                  <Input outlined
                         autocomplete="off"
                         radius="8"
                         placeholder="Practice name"
                         :value="form.organization.practiceName"
                         @input="form.organization.practiceName=$event"
                         :rules="notEmtpy.concat([lengthPracticeName('Practice name')])">
<!--                    <v-icon
                      slot="append"
                      style="font-size: 17px;cursor: pointer"
                      @click="form.organization.practiceName = ''"
                      color="#8492A6"
                    >
                      mdi-close
                    </v-icon>-->
                  </Input>
                </v-col>
              </v-row>
              <v-row>
                <v-col class="py-0">
                  <div class="label">Practice Slogan</div>
                  <Input outlined
                         autocomplete="off"
                         radius="8"
                         :value="form.organization.practiceTitle"
                         @input="form.organization.practiceTitle=$event"
                  /><!--:rules="[notEmtpy]" :rules="[lengthPracticeName('Practice Slogan')]"-->
                </v-col>
              </v-row>
              <v-row>
                <v-col class="py-0">
                  <div class="label">Address</div>
                  <Input outlined
                         autocomplete="off"
                         radius="8"
                         :value="form.organization.addressLine1"
                         @input="form.organization.addressLine1=$event"
                         :rules="notEmtpy.concat([lengthPracticeName('Address')])"
                  />
                </v-col>
              </v-row>
              <v-row>
                <v-col class="py-0">
<!--                  <div class="label">City/State/Zip</div>-->
                  <v-row>
                    <v-col cols="6">
                      <div class="label">City</div>
                      <Input outlined
                             autocomplete="off"
                             radius="8"
                             :value="form.organization.city"
                             @input="form.organization.city=$event"
                             :rules="notEmtpy.concat([lengthPracticeName('City')])"
                      />
                    </v-col>
                    <v-col cols="3">
<!--                      <search-select outlined
                                     @change:object="form.state=$event"
                                     placeholder="Select state of us"
                                     itemText="abbreviation"
                                     itemValue="abbreviation"
                                     :items="states"
                                     :value="form.state"
                      />-->
                      <div class="label">State</div>
                      <Input outlined
                             autocomplete="off"
                             radius="8"
                             placeholder="State of US"
                             :value="form.organization.state"
                             @input="form.organization.state=$event"
                             :rules="notEmtpy.concat([lengthPracticeName('State')])"
                      />
                    </v-col>
                    <v-col cols="3">
                      <div class="label">Zip</div>
                      <Input outlined
                             autocomplete="off"
                             radius="8"
                             placeholder="XXXXX"
                             :value="form.organization.zip"
                             @input="form.organization.zip=$event"
                             :rules="notEmtpy.concat([lengthPracticeName('Zip')])"
                      />
                    </v-col>
                  </v-row>
                </v-col>
              </v-row>
              <v-row>
                <v-col class="py-0">
                  <v-row>
                    <v-col>
                      <div class="label">Phone</div>
                      <PhoneInput radius="8" :rules="notEmtpy" @input="form.organization.phoneNumber=$event" :value="form.organization.phoneNumber" />
                    </v-col>
                    <v-col>
                      <div class="label">Fax</div>
                      <PhoneInput radius="8" @input="form.organization.fax=$event" :value="form.organization.fax" />
                    </v-col>
                  </v-row>
                </v-col>
              </v-row>
              <v-row>
                <v-col class="py-0">
                  <v-row>
                    <v-col>
                      <div class="label">Website</div>
                      <Input outlined
                             autocomplete="off"
                             class="flex-grow-1 mr-4"
                             placeholder="www.john-smith.com"
                             radius="8"
                             :value="form.organization.web"
                             @input="form.organization.web=$event"
                             :rules="localUrlRules"
                      />
                    </v-col>
                    <v-col>
                      <div class="label">Communication email</div>
                      <Input outlined
                             autocomplete="off"
                             class="flex-grow-1 mr-4"
                             placeholder="john.smith@gmail.com"
                             persistent-hint
                             hint="This email will be used for communications as From Address."
                             radius="8"
                             :value="form.organization.communicationEmail"
                             @input="form.organization.communicationEmail=$event"
                             :rules="emailRules"
                      />
                    </v-col>
                  </v-row>
                </v-col>
              </v-row>
              <v-row>
                <v-col class="d-flex flex-row justify-end">
                  <Button height="45"
                          width="100"
                          btn-bg="dark-green"
                          dark
                          :styles="{ 'border-radius': '16px' }"
                          :disabled="!valid"
                          @click.native="preSave">Save</Button>
                </v-col>
              </v-row>
            </v-form>
          </v-col>
        </v-row>
      </v-card>
    </v-container>
    <!--upload file-->
    <v-row v-if="dialogs.uploadAvatar.isOpen">
      <v-col cols="12">
        <UploadAvatar
          :id="id"
          :avatar="avatar"
          :refName="refName"
          @modalClose="clearAvatarInputValue"
          @selectedNewAvatar="setNewAvatar"/>
      </v-col>
    </v-row>
    <!--upload file-->
    <div class="custom-alert">
      <v-alert
        color="#7ac1fb"
        dismissible
        type="info"
        v-model="alertIsOpen"
      >
        <span style="font-size: 14px;">{{popupMessage}}</span>
      </v-alert>
    </div>
  </div>
</template>

<script>
import { mapActions, mapMutations, mapState } from 'vuex'
import Input from '@/components/elements/Input'
import Button from '@/components/elements/Button'
import PhoneInput from '@/components/elements/phone-input'
// import searchSelect from '@/components/elements/search-select'
import Validate from '@/mixins/Validation'
import fakeData from '@/assets/fakeData'
import UploadAvatar from '@/components/elements/UploadAvatar'

export default {
  name: 'profileSettings',
  mixins: [Validate],
  components: { Input, PhoneInput, Button, UploadAvatar }, // searchSelect
  data: () => ({
    ...fakeData,
    id: null,
    avatar: null,
    refName: null,
    form: {},
    popupMessage: null,
    alertIsOpen: false,
    localUrlRules: [],
    staticIcons: ['https://static.vecteezy.com/system/resources/previews/000/623/572/non_2x/agriculture-business-logo-template-unique-green-vector-image.jpg'],
    isAutofocus: true,
  }),
  async mounted () {
    if (this.isAuth) {
      await this.getCurrentOrganizationProfile()
      const { doctor, organization } = this.organizationRecord
      this.form = Object.assign({}, { doctor, organization: { ...organization, logoFull: '' } })
    }
  },
  methods: {
    ...mapActions('organizations', ['getCurrentOrganizationProfile', 'updateCurrentOrganizationProfile', 'updateOrganization']),
    ...mapMutations('dialogs', ['TOGGLE_DIALOG']),
    ...mapMutations(['UPDATE_DATA']),
    preSave () {
      this.setDynamicValidationRules({
        localUrlRules: [
          v => (/^(https?:\/\/)?([\da-z.-]+)\.([a-z.]{2,6})([\\/\w .-]*)*\/?$/.test(v) || !v?.length) || 'Url address must be valid'
        ]
      })
      this.$nextTick(() => {
        this.update()
      }, 200)
    },
    async update () {
      if (this.validate()) {
        // delete this.form.doctor.logoSmall
        // delete this.form.organization.logoSmall
        const dataForSend = JSON.parse(JSON.stringify(this.form))
        delete dataForSend.doctor.logoSmall
        delete dataForSend.organization.logoSmall
        if (!this.form.organization.customAvatar) {
          delete dataForSend.organization.logoFull
        }
        const res = await this.updateCurrentOrganizationProfile(dataForSend)
        if (res.record) {
          this.UPDATE_DATA({ state: 'userProfile', key: 'profileIcon', value: res.record.doctor.logoSmall })
          this.UPDATE_DATA({ state: 'organization', key: 'logoSmall', value: res.record.organization.logoSmall })
          this.UPDATE_DATA({ state: 'organization', key: 'practiceName', value: res.record.organization.practiceName })
          this.UPDATE_DATA({ state: 'organization', key: 'practiceTitle', value: res.record.organization.practiceTitle })
          this.UPDATE_DATA({ state: 'userProfile', key: 'firstName', value: res.record.doctor.firstName })
          this.UPDATE_DATA({ state: 'userProfile', key: 'lastName', value: res.record.doctor.lastName })
          if (localStorage.getItem('isNewUser')) {
            this.showPopup()
          } else {
            localStorage.setItem('isNewUser', 'true')
            this.$router.push({ name: 'Patients' })
          }
        }
      }
    },
    showPopup () {
      this.alertIsOpen = true
      this.popupMessage = 'Data was saved successfully.'
    },
    openUploadFile (name) {

      this.refName = name

      if (name === 'inputAvatarDoctorProfile') {
        this.id = 'doctor'
      }


      if (name === 'inputAvatarOrganization') {
        this.id = 'company'
      }


      this.TOGGLE_DIALOG({ name: 'uploadAvatar', isOpen: true, data: this.avatar })

    },
    uploadFile (ref, e) {
      this.refName = ref

      const image = e.target.files[0]
      const reader = new FileReader()
      reader.readAsDataURL(image)
      reader.onload = (e) => {
        this.avatar = e.target.result
        this.TOGGLE_DIALOG({ name: 'uploadAvatar', isOpen: true, data: this.avatar })
      }
    },


    clearAvatarInputValue (refName) {
      this.avatar = null
      this.$refs[refName].value = null
    },

    setNewAvatar (newAvatar) {
      if (this.refName === 'inputAvatarDoctorProfile') {
        this.form.doctor.logoFull = newAvatar
        this.form.doctor.customAvatar = true
      }

      if (this.refName === 'inputAvatarOrganization') {
        this.form.organization.logoFull = newAvatar
        this.form.organization.customAvatar = true
      }
      this.refName = null
    }
  },
  computed: {
    ...mapState({
      isAuth: state => state.isAuth,
      organizationRecord: state => state.organizations?.organization?.record,
      userProfile: state => state.userProfile,
      organization: state => state.organization
    }),
    dialogs () {
      return this.$store.state.dialogs
    }
  },
  watch: {
    isAuth (val) {
      if (val && this._.lowerCase(this.$route.name) === 'profile') {
        this.getCurrentOrganizationProfile()
      }
    },
    alertIsOpen (val) {
      const self = this
      if (val) {
        setTimeout(function () {
          self.alertIsOpen = false
        }, 5000)
      }
    }
  }
}
</script>

<style scoped lang="scss">

.clickable {
    cursor: pointer;
  }

.profile-organization {
  ::v-deep .v-card {
    padding: 16px;
    border-radius: 16px;
    background: white!important;
  }
  .main-row {
    display: flex;
    flex-wrap: wrap;
    column-gap: 1rem;
    align-items: center;
    .avatar {
      cursor: pointer;
      &__avatar-badge {
        ::v-deep .v-badge__wrapper .v-badge__badge {
          width: 30px !important;
          height: 30px !important;
          border-radius: 50% !important;
          background-color: #A6B7D4 !important;
        }
        #hiddenUploadInput {
          display: none;
        }
      }
      &__avatar-modify  {
        width: 80px !important;
        height: 80px !important;
      }
    }
    .info-title {
      font-weight: 600;
      font-size: 18px;
      color: #1A202C;
    }
    .reset-logo {
      color: rgb(66, 84, 102);
      cursor: pointer;
      text-decoration: underline;


    }
  }
  .label {
    font-weight: 500;
    font-size: 14px;
    color: #425466;
  }
  .custom-alert {
    max-width: 300px;
    position: fixed;
    top: 10px;
    right: 20px;
    z-index: 100;
  }
}
</style>
