<template>
  <div class="login d-flex justify-center">
    <div class="login-area d-flex justify-center">
      <div class="login-area__form-wrapper d-flex flex-column">
        <div class="logo flex-grow-1 d-flex align-center">
          <Img :src="getImgWithExt(['images', 'logo_drawer', 'svg'])" width="172" />
        </div>
        <div class="login-area__form flex-grow-1">
          <login-form v-if="$route.name == 'SignIn'" />
          <registration-form v-if="$route.name == 'SignUp'" />
          <comfirm-account v-if="$route.name == 'ConfirmAccount'" />
          <redirect-auto-auth v-if="$route.name == 'RedirectAutoAuth'"/>
        </div>
      </div>
    </div>
    <div class="slider-area d-none d-lg-block">
      <div class="slider-area__carusel d-flex justify-center align-center">
        <carousel :items="items" />
      </div>
    </div>
  </div>
</template>

<script>
import Image from '@/mixins/Image'
import loginForm from '@/components/layouts/forms/login'
import registrationForm from '@/components/layouts/forms/registration'
import comfirmAccount from '@/views/confirm-account'
import carousel from '@/components/layouts/carusel'
import Img from '@/components/elements/Img'
import RedirectAutoAuth from '@/views/redirect-auto-auth.vue'

export default {
  name: 'login',
  mixins: [Image],
  components: { RedirectAutoAuth, loginForm, registrationForm, comfirmAccount, carousel, Img },
  computed: {
    items () {
      return [
        {
          src: this.getImgWithExt(['images', 'slider', 'slide1', 'jpeg']),
          content: 'Seamlessly interact with your patients and have them complete the survey online.<br> Ask Maestro Nutrition about the Patient Portal.'
        },
        {
          src: this.getImgWithExt(['images', 'slider', 'slide2', 'jpeg']),
          content: 'Reach beyond your geographic boundaries, practice virtually, and reach more patients.<br> Ask Maestro Nutrition about the Patient Generator for your practice.'
        },
        {
          src: this.getImgWithExt(['images', 'slider', 'slide3', 'jpeg']),
          content: 'Maestro Nutrition makes it easy to know when your patient\'s protocol is ending or<br>  when they need to reorder products.'
        }
      ]
    }
  }
}
</script>

<style lang="scss" scoped>
  .login {
    .login-area,
    .slider-area {
      width: 50%;
      height: 100vh;
    }
    .login-area {
      background-color: #FFFFFF;
      @media (max-width: 960px) {
        width: 100%;
        padding: 0 30px;
      }
      &__form-wrapper {
        width: 439px;
      }
      &__form {
        width: 100%;
      }
    }
    .slider-area {
      position: relative;
      background-color: $dark-green;
      overflow: hidden;
      &__carusel {
        //position: absolute;
        //left: 15%;
        height: 100vh;
      }
    }
  }
</style>
