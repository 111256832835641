import Vue from 'vue'

const state = () => ({
  availableProducts: {},
  currentSubscription: {},
  paymentHistory: null
})

const getters = {}

const actions = {
  async getAvailableProducts ({ commit }) {
    const result = await Vue.$http.get('/Subscriptions/GetAvailableProducts')
    if (result?.status === 200) {
      commit('SET_DATA', ['availableProducts', result?.data.$values])
      return result?.data
    }
  },
  async createCheckoutSession ({ commit }, params) {
    const result = await Vue.$http.post('/Subscriptions/CreateCheckoutSession', { ...params })
    if (result?.status === 200) {
      return result?.data
    }
  },
  async createCustomerPortalSession ({ commit }, params) {
    const result = await Vue.$http.post('/Subscriptions/create-customer-portal-session', { ...params })
    if (result?.status === 200) {
      return result?.data
    }
  },
  async getCurrentSubscriptionPlan ({ commit }) {
    const result = await Vue.$http.get('/Subscriptions/GetCurrentSubscription')
    if (result?.status === 200) {
      commit('SET_DATA', ['currentSubscription', result?.data])
      return result?.data
    }
  },
  async getPaymentHistory ({ commit }) {
    const result = await Vue.$http.get('/Subscriptions/GetPaymentHistory')
    if (result?.status === 200) {
      commit('SET_DATA', ['paymentHistory', result?.data.$values])
      return result?.data
    }
  }
}

const mutations = {
  SET_DATA: (state, payload) => {
    const [property, value] = payload
    Vue.set(state, property, value)
  }
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}
