<template>
  <div class="add-product-dialog">
    <Dialog width="600">
      <template slot="title">
        <span>{{addProductDialog.isOpen ? addProductDialog.title : editProductDialog.title}}</span>
      </template>

      <template slot="body">
        <div class="add-product-dialog__form">
          <v-form ref="form" v-model="valid" lazy-validation id="edit-protocol" class="pt-4 pb-4 px-4">
            <v-row>
              <v-col class="py-0">
                <div class="label">Name</div>
                <Input outlined
                       radius="8"
                       :value="!addProductDialog.isOpen ? form.name = editProductDialog.data.name : form.name"
                       @input="form.name=$event"
                       :rules="notEmtpy"
                />
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="6" class="py-0">
                <div class="label">Product number</div>
                <Input outlined
                       radius="8"
                       :value="!addProductDialog.isOpen ? form.productNumber = editProductDialog.data.productNumber : form.productNumber"
                       @input="form.productNumber=$event"
                />
              </v-col>
              <v-col cols="6" class="py-0">
                <div class="label">List price</div>
                <Input outlined
                       radius="8"
                       type="number"
                       :value="!addProductDialog.isOpen ? form.listPrice = editProductDialog.data.listPrice : form.listPrice"
                       @input="form.listPrice=$event"
                       :rules="shouldBeSet"
                />
              </v-col>
            </v-row>
            <v-row class="justify-space-around">
              <v-switch class="py-0 my-0" color="#008C89" inset label="Discontinued" @change="changeDiscontinued" :input-value="form.isDiscontinued" />
              <v-switch class="py-0 my-0" color="#008C89" inset label="Default product" @change="changeDefault" :input-value="form.isDefault" />
              <v-switch class="py-0 my-0" color="#008C89" inset label="Taxable" @change="changeTaxable" :input-value="form.isTaxable" />
            </v-row>
          </v-form>
        </div>
      </template>

      <template slot="actions">
        <div class="d-flex justify-space-between w-100 pb-4 px-4">
          <Button
            dark
            outlined
            color="grey"
            @click.native="close"
            :styles="{ 'border-radius': '8px' }"
          >
            <span style="color: #425466">Cancel</span>
          </Button>
          <Button
            v-if="editProductDialog.isOpen"
            btn-bg="dark-green"
            @click.native="update"
            :styles="{ 'border-radius': '8px' }"
          >
            <span style="color: #FFFFFF">Update</span>
          </Button>
          <Button
            v-else
            btn-bg="dark-green"
            @click.native="create"
            :styles="{ 'border-radius': '8px' }"
          >
            <span style="color: #FFFFFF">Create</span>
          </Button>
        </div>
      </template>
    </Dialog>
  </div>
</template>

<script>
import { mapState, mapMutations, mapActions } from 'vuex'
import Dialog from '@/components/layouts/dialog'
import Button from '@/components/elements/Button'
import Validation from '@/mixins/Validation'
import Input from '@/components/elements/Input'
import Textarea from '@/components/elements/Textarea'

export default {
  name: 'add-product-dialog',
  components: { Dialog, Button, Input, Textarea },
  mixins: [Validation],
  data: () => ({
    form: {
      name: '',
      productNumber: '',
      listPrice: 0,
      isDefault: false,
      isDiscontinued: false,
      isTaxable: false,
      organizationId: ''
    }
  }),
  mounted () {
    if (this.addProductDialog.isOpen) {
      this.form = Object.assign(this.form, this.addProductDialog.data)
    }
    if (this.editProductDialog.isOpen) {
      this.form = Object.assign(this.form, this.editProductDialog.data)
    }
  },
  methods: {
    ...mapMutations('dialogs', ['TOGGLE_DIALOG']),
    ...mapActions('products', ['addProduct', 'updateProduct']),
    async update () {
      if (this.valid) {
        delete this.form.organizationId
        delete this.form.$id
        const dataSend = {
          id: this.editProductDialog.data.id,
          data: this.form
        }
        const res = await this.updateProduct(dataSend)
        if (res) {
          const { afterAction } = this.editProductDialog
          await this.$store.dispatch(afterAction.name, afterAction.product)
          this.TOGGLE_DIALOG({ name: 'editProductDialog', isOpen: false })
        }
      }
    },
    async create () {
      if (this.valid) {
        this.form.organizationId = this.organization.id
        const res = await this.addProduct(this.form)
        if (res) {
          const { afterAction } = this.addProductDialog
          await this.$store.dispatch(afterAction.name, afterAction.product)
          this.TOGGLE_DIALOG({ name: 'addProductDialog', isOpen: false })
        }
      }
    },
    changeDiscontinued(val) {
      this.form.isDiscontinued = val
      if (val) {
        this.form.isDefault = false
      }
    },
    changeDefault (val) {
      this.form.isDefault = val
      if (val) {
        this.form.isDiscontinued = false
      }
    },
    changeTaxable (val) {
      this.form.isTaxable = val
    },
    close () {
      this.TOGGLE_DIALOG({ name: 'addProductDialog', isOpen: false })
    }
  },
  computed: {
    ...mapState({
      organization: state => state.organization,
      addProductDialog: state => state.dialogs.addProductDialog,
      editProductDialog: state => state.dialogs.editProductDialog
    })
  }
}
</script>

<style lang="scss" scoped>
.w-100 {
  width: 100%;
}
.add-product-dialog {
  ::v-deep .v-dialog__content {
    .v-dialog {
      height: unset!important;
      .v-card {
        height: 42vh!important;
      }
    }
  }
  &__form {
    display: flex;
    justify-content: center;
    width: 91%;
    margin: 0 auto;
    #edit-protocol {
      width: 100%;
    }
  }
  //::v-deep {
  //  .v-card__title {
  //    position: relative!important;
  //    .v-icon {
  //      position: absolute!important;
  //      right: 15px;
  //      top: 0;
  //    }
  //  }
  //}
  //.title {
  //  width: 100%;
  //  text-align: center;
  //}
  //.description {
  //  padding: 0 16px;
  //}
  ::v-deep .v-dialog__container {
    .v-card {
      &__title {
        background: #edf2f7;
        padding: 25px 30px 17px 46px !important;
        justify-content: center!important;
        position: relative;
        font-size: 25px !important;
        font-weight: 600 !important;
        .v-icon {
          position: absolute;
          right: 15px;
          top: 25px;
        }
      }
      &__text {
        .description {
          font-family: 'Poppins';
          font-weight: 400;
          font-size: 14px;
          text-align: center;
          color: #425466;
        }
        .v-input {
          .v-text-field__details {
            display: block!important;
            margin-bottom: 0!important;
            .v-messages {
              display: block!important;
            }
          }
        }
      }
    }
  }
}
</style>
