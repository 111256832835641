<template>
  <v-card class="patient-list" elevation="1">
    <v-card-title class="patient-list__title">
      {{title}}
    </v-card-title>
    <v-card-text>
      <template v-if="data">
        <v-row class="main-row" v-for="item in data.$values" :key="item.id" @click="redirectByType(item.entryId, item.visitId, type, item)">
          <div class="avatar-full-name">
            <div class="avatar">
              <v-avatar size="40">
                <v-img :src="type === 'follow-up' ? item.patient.profileAvatar : item.profileAvatar"></v-img>
              </v-avatar>
            </div>
            <div class="full-name">
              {{type === 'follow-up' ? item.patient.firstName : item.title}} {{type === 'follow-up' ? item.patient.lastName : ''}}
            </div>
          </div>
          <div v-if="type === 'follow-up'" class="date">{{$moment(item.visitDate).format('MM/DD/YYYY')}}</div>
          <div v-if="type === 'follow-up'" class="status-block">
            <v-tooltip left :open-on-hover="true"><!--false-->
              <template v-slot:activator="{ on, attrs }">
                <div v-bind="attrs"
                     v-on="on"
                     @click.stop="openVisit(item)">
                  <status v-if="getStatusCodeName[item.statusCode]"
                          :class="getStatusCodeName[item.statusCode].class"
                          radius="6">
                    {{getStatusCodeName[item.statusCode].name}}
                  </status>
                </div>
              </template>
              <div style="display: block;max-width: 300px;">{{getStatusCodeName[item.statusCode].description(item.statusDays)}}</div>
            </v-tooltip>
          </div>
          <div v-if="type === 'recently'" class="status-block">
            <v-tooltip left :open-on-hover="true"><!--false-->
              <template v-slot:activator="{ on, attrs }">
                <div v-bind="attrs"
                     v-on="on"
                     @click.stop="openVisit(item)">
                  <status v-if="getStatusCodeName[item.statusCode]"
                          :class="getStatusCodeName[item.statusCode].class"
                          radius="6">
                    {{getStatusCodeName[item.statusCode].name}}
                  </status>
                </div>
              </template>
              <div style="display: block;max-width: 300px;">{{getStatusCodeName[item.statusCode].description(item.statusDays)}}</div>
            </v-tooltip>
          </div>
          <div v-if="type === 'recently'" class="action">
            <v-icon color="#A0AEC0">mdi-eye-outline</v-icon>
          </div>
        </v-row>
      </template>
      <div v-else class="loading d-flex justify-center px-2 py-2 align-center">
        <v-progress-circular
          indeterminate
          color="#008C89"
        ></v-progress-circular>
      </div>
    </v-card-text>
  </v-card>
</template>

<script>
import Mapping from '@/mixins/Mapping'
import status from '@/components/layouts/status'
import { mapMutations } from 'vuex'

export default {
  name: 'patient-list',
  props: {
    data: Object,
    title: String,
    type: String
  },
  mixins: [Mapping],
  components: { status },
  methods: {
    ...mapMutations('dialogs', ['TOGGLE_DIALOG']),
    openVisit (item) {
      if (this.getStatusCodeName[item.statusCode].name === 'Need Survey') {
        this.TOGGLE_DIALOG({ id: item.patient.id, name: 'addVisit', title: 'Add new evaluation date', isOpen: true, data: item.patient })
      }
    },
    redirectByType (detailId, visitId, type, obj) {
      if (type === 'follow-up') {
        switch (obj.statusCode) {
          case 'InActive':
            this.TOGGLE_DIALOG({
              id: obj.patient.id,
              name: 'confirm',
              isOpen: true,
              action: 'patients/activatePatient',
              afterAction: { name: 'patients/getPatients' },
              title: 'Confirm activating the Patient',
              description: `Please confirm Patient <b>${obj.patient.firstName} ${obj.patient.lastName}</b> activation.<br>Are you sure want to activate the Patient?`
            })
            break
          case 'NeedSurvey':
            this.TOGGLE_DIALOG({
              id: obj.patient.id,
              name: 'addVisit',
              title: 'Add new evaluation date',
              isOpen: true,
              data: obj.patient
            })
            break
          case 'NeedReeval':
            this.TOGGLE_DIALOG({
              id: obj.patient.id,
              name: 'addVisit',
              title: 'Add new evaluation date',
              isOpen: true,
              data: obj.patient
            })
            break
          case 'SurveySent':
            this.$router.push({ path: `patients/visit/${obj.visitId}` })
            break
          case 'SurveyDue':
            this.$router.push({ path: `patients/visit/${obj.visitId}` })
            break
          case 'NeedReview':
            this.$router.push({ path: `patients/visit/${obj.visitId}` })
            break
          case 'Reviewed':
            this.$router.push({ path: `patients/visit/${obj.visitId}?tabIndex=1` })
            break
          case 'InProgress':
            if (this.subscription.features.$values.includes('Survey')) {
              this.$router.push({ path: `patients/visit/${obj.visitId}` }) // obj.patient.nextVisitId
            }
            if (this.subscription.features.$values.includes('Report')) {
              this.$router.push({ path: `patients/visit/${obj.visitId}?tabIndex=3` }) // obj.patient.nextVisitId
            }
            break
          case 'InProtocol':
            this.$router.push({ path: `patients/visit/${obj.visitId}?tabIndex=3` })
            break
          case 'Completed':
            this.$router.push({ path: `patients/visit/${obj.visitId}?tabIndex=2` })
            break
        }
      } else {
        this.$router.push({ name: 'View patient', params: { id: detailId } })
      }
    }
  },
  computed: {
    dialogs () {
      return this.$store.state.dialogs
    },
    subscription () {
      return this.$store.state.subscription
    }
  }
}
</script>

<style scoped lang="scss">
.patient-list {
  border-radius: 16px;
  ::v-deep .v-card {
    &__title {
      padding: 10px 16px;
    }
    &__text {
      height: 460px; // max-height
      overflow-y: unset!important; // auto
    }
  }
  &__title {
    font-weight: 600;
    font-size: 20px;
    color: #16192C;
  }
  .loading {
    height: 100%;
  }
  .main-row {
    cursor: pointer;
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: space-between;
    padding: 2px 10px;
    border-bottom: 1px solid #c0bfbf;
    .avatar-full-name {
      width: 225px;
      display: flex;
      align-items: center;
      column-gap: 1rem;
      .avatar {}
      .full-name {
        max-width: 175px;
        font-weight: 600;
        font-size: 12px;
        color: #425466;
      }
    }
    .date {
      max-width: 80px;
      font-weight: 500;
      font-size: 12px;
      text-align: center;
      color: #8492A6;
    }
    .status-block {
      width: 150px;
      display: flex;
      justify-content: flex-end;
      .status {
        width: 115px;
      }
    }
    .recently-status {
      max-width: 100px;
      .status {
        font-weight: 500;
        font-size: 12px;
        color: #8492A6;
      }
    }
    .action {
      cursor: pointer;
    }
  }
  .main-row:hover {
    background: #ecf5ff;
  }
  .main-row:first-child {
    margin-top: 1px;
  }
  .main-row:last-child {
    border: unset;
  }
  ::v-deep .status {
    .v-chip {
      cursor: pointer!important;
      background: unset!important;
      color: unset!important;
      width: -webkit-fill-available!important;
      justify-content: center!important;
    }
  }
  .need-product {
    background: rgba(255, 237, 227, 0.7)!important;
    color: #F7936F!important;
    border-radius: 6px!important;
    text-align: center!important;
  }
  .in-active {
    background: #EDF2F7!important;
    color: #A0AEC0!important;
    border-radius: 6px!important;
    text-align: center!important;
  }
  .in-progress {
    background: rgba(208, 220, 255, 0.5);
    color: #668BFF;
    border-radius: 6px!important;
    text-align: center!important;
  }
  .need-survey {
    background: rgba(255, 237, 227, 0.7)!important;
    color: #F7936F!important;
    border-radius: 6px!important;
    text-align: center!important;
  }
  .need-reeval {
    background: rgba(255, 237, 227, 0.7)!important;
    color: #F7936F!important;
    border-radius: 6px!important;
    text-align: center!important;
  }
  .survey-sent {
    background: rgba(208, 220, 255, 0.5);
    color: #668BFF;
    border-radius: 6px!important;
    text-align: center!important;
  }
  .survey-due {
    background: rgba(255, 230, 228, 0.7)!important;
    color: #F16063!important;
    border-radius: 6px!important;
    text-align: center!important;
  }
  .need-review {
    background: rgba(255, 237, 227, 0.7)!important;
    color: #F7936F!important;
    border-radius: 6px!important;
    text-align: center!important;
  }
  .reviewed {
    background: rgba(208, 220, 255, 0.5)!important;
    color: #668BFF!important;
    border-radius: 6px!important;
    text-align: center!important;
  }
  .in-protocol {
    background: rgba(0, 140, 137, 0.16)!important;
    color: #008C89!important;
    border-radius: 6px!important;
    text-align: center!important;
  }
  .protocol-ending {
    background: #FFE9E2!important;
    color: #E69376!important;
    border-radius: 6px!important;
    text-align: center!important;
  }
  .completed {
    background: rgba(141, 186, 106, 0.2)!important;
    color: #8DBA6A!important;
    border-radius: 6px!important;
    text-align: center!important;
  }
}
</style>
