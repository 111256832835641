<template>
  <div class="invite-patient">
    <Dialog width="568" scrollable v-if="dialog.data">
      <template slot="title">
        <span>
          {{dialog.title}}
        </span>
      </template>
      <template slot="body">
        <v-form ref="form" v-model="valid" lazy-validation id="addForm" class="py-4">
          <div class="main-row">
            <div class="section__avatar">
              <v-avatar size="80">
                <v-img :src="dialog.data.profileAvatar"></v-img>
              </v-avatar>
            </div>
            <div class="section__info">
              <div class="main-row">
                <div class="label">Joined {{ dialog.data.createdOn ? $moment(dialog.data.createdOn).format('LL') : '-' }}</div>
                <div class="name">{{ dialog.data.firstName + ' ' + dialog.data.lastName }}</div>
              </div>
              <div class="main-row mt-2">
                <div class="phone">
                  <div class="label">Phone Number</div>
                  <div class="phone-number">{{dialog.data.number}}</div>
                </div>
                <div class="email">
                  <div class="label">E-mail</div>
                  <div class="email-value">{{dialog.data.email}}</div>
                </div>
              </div>
            </div>
          </div>
          <v-row class="mt-2">
            <v-col class="pb-0">
              <div class="label mb-1 d-flex justify-space-between">
                <div>Optional Portal Invite Note</div>
                <div class="d-flex align-center" style="cursor: pointer" @click="openPreviewEmail({ isPortal: true })"><v-icon>mdi-email-open</v-icon> Preview</div>
              </div>
              <Textarea
                dense
                auto-grow
                outlined
                @input:text-area="patientMessage=$event"
                :value="patientMessage"
                :style="{'background': '#EDF2F7','padding':' 0 5px','border-radius': '12px'}"
              />
              <div class="mt-1 short-message">
                <span>Patient will recieve email with <span class="bold">instruction to register on Patient Portal</span></span>
              </div>
            </v-col>
          </v-row>
        </v-form>
        <div v-if="loading" class="loading d-flex justify-center px-2 py-2 align-center">
          <v-progress-circular
            indeterminate
            color="#008C89"
          ></v-progress-circular>
        </div>
      </template>
      <template slot="actions">
        <div class="w100 d-flex justify-end py-4 pl-5 pr-5 align-center">
          <Button
            dark
            btn-bg="dark-green"
            @click.native="invite">
            Invite
          </Button>
        </div>
      </template>
    </Dialog>
    <preview-email-template v-if="dialog.name === 'previewEmailTemplateDialog'" />
  </div>
</template>

<script>
import Dialog from '@/components/layouts/dialog'
import Button from '@/components/elements/Button'
import { mapActions, mapMutations, mapState } from 'vuex'
import Validation from '@/mixins/Validation'
import Textarea from '@/components/elements/Textarea'
import previewEmailTemplate from '@/components/layouts/dialogs/preview-email-template'

export default {
  name: 'invite-patient',
  components: {
    Dialog,
    Textarea,
    Button,
    previewEmailTemplate
  },
  mixins: [Validation],
  data: () => ({
    patientMessage: '',
    loading: false
  }),
  mounted () {
    // this.patientMessage = `Hi, ${this.dialog.data.name}\nPlease join Patients Portal to manage your survey and schedules.`
  },
  computed: mapState({
    dialog (state) {
      const modalName = this._.findKey(state.dialogs, ['isOpen', true])
      return state.dialogs[modalName]
    }
  }),
  methods: {
    ...mapActions('organizations', ['invitePatientToThePortal']),
    ...mapMutations('dialogs', ['TOGGLE_DIALOG']),
    async invite () {
      this.loading = true
      const result = await this.invitePatientToThePortal({ patientId: this.dialog.data.id, notes: this.patientMessage })
      if (result && result.validationResults.isValid) {
        this.loading = false
        this.$emit('showPopup', { type: 'invite-patient', data: result })
      }
    },
    close () {
      this.TOGGLE_DIALOG({ name: this.dialog.name, isOpen: false })
    },
    openPreviewEmail ({ isPortal }) {
      console.log('openPreviewEmail')
      this.TOGGLE_DIALOG({
        name: 'previewEmailTemplateDialog',
        isOpen: true,
        title: '',
        data: { ...this.dialog.data, notes: this.patientMessage },
        isPortal
      })
    }
  }
}
</script>

<style scoped lang="scss">
.invite-patient {
  .loading {
    min-height: 100px;
    position: absolute;
    z-index: 1;
    height: 82%;
    width: 99%;
    top: 76px;
    left: 2px;
    background: rgba(213, 213, 213, 0.5);
    border-radius: 8px;
  }
  ::v-deep .v-dialog__container {
    .v-card {
      &__title {
        background: #edf2f7;
        padding: 25px 30px 17px 46px !important;
        justify-content: center!important;
        position: relative;
        .v-icon {
          position: absolute;
          right: 15px;
          top: 25px;
        }
      }
      &__text {
        .v-input {
          .v-text-field__details {
            display: none;
          }
        }
      }
    }
  }
  #addForm {
    .main-row {
      display: flex;
      flex-wrap: wrap;
      column-gap: 2rem;
      row-gap: 0.5rem;
      .section__avatar {
        display: flex;
        flex-wrap: wrap;
        align-items: center;
        column-gap: 1rem;
        width: fit-content;
      }
      .section__info {
        width: calc(100% - 125px);
      }
      .label {
        font-weight: 600;
        color: #afafaf;
      }
      .name {
        width: 100%;
        font-size: 20px;
        font-weight: bold;
      }
      .phone-number {
        font-weight: 600;
      }
      .email-value {
        font-weight: 600;
      }
    }
    .short-message {
      .bold {
        font-weight: 600;
      }
    }
  }
  ::v-deep .v-dialog__container {
    .v-card__actions {
      justify-content: end;
    }
  }
}
</style>
