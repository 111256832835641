import Vue from 'vue'
import axios from 'axios'
import VueAxios from 'vue-axios'

import store from '@/store'
import router from '@/router'

const LOGIN = 'SSMCDev'
const PASSWORD = 'cg]VzufBzCMxUgw?Y_U:#?u9'
const basicAuth = window.btoa(`${LOGIN}:${PASSWORD}`)

const axiosInstance = axios.create({
  baseURL: process.env.VUE_APP_BACK_API_HOST,
  headers: { },
  validateStatus: (status) => {
    return status >= 200 && status < 500
  }
})

axiosInstance.interceptors.request.use((config) => {
  const token = (store.state?.token)
    ? `Bearer ${store.state?.token}`
    : `Basic ${basicAuth}`
  config.headers.Authorization = token
  return config
})

axiosInstance.interceptors.response.use((response) => {
  if (response.status === 401) {
    router.push('/sign-in').catch(() => {})
    store.commit('SAVE_TOKEN', '')
  }
  if (response.data.status === 401) {
    router.push('/sign-in').catch(() => {})
    store.commit('SAVE_TOKEN', '')
  }
  return response
}, (error) => {
  try {
    const { validationResults } = error.response.data
    const messages = []
    const message = {}
    const { status, statusText, data, headers } = error.response
    console.error({ status, statusText, data, headers })
    if (error.response.status >= 100 && error.response.status <= 599) {
      if (validationResults && !validationResults.isValid) {
        for (let i = 0; validationResults.results[i]; i++) {
          switch (true) {
            case (error.response.status >= 100 && error.response.status <= 199):
              message.type = 'warning'
              message.color = 'orange'
              break
            case (error.response.status >= 200 && error.response.status <= 299):
              message.type = 'success'
              message.color = 'green'
              break
            case (error.response.status >= 400 && error.response.status <= 599):
              message.type = 'error'
              message.color = 'error'
              break
          }
          message.msg = validationResults.results[i].message
          messages.push(message)
          store.commit('SET_MESSAGE', messages)
        }
      }
      if (error.response.status === 401) {
        router.push('/sign-in').catch(() => {})
        store.commit('SAVE_TOKEN', '')
      }
    }
  } catch (error) {
    return Promise.reject(error)
  }
})

store.subscribe((mutation, state) => {
  switch (mutation.type) {
    case 'SAVE_TOKEN':
      store.commit('SET_DATA', ['isAuth', !!mutation?.payload?.length])
      break
  }
})

Vue.use(VueAxios, axiosInstance)

export default axiosInstance
