<template>
  <div class="card">
    <v-card outlined :class="{'is-disabled': isDisabled}">
      <v-card-text class="px-0 py-0">
        <slot name="body" />
      </v-card-text>
    </v-card>
  </div>
</template>

<script>
export default {
  props: {
    isDisabled: [Boolean]
  },
  name: 'card'
}
</script>

<style lang="scss" scoped>
  .card {
    ::v-deep .v-card {
      padding: 30px;
      border: none !important;
      border-radius: 16px !important;
      box-shadow: 0px 10px 20px rgba(113, 128, 150, 0.06);
    }
    ::v-deep .v-card.is-disabled {
      background: #f7fafc;
      .v-window .v-window__container {
        background: #f7fafc;
      }
    }
  }
</style>
