<template>
  <div class="add-supplement-product">
    <Dialog width="550" scrollable>
      <template slot="title">
        <div>{{dialog.title}}</div>
      </template>
      <template slot="body">
        <v-form ref="form" v-model="valid" lazy-validation id="add-edit-product" class="py-2 pl-3 pr-3">
          <v-row class="mt-0 mb-0">
            <v-col class="pb-0 pt-0">
              <div class="label">Supplement</div>
              <h3 style="text-align: center">{{supplementName.name || dialog.data.name}}</h3>
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="6">
              <v-row class="mt-0">
                <v-col class="pb-0 pt-0">
                  <div class="label">Product number</div>
                  <Input outlined
                         radius="8"
                         :disabled="disabledByUserDefined"
                         @input="form.productNumber=$event"
                         :value="form.productNumber"
                         :rules="notEmtpy"
                  />
                </v-col>
              </v-row>
              <v-row class="mt-0">
                <v-col class="pb-0 pt-0">
                  <div class="label">Quantity</div>
                  <Input outlined
                         radius="8"
                         :disabled="disabledByUserDefined"
                         @input="form.quantity=$event"
                         :value="form.quantity"
                         :rules="notEmtpy"
                  />
                </v-col>
              </v-row>
              <v-row class="mt-0">
                <v-col class="pb-0 pt-0 switcher">
                  <div class="label">Discontinued</div>
                  <v-switch
                    v-model="form.discontinued"
                    :disabled="disabledByUserDefined"
                    label="Yes"
                  ></v-switch>
                </v-col>
              </v-row>
              <v-row v-if="disabledByUserDefined" class="mt-0 mt-3">
                <v-col class="pb-0 pt-0">
                  <div class="label">List price</div>
                  <Input outlined
                         radius="8"
                         prepend-inner-icon="mdi-currency-usd"
                         :disabled="disabledByUserDefined"
                         @input="form.listPrice=$event"
                         :value="form.listPrice"
                         :rules="notEmtpy"
                  />
                </v-col>
              </v-row>
            </v-col>
            <v-col cols="6">
              <v-row class="mt-0">
                <v-col class="pb-0 pt-0">
                  <div class="label">Doses per bottle</div>
                  <Input outlined
                         radius="8"
                         :disabled="disabledByUserDefined"
                         @input="form.dosesPerBottle=$event"
                         :value="form.dosesPerBottle"
                         :rules="notEmtpy"
                  />
                </v-col>
              </v-row>
              <v-row class="mt-0">
                <v-col class="pb-0 pt-0">
                  <div class="label">Cost</div>
                  <Input outlined
                         :disabled="disabledByUserDefined"
                         radius="8"
                         prepend-inner-icon="mdi-currency-usd"
                         @input="form.cost=$event"
                         :value="form.cost"
                         :rules="notEmtpy"
                  />
                </v-col>
              </v-row>
              <v-row v-if="!disabledByUserDefined" class="mt-0 mt-3">
                <v-col class="pb-0 pt-0">
                  <div class="label">List price</div>
                  <Input outlined
                         radius="8"
                         prepend-inner-icon="mdi-currency-usd"
                         :disabled="disabledByUserDefined"
                         @input="form.listPrice=$event"
                         :value="form.listPrice"
                         :rules="notEmtpy"
                  />
                </v-col>
              </v-row>
              <v-row class="mt-10">
                <v-col class="pb-0 pt-0">
                </v-col>
              </v-row>
              <v-row style="margin-top: 29px" v-if="disabledByUserDefined">
                <v-col class="pb-0 pt-0">
                  <div class="label">Override List Price</div>
                  <Input outlined
                         radius="8"
                         @keypress="filterInputData($event, 'number')"
                         @input="form.overrideListPrice=+$event"
                         :value="form.overrideListPrice"
                  />
                </v-col>
              </v-row>
            </v-col>
          </v-row>
          <v-row class="mt-0">
            <v-col class="py-0">
              <div style="width: 100%" class="d-flex justify-end pt-0 py-4 align-center">
                <Button
                  btn-bg="dark-green"
                  @click.native="add"
                  :styles="{ 'border-radius': '8px', 'font-weight': '600' }"
                  :disabled="!form.productNumber || !form.quantity || !form.dosesPerBottle || !form.cost || !form.listPrice"
                  v-if="dialog.name == 'addSupplementProduct'">
                  <span style="color: white">Create</span>
                </Button>
                <Button btn-bg="dark-green"
                        :styles="{ 'border-radius': '8px', 'font-weight': '600' }"
                        @click.native="update" v-if="dialog.name == 'editSupplementProduct'">
                  <span style="color: white">Update</span>
                </Button>
              </div>
            </v-col>
          </v-row>
        </v-form>
      </template>
    </Dialog>
  </div>
</template>

<script>
import Dialog from '@/components/layouts/dialog'
import { mapActions, mapMutations, mapState } from 'vuex'
import Validation from '@/mixins/Validation'
import Input from '@/components/elements/Input'
import Button from '@/components/elements/Button'
import Number from '@/mixins/Number'

export default {
  name: 'add-supplement-product',
  props: {
    query: {
      type: Object
    }
  },
  mixins: [Validation, Number],
  components: { Dialog, Input, Button },
  data: () => ({
    form: {
      productNumber: '',
      quantity: '',
      discontinued: false,
      dosesPerBottle: '',
      cost: '',
      listPrice: '',
      overrideListPrice: ''
    }
  }),
  async mounted () {
    if (this.dialog.name === 'editSupplementProduct') {
      const res = await this.getProductById({ id: this.dialog.data.id })
      if (res) {
        this.form = Object.assign({}, this.productById.record)
      }
      await this.getDefaultDosagesBySupplement(this.dialog.data.data.supplementId)
    }
  },
  methods: {
    ...mapMutations('dialogs', ['TOGGLE_DIALOG']),
    ...mapActions('supplements', ['addProduct', 'getProductById', 'getDefaultDosagesBySupplement']),
    async add () {
      const { id, action, afterAction } = this.dialog
      const data = {
        supplementId: id,
        supplements: this.dialog.data,
        ...this.form
      }
      const res = await this.$store.dispatch(action, data)
      if (res) {
        await this.$store.dispatch(afterAction.name, this.query)
        this.close()
      }
    },
    async update () {
      const { id, action, afterAction } = this.dialog
      delete this.form.supplement
      const dataLoad = {
        id,
        ...this.form
      }
      delete this.form.$id
      if (!this.form.overrideListPrice) {
        delete dataLoad.overrideListPrice
      }
      const res = await this.$store.dispatch(action, dataLoad)
      if (res) {
        await this.$store.dispatch(afterAction.name, this.query)
        this.TOGGLE_DIALOG({ name: this.dialog.name, state: false })
      }
    },
    close () {
      this.TOGGLE_DIALOG({ name: 'addSupplementProduct', state: false })
    }
  },
  computed: {
    ...mapState({
      dialog (state) {
        const modalName = this._.findKey(state.dialogs, ['isOpen', true])
        return state.dialogs[modalName]
      },
      productById: state => state.supplements.productById,
      supplements: state => state.supplements?.supplements,
      supplementName: state => state.supplements?.supplementById
    }),
    disabledByUserDefined () {
      if (this.dialog.data.additionalParams && !this.dialog.data.additionalParams.userDefined) {
        return true
      } else {
        return false
      }
    }
  }
}
</script>

<style scoped lang="scss">
.add-supplement-product {
  ::v-deep .v-dialog__container {
    .v-dialog__content {
      .v-dialog {
        height: 465px!important;
        .v-card {
          &__title {
            background: #edf2f7;
            padding: 25px 30px 17px 46px !important;
            justify-content: center!important;
            position: relative;
            .v-icon {
              position: absolute;
              right: 15px;
              top: 25px;
            }
          }
          &__text {
            overflow-y: hidden;
          }
          #add-edit-product {
            .switcher {
              .v-input {
                margin-top: 0!important;
              }
            }
            .v-input {
              &__control {
                .v-input__slot {
                  .v-input__prepend-inner {
                    margin-top: 5px!important;
                    padding-right: 0!important;
                    margin-right: 0!important;
                    .v-icon {
                      font-size: 19px!important;
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }
}
</style>
