const functions = {
  number_format (val, digits = 0) {
    if (val === null || val === undefined) return ''
    return (+val).toFixed(digits).replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1 ')
  },
  percent (val) {
    if (val === null || val === undefined) return ''
    return (+val).toFixed(2) + '%'
  },
  filterInputData (param, rules, type) {
    const event = (param) || window.event
    let expect = null
    if (event.target.selectionEnd !== event.target.selectionStart) {
      expect = event.target.value.toString()
    } else {
      expect = event.target.value.toString() + event.key.toString()
    }

    if (type === 'number') {
      if (parseInt(expect) >= rules.min && parseInt(expect) <= rules.max) {
        this.validationData(event, expect)
      } else {
        event.preventDefault()
      }
    } else if (type === 'string') {
      this.validationDataAlphaNumeric(event, expect)
    } else {
      this.validationData(event, expect)
    }
  },
  isSelected(event) {
    console.log('')
  },
  validationData (event, expect) {
    if (!/^[-+]?[0-9]*\.?[0-9]*$/.test(expect)) {
      event.preventDefault()
    } else {
      return true
    }
  },
  validationDataAlphaNumeric (event, expect) {
    if (!/^[a-z0-9A-Z]+$/.test(expect)) {
      event.preventDefault()
    } else {
      return true
    }
  }
}

export default {
  methods: functions,
  filters: functions
}
