var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"reports"},[_c('v-row',{staticStyle:{"gap":"15px"},attrs:{"no-gutters":""}},[_c('v-col',{staticStyle:{"max-width":"24%"},attrs:{"cols":"3"}},[_c('v-card',{staticClass:"px-2 py-1"},[_c('v-card-title',{staticClass:"report-label"},[_vm._v("Clinician Reports")]),_c('v-card-text',{staticClass:"btn-group-report"},[_c('div',{ref:"clinicReport",staticClass:"btn-report",on:{"click":function($event){$event.preventDefault();return _vm.getReport(
								'clinicReport',
								'ClinicianFinalReport',
								'ClinicReport'
							)}}},[_c('v-checkbox',{attrs:{"color":"#008C89","label":"Clinician Report"},on:{"click":function($event){_vm.selectReport(
									$event,
									'ClinicianFinalReport',
									_vm.clinicReport,
									() =>
										_vm.getReport(
											'clinicReport',
											'ClinicianFinalReport',
											'ClinicReport'
										)
								)}},model:{value:(_vm.clinicReport),callback:function ($$v) {_vm.clinicReport=$$v},expression:"clinicReport"}})],1),_c('div',{ref:"clinicianWarnings",staticClass:"btn-report",on:{"click":function($event){$event.preventDefault();return _vm.getReport(
								'clinicianWarnings',
								'ClinicianWarnings',
								'ClinicianWarnings'
							)}}},[_c('v-checkbox',{attrs:{"color":"#008C89","label":"Clinician Warnings"},on:{"click":function($event){_vm.selectReport(
									$event,
									'ClinicianWarnings',
									_vm.clinicianWarnings,
									() =>
										_vm.getReport(
											'clinicianWarnings',
											'ClinicianWarnings',
											'ClinicianWarnings'
										)
								)}},model:{value:(_vm.clinicianWarnings),callback:function ($$v) {_vm.clinicianWarnings=$$v},expression:"clinicianWarnings"}})],1),(_vm.isSubscriptionIncludesBlood)?_c('div',{ref:"clinicianBloodPanelResults",staticClass:"btn-report",on:{"click":function($event){$event.preventDefault();return _vm.getReport(
								'clinicianBloodPanelResults',
								'BloodPanelClinician',
								'ClinicianBloodPanelResults'
							)}}},[_c('v-checkbox',{attrs:{"color":"#008C89","label":"Clinician Blood Panel Results"},on:{"click":function($event){_vm.selectReport(
									$event,
									'BloodPanelClinician',
									_vm.clinicianBloodPanelResults,
									() =>
										_vm.getReport(
											'clinicianBloodPanelResults',
											'BloodPanelClinician',
											'ClinicianBloodPanelResults'
										)
								)}},model:{value:(_vm.clinicianBloodPanelResults),callback:function ($$v) {_vm.clinicianBloodPanelResults=$$v},expression:"clinicianBloodPanelResults"}})],1):_vm._e()])],1),_c('br'),_c('v-card',{staticClass:"px-2 py-1"},[_c('v-card-title',{staticClass:"report-label"},[_vm._v("Patient Reports")]),_c('v-card-text',{staticClass:"btn-group-report"},[_c('div',{ref:"supplementSchedule",staticClass:"btn-report",on:{"click":function($event){$event.preventDefault();return _vm.getReport(
								'supplementSchedule',
								'PatientSchedule',
								'SupplementSchedule'
							)}}},[_c('v-checkbox',{attrs:{"color":"#008C89","label":"Supplement Schedule"},on:{"click":function($event){_vm.selectReport(
									$event,
									'PatientSchedule',
									_vm.supplementSchedule,
									() =>
										_vm.getReport(
											'supplementSchedule',
											'PatientSchedule',
											'SupplementSchedule'
										)
								)}},model:{value:(_vm.supplementSchedule),callback:function ($$v) {_vm.supplementSchedule=$$v},expression:"supplementSchedule"}})],1),_c('div',{ref:"groupDescription",staticClass:"btn-report custom disable-label",on:{"click":function($event){$event.preventDefault();return _vm.getReport(
								'groupDescription',
								'PatientGroupsInfo',
								'PatientGroupsInfo'
							)}}},[_c('v-checkbox',{attrs:{"color":"#008C89","label":"Group Description"},on:{"click":function($event){_vm.selectReport(
									$event,
									'PatientGroupsInfo',
									_vm.localGroupDescription,
									() =>
										_vm.getReport(
											'groupDescription',
											'PatientGroupsInfo',
											'PatientGroupsInfo'
										)
								)}},model:{value:(_vm.localGroupDescription),callback:function ($$v) {_vm.localGroupDescription=$$v},expression:"localGroupDescription"}}),_c('div',{staticClass:"wrapper-group-description-report"},[_c('div',{staticClass:"group-description-report"},[_c('v-select',{staticClass:"custom-select",attrs:{"items":_vm.items,"value":_vm.items[0],"solo":""},model:{value:(_vm.groupDescription),callback:function ($$v) {_vm.groupDescription=$$v},expression:"groupDescription"}})],1)])],1),_c('div',{ref:"surveyScoreSheet",staticClass:"btn-report",on:{"click":function($event){$event.preventDefault();return _vm.getReport(
								'surveyScoreSheet',
								'PatientSurveyScoreSheet',
								'SurveyScoreSheet'
							)}}},[_c('v-checkbox',{attrs:{"color":"#008C89","label":"Survey Score Sheet"},on:{"click":function($event){_vm.selectReport(
									$event,
									'PatientSurveyScoreSheet',
									_vm.localSurveyScoreSheet,
									() =>
										_vm.getReport(
											'surveyScoreSheet',
											'PatientSurveyScoreSheet',
											'SurveyScoreSheet'
										)
								)}},model:{value:(_vm.localSurveyScoreSheet),callback:function ($$v) {_vm.localSurveyScoreSheet=$$v},expression:"localSurveyScoreSheet"}})],1),_c('div',{ref:"supplementCostAndQuantities",staticClass:"btn-report",on:{"click":function($event){$event.preventDefault();return _vm.getReport(
								'supplementCostAndQuantities',
								'PatientSurveyCostsAndQuantities',
								'SupplementCostAndQuantities'
							)}}},[_c('v-checkbox',{attrs:{"color":"#008C89","label":"Costs and Quantities"},on:{"click":function($event){_vm.selectReport(
									$event,
									'PatientSurveyCostsAndQuantities',
									_vm.supplementCostAndQuantities,
									() =>
										_vm.getReport(
											'supplementCostAndQuantities',
											'PatientSurveyCostsAndQuantities',
											'SupplementCostAndQuantities'
										)
								)}},model:{value:(_vm.supplementCostAndQuantities),callback:function ($$v) {_vm.supplementCostAndQuantities=$$v},expression:"supplementCostAndQuantities"}})],1),_c('div',{ref:"surveyFormSummary",staticClass:"btn-report",on:{"click":function($event){$event.preventDefault();return _vm.getReport(
								'surveyFormSummary',
								'PatientSurveyFormSummary',
								'SurveyFormSummary'
							)}}},[_c('v-checkbox',{attrs:{"color":"#008C89","label":"Survey Form Summary"},on:{"click":function($event){_vm.selectReport(
									$event,
									'PatientSurveyFormSummary',
									_vm.surveyFormSummary,
									() =>
										_vm.getReport(
											'surveyFormSummary',
											'PatientSurveyFormSummary',
											'SurveyFormSummary'
										)
								)}},model:{value:(_vm.surveyFormSummary),callback:function ($$v) {_vm.surveyFormSummary=$$v},expression:"surveyFormSummary"}})],1),_c('div',{ref:"surveyComparisonChart",staticClass:"btn-report",on:{"click":function($event){$event.preventDefault();return _vm.getReport(
								'surveyComparisonChart',
								'SurveyComparisonChart',
								'SurveyComparisonChart'
							)}}},[_c('v-checkbox',{attrs:{"color":"#008C89","label":"Survey Comparison Chart"},on:{"click":function($event){_vm.selectReport(
									$event,
									'SurveyComparisonChart',
									_vm.surveyComparisonChart,
									() =>
										_vm.getReport(
											'surveyComparisonChart',
											'SurveyComparisonChart',
											'SurveyComparisonChart'
										)
								)}},model:{value:(_vm.surveyComparisonChart),callback:function ($$v) {_vm.surveyComparisonChart=$$v},expression:"surveyComparisonChart"}})],1),(_vm.isSubscriptionIncludesBlood)?_c('div',{ref:"bloodPanelResults",staticClass:"btn-report",on:{"click":function($event){$event.preventDefault();return _vm.getReport(
								'bloodPanelResults',
								'BloodPanelPatient',
								'BloodPanelResults'
							)}}},[_c('v-checkbox',{attrs:{"color":"#008C89","label":"Blood Panel Results"},on:{"click":function($event){_vm.selectReport(
									$event,
									'BloodPanelPatient',
									_vm.bloodPanelResults,
									() =>
										_vm.getReport(
											'bloodPanelResults',
											'BloodPanelPatient',
											'BloodPanelResults'
										)
								)}},model:{value:(_vm.bloodPanelResults),callback:function ($$v) {_vm.bloodPanelResults=$$v},expression:"bloodPanelResults"}})],1):_vm._e(),(_vm.isSubscriptionIncludesBlood)?_c('div',{ref:"bloodPanelResultsWithAboveBelowOptimal",staticClass:"btn-report",on:{"click":function($event){$event.preventDefault();return _vm.getReport(
								'bloodPanelResultsWithAboveBelowOptimal',
								'BloodPanelPatientOptimals',
								'BloodPanelResultsWithAboveBelowOptimal'
							)}}},[_c('v-checkbox',{attrs:{"color":"#008C89","label":"Blood Panel Results w/ Optimals"},on:{"click":function($event){_vm.selectReport(
									$event,
									'BloodPanelPatientOptimals',
									_vm.bloodPanelResultsWithAboveBelowOptimal,
									() =>
										_vm.getReport(
											'bloodPanelResultsWithAboveBelowOptimal',
											'BloodPanelPatientOptimals',
											'BloodPanelResultsWithAboveBelowOptimal'
										)
								)}},model:{value:(_vm.bloodPanelResultsWithAboveBelowOptimal),callback:function ($$v) {_vm.bloodPanelResultsWithAboveBelowOptimal=$$v},expression:"bloodPanelResultsWithAboveBelowOptimal"}})],1):_vm._e(),(_vm.isSubscriptionIncludesBlood)?_c('div',{ref:"bloodPanelCompare",staticClass:"btn-report",on:{"click":function($event){$event.preventDefault();return _vm.getReport(
								'bloodPanelCompare',
								'BloodPanelComparison',
								'BloodPanelCompare'
							)}}},[_c('v-checkbox',{attrs:{"color":"#008C89","label":"Blood Panel Compare"},on:{"click":function($event){_vm.selectReport(
									$event,
									'BloodPanelComparison',
									_vm.bloodPanelCompare,
									() =>
										_vm.getReport(
											'bloodPanelCompare',
											'BloodPanelComparison',
											'BloodPanelCompare'
										)
								)}},model:{value:(_vm.bloodPanelCompare),callback:function ($$v) {_vm.bloodPanelCompare=$$v},expression:"bloodPanelCompare"}})],1):_vm._e()])],1)],1),_c('v-col',{staticStyle:{"max-width":"74%"},attrs:{"cols":"9"}},[_c('v-card',{staticStyle:{"height":"704px"}},[(
						_vm.dialogs.openReportPdfView &&
						_vm.dialogs.openReportPdfView.isOpen &&
						_vm.initialDoc
					)?_c('ReportView',{attrs:{"disabledSpanish":true,"activeLanguage":_vm.activeLanguage,"initialDoc":_vm.initialDoc,"isMultipleReports":!!_vm.arrayOfSelectedReports.length},on:{"switchLanguage":_vm.switchLanguage,"sendSurveyFormToPatient":_vm.sendToPatientEmail,"downloadMultipleReports":_vm.downloadMultipleReports}}):_c('div',{staticStyle:{"text-align":"center"}},[_c('h3',{staticClass:"pt-10",staticStyle:{"color":"rgba(0, 0, 0, 0.6)"}},[_vm._v(" Please select report ")])]),(_vm.loading)?_c('div',{staticClass:"loading d-flex justify-center px-2 py-2 align-center"},[_c('v-progress-circular',{attrs:{"indeterminate":"","color":"#008C89"}})],1):_vm._e()],1)],1)],1),_c('div',{staticClass:"custom-alert"},[_c('v-alert',{attrs:{"color":!_vm.isInvalidEmail ? '#7ac1fb' : 'rgb(207, 70, 85)',"dismissible":"","type":"info"},model:{value:(_vm.alertIsOpen),callback:function ($$v) {_vm.alertIsOpen=$$v},expression:"alertIsOpen"}},[_c('span',{staticStyle:{"font-size":"14px"}},[_vm._v(_vm._s(_vm.popupMessage))])])],1),(_vm.dialogs.confirm.isOpen)?_c('ConfirmDialog',{attrs:{"callback":_vm.emailCallback}}):_vm._e()],1)
}
var staticRenderFns = []

export { render, staticRenderFns }