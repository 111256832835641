<template>
  <div class="checkbox">
    <v-checkbox v-bind="$props" v-on="checkboxListeners" />
  </div>
</template>

<script>
export default {
  name: 'checkbox',
  props: {
    bgDefault: Boolean,
    color: String,
    dark: Boolean,
    disabled: Boolean,
    hint: String,
    inputValue: Boolean,
    label: String,
    large: Boolean,
    value: Boolean,
    rules: Array
  },
  computed: {
    checkboxListeners: function () {
      var vm = this
      return Object.assign({},
        this.$listeners,
        {
          change: function (event) {
            vm.$emit('change', event)
          }
        }
      )
    }
  }
}
</script>

<style lang="scss" scoped>
  .checkbox {
    display: flex;
    align-items: center;
    ::v-deep .v-input {
      padding: 0;
      margin: 0;
      .v-icon {
        font-size: 23px !important;
      }
    }
  }
</style>
