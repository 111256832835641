import Vue from 'vue'

const state = () => ({
  brands: []
})

const getters = { }

const actions = {
  async getBrands ({ commit }) {
    const result = await Vue.$http.get('/brands/dropdown')
    if (result?.status === 200) {
      commit('SET_DATA', ['brands', result.data?.record?.$values])
      return true
    } else if (result?.status === 400) {
      return result
    }
  },
  async createBrand({ commit }, params) {
    const result = await Vue.$http.post('/brands/create', { ...params })
    if (result?.status === 200) {
      return result.data.record
    } else if (result?.status === 400) {
      return result
    }
  },
  async selectBrandVendor({ commit }, params) {
    const result = await Vue.$http.post('/Organization/brand-vendors', { ...params })
    if (result?.status === 200) {
      return result.data.record
    } else if (result?.status === 400) {
      return result
    }
  }
}

const mutations = {
  SET_DATA: (state, payload) => {
    const [property, value] = payload
    Vue.set(state, property, value)
  }
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}
