<template>
  <div class="chat-page" v-if="userInfo">
    <div class="chat" ref="messageContainer" @scroll="throttleScroll">
      <div v-for="(messages, prop) in groupMessagesByDate" :key="prop">
        <div class="date-line">
          <span>{{$moment(prop).format('ll')}}</span>
        </div>
        <Message
          v-for="message in messages"
          :key="message.id"
          :messageData="message"
          :owner="userInfo.userId === message.srcMemberId"
          :avatarsAndLogos="avatarsAndLogos"
        />
      </div>
    </div>
    <div class="chat-form">
      <ChatForm
        @sendNewMessage="sendNewMessage"
        @markAsReadMessage="markAsReadMessage"
      />
    </div>
  </div>
</template>

<script>
import Message from '@/components/elements/Message'
import ChatForm from '@/components/elements/ChatForm'
import ChatHub from '@/chatHub'
import { throttle } from 'lodash'
import { mapActions, mapState } from 'vuex'

export default {
  name: 'chat',
  components: { Message, ChatForm },
  data: () => ({
    limitMessage: 10,
    skipMessage: 0
  }),
  mounted () {
    window.addEventListener('scroll', this.handleScrollChat)
    this.loadData()
    ChatHub.client.on('ReceiveUnreadMessageCountUpdate', (data) => {
      console.log('ReceiveUnreadMessageCountUpdate', data)
      this.$store.commit('chat/UPDATE_TOTAL_COUNT_UNREAD_MESSAGE', data)
    })
    ChatHub.client.on('ReceiveMessageUpdate', (data) => {
      console.log('ReceiveMessageUpdate', data)
      const messageExist = this.allMessages.find(message => message.id === data.id)
      if (!messageExist) {
        console.log('PUSH_NEW_MESSAGE')
        this.$store.commit('chat/PUSH_NEW_MESSAGE', data)
      }
      this.$nextTick(() => this.scrollDown())
      if (messageExist) {
        console.log('UPDATE_MESSAGE')
        this.$store.commit('chat/UPDATE_MESSAGE', data)
      }
    })
    // ChatHub.client.onclose(function () {
    //   console.log('CHAT HUB WAS CLOSED')
    //   setTimeout(function () {
    //     ChatHub.client.start()
    //   }, 1500) // Restart connection after 1.5 seconds
    // })
  },
  beforeDestroy () {
    window.removeEventListener('scroll', this.handleScrollChat)
    this.$store.state.chat.messages = []
  },
  methods: {
    ...mapActions('chat', ['getMessages', 'createMessage', 'markAsReadMsg']),
    loadData () {
      this.getMessages({ additionalParam: `?skip=${this.skipMessage}&limit=${this.limitMessage}&memberId=${this.$route.params.id}` }).then(() => {
        if (this.allMessages.length > 0) {
          this.scrollDown()
        }
      })
    },
    markAsReadMessage () {
      console.log('markAsReadMessage')
      const unReadMessages = this.allMessages.filter(message => {
        if (message.dstMemberId === this.userInfo.userId && !message.isRead) {
          return message
        }
      }).map(item => item.id)
      this.markAsReadMsg({ IDs: unReadMessages })
      // if (this.dashboardInfo.patient.id !== lastMessage[this.allMessages.length - 1].srcMemberId) {
      //   this.markAsReadMsg({ id: lastMessage[this.allMessages.length - 1].id })
      // }
    },
    sendNewMessage (text) {
      if (text.trim()) {
        const newMessage = {
          dstMemberId: this.$route.params.id,
          dstMemberType: 2,
          messageType: 0,
          message: text,
          extra: ''
        }
        this.createMessage(newMessage).then(() => {
          this.scrollDown()
        })
        // ChatHub.client.invoke('SendMessage')
      }
    },
    scrollDown () {
      this.$nextTick(() => {
        if (this.$refs.messageContainer) { // && this.skipMessage === 0
          const container = this.$refs.messageContainer
          container.scrollTop = container.scrollHeight
        }
      })
    },
    throttleScroll: throttle(function (event) {
      this.handleScrollChat(event)
    }, 1000),
    handleScrollChat (event) {
      if (event.target.scrollTop < 40) {
        this.skipMessage += 10
        this.getMessages({ additionalParam: `?skip=${this.skipMessage}&limit=10` })
      }
    }
  },
  computed: {
    ...mapState({
      patients: state => state.patients.patient,
      allMessages: state => state.chat.messages,
      userInfo: state => state.userProfile
    }),
    groupMessagesByDate () {
      return this._.groupBy(this.allMessages, 'customDate')
    },
    avatarsAndLogos () {
      const obj = {}
      obj[this.userInfo.userId] = this.userInfo.defaultProfileIcon
      // obj[this.patients.organization.id] = this.dashboardInfo.organization.logoSmall
      obj[this.patients.id] = this.patients.profileAvatar
      return obj
    }
  },
  watch: {
    // allMessages (messages) {
    //   if (messages.length > 0) {
    //     this.scrollDown()
    //   }
    // }
  }
}
</script>

<style scoped lang="scss">
.chat-page {
  //border: 1px solid grey;
  padding: 0 16px;
  background: #F7FAFC;
  min-height: calc(100vh - 121px);
  position: relative;
  overflow: hidden;

  .chat {
    position: absolute;
    top: 0;
    right: 0;
    left: 0;
    bottom: 80px;
    padding: 1rem;
    overflow-y: auto;
    .date-line {
      width: 100%;
      text-align: center;
      border-bottom: 1px solid #A0AEC0;
      line-height: 0.1em;
      margin: 10px 0 20px;
      span {
        background: #f7fafc;
        padding: 0 10px;
        color: #A0AEC0;
        font-weight: 500;
        font-size: 12px;
        text-align: center;
      }
    }
  }

  .chat-form {
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    padding: 1rem;
    height: 80px;
    background: #f1f3f4;
  }
  ::-webkit-scrollbar {
    width: 5px;
  }
  ::-webkit-scrollbar-track {
    background: #c3d2e5;
  }
  ::-webkit-scrollbar-thumb {
    background: red;
  }
}
</style>
