import Vue from 'vue'

const state = () => ({
  bloodMarkersItems: [],
  bloodData: {},
})

const getters = { }

const actions = {
  async getBloodMarkers ({ commit }, { id, groupingType }) {
    const result = await Vue.$http.post(`/Blood/tests/${id}/details`, {
      bloodTestId: id,
      groupingType
    })
    if (result?.status === 200) {
      commit('SET_DATA', ['bloodMarkersItems', result.data?.record?.bloodGroups.$values])
      commit('SET_DATA', ['bloodData', result.data?.record])
      return true
    } else if (result?.status === 400) {
      return result
    }
  },
  async updateBloodMarkers ({ commit }, { id, params }) {
    const result = await Vue.$http.post(`/Blood/tests/${id}/values`, { ...params })
    if (result?.status === 200) {
      commit('UPDATE_DATA_POINT', { groupId: result.data?.record.groupId, markerId: params.markerId, obj: result.data?.record })
      return result.data?.record
    } else if (result?.status === 400) {
      return result
    }
  },
  async enableOrDisableBloodFasting ({ commit }, { id, isEnableString = false }) {
    const result = await Vue.$http.post(`/Blood/tests/${id}/fasting/${isEnableString ? 'enable' : 'disable'}`)
    if (result?.status === 200) {
      return true
    } else if (result?.status === 400) {
      return result
    }
  },
  async updateBloodTestDate({ commit }, { id, params }) {
    const result = await Vue.$http.put(`/Blood/tests/${id}/date`, { ...params })
    if (result?.status === 200) {
      commit('UPDATE_BLOOD_DATE', { bloodTestDate: result.data?.record.bloodTestDate })
      return true
    } else if (result?.status === 400) {
      return result
    }
  },
  async createBrand({ commit }, params) {
    const result = await Vue.$http.post('/brands/create', { ...params })
    if (result?.status === 200) {
      return result.data.record
    } else if (result?.status === 400) {
      return result
    }
  },
  async selectBrandVendor({ commit }, params) {
    const result = await Vue.$http.post('/Organization/brand-vendors', { ...params })
    if (result?.status === 200) {
      return result.data.record
    } else if (result?.status === 400) {
      return result
    }
  }
}

const mutations = {
  SET_DATA: (state, payload) => {
    const [property, value] = payload
    Vue.set(state, property, value)
  },
  UPDATE_DATA_POINT: (state, payload) => {
    if (state.bloodMarkersItems.length === 1) {
      const indexMarker = state.bloodMarkersItems[0].markers.$values.findIndex(marker => marker.id === payload.markerId)
      Object.assign(state.bloodMarkersItems[0].markers.$values[indexMarker], { ...payload.obj })
    } else {
      const indexGroup = state.bloodMarkersItems.findIndex(group => group.id === payload.groupId)
      const indexMarker = state.bloodMarkersItems[indexGroup].markers.$values.findIndex(marker => marker.id === payload.markerId)
      Object.assign(state.bloodMarkersItems[indexGroup].markers.$values[indexMarker], { ...payload.obj })
    }
  },
  UPDATE_BLOOD_DATE: (state, payload) => {
    state.bloodData.bloodTestDate = payload.bloodTestDate
  }
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}
