<template>
  <div class="leads">
    <div class="main-row">
      <div class="label d-flex align-center mr-10">Patient Generator QR Code and URL Link</div>
    </div>
    <v-container fluid>
      <v-card flat>
        <v-row>
          <v-col cols="5">
            <v-row>
              <v-col cols="4">
                <v-row>
                  <v-col cols="12">
                    <Img
                      :src="leadsInfo.qrImageData"
                      width="100%" />
                  </v-col>
                </v-row>
                <v-row>
                  <v-col cols="12">
                    Share QR Code
                  </v-col>
                </v-row>
              </v-col>
              <v-col cols="8">
                <v-row>
                  <v-col cols="12">
                    Scroll to increase the output QR image size
                  </v-col>
                </v-row>
                <v-row>
                  <v-col cols="12">
                    <v-slider
                      v-model="qrCodeSize"
                      hide-details
                      :max="4000"
                      :min="400"
                      :thumb-label="true"
                      thumb-size="28"
                      color="#008c89"
                      :step="100"
                    >
                      <template v-slot:prepend>
                        400px
                      </template>
                      <template v-slot:append>
                        4000px
                      </template>
                    </v-slider>
                  </v-col>
                </v-row>
                <v-row>
                  <v-col cols="12">
                    You can download the QR code shown or use the URL as a link within your marketing or advertising. You may also customize the color, design, etc., of the QR code by using the URL link with online third-party QR generators.
                  </v-col>
                </v-row>
                <v-row>
                  <v-col cols="12" class="d-flex justify-end">
                    <Button btn-bg="dark-green"
                            @click.native="downloadQrImage"
                            :styles="{'border-radius': '8px', 'margin-left': '5px', 'color': 'white'}">
                      <span class="ml-2">Download</span>
                    </Button>
                  </v-col>
                </v-row>
              </v-col>
            </v-row>
<!--            <v-row>
              <v-col cols="12">
                Use following link to invite potential leads for the Patient Portal
              </v-col>
            </v-row>-->
            <v-row>
              <v-col cols="8" class="d-flex justify-start">
                <Input outlined
                       placeholder="Url"
                       radius="8"
                       :value="leadsInfo.urlKey"
                       style="width: 22vw"
                />
              </v-col>
              <v-col cols="4" class="d-flex justify-end">
                <Button btn-bg="dark-green"
                        @click.native="copyLink"
                        :styles="{'border-radius': '8px', 'margin-left': '5px', 'color': 'white'}">
                  <span class="ml-2">
                    <v-icon>mdi-layers-outline</v-icon>
                    Copy
                  </span>
                </Button>
              </v-col>
            </v-row>
          </v-col>
        </v-row>
      </v-card>
    </v-container>
  </div>
</template>

<script>
import Image from '@/mixins/Image'
import Button from '@/components/elements/Button'
import Input from '@/components/elements/Input'
import { mapActions, mapState } from 'vuex'
export default {
  name: 'tabLeads',
  mixins: [Image],
  components: { Button, Input },
  data: () => ({
    qrCodeSize: 1
  }),
  mounted () {
    this.getLeadsInfo({ baseUrl: 'https://portal.kliksoft.services' })
  },
  methods: {
    ...mapActions('leads', ['getLeadsInfo', 'increaseQrImage', 'downloadQr']),
    async downloadQrImage () {
      const res = await this.downloadQr({ size: this.qrCodeSize })
      const blob = new Blob([res.data], { type: res.headers['content-type'] })
      const url = window.URL.createObjectURL(blob)
      const link = document.createElement('a')
      document.getElementsByTagName('body')[0].appendChild(link)
      link.href = url
      link.download = 'QR.png'
      link.click()
      document.getElementsByTagName('body')[0].removeChild(link)
    },
    copyLink () {
      const textArea = document.createElement('textarea')
      textArea.value = this.leadsInfo.urlKey
      document.body.appendChild(textArea)
      textArea.select()
      document.execCommand('copy')
      textArea.remove()
    }
  },
  computed: {
    ...mapState({
      leadsInfo: state => state.leads.leadsInfo
    })
  }
  // watch: {
  //   qrCodeSize (val) {
  //     if (val !== 0) {
  //       this.increaseQrImage({ size: val })
  //     }
  //   }
  // }
}
</script>

<style scoped lang="scss">
.leads {
  ::v-deep .v-card {
    padding: 16px;
    border-radius: 16px;
    background: white!important;
  }
  .label {
    color: #425466;
    font-size: 20px;
    font-weight: 600;
  }
  .main-row {
    display: flex;
    flex-wrap: wrap;
    width: 100%;
  }
  .title {
    font-weight: 600;
    font-size: 28px;
    text-align: center;
    color: #27272E;
  }
}
</style>
