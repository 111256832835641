import Vue from 'vue'
import Vuex from 'vuex'

import createPersistedState from 'vuex-persistedstate'
import router from '@/router/index'

import dialogs from '@/store/modules/dialogs'
import defaultIconsState from '@/store/modules/defaultIconsState'
import organizations from '@/store/modules/organizations'
import patients from '@/store/modules/patients'
import visits from '@/store/modules/visits'
import results from '@/store/modules/results'
import supplements from '@/store/modules/supplements'
import surveys from '@/store/modules/surveys'
import chat from '@/store/modules/chat'
import reports from '@/store/modules/reports'
import charts from '@/store/modules/charts'
import protocols from '@/store/modules/protocols'
import subscriptions from '@/store/modules/subscriptions'
import leads from '@/store/modules/leads'
import receipts from '@/store/modules/receipts'
import brands from '@/store/modules/brands'
import products from '@/store/modules/products'
import blood from '@/store/modules/blood'
import * as Sentry from '@sentry/browser'

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    messages: null,
    settings: null,
    pageSettings: null,
    userProfile: null,
    organization: null,
    defaultIconsState: null,
    subscription: null,
    isAuth: localStorage.getItem('vuex') ? JSON.parse(localStorage.getItem('vuex')).isAuth : false,
    token: localStorage.getItem('vuex') ? JSON.parse(localStorage.getItem('vuex')).token : '',
    drawerState: true,
    permissions: ['ClinicAdmin', 'Doctor', 'Patient', 'SystemAdmin']
  },
  getters: {
    BUILD_URL_PARAMS: () => (params, isQmark = true) => {
      let paramsStr = (isQmark) ? '?' : ''
      if (params) {
        for (const key in params) {
          if (Object.prototype.hasOwnProperty.call(params, key)) {
            let k = key
            if (key === 'limit' || key === 'page' || key === 'offset') {
              k = `pagination[${key}]`
            }
            paramsStr += `${k}=${params[key]}&`
          }
        }
      } else {
        paramsStr = ''
      }

      return paramsStr.substring(paramsStr.length - 1, -1)
    }
  },
  mutations: {
    SAVE_TOKEN: (state, payload) => {
      Vue.set(state, 'token', payload)
    },
    SAVE_PROFILE_DATA: (state, payload) => {
      Vue.set(state, 'userProfile', payload)
      Sentry.setUser({
        id: payload.userId,
        username: payload.userName
      })
    },
    SAVE_ORGANIZATION_DATA: (state, payload) => {
      Vue.set(state, 'organization', payload)
    },
    SAVE_SUBSCRIPTION_DATA: (state, payload) => {
      Vue.set(state, 'subscription', payload)
    },
    UPDATE_DATA: (state, payload) => {
      state[payload.state][payload.key] = payload.value
    },
    SET_DATA: (state, payload) => {
      const [property, value] = payload
      Vue.set(state, property, value)
    },
    SET_PAGE_SETTINGS: (state, payload) => {
      Vue.set(state, 'pageSettings', payload)
    },
    SET_MESSAGE: (state, payload) => {
      Vue.set(state, 'messages', payload)
      setTimeout(() => {
        Vue.set(state, 'messages', null)
      }, 5000)
    }
  },
  actions: {
    async registration ({ commit }, params) {
      const result = await Vue.$http.post('/Auth/Register', { ...params })
      if (result?.status === 200) {
        console.log(`Respons: The user is registerd; Status: ${result?.status}`)
        // router.push('/').catch(() => {})
      }
      return result
    },
    async login ({ commit }, params) {
      const result = await Vue.$http.post('/Auth/Login', { ...params, source: 'dp' })
      if (result?.status === 200) {
        const { token, userProfile, organization, subscription } = result.data
        commit('SAVE_TOKEN', token)
        commit('SAVE_PROFILE_DATA', userProfile)
        commit('SAVE_ORGANIZATION_DATA', organization)
        commit('SAVE_SUBSCRIPTION_DATA', subscription)
        if (subscription.showPopup) {
          commit('dialogs/TOGGLE_DIALOG', { name: 'trialSubscriptionDialog', isOpen: true }, { root: true })
        }
        if (subscription.forcePayment) {
          commit('dialogs/TOGGLE_DIALOG', { name: 'forcingSubscriptionDialog', isOpen: true }, { root: true })
        }
        console.log(`Response: The user is authenticated; Status: ${result?.status}`)
        if (result.data.userProfile.firstLogin) {
          router.push({ name: 'Settings' }).catch(() => {})
        } else {
          if (localStorage.getItem('firstPage')) {
            router.push({ path: localStorage.getItem('firstPage') }).catch(() => {})
            localStorage.removeItem('firstPage')
          } else {
            router.push({ name: 'Home' }).catch(() => {})
          }
        }
        return result?.data
      } else {
        Sentry.setUser({})
        return result?.data
      }
    },
    async confirmEmail ({ commit }, params) {
      const result = await Vue.$http.post('/Auth/ConfirmEmail', { ...params })
      if (result?.status === 200) {
        const { token, userProfile, organization, subscription } = result?.data
        commit('SAVE_TOKEN', token)
        commit('SAVE_PROFILE_DATA', userProfile)
        commit('SAVE_ORGANIZATION_DATA', organization)
        commit('SAVE_SUBSCRIPTION_DATA', subscription)
        if (subscription.showPopup) {
          commit('dialogs/TOGGLE_DIALOG', { name: 'trialSubscriptionDialog', isOpen: true }, { root: true })
        }
        // if (userProfile.userType === 'ClinicAdmin') {
        //   commit('dialogs/TOGGLE_DIALOG', { name: 'modalBrandVendorSelection', isOpen: true, title: 'Results Calculator Option', canCloseManually: false }, { root: true })
        // }
        console.log(`Respons: The email is confirmed; Status: ${result?.status}`)
        router.push('/settings').catch(() => {})
      }
    },
    async logout ({ commit, state }) {
      const result = await Vue.$http.post('/Auth/Logout')
      if (result?.status === 200) {
        console.log(`Respons: You are logout; Status: ${result?.status}`)
        router.push('/sign-in').catch(() => {})
        sessionStorage.removeItem('selectedPage')
        Sentry.setUser({})
        localStorage.removeItem('quickFilterIndex')
        state.token = ''
        commit('SAVE_TOKEN', '')
        localStorage.removeItem('vuex')
      }
    },
    async appSettings ({ commit }) {
      const result = await Vue.$http.get('/Helper/AppSettings')
      if (result?.status === 200) {
        commit('SET_DATA', ['settings', result?.data?.record])
      }
    },
    async forgotPassword ({ _ }, email) {
      return new Promise((resolve) => {
        Vue.$http.put(`/Auth/ForgotPassword?email=${email}&src=dp`).then((result) => {
          if (result?.status === 200) {
            resolve(result?.status === 200)
            console.log(`Respons: Recovery password request is created: ${result?.status}`)
          }
        })
      })
    },
    async passwordUpdate ({ commit }, params) {
      console.log('passwordUpdate', params)
      const result = await Vue.$http.put('/Auth/PasswordUpdate', params)
      if (result?.status === 200) {
        const { token, userProfile, organization, subscription } = result.data
        commit('SAVE_TOKEN', token)
        commit('SAVE_PROFILE_DATA', userProfile)
        commit('SAVE_ORGANIZATION_DATA', organization)
        commit('SAVE_SUBSCRIPTION_DATA', subscription)
        // if (userProfile.firstLogin && !userProfile.legacy) {
        //   commit('dialogs/TOGGLE_DIALOG', { name: 'modalBrandVendorSelection', isOpen: true, title: 'Results Calculator Option', canCloseManually: false }, { root: true })
        // }
        console.log(`Respons: Password was updated: ${result?.status}`)
        router.push('/').catch(() => {})
        return true
      }
    },
    async passwordConfirmation ({ _ }, params) {
      const result = await Vue.$http.get('/Auth/PasswordConfirmation?userId=' + params.id + '&token=' + params.token + '&type=' + params.type) // params.type
      if (result?.status === 200) {
        console.log(`Respons: Password was confirmed: ${result?.status}`)
      }
      return result?.data
    },
    async refreshToken ({ commit }) {
      const result = await Vue.$http.post('/Auth/token/refresh')
      if (result?.status === 200) {
        const { token } = result.data
        commit('SAVE_TOKEN', token)
        return result?.data
      }
    },
    async authCheck ({ commit }, { userId, token }) {
      const result = await Vue.$http.put(`/auth/check`, {
        userId,
        token
      })
      if (result?.status === 200) {
        const { token, userProfile, organization, subscription } = result.data
        commit('SAVE_TOKEN', token)
        commit('SAVE_PROFILE_DATA', userProfile)
        commit('SAVE_ORGANIZATION_DATA', organization)
        commit('SAVE_SUBSCRIPTION_DATA', subscription)
        if (subscription.showPopup) {
          commit('dialogs/TOGGLE_DIALOG', { name: 'trialSubscriptionDialog', isOpen: true }, { root: true })
        }
        if (subscription.forcePayment) {
          commit('dialogs/TOGGLE_DIALOG', { name: 'forcingSubscriptionDialog', isOpen: true }, { root: true })
        }
        console.log(`Response: The user is authenticated; Status: ${result?.status}`)
        if (result.data.userProfile.firstLogin) {
          router.push({ name: 'Settings' }).catch(() => {})
        } else {
          if (localStorage.getItem('firstPage')) {
            router.push({ path: localStorage.getItem('firstPage') }).catch(() => {})
            localStorage.removeItem('firstPage')
          } else {
            router.push({ name: 'Home' }).catch(() => {})
          }
        }
        return result?.data
      } else {
        Sentry.setUser({})
        return result?.data
      }
    }
  },
  modules: {
    organizations,
    supplements,
    patients,
    results,
    surveys,
    visits,
    dialogs,
    chat,
    reports,
    charts,
    protocols,
    subscriptions,
    leads,
    receipts,
    brands,
    products,
    blood,
    defaultIconsState
  },
  plugins: [createPersistedState({ paths: ['token', 'isAuth', 'userProfile', 'organization', 'subscription', 'settings'] })]
  // plugins: [createPersistedState()]
})
