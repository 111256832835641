<template>
  <div class="add-patient">
    <Dialog width="568"><!--scrollable-->
      <template slot="title">
        <span>
          {{dialog.title}}
        </span>
      </template>
      <template slot="body">
        <v-form ref="form" v-model="valid" lazy-validation id="addForm" autocomplete="off">
          <Tabs color="#008C89" :items="tabs" @update:tab="tabIndex = $event" style="margin-top: -4px"></Tabs><!--fixed-tabs-->
          <template v-if="tabIndex == 0">
            <v-row style="margin-top: 2px!important;" class="mt-0">
              <v-col class="pb-0 pt-0">
                <div class="label">First name<span class="required-field">*</span></div>
                <Input
                  outlined
                  autocomplete="off"
                  placeholder="First name"
                  radius="8"
                  :isAutofocus="isAutofocusFirstTab"
                  @input="form.firstName=$event"
                  :value="form.firstName"
                  :rules="[filedIsRequired.required('First Name')]"
                />
              </v-col>
            </v-row>
            <v-row class="mt-0">
              <v-col class="pb-0 pt-1">
                <div class="label">Last name<span class="required-field">*</span></div>
                <Input
                  outlined
                  autocomplete="off"
                  placeholder="Last name"
                  radius="8"
                  @input="form.lastName=$event"
                  :value="form.lastName"
                  :rules="[filedIsRequired.required('Last Name')]"
                />
              </v-col>
            </v-row>
            <v-row class="mt-0">
              <v-col class="pb-0 pt-1">
                <div class="label">Email</div>
                <Input
                  outlined
                  autocomplete="off"
                  placeholder="Email"
                  radius="8"
                  :disabled="dialog.name === 'editPatient' ? form.registerPortal : false"
                  @input="form.email=$event"
                  :value="form.email"
                  @focusout="checkEmail"
                  :rules="loginEmailRules"
                />
              </v-col>
              <v-col cols="4" class="pb-0 mt-1 pt-1 d-flex align-center" v-if="!isValidEmail">
                <div v-if="!isValidEmail">
<!--                  <v-progress-circular
                    class="mr-2"
                    indeterminate
                    color="error"
                  ></v-progress-circular>-->
                  <span class="red--text">Patient with this email already exists</span>
                </div>
              </v-col>
            </v-row>
            <v-row class="mt-0">
              <v-col class="pb-0 pt-1">
                <div class="label">Assign to practitioner<span class="required-field">*</span></div>
                <Select
                  v-if="doctors"
                  outlined
                  itemText="name"
                  itemValue="id"
                  radius="8"
                  :items="doctors"
                  :value="doctors.length === 1 ? doctors[0].id : form.doctorAssignedId"
                  :disabled="userProfile.UserType === 'Doctor'"
                  @change="form.doctorAssignedId = $event"
                /><!--doctors[0]-->
              </v-col>
            </v-row>
            <v-row class="mt-4 mb-0">
              <v-col class="pb-0 pt-0">
                <div class="label">Height (inch)</div>
                <Input
                  outlined
                  type="Number"
                  autocomplete="off"
                  placeholder="Height"
                  radius="8"
                  :min="0"
                  @input="form.height=+$event"
                  :value="form.height"
                /><!--:rules="[filedIsRequired.required('Height')]"-->
              </v-col>
              <v-col class="pb-0 pt-0">
                <div class="label">Weight (lb)</div>
                <Input
                  outlined
                  type="Number"
                  autocomplete="off"
                  placeholder="Weight"
                  radius="8"
                  :min="0"
                  @input="form.weight=+$event"
                  :value="form.weight"
                /><!--:rules="[filedIsRequired.required('Weight')]"-->
              </v-col>
            </v-row>
            <v-row class="mt-0">
              <v-col class="mt-0 pb-0 pt-0">
                <div class="label">Gender<span class="required-field">*</span></div>
<!--                <custom-switch :btns="genderBtns" :value="radioGroup" @update:switch="radioGroup=$event" />-->
                <div class="d-flex add-patient__gender">
                  <v-checkbox
                    class="pt-1 pb-1"
                    color="#008C89"
                    v-model="form.sex"
                    value="Male"
                    @change="genderIsTouched = true"
                    label="Male"/>
                  <v-checkbox
                    color="#008C89"
                    class="pt-1 pb-1"
                    v-model="form.sex"
                    value="Female"
                    @change="genderIsTouched = true"
                    label="Female"/>
                </div>
                <div v-if="!form.sex && genderIsTouched" style="color: #ff5252;font-size: 12px;hyphens: auto;word-wrap: break-word;word-break: break-word;line-height: 12px">
                  This field is required
                </div>
              </v-col>
              <v-col class="mt-0 pb-0 pt-0" style="position: relative">
                <div class="label">Birthdate</div><!--<span class="required-field">*</span>-->
                <PhoneInput
                  radius="8"
                  :mask="'##/##/####'"
                  :placeholder="' '"
                  :value="form.customBirthDate"
                  @input="form.birthDate=$event"
                />
                <v-icon color="#008C89" @click="openDatePickerBirthDate" style="z-index: 9;cursor: pointer;position: absolute;right: 17px;top: 30px;">mdi-calendar-blank</v-icon>
                <datepicker
                  ref="birthdateInput"
                  style="position: absolute;right: 12px;opacity: 0;width: 48%;top: 21px;"
                  @date:update="form.birthDate = $moment($event).format('MM/DD/YYYY'), form.customBirthDate=form.birthDate"
                  :max="fiveYearsAgo"
                  :showCurrent="fiveYearsAgo"
                  :value="form.birthDate && form.birthDate.substring(0, 10)"
                /><!--:rules="[filedIsRequired.required('Birth Date required')]" v-if="form.birthDate || dialog.name == 'addPatient'"-->
              </v-col>
            </v-row>
            <v-row class="mt-0">
              <v-col class="pb-0 mb-2 pt-1">
                <div class="label">Dietary</div>
                <div class="d-flex add-patient__dietary">
                  <v-checkbox
                    class="pt-1 pb-1"
                    color="#008C89"
                    v-model="form.vegetarian"
                    label="Vegetarian"/>
                  <v-checkbox
                    color="#008C89"
                    class="pt-1 pb-1"
                    v-model="form.glutenFree"
                    label="Gluten free"/>
                </div>
              </v-col>
            </v-row>
            <v-row class="mt-0" v-if="false">
              <v-col>
                <v-switch v-if="dialog.name == 'editPatient'"
                  label="Active"
                  :input-value="toggleActivate"
                  @change="toggleActivate=$event"
                />
              </v-col>
            </v-row>
          </template>
          <template v-else-if="tabIndex == 1">
            <v-row style="margin-top: 2px!important;" class="mt-0">
              <v-col cols="6" class="pb-0 pt-1">
                <div class="label">Cell Phone</div>
                <PhoneInput
                  :isAutofocus="isAutofocusSecondTab"
                  radius="8"
                  :value="form.cellPhone"
                  @input="form.cellPhone=$event"
                />
              </v-col>
              <v-col cols="6" class="pb-0 pt-1">
                <div class="label">Home Phone</div>
                <PhoneInput
                  radius="8"
                  :value="form.homePhone"
                  @input="form.homePhone=$event"
                />
              </v-col>
            </v-row>
            <v-row class="mt-0">
              <v-col cols="6" class="pb-0 pt-1">
                <div class="label">Work Phone</div>
                <PhoneInput
                  radius="8"
                  :value="form.workPhone"
                  @input="form.workPhone=$event"
                />
              </v-col>
              <v-col cols="6" class="pt-1 add-patient__language">
                <div class="label">Language</div>
                <v-checkbox
                  class="pt-1 pb-1"
                  color="#008C89"
                  v-model="form.spanish"
                  label="Spanish"/>
              </v-col>
            </v-row>
            <v-row class="mt-0">
              <v-col class="pb-0 pt-1">
                <div class="label pb-0">Address</div>
                <Input
                  outlined
                  autocomplete="off"
                  radius="8"
                  :value="form.line1"
                  @input="form.line1=$event"
                  />
              </v-col>
            </v-row>
            <v-row v-show="false" class="mt-0">
              <v-col cols="3" class="py-0 mb-2 d-flex align-center">Line 2</v-col>
              <v-col cols="9" class="py-0 mb-2">
                <Input outlined @input="form.line2=$event" :value="form.line2" autocomplete="off" />
              </v-col>
            </v-row>
            <v-row v-show="false" class="mt-0">
              <v-col cols="3" class="py-0 mb-2 Sd-flex align-center">Line 3</v-col>
              <v-col cols="9" class="py-0 mb-2">
                <Input outlined @input="form.line3=$event" :value="form.line3" autocomplete="off" />
              </v-col>
            </v-row>
            <v-row class="mt-0">
              <v-col cols="4" class="pb-0 pt-1">
                <div class="label">City</div>
                <Input
                  outlined
                  autocomplete="off"
                  radius="8"
                  :value="form.city"
                  @input="form.city=$event"
                />
              </v-col>
              <v-col cols="4" class="pb-0 pt-1">
                <div class="label">State</div>
                <Input
                  outlined
                  autocomplete="off"
                  radius="8"
                  :value="form.state"
                  @input="form.state=$event"
                />
              </v-col>
              <v-col cols="4" class="pb-0 pt-1">
                <div class="label">Zip</div>
                <PhoneInput outlined
                       radius="8"
                       placeholder="XXXXX"
                       :mask="'#####'"
                       :value="form.zip"
                       @input="form.zip=$event"
                />
              </v-col>
            </v-row>
            <v-row class="d-flex align-center mt-0">
              <v-col cols="8" class="pb-0 pt-1">
                <v-checkbox
                  color="#008C89"
                  v-model="useSalesTax"
                  label="Use Supplement Sales Tax %"
                ></v-checkbox>
              </v-col>
              <v-col cols="3" class="pb-0 pt-1">
                <Input
                  type="Number"
                  min="0"
                  max="100"
                  radius="8"
                  :disabled="!useSalesTax"
                  :value="form.salesTaxPercent"
                  @input="form.salesTaxPercent=+$event"
                />
              </v-col>
            </v-row>
            <v-row class="d-flex align-center mt-0">
              <v-col cols="8" class="pb-0 pt-1">
                <v-checkbox
                  color="#008C89"
                  v-model="useProductsSalesTaxPercent"
                  label="Use Non-Supplement Sales Tax %"
                ></v-checkbox>
              </v-col>
              <v-col cols="3" class="pb-0 pt-1">
                <Input
                  type="Number"
                  min="0"
                  max="100"
                  radius="8"
                  :disabled="!useProductsSalesTaxPercent"
                  :value="form.productsSalesTaxPercent"
                  @input="form.productsSalesTaxPercent=+$event"
                />
              </v-col>
            </v-row>
            <v-row class="d-flex align-center mt-0">
              <v-col cols="8" class="pb-0 pt-1">
                <v-checkbox
                  color="#008C89"
                  v-model="usePriceModifier"
                  label="Supplement Discount/Increase %"
                ></v-checkbox>
              </v-col>
              <v-col cols="3" class="pb-0 pt-1">
                <Input
                  radius="8"
                  type="Number"
                  :min="'-100'"
                  :max="100"
                  :disabled="!usePriceModifier"
                  :value="form.priceModifierPercent"
                  @input="form.priceModifierPercent=+$event"
                />
              </v-col>
            </v-row>
          </template>
          <template v-else>
            <template v-if="dialog.name === 'addPatient'">
              <!--NEW-->
              <v-row class="ml-2 mt-0 custom-checkbox color-red" v-if="subscriptionFeatures.features.$values.includes('Portal')">
                <v-col class="pb-0 mt-2 pt-1">
                  <div class="d-flex align-center add-patient__visit-invitation">
                    <v-checkbox
                      style="padding: 0"
                      color="#008C89"
                      autofocus
                      tabindex="0"
                      v-model="form.registerPortal"
                      label="If you want the Patient to complete surveys online or just want them to have Portal Access, check here to Invite Patient to the Portal."/>
                  </div>
                </v-col>
              </v-row>
              <br>
              <div style="width: 100%; border-bottom: 1px solid #9a9a9a70;"></div>
              <v-row class="ml-2 mt-2 mb-2">
                <v-col class="pb-0 mt-2 pt-1" style="font-weight: 600;text-align: center;font-size: 16px">
                  Evaluation Type (choose one)
                </v-col>
              </v-row>
              <v-row class="ml-2 mt-0 custom-checkbox mb-3" style="margin-top: -8px!important;">
                <v-col class="pb-0 mt-2 pt-1">
                  <div class="d-flex align-center justify-space-between add-patient__visit-invitation">
                    <v-checkbox
                      style="padding: 0"
                      color="#008C89"
                      v-model="form.none"
                      :class="{'disabled': form.none}"
                      label="None"/>
                    <v-checkbox
                      style="padding: 0"
                      color="#008C89"
                      v-model="form.visitInvitation"
                      :class="{'disabled': form.visitInvitation}"
                      label="Survey Evaluation"/>
                    <v-checkbox
                      style="padding: 0"
                      color="#008C89"
                      v-model="form.nonSurvey"
                      :class="{'disabled': form.nonSurvey}"
                      label="Non-Survey Evaluation"/>
                  </div>
                </v-col>
              </v-row>
              <!--NEW-->
<!--              <v-row class="ml-2 mt-0" style="margin-top: -8px!important;">
                <v-col class="pb-0 mt-2 pt-1">
                  <div class="d-flex align-center add-patient__visit-invitation">
                    <v-checkbox
                      style="padding: 0"
                      color="#008C89"
                      v-model="form.visitInvitation"
                      label="Invite to Evaluation"/>
&lt;!&ndash;                    <v-icon color="#BCB3E2">mdi-help-circle</v-icon>&ndash;&gt;
                  </div>
                </v-col>
              </v-row>-->
              <v-row class="align-center ml-2 mt-0">
                <v-col class="pb-0 pt pt-1">
                  <div class="add-patient__evaliation-date">
                    <div class="label">Add Evaluation Date</div>
                  </div>
                </v-col>
                <v-col class="pb-0 pt-1 d-flex align-end">
                  <datepicker
                    :min="nowDate"
                    :disabled="form.none"
                    v-model="form.plannedVisitDate"
                    @date:update="form.plannedVisitDate = $event" />
                </v-col>
              </v-row>
              <br>
              <div style="width: 100%; border-bottom: 1px solid #9a9a9a70;"></div>
              <br>
              <div v-if="(form.registerPortal || form.visitInvitation) && !form.none" class="d-flex align-center justify-end" style="cursor: pointer" @click="openPreviewEmail({ isPortal: true })"><v-icon>mdi-email-open</v-icon> Preview</div>
<!--              <v-row class="ml-2 mt-0">
                <v-col class="pb-0 pt-1">
                  <div class="label mb-1 d-flex justify-space-between">
                    <div>Optional Evaluation Notes</div>
                    <div class="d-flex align-center" style="cursor: pointer" @click="openPreviewEmail({ isPortal: false })"><v-icon>mdi-email-open</v-icon> Preview</div>
                  </div>
                  <Textarea
                    dense
                    auto-grow
                    :rows="2"
                    outlined
                    @input:text-area="form.visitInvitationMessage=$event"
                    :value="form.visitInvitationMessage"
                    :style="{'background': '#EDF2F7','padding':' 0 5px','border-radius': '12px'}"
                  />
&lt;!&ndash;                  <div class="mt-1 short-message">
                    <span>Patient will recieve email with <span class="bold">instruction</span></span>
                  </div>&ndash;&gt;
                </v-col>
              </v-row>
              <v-row class="ml-2 mt-4 d-flex align-center justify-space-between">
                <v-col class="pt-0 add-patient__survey-form">
                  <v-checkbox
                    style="padding: 4px 0"
                    color="#008C89"
                    v-model="form.addSurveyForm"
                    label="Add Survey Form to Email"/>
                </v-col>
                <v-col class="pt-0" cols="5">
                  <Select outlined
                          radius="8"
                          itemText="name"
                          itemValue="code"
                          :items="settingsForms"
                          :value="settingsForms[0]"
                          @change="changeSurveyForm($event)"
                  />
                </v-col>
              </v-row>-->
<!--              <v-row class="ml-2 mt-1 d-flex align-center justify-space-between">
                <v-col class="pt-0 add-patient__survey-form">
                  <v-checkbox
                    style="padding: 4px 0"
                    color="#008C89"
                    v-model="form.printSurveyForm"
                    label="Print Survey Form"/>
                </v-col>
                <v-col class="pt-0" cols="5">
                  <Select outlined
                          radius="8"
                          itemText="name"
                          itemValue="code"
                          :items="settingsForms"
                          :value="settingsForms[0]"
                          @change="form.printSurveyFormCode=$event"
                  />
                </v-col>
              </v-row>-->
<!--              <template v-if="subscriptionFeatures.features.$values.includes('Portal') || subscriptionFeatures.productName.includes('Trial')">
                <v-row class="ml-2 mt-0">
                  <v-col class="pb-0 mt-2">
                    <div class="d-flex align-center add-patient__register-portal">
                      <v-checkbox
                        style="padding: 0"
                        color="#008C89"
                        v-model="form.registerPortal"
                        label="Invite to the portal"/>
                      &lt;!&ndash;                    <v-icon color="#BCB3E2">mdi-help-circle</v-icon>&ndash;&gt;
                    </div>
                  </v-col>
                </v-row>
                <v-row class="mb-3 ml-2 mt-0">
                  <v-col class="pb-0 pt-1">
                    <div class="label mb-1 d-flex justify-space-between">
                      <div>Optional Portal Invite Note</div>
                      <div class="d-flex align-center" style="cursor: pointer" @click="openPreviewEmail({ isPortal: true })"><v-icon>mdi-email-open</v-icon> Preview</div>
                    </div>
                    <Textarea
                      dense
                      auto-grow
                      outlined
                      :rows="2"
                      @input:text-area="form.registerPortalMessage=$event"
                      :value="form.registerPortalMessage"
                      :style="{'background': '#EDF2F7','padding':' 0 5px','border-radius': '12px'}"
                    />
&lt;!&ndash;                    <div class="mt-1 short-message">
                      <span>Patient will recieve email with <span class="bold">instruction to register on Patient Portal</span></span>
                    </div>&ndash;&gt;
                  </v-col>
                </v-row>
              </template>-->
            </template>
          </template>
        </v-form>
        <div v-if="loading" class="loading d-flex justify-center px-2 py-2 align-center">
          <v-progress-circular
            indeterminate
            color="#008C89"
          ></v-progress-circular>
        </div>
      </template>
      <template slot="actions" class="pt-1">
        <div class="w100 d-flex justify-space-between pt-0 py-4 pl-3 pr-3 align-center">
          <div class="legend-errors">
            <span class="required-field">*</span> - <span class="info-text">Required fields</span>
          </div>
          <Button
            btn-bg="dark-green"
            @click.native="add"
            :disabled="!form.firstName || !form.lastName || customCheck() || !form.sex"
            v-if="dialog.name == 'addPatient'"><!--|| !form.email-->
            <span style="color: white">Add Patient</span>
          </Button>
          <Button btn-bg="dark-green" @click.native="update" v-if="dialog.name == 'editPatient'">
            <span style="color: white">Update</span>
          </Button>
        </div>
      </template>
    </Dialog>
    <preview-email-template v-if="dialog.name === 'previewEmailTemplateDialog'" />
  </div>
</template>

<script>
import { mapState, mapMutations, mapActions } from 'vuex'
import Validation from '@/mixins/Validation'
import Dialog from '@/components/layouts/dialog'
import Input from '@/components/elements/Input'
import Select from '@/components/elements/Select'
import PhoneInput from '@/components/elements/phone-input'
import datepicker from '@/components/elements/datepicker'
import Button from '@/components/elements/Button'
import Tabs from '@/components/layouts/Tabs'
// import customSwitch from '@/components/elements/custom-switch'
import Textarea from '@/components/elements/Textarea'
import previewEmailTemplate from '@/components/layouts/dialogs/preview-email-template'

const GENDER = [{ name: 'Male' }, { name: 'Female' }]

export default {
  name: 'add-patient',
  props: {
    query: {
      type: Object
    }
  },
  mixins: [Validation],
  components: {
    Dialog,
    Input,
    Select,
    datepicker,
    PhoneInput,
    Button,
    Tabs,
    Textarea,
    previewEmailTemplate
  },
  data: () => ({
    radioGroup: null,
    toggleActivate: true,
    isValidEmail: true,
    genderIsTouched: false,
    form: {
      customDate: null,
      firstName: '',
      lastName: '',
      email: null,
      doctorAssignedId: null,
      height: null,
      weight: null,
      sex: null,
      vegetarian: false,
      glutenFree: false,
      birthDate: null,
      customBirthDate: null,
      line1: null,
      line2: null,
      line3: null,
      line4: null,
      city: null,
      state: null,
      zip: null,
      homePhone: null,
      workPhone: null,
      cellPhone: null,
      number: null,
      spanish: false,
      none: true,
      visitInvitation: false,
      nonSurvey: false,
      plannedVisitDate: new Date(new Date().setDate(new Date().getDate() + 14)).toLocaleDateString('en-US'),
      registerPortal: true,
      visitInvitationMessage: null,
      registerPortalMessage: null,
      addSurveyForm: null,
      addSurveyFormCode: null,
      printSurveyForm: null,
      printSurveyFormCode: null,
      salesTaxPercent: 0,
      productsSalesTaxPercent: 0,
      priceModifierPercent: 0,
    },
    useSalesTax: false,
    useProductsSalesTaxPercent: false,
    usePriceModifier: false,
    tabIndex: 0,
    nowDate: new Date(new Date().setDate(new Date().getDate() + 1)).toISOString().slice(0, 10),
    fiveYearsAgo: new Date(new Date().setDate(new Date().getDate() + 1)).toISOString().slice(0, 10), // new Date(new Date().setFullYear(new Date().getFullYear() - 5)).toISOString().slice(0, 10),
    loading: false,
    isAutofocusFirstTab: true,
    isAutofocusSecondTab: false,
    isAutofocusThirdTab: false,
    // endDate: new Date(new Date().getFullYear(), new Date().getMonth() + 1, new Date().getDate()).toISOString().slice(0, 10)
  }),
  async created () {
    this.getDoctors()
    if (this.dialog.name === 'editPatient') {
      await this.getPatient({ id: this.dialog.id, urlParams: { includeVisitsHistory: true } })
      this.$set(this.$data, 'radioGroup', this.patient.sex === 'Female' ? 1 : 0)
      this.form = Object.assign({}, this.patient)
      this.form.customBirthDate = this.$moment(this.form.birthDate).format('MM/DD/YYYY')
      this.useSalesTax = this.patient.salesTaxPercent !== null ? 1 : 0
      this.useProductsSalesTaxPercent = this.patient.productsSalesTaxPercent !== null ? 1: 0
      this.usePriceModifier = this.patient.priceModifierPercent !== null ? 1 : 0
      this.form.doctorAssignedId = this.patient.doctorAssignedId
    }
  },
  mounted () {
    this.form.addSurveyFormCode = this.settingsForms[0].code
    this.isAutofocusFirstTab = false
  },
  methods: {
    ...mapMutations('dialogs', ['TOGGLE_DIALOG']),
    ...mapActions('patients', [
      'getDoctors',
      'addPatient',
      'validateEmail',
      'getPatients',
      'getPatient',
      'updatePatient'
    ]),
    openDatePickerBirthDate() {
      this.$refs.birthdateInput.menu = true
    },
    customCheck() {
      if (this.doctors.length === 1) {
        return false
      } else {
        return !this.form.doctorAssignedId
      }
    },
    async add () {
      this.loading = true
      if (this.validate()) {
        if (!this.useSalesTax) {
          this.form.salesTaxPercent = null
        }

        if (!this.useProductsSalesTaxPercent) {
          this.form.productsSalesTaxPercent = null
        }

        if (!this.usePriceModifier) {
          this.form.priceModifierPercent = null
        }

        if(!this.subscriptionFeatures.features.$values.includes('Portal')) {
          this.form.registerPortal = false
        }

        const form = Object.assign({}, this.form)

        delete form.customDate
        delete form.customBirthDate

        if (form.none) {
          form.visitInvitation = false
        }

        if (form.visitInvitation || form.nonSurvey) {
          form.visitInvitation = true
        }

        const res = await this.addPatient(Object.assign(form, { plannedVisitDate: this.$moment(form.plannedVisitDate).format('MM/DD/YYYY'), doctorAssignedId: this.doctors.length === 1 ? this.doctors[0].id : form.doctorAssignedId }))
        this.$emit('showPopup', { type: 'add-patient', data: res })
        // await this.getPatients(this.query)
      }
      this.loading = false
    },
    async update () {
      this.loading = true

      const isValid = await this.checkEmail();

      if(!isValid) {
        this.loading = false
        return
      }

      if (this.validate()) {
        if (!this.useSalesTax) {
          this.form.salesTaxPercent = null
        }
        if (!this.useProductsSalesTaxPercent) {
          this.form.productsSalesTaxPercent = null
        }
        if (!this.usePriceModifier) {
          this.form.priceModifierPercent = null
        }
        const form = Object.assign({}, this.form)
        delete form.customDate
        delete form.customBirthDate
        const res = await this.updatePatient({ id: this.dialog.id, form: form })
        this.$emit('showPopup', { type: 'edit-patient', data: res })
        await this.getPatients(this.query)
      }
      this.loading = false
    },
    changeSurveyForm (data) {
      if (data.code) {
        this.form.addSurveyFormCode = data.code
      } else {
        this.form.addSurveyFormCode = data
      }
    },
    async checkEmail () {
      const { email } = this.form
      const params = { email }
      if (this.dialog.name === 'addPatient') {
        params.patientId = ''
      } else {
        params.patientId = this.dialog.id
      }
      const res = await this.validateEmail(params)

      this.setDynamicValidationRules({
        loginEmailRules: [
          res.data.validationResults.isValid || res.data.message
        ]
      })

      return res?.data?.success

    },
    openPreviewEmail ({ isPortal }) {
      this.TOGGLE_DIALOG({ name: 'previewEmailTemplateDialog', isOpen: true, title: '', data: this.form, isPortal, isPreviewInvitation: true })
    }
  },
  computed: mapState({
    dialog (state) {
      const modalName = this._.findKey(state.dialogs, ['isOpen', true])
      return state.dialogs[modalName]
    },
    doctors: state => state.patients?.doctors?.$values,
    patient: state => state.patients?.patient,
    genderBtns: () => GENDER,
    tabs () {
      if (this.dialog.name === 'addPatient') {
        return [
          { title: 'Personal Details', component: 'Personal Detais' },
          { title: 'Contact and Price Details', component: 'Contact Details' },
          { title: 'Invites', component: 'Add Evaluation' }
        ]
      }
      if (this.dialog.name === 'editPatient') {
        return [
          { title: 'Personal Details', component: 'Personal Detais' },
          { title: 'Contact and Price Details', component: 'Contact Details' }
        ]
      }
    },
    userProfile: state => state.userProfile,
    subscriptionFeatures: state => state.subscription,
    settingsForms: state => state.settings.patients.forms.$values.map(item => { return { name: item.name.replace('Survey', '').trim(), code: item.code } }),
  }),
  watch: {
    radioGroup (val) {
      this.form.sex = GENDER[val].name
    },
    tabIndex (val) {
      // if (val !== 0 && val !== 1) {
      //   this.form.visitInvitationMessage = `Hi, ${this.form.firstName} ${this.form.lastName}\nPlease find out our next visit on ${this.form.plannedVisitDate ? this.form.plannedVisitDate : ''}`
      //   this.form.registerPortalMessage = `Hi, ${this.form.firstName} ${this.form.lastName}\nPlease join out Patient Portal to manage your surveys`
      // }
      switch (val) {
        case 1:
          this.isAutofocusSecondTab = true
          setTimeout(() => {
            this.isAutofocusSecondTab = false
          }, 10)
          break
        case 2:
          this.isAutofocusThirdTab = true
          setTimeout(() => {
            this.isAutofocusThirdTab = false
          }, 10)
          break
      }
    },
    // 'form.plannedVisitDate' (val) {
    //   this.form.visitInvitationMessage = `Hi, ${this.form.firstName} ${this.form.lastName}\nPlease find out our next visit on ${this.form.plannedVisitDate ? this.form.plannedVisitDate : ''}`
    // }
    'form.none' (val) {
      if (val) {
        this.form.visitInvitation = false
        this.form.nonSurvey = false
      }
    },
    'form.visitInvitation' (val) {
      if (val) {
        this.form.none = false
        this.form.nonSurvey = false
      }
    },
    'form.nonSurvey' (val) {
      if (val) {
        this.form.none = false
        this.form.visitInvitation = false
      }
    }
  }
}
</script>

<style lang="scss" scoped>
  .w100 {
    width: 100%;
  }
  .label {
    font-size: 14px;
    font-weight: 500;
    color: #425466;
  }
  .add-patient {
    ::v-deep .v-dialog__container {
      .v-card {
        min-height: 650px;

        @media screen and (max-height: 650px) {
          height: 90vh;
          min-height: 500px !important;
          overflow: scroll;

          &__actions {
            padding-top: 50px;
            position: static !important;
          }

        }

        &__title {
          background: #edf2f7;
          padding: 25px 30px 17px 46px !important;
          justify-content: center!important;
          position: relative;
          .v-icon {
            position: absolute;
            right: 15px;
            top: 25px;
          }
        }
        &__title {
          padding: 12px 0 0 8px !important;
        }

        &__actions {
          position: absolute;
          width: 100%!important;
          bottom: 0!important;
          margin-top: -10px!important;
        }
      }
    }
    ::v-deep .tabs {
      .v-window {
        display: none;
      }
    }
    ::v-deep .checkbox {
      label {
        font-size: 14px;
        font-weight: 500;
      }
    }
    &__gender {
      ::v-deep .v-input {
        &__control {
          .v-messages {
            display: none;
          }
        }
      }
    }
    &__dietary {
      ::v-deep .v-input {
        &__control {
          .v-messages {
            display: none;
          }
        }
      }
    }
    &__language {
      ::v-deep .v-input {
        &__control {
          .v-messages {
            display: none;
          }
        }
      }
    }
    &__gender,
    &__dietary,
    &__language,
    &__visit-invitation,
    &__survey-form,
    &__register-portal,
    &__evaliation-date {
      ::v-deep .v-input {
        padding: 10px 13px 10px 13px;
        label {
          font-size: 14px;
          font-weight: 500;
        }
        &:hover {
          background: #F7FAFC;
          border-radius: 8px;
        }
      }
    }
    &__survey-form {
      ::v-deep .v-input {
       .v-messages {
         display: none!important;
       }
      }
    }
    ::v-deep .select .v-text-field {
      &__details {
        display: none;
      }
    }
    ::v-deep .v-input {
      &__slot {
        margin: 0;
      }
      .v-messages {
        //display: none;
      }
     &--selection-controls {
       margin: 0;
     }
    }
    .short-message {
      .bold {
        font-weight: 600;
      }
    }
    .required-field {
      color: #CF4655;
    }
    .legend-errors {
      .info-text {
        font-size: 16px;
        font-weight: 500;
        color: #425466;
      }
    }
    .loading {
      min-height: 100px;
      position: absolute;
      height: 85%;
      width: 98%;
      top: 84px;
      left: 6px;
      background: rgba(213, 213, 213, 0.5);
      border-radius: 16px;
    }
    .custom-checkbox {
      ::v-deep {
        .v-input {
          .v-input__control {
            .v-messages {
              display: none!important;
            }
          }
        }
        .v-input.disabled {
          pointer-events: none!important;
        }
      }
    }
    .custom-checkbox.color-red {
      ::v-deep {
        .v-input {
          label {
            color: red!important;
          }
        }
      }
    }
    .preview-hide {
      visibility: hidden!important;
      pointer-events: none!important;
    }
  }
</style>
