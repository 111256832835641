import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import vuetify from './plugins/vuetify'

import axios from './plugins/axios'
import lodash from './plugins/lodash'
import VueMask from 'v-mask'
import vueMoment from './plugins/moment'
import { VueReCaptcha } from 'vue-recaptcha-v3'
import ChatHubPlugin from '@/plugins/chatHubPlugin'
import * as Sentry from '@sentry/browser'
// import * as SentryVue from '@sentry/vue'
import * as Integrations from '@sentry/integrations'
// import { BrowserTracing } from '@sentry/tracing'

Sentry.init({
  Vue,
  dsn: process.env.VUE_APP_SENTRY_DSN,
  integrations: [
    // new BrowserTracing({
    //   routingInstrumentation: SentryVue.vueRouterInstrumentation(router),
    //   tracingOrigins: ['localhost', 'https://test.kliksoft.services', /^\//]
    // }),
    new Integrations.Vue({
      Vue,
      attachProps: true,
      logErrors: true
    })
  ],
  // Set tracesSampleRate to 1.0 to capture 100%
  // of transactions for performance monitoring.
  // We recommend adjusting this value in production
  tracesSampleRate: 1.0
})

Vue.use(ChatHubPlugin)
Vue.use(VueReCaptcha, { siteKey: process.env.VUE_APP_GOOGLE_API_TOKERN })

Vue.use(VueMask)

Vue.config.productionTip = false

const permissions = {
  install (Vue, options) {
    Vue.prototype.$adminPermission = (value) => {
      if (!value) return false
      const permissions = store.state.permissions
      return permissions ? permissions.includes(value) : false
    }
  }
}
Vue.use(permissions)

new Vue({
  axios,
  lodash,
  vueMoment,
  router,
  store,
  vuetify,
  render: h => h(App)
}).$mount('#app')
