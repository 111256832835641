<template>
  <v-card class="chart">
    <v-card-title class="info-title">
      <div class="main-row justify-space-between">
        <div class="title">
          {{title}}
        </div>
        <div class="select">
          <Select
            outlined
            itemText="name"
            itemValue="name"
            :items="listOfChartBy"
            :value="period"
            @change="updateChartLineBy($event)"
          />
        </div>
      </div>
    </v-card-title>
    <v-card-text>
      <chart-line v-if="type === 'line'" :dataSet="data"/>
      <chart-doughnut v-if="type === 'doughnut'" :dataSet="data"/>
    </v-card-text>
  </v-card>
</template>

<script>

import ChartLine from '@/views/home-dashboard/charts/chart-line'
import ChartDoughnut from '@/views/home-dashboard/charts/chart-doughnut'
import Select from '@/components/elements/Select'

export default {
  name: 'chart',
  props: {
    title: String,
    type: String,
    data: Object,
    period: String
  },
  components: { ChartLine, ChartDoughnut, Select },
  data: () => ({
    listOfChartBy: [
      {
        name: 'Week'
      },
      {
        name: 'Month'
      },
      {
        name: 'Year'
      }
    ]
  }),
  methods: {
    updateChartLineBy (value) {
      if (this.period !== value) {
        this.$emit('updateChartLineBy', { value, type: this.type })
      }
    }
  }
}
</script>

<style scoped lang="scss">
.chart {
  border-radius: 16px;
  ::v-deep .v-card {
    &__title {
      padding: 10px 16px;
    }
  }
  .info-title {
    .main-row {
      align-items: center;
      display: flex;
      column-gap: 1rem;
      width: 100%;
      .title {
        width: 300px;
        font-weight: 600;
        font-size: 20px;
        color: #16192C;
      }
      .select {
        width: 115px;
        ::v-deep .v-input {
          box-shadow: unset!important;
          border: unset!important;
          .v-text-field__details {
            display: none;
          }
          .v-select__selection {
            color: #A0AEC0!important;
          }
          .v-input__icon {
            i {
              color: #A0AEC0!important;
            }
          }
        }
      }
    }
  }
}
</style>
