<template>
  <div class="redirect-auto-auth"></div>
</template>

<script>
import { mapActions } from 'vuex'

export default {
  name: 'redirect-auto-auth',
  mounted () {
    this.authCheck({ userId: this.$route.params.userId, token: this.$route.params.token })
  },
  methods: {
    ...mapActions(['authCheck'])
  }
}
</script>

<style scoped>

</style>
