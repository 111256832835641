import Vue from 'vue'
import VueRouter from 'vue-router'

import Index from '@/views/Index'
import settings from '@/views/settings'
import patients from '@/views/patients/patients'
import viewPatient from '@/views/patients/view-patient'
import hipaa from '@/views/hipaa'
import support from '@/views/support'
import viewVisit from '@/views/visits/view-visit'
import confirmPassword from '@/views/confirm-password'

import unauth from '@/views/unauth'

import store from '@/store'

Vue.use(VueRouter)

function guardRoutes(to, from, next) {
  if(!store.state?.subscription?.expired) {
    next()
  } else {
    next(from)
  }
}

const routes = [
  { path: '*', component: Index },
  {
    path: '/',
    name: 'Home',
    component: Index,
    children: [
      {
        path: '/settings',
        name: 'Settings',
        component: settings,
        meta: {
          headerTitle: 'Settings'
        }
      },
      {
        path: '/patients',
        name: 'Patients',
        beforeEnter: guardRoutes,
        component: patients,
        meta: {
          headerTitle: 'Patients'
        },
        children: [
          {
            path: '/patients/:id',
            name: 'View patient',
            component: viewPatient,
            meta: {
              headerTitle: 'Patient'
            }
          },
          {
            path: '/patients/visit/:id',
            name: 'View Evaluation',
            component: viewVisit,
            meta: {
              headerTitle: 'Evaluation details'
            }
          }
        ]
      // {
      //   path: '/patients/:id',
      //   name: 'View patient',
      //   component: viewPatient,
      //   meta: {
      //     headerTitle: 'Patient'
      //   }
      // },
      // {
      //   path: '/visit/:id',
      //   name: 'View visit',
      //   component: viewVisit,
      //   meta: {
      //     headerTitle: 'Evaluation details'
      //   }
      },
      {
        path: '/hipaa',
        name: 'Hipaa',
        component: hipaa
      },
      {
        path: '/support',
        name: 'Support',
        component: support
      }
    ]
  },
  {
    path: '/sign-in',
    name: 'SignIn',
    component: unauth
  },
  {
    path: '/sign-up',
    name: 'SignUp',
    component: unauth
  },
  {
    path: '/confirm-password/:id/:token/:type',
    name: 'ConfirmPassword',
    component: confirmPassword
  },
  {
    path: '/confirm/:id/:token',
    name: 'ConfirmAccount',
    component: unauth
  },
  {
    path: '/redirect/:userId/:token',
    name: 'RedirectAutoAuth',
    component: unauth
  },
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

router.beforeEach((to, from, next) => {
  if (!store.state?.isAuth && (to.name !== 'SignIn' && to.name !== 'SignUp' && to.name !== 'ConfirmAccount' && to.name !== 'ConfirmPassword' && to.name !== 'RedirectAutoAuth')) {
    localStorage.setItem('firstPage', to.path)
    next({ name: 'SignIn' })
  } else {
    next()
  }
})

export default router
