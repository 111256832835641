<template>
  <div class="select">
    <v-select v-bind="$props" @change="emitData($event)" :menu-props="{ buttom: true, offsetY: true }" :style="styles" attach />
  </div>
</template>

<script>
export default {
  name: 'Select',
  props: {
    appendIcon: { type: String },
    radius: [Number, String],
    prependIcon: { type: String },
    prependInnerIcon: { type: String },
    fullWidth: { type: Boolean },
    itemColor: { type: String },
    backgroundColor: { type: String },
    itemText: { type: String },
    itemValue: { type: String },
    isSlotableItem: { type: Boolean, default: false },
    isSlotableSelection: { type: Boolean, default: false },
    placeholder: { type: String },
    color: { type: String },
    items: { type: Array },
    height: { default: 36, type: [Number, String] },
    disabled: { type: Boolean },
    readonly: { type: Boolean },
    returnObject: { type: Boolean },
    value: { type: [Object, String, Number] },
    dark: { type: Boolean },
    multiple: { type: Boolean },
    outlined: { type: Boolean }
  },
  created () {
    // this.$emit('change', this.value)
  },
  methods: {
    emitData ($event) {
      this.$emit('change', $event)
    }
  },
  computed: {
    styles () {
      return (this.radius) ? {
        'border-radius': this.radius + 'px'
      } : ''
    }
  }
}
</script>

<style lang="scss" scoped>
  .select {
    ::v-deep .v-text-field {
      &--outlined fieldset {
        border: unset !important; //  1px solid red!important;
      }
    }
    ::v-deep .v-select {
      box-shadow: 0px 1px 2px rgba(50, 50, 71, 0.08), 0px 0px 1px rgba(50, 50, 71, 0.2);
      border: 1px solid rgba(0, 140, 137, 0.16); // 1px solid #cdcdcd!important; // 1px solid transparent;
      &--is-menu-active {
        box-shadow: 0px 0px 0px 2px $dark-green; // 0px 0px 0px 2px rgba(76, 111, 255, 0.3);
        border: 1px solid #75DC98;
      }
    }
    ::v-deep .v-input {
      &__slot {
        height: 36px !important;
        min-height: 36px !important;
        padding: 0;
        margin: 0;
        &::before,
        &::after {
          display: none;
        }
      }
      .v-select__slot {
        .v-input__append-inner {
          .v-input__icon {
            .v-icon {
              background: rgba(0, 140, 137, 0.16);
              border-radius: 50%;
              color: $dark-green;
            }
          }
        }
      }
      &__append-inner {
        margin-top: 7px !important;
      }
      .v-menu__content {
        background-color: #fff;
        margin-top: 9px;
        box-shadow: 0px 0px 1px rgba(12, 26, 75, 0.2), 0px 1px 3px rgba(50, 50, 71, 0.1);
        border-radius: 8px;
        padding: 0 5px;
        .v-select-list {
          padding: 5px 0;
          .v-list-item {
            border-radius: 8px;
            height: 36px !important;
            min-height: 36px !important;
            &:not(:first-child) {
              margin-top: 5px;
            }
            &:hover:not(.v-list-item--active) {
              background: #EDF2F7 !important;
            }
            &__content {
              border-radius: 8px;
            }
            &--active {
              border-radius: 8px;
              background: $dark-green !important;
              color: #fff !important;
              font-weight: 500;
              font-size: 14px;
              line-height: 14px;
            }
          }
        }
      }
    }
  }
</style>
