<template>
  <div class="view-visit">
    <v-container fluid class="wrap-container justify-space-between d-flex" v-if="visit">
      <v-col :cols="tabs[componentInd].component === 'reports' ? 12 : 9" style="padding-right: 0;">
        <!--<v-row>
        <v-col cols="4">
          <div class="d-flex">
            <div class="mr-2">
               <v-avatar size="84" v-if="visit.patientAvatar">
                 <v-img :src="'data:image/png;base64,'+visit.patientAvatar" />
               </v-avatar>
            </div>
            <div class="d-flex flex-column font-weight-bold">
              <div>Visit {{$moment(visit.date).format('MM/DD/YYYY')}}</div>
              <div>
                <Link @click.native="edit" to="">Reschedule</Link>
              </div>
              <div>
                <v-chip color="primary" >
                  <v-icon left>
                    mdi-account-circle-outline
                  </v-icon>
                  {{status || 'N/A'}}
                </v-chip>
              </div>
            </div>
          </div>
          &lt;!&ndash;<div class="d-flex pt-2">
            <btn-group :btns="btns" @update:btn="changeStatus" />
          </div>&ndash;&gt;
        </v-col>
        <v-col cols="4">
          <div>
            <div>14 yers old</div>
            <div>
              <span class="mr-2 font-weight-bold">Male</span>
              <span>{{visit.weight}} kg</span>
            </div>
            <div>{{visit.height}} cm / 22.3</div>
          </div>
        </v-col>
        <v-col cols="4">
          <div class="font-weight-bold">Rerports of VISIT:</div>
          <Link to="#">Survey from A</Link>
          <Link to="#">Survey from B</Link>
        </v-col>
      </v-row>-->
        <!--new block header-->
        <!--<v-row class="align-center">
          <v-col cols="12" class="pb-1">
            <div class="view-visit__header">
              <div class="view-visit__header-title">
                <span>Evaluation</span>
              </div>
&lt;!&ndash;              <div class="view-visit__header-status">
                <v-tooltip right :open-on-hover="false">
                  <template v-slot:activator="{ on, attrs }">
                    <div v-bind="attrs"
                         v-on="on">
                      <div class="custom-btn-status" :class="getStatusCodeName[visit.status].class">
                        <span>{{getStatusCodeName[visit.status].name}}</span>
                      </div>
                    </div>
                  </template>
                  <div style="display: block;max-width: 300px;">{{getStatusCodeName[visit.status].description}}</div>
                </v-tooltip>
              </div>&ndash;&gt;
              <div class="time-line-wrap">
                <div
                  v-for="statusBtn in visit.timeLines.$values"
                  :key="statusBtn.name"
                  class="status-btn"
                  @click="runMethod(statusBtn.name)"
                  :class="{'light-grey-bg': statusBtn.past || statusBtn.future, 'green-light-bg': statusBtn.now, 'green-bg': statusBtn.isApprove || statusBtn.isComplete}"
                  style="height: 40px;flex-shrink: 0;">&lt;!&ndash;:style="{'background-color': statusBtn.bgColor}"&ndash;&gt;
                  <div
                    class="current-status triangle"
                    v-if="statusBtn.now"
                    :class="{'border-left-light-grey border-top-light-grey': statusBtn.past || statusBtn.future, 'border-left-light-green border-top-light-green': statusBtn.now, 'border-left-green border-top-green': statusBtn.isApprove || statusBtn.isComplete}"
                  ></div>&lt;!&ndash;:style="{'border-left': `solid 5px ${statusBtn.bgColor}`, 'border-top': `solid 5px ${statusBtn.bgColor}`}"&ndash;&gt;
                  <div class="triangle-left"></div>
                  <div class="triangle"
                       :class="{'border-left-light-grey border-top-light-grey': statusBtn.past || statusBtn.future, 'border-left-light-green border-top-light-green': statusBtn.now, 'border-left-green border-top-green': statusBtn.isApprove || statusBtn.isComplete}">&lt;!&ndash; :style="{'border-left': `solid 10px ${statusBtn.bgColor}`, 'border-top': `solid 10px ${statusBtn.bgColor}`}"&ndash;&gt;&lt;!&ndash;v-if="!statusBtn.isApprove && !statusBtn.isComplete"&ndash;&gt;
                  </div>
                  <div class="status-name"
                       :class="{'light-grey-text': statusBtn.past, 'white-text': statusBtn.isApprove || statusBtn.isComplete, 'green-text': statusBtn.now}">&lt;!&ndash;:style="{'color': statusBtn.colorText}"&ndash;&gt;
                    {{statusBtn.name}}
                  </div>
                  <div class="status-date"
                       v-if="statusBtn.info"
                       :class="{'light-grey-text': statusBtn.past, 'white-text': statusBtn.isApprove || statusBtn.isComplete, 'green-text': statusBtn.now}">&lt;!&ndash;:style="{'color': statusBtn.colorText}"&ndash;&gt;
                    {{statusBtn.info}}
                  </div>
                  <div v-if="statusBtn.now" style="position: absolute;bottom: -19px;font-size: 13px;font-weight: bold;color: #008c89;">now</div>
                </div>
              </div>
            </div>
          </v-col>
          &lt;!&ndash;<v-col cols="6" class="d-flex justify-end" v-if="visit.status === 'NeedReview' || visit.status === 'Reviewed'">
                      <Button
                        dark
                        width="150"
                        color="#4FAA6E"
                        @click.native="openModalCompleteVisit"
                        :styles="{ 'border-radius': '8px' }"
                      >
                        <span style="color: #FFFFFF">Complete</span>
                      </Button>
           </v-col>&ndash;&gt;
        </v-row>-->
        <v-row :class="{'justify-space-between': tabs[componentInd].component !== 'reports'}">
          <v-col :cols="tabs[componentInd].component === 'reports' ? 5 : 6" :style="{ 'max-width': tabs[componentInd].component === 'reports' ? '38%' : '' }" style="padding: 4px 4px 4px 4px">
            <v-card elevation="2" class="visit-card-main-data">
              <v-card-text>
                <div class="main-row" style="height: 92px;">
                  <div class="full-name-avatar">
                    <div class="full-name-avatar__avatar" @click="openPatientDetails">
                      <v-avatar size="70">
                        <img v-if="visit.patient.profileAvatar" :src="visit.patient.profileAvatar" :alt="visit.patient.firstName" />
                      </v-avatar>
                    </div>
                    <div class="full-name-avatar__full-name">
                      <span class="fio" @click="openPatientDetails">{{visit.patient.firstName}} {{visit.patient.lastName}}</span>
                      <div class="d-flex">
                        <v-tooltip top v-if="visit.patient.vegetarian">
                          <template v-slot:activator="{ on, attrs }">
                            <div v-bind="attrs" v-on="on" class="mr-1" style="width: 25px">
                              <Img :src="getImgWithExt(['icons', 'vegan_icon', 'svg'])" />
                            </div>
                          </template>
                          <span>Patient is Vegetarian</span>
                        </v-tooltip>
                        <v-tooltip top v-if="visit.patient.glutenFree" >
                          <template v-slot:activator="{ on, attrs }">
                            <div v-bind="attrs" v-on="on" class="mr-1" style="width: 25px">
                              <Img :src="getImgWithExt(['icons', 'gluten_icon', 'svg'])" />
                            </div>
                          </template>
                          <span>Patient is Gluten Free</span>
                        </v-tooltip>
                        <v-tooltip top v-if="visit.patient.registerPortal" >
                          <template v-slot:activator="{ on, attrs }">
                            <div v-bind="attrs" v-on="on" class="mr-1" style="width: 25px">
                              <Img :src="getImgWithExt(['icons', 'portal_regestreted_icon', 'svg'])" />
                            </div>
                          </template>
                          <span>Patient Portal Registered</span>
                        </v-tooltip>
                        <v-tooltip top v-if="visit.patient.lead" >
                          <template v-slot:activator="{ on, attrs }">
                            <v-icon color="primary" v-bind="attrs" v-on="on">mdi-human-queue</v-icon>
                          </template>
                          <span>Patient has leads</span>
                        </v-tooltip>
                      </div>
                    </div>
                  </div>
                  <div class="patient-parameters">
                    <div class="patient-parameters__block">
                      <div class="patient-parameters__title">Gender</div>
                      <div class="patient-parameters__value">{{patient.sex}}</div>
                    </div>
                    <div class="patient-parameters__block">
                      <div class="patient-parameters__title">Weight</div>
                      <div class="patient-parameters__value">{{patient.weight}} lb</div>
                    </div>
                    <div class="patient-parameters__block">
                      <div class="patient-parameters__title">Height</div>
                      <div class="patient-parameters__value">{{patient.height}} in</div>
                    </div>
                    <div class="patient-parameters__block">
                      <div class="patient-parameters__title">Age</div>
                      <div class="patient-parameters__value">{{patient.age || '-'}}</div>
                    </div>
                  </div>
                </div>
              </v-card-text>
            </v-card>
          </v-col>
          <v-col :cols="tabs[componentInd].component === 'reports' ? 4 : 6" :style="{ 'max-width': tabs[componentInd].component === 'reports' ? '36%' : '' }" style="padding: 4px 0 4px 4px">
            <v-card elevation="2" class="visit-card-main-data">
              <v-card-text>
                <div class="main-row" style="row-gap: 0" :style="{ 'flex-wrap': tabs[componentInd].component === 'reports' ? 'nowrap' : 'wrap' }">
                  <div>
                    <div class="last-visit">
                      <div class="last-visit__date">
                        <div class="btn-date" :class="{'disabled': ['InProtocol', 'Completed', 'Closed', 'ProtocolEnding'].includes(visit.status)}">
                          <v-icon color="#008C89">mdi-calendar-blank</v-icon>
                          <span class="btn-date__text">{{$moment(visitDate).format('MM/DD/YYYY')}}</span>
                          <datepicker
                            style="position: absolute;top: 0;left: 0;opacity: 0;"
                            :min="nowDate"
                            @date:update="updateVisitDate"
                            :value="visitDate"
                          />
                        </div>
                      </div>
                    </div>
                    <div class="view-visit__header-status">
                      <v-tooltip right :open-on-hover="false" v-model="showDescriptionStatus">
                        <template v-slot:activator="{}">
                          <div @click="showStatusDescription" class="custom-btn-status" :class="getStatusCodeName[visit.status].class">
                            <span>{{getStatusCodeName[visit.status] ? getStatusCodeName[visit.status].name : '-'}}</span>
                          </div>
                        </template>
                        <div style="display: block;max-width: 300px;">{{getStatusCodeName[visit.status] ? getStatusCodeName[visit.status].description() : '-'}}</div>
                      </v-tooltip>
                    </div>
                  </div>
                  <div class="visit-controls">
                    <div>
                      <div class="d-flex align-center">
                        <span style="color: #27272E;font-size: 14px">Show Price as</span>
                        <div class="price-model-select mr-2">
                          <v-menu offset-y :disabled="['Completed', 'Closed'].includes(visit.status)">
                            <template v-slot:activator="{ on, attrs }">
                              <v-btn
                                color="#008C89"
                                :disabled="['Completed', 'Closed'].includes(visit.status)"
                                style="width: 178px"
                                v-bind="attrs"
                                v-on="on"
                              >
                                <span class="font-weight-bold" style="color: white">{{priceModel.name}}</span>
                                <v-icon style="color: white">mdi-arrow-down-thin</v-icon>
                              </v-btn>
                            </template>
                            <v-list>
                              <v-list-item
                                v-for="(item, index) in patientOrderMethods"
                                :key="index"
                              >
                                <v-list-item-title style="cursor:pointer;" @click="updatePriceModel({ data: item }, priceModel.name)">{{ item.name }}</v-list-item-title>
                              </v-list-item>
                            </v-list>
                          </v-menu>
                        </div>
                      </div>
                      <div class="d-flex align-center">
                        <span style="color: #27272E;font-size: 14px">Calc. Method</span>
                        <div class="price-model-select mr-2 ml-1 d-flex" style="column-gap: 0.2rem">
                          <v-menu offset-y :disabled="['InProtocol', 'Completed', 'Closed', 'ProtocolEnding'].includes(visit.status)">
                            <template v-slot:activator="{ on, attrs }">
                              <v-btn
                                color="#008C89"
                                style="width: 93px"
                                :disabled="['InProtocol', 'Completed', 'Closed', 'ProtocolEnding'].includes(visit.status)"
                                v-bind="attrs"
                                v-on="on"
                              >
                                <span class="font-weight-bold" style="color: white">{{calcMethod.code}}</span>
                                <v-icon style="color: white">mdi-arrow-down-thin</v-icon>
                              </v-btn>
                            </template>
                            <v-list>
                              <v-list-item
                                v-for="(item, index) in calcMethodList"
                                :key="index"
                              >
                                <v-list-item-title style="cursor:pointer;" @click="updateCalcMethod({ data: item }, calcMethod.code)">{{ item.name }}</v-list-item-title>
                              </v-list-item>
                            </v-list>
                          </v-menu>
                          <Button btn-bg="dark-green"
                                  :disabled="['InProtocol', 'Completed', 'Closed', 'NeedProduct', 'ProtocolEnding'].includes(visit.status)"
                                  @click.native="onRecalcResults(visit.status)"
                                  :styles="{ 'border-radius': '8px' }">
                            <span style="color: white">ReCalc</span>
                          </Button>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </v-card-text>
            </v-card>
          </v-col>
          <v-col v-if="tabs[componentInd].component === 'reports'" cols="3" :style="{ 'max-width': tabs[componentInd].component === 'reports' ? '25%' : '' }" style="flex: unset;padding: 15px 0px 0px 0px">
            <v-row>
              <div class="visit-controls__supply-days">
                <div style="display: flex" @click="openChangeDaysSupplModal" :class="{'disabled': ['InProtocol', 'Completed', 'Closed', 'ProtocolEnding'].includes(visit.status)}">
                  <Img :src="getImgWithExt(['icons', 'purse', 'svg'])" width="20px"/>
                  <span style="padding: 0 5px;">Supply</span>
                  <span
                    class="days-60"
                    :class="{'disable-days': (tabs[componentInd].component === 'results' && (visit.finishedOn || ['InProtocol', 'NeedProduct', 'Completed', 'ProtocolEnding'].includes(visit.status))) || ['InProtocol', 'Completed', 'Closed', 'ProtocolEnding'].includes(visit.status)}">{{visit.supplyDays}} days</span>
                  <v-icon
                    v-if="!['NeedProduct', 'InProtocol', 'Completed', 'ProtocolEnding'].includes(visit.status)"
                    style="cursor:pointer;color: #CF4655;position: absolute;top: 12px;right: 20px;"
                    @click.native="openModalConfirmationDeleteVisit"
                  >mdi-trash-can-outline</v-icon>
                </div>
              </div>
            </v-row>
            <v-row class="mt-4 mb-3">
              <Button btn-bg="dark-green"
                      width="100%"
                      height="43px"
                      class="visit-controls__main-button"
                      :class="getDataAndStylesMainVisitControl[visit.status].class"
                      style="margin-left: 36px;width: 100%"
                      dark
                      @click.native="processVisit"
                      :styles="{ 'border-radius': '8px' }">
                <span>{{getDataAndStylesMainVisitControl[visit.status].buttonTitle}}</span>
              </Button>
              <div style="margin-left: 32px;" class="mt-1">{{getDataAndStylesMainVisitControl[visit.status].description}}</div>
            </v-row>
          </v-col>
        </v-row>
        <!--new block header-->
        <v-row>
          <v-col class="px-0 py-0">
            <Tabs :items="tabs" @update:tab="tab" :tab-index="tabIndex" left :dividerHeight="'20px'"
                  :isElevation="tabs[componentInd].component === 'survey' || tabs[componentInd].component === 'results'"
                  :isHiddenBg="tabs[componentInd].component === 'receipt' || tabs[componentInd].component === 'reports'">
              <template v-slot:sliderColor>
                <v-tabs-slider color="#008C89"></v-tabs-slider>
              </template>
              <template v-slot:buttonControlsForAllTabs>
                <div class="d-flex flex-grow-1 justify-end align-center">
<!--                  <Button outlined
                          v-if="tabs[componentInd].component === 'results'"
                          :styles="{ 'border-radius': '8px', 'border': '1px solid #008C89', 'min-width': '45px' }"
                          @click.native="openGroupSupplementTotals('Group')"
                          class="mr-2">
                    <span class="green-btn-color-text">G</span>
                  </Button>
                  <Button outlined
                          v-if="tabs[componentInd].component === 'results'"
                          :styles="{ 'border-radius': '8px', 'border': '1px solid #008C89', 'min-width': '45px' }"
                          @click.native="openGroupSupplementTotals('Supplement')"
                          class="mr-2">
                    <span class="green-btn-color-text">S</span>
                  </Button>-->
                  <Button outlined
                          :disabled="(visit && (visit.finishedOn !== null)) || ['InProtocol', 'NeedProduct', 'Completed', 'ProtocolEnding'].includes(visit.status)"
                          v-if="tabs[componentInd].component === 'results' && visit.status !== 'Completed' && visit.previousResultsExist"
                          :styles="{
                            'border-radius': '8px',
                            'border': `1px solid ${visit.finishedOn || (visit.status === 'InProtocol' || visit.status === 'NeedProduct' || visit.status === 'Completed' || visit.status === 'ProtocolEnding') ? '#0000001f' : '#008C89'}`
                          }"
                          @click.native="openShowPreviousProtocol"
                          class="mr-2">
                    <v-icon class="green-btn-color-text">mdi-plus</v-icon>
                    <span class="green-btn-color-text" :class="{'gray-btn-color-text': visit && visit.finishedOn || ['InProtocol', 'NeedProduct', 'Completed', 'ProtocolEnding'].includes(visit.status)}">Previous Protocol</span>
                  </Button>
                  <Button outlined
                          :disabled="(visit && (visit.finishedOn !== null)) || ['InProtocol', 'NeedProduct', 'Completed', 'ProtocolEnding'].includes(visit.status)"
                          v-if="tabs[componentInd].component === 'results' && visit.status !== 'Completed'"
                          :styles="{
                            'border-radius': '8px',
                            'border': `1px solid ${visit.finishedOn || (visit.status === 'InProtocol' || visit.status === 'NeedProduct' || visit.status === 'Completed' || visit.status === 'ProtocolEnding') ? '#0000001f' : '#008C89'}`
                          }"
                          @click.native="openShowProtocol"
                          class="mr-2">
                    <v-icon class="green-btn-color-text">mdi-plus</v-icon>
                    <span class="green-btn-color-text" :class="{'gray-btn-color-text': visit && visit.finishedOn || ['InProtocol', 'NeedProduct', 'Completed', 'ProtocolEnding'].includes(visit.status)}">Protocol</span>
                  </Button>
                  <Button btn-bg="dark-green"
                          v-if="tabs[componentInd].component === 'results'"
                          :disabled="(visit && (visit.finishedOn !== null)) || ['InProtocol', 'NeedProduct', 'Completed', 'ProtocolEnding'].includes(visit.status)"
                          dark
                          :styles="{ 'border-radius': '8px' }"
                          @click.native="openResult">
                    <v-icon>mdi-plus</v-icon>
                    <span>Supplement</span>
                  </Button>
                  <Button btn-bg="dark-green"
                          :disabled="(visit && (visit.finishedOn !== null)) || ['InProtocol', 'NeedProduct', 'Completed', 'ProtocolEnding'].includes(visit.status)"
                          v-if="tabs[componentInd].component === 'survey'"
                          :styles="{
                            'border-radius': '8px',
                            'border': `1px solid ${visit.finishedOn || (visit.status === 'InProtocol' || visit.status === 'NeedProduct' || visit.status === 'Completed' || visit.status === 'ProtocolEnding') ? '#0000001f' : '#008C89'}`
                          }"
                          @click.native="openQuickEntry('quickEntry_1')"
                          class="mr-2">
                    <span style="color: white">Quick entry 1</span>
                  </Button>
                  <Button btn-bg="dark-green"
                          :disabled="(visit && (visit.finishedOn !== null)) || ['InProtocol', 'NeedProduct', 'Completed', 'ProtocolEnding'].includes(visit.status)"
                          v-if="tabs[componentInd].component === 'survey'"
                          :styles="{
                            'border-radius': '8px',
                            'border': `1px solid ${visit.finishedOn || (visit.status === 'InProtocol' || visit.status === 'NeedProduct' || visit.status === 'Completed' || visit.status === 'ProtocolEnding') ? '#0000001f' : '#008C89'}`
                          }"
                          @click.native="openQuickEntry('quickEntry_2')"
                          class="mr-2">
                    <span style="color: white">Quick entry 2</span>
                  </Button>
                  <v-icon v-if="tabs[componentInd].component === 'survey'" style="cursor:pointer;" @click="refreshData">mdi-refresh</v-icon>
                </div>
              </template>
              <template v-slot:newLineControlForAllTabs>
                <div v-if="tabs[componentInd].component === 'blood'">
                  <div class="wrapper-blood-control">
                    <div class="wrapper-blood-control__block">
                      <div class="legend blood-optimal">
                        <span class="legend-text">Above</span>
                        <span class="legend-icon">
                          <img :src="getImgWithExt(['icons', 'optimal-above', 'svg'])" alt="icon" />
                        </span>
                        <span class="legend-text bold">- OPTIMAL -</span>
                        <span class="legend-icon">
                          <img :src="getImgWithExt(['icons', 'optimal-below', 'svg'])" alt="icon" />
                        </span>
                        <span class="legend-text">Below</span>
                      </div>
                      <div class="legend blood-lab">
                        <span class="legend-text">Above</span>
                        <span class="legend-icon">
                          <img :src="getImgWithExt(['icons', 'lab-above', 'svg'])" alt="icon" />
                        </span>
                        <span class="legend-text bold">- LAB -</span>
                        <span class="legend-icon">
                          <img :src="getImgWithExt(['icons', 'lab-below', 'svg'])" alt="icon" />
                        </span>
                        <span class="legend-text">Below</span>
                      </div>
                    </div>
                    <div class="wrapper-blood-control__block">
                      <div class="blood-draw-date">
                        <div class="blood-draw-date__label">
                          Blood Draw date
                        </div>
                        <div class="blood-draw-date__date">
                          <img :src="getImgWithExt(['icons', 'blood-draw-date', 'svg'])" alt="icon" />
                          {{$moment(bloodData.bloodTestDate).format('MM/DD/YYYY')}}
                          <datepicker
                            v-if="!bloodData.isReadOnly"
                            style="position: absolute;top: 0;left: 0;opacity: 0;"
                            @date:update="updateBloodDate"
                            :value="bloodData.bloodTestDate"
                          />
                        </div>
                      </div>
                      <v-checkbox
                        color="#008C89"
                        label="Fasting"
                        :disabled="bloodData.isReadOnly"
                        :value="bloodData.isFasting"
                        v-model="bloodIsFasting"
                      ></v-checkbox>
                    </div>
                  </div>
                </div>
              </template>
              <template v-slot:[`${tabs[componentInd].component}`]>
                <component :elevation="2"
                           :orderMethod="priceModel"
                           :calcMethod="calcMethod"
                           :isUpdateResults="isUpdateResults"
                           :isUpdateSurvey="isUpdateSurvey"
                           :isUpdateDaysSupply="isUpdateDaysSupply"
                           :isUpdateVisit="isUpdateVisit"
                           :bloodIsFasting="bloodIsFasting"
                           @updateIsUpdateVisit="isUpdateVisit = $event"
                           @updateIsUpdateDaysSupply="isUpdateDaysSupply = $event"
                           @updateIsUpdateResults="isUpdateResults = $event"
                           @updateOrderMethod="updateOrderMethod"
                           @updateIsUpdateSurvey="isUpdateSurvey = $event"
                           :is="tabs[componentInd].component"
                           @changeTab="changeTab"></component>
              </template>
              <template v-slot:totalResult v-if="tabs[componentInd].component === 'results'">
                <results-total/>
              </template>
            </Tabs>
          </v-col>
        </v-row>
      </v-col>
      <v-col cols="3" style="margin-top:5px;padding-left: 0;">
        <v-row>
          <div class="visit-controls__supply-days">
            <div style="display: flex" @click="openChangeDaysSupplModal" :class="{'disabled': ['InProtocol', 'Completed', 'Closed', 'ProtocolEnding'].includes(visit.status)}">
              <Img :src="getImgWithExt(['icons', 'purse', 'svg'])" width="20px"/>
              <span style="padding: 0 5px;">Supply</span>
              <span
                class="days-60"
                :class="{'disable-days': (tabs[componentInd].component === 'results' && (visit.finishedOn || ['InProtocol', 'NeedProduct', 'Completed', 'ProtocolEnding'].includes(visit.status))) || ['InProtocol', 'Completed', 'Closed', 'ProtocolEnding'].includes(visit.status)}">{{visit.supplyDays}} days</span>
            </div>
          </div>
        </v-row>
        <v-row class="mt-4 mb-3">
          <Button btn-bg="dark-green"
                  width="100%"
                  height="43px"
                  class="visit-controls__main-button"
                  :class="getDataAndStylesMainVisitControl[visit.status].class"
                  style="margin-left: 36px;width: 100%"
                  dark
                  @click.native="processVisit"
                  :styles="{ 'border-radius': '8px' }">
            <span>{{getDataAndStylesMainVisitControl[visit.status].buttonTitle}}</span>
          </Button>
          <div style="margin-left: 32px;height: 103px;" class="mt-1">{{getDataAndStylesMainVisitControl[visit.status].description}}</div>
        </v-row>
        <v-row style="position: relative">
          <v-icon
            v-if="!['NeedProduct', 'InProtocol', 'Completed', 'ProtocolEnding'].includes(visit.status)"
            style="cursor:pointer;margin-left: 10px;color: #CF4655;position: absolute;top: -190px;right: 20px;"
            @click.native="openModalConfirmationDeleteVisit"
          >mdi-trash-can-outline</v-icon>
        </v-row>
        <v-row v-for="(item, index) in notes" :key="item.id" class="mb-2">
          <simple-info-block
            :info="item"
            :index="index"
            @updateNote="updateNote"
          />
        </v-row>
      </v-col>
    </v-container>
    <div v-else class="loading d-flex justify-center px-2 py-2 align-center">
      <v-progress-circular
        indeterminate
        color="#008C89"
      ></v-progress-circular>
    </div>
    <add-visit v-if="dialogs.addVisit.isOpen || dialogs.editVisit.isOpen" />
    <complete-visit v-if="dialogs.completeVisit.isOpen" @complete="completeSurvey" />
    <days-supply-dialog v-if="dialogs.daysSupplyDialog.isOpen" @showPopup="showPopup"/>
    <quick-entry-form @updateSurvey="updateSurvey" v-if="dialogs.quickEntryDialog.isOpen"/>
    <result-group-supplement-totals v-if="dialogs.resultGroupSupplementTotals.isOpen"/>
    <previous-protocol @selectedPreviousProtocol="updateProtocol" v-if="dialogs.dialogShowPreviousProtocol.isOpen"/>
    <protocol @updateResult="updateResult" v-if="dialogs.dialogShowProtocol.isOpen"/>
    <modal-confirmation-delete-visit v-if="dialogs.modalConfirmationDeleteVisit.isOpen"/>
    <modal-confirmation-end-protocol v-if="dialogs.modalConfirmationEndProtocol.isOpen" @request="requestType"/>
<!--    <edit-protocol v-if="dialogs.editProtocol.isOpen" />-->
    <div class="custom-alert">
      <v-alert
        :color="alertColor"
        dismissible
        type="info"
        v-model="alertIsOpen"
      >
        <span style="font-size: 14px;">{{message || 'Visit was completed. Please check the available Reports'}}</span>
      </v-alert>
    </div>
  </div>
</template>

<script>
import { mapActions, mapMutations, mapState } from 'vuex'

import Tabs from '@/components/layouts/Tabs'
import Link from '@/components/elements/Link'
import results from '@/views/visits/tabs/results'
import reports from '@/views/visits/tabs/reports'
import ResultsTotal from '@/views/visits/tabs/results-total'
import blood from '@/views/visits/tabs/blood'
import receipt from '@/views/visits/tabs/receipt'
import survey from '@/views/visits/tabs/survey'
import BtnGroup from '@/components/layouts/btn-group'
import DosageControl from '@/components/elements/dosage-control'

import addVisit from '@/components/layouts/dialogs/add-visit'
import Button from '@/components/elements/Button'
import SimpleInfoBlock from '@/components/elements/simple-info-block'
import Mapping from '@/mixins/Mapping'
import datepicker from '@/components/elements/datepicker'
import CompleteVisit from '@/components/layouts/dialogs/complete-visit'
import Image from '@/mixins/Image'
import Img from '@/components/elements/Img'
import DaysSupplyDialog from '@/components/layouts/dialogs/days-supply-dialog'
import checkbox from '@/components/elements/checkbox'
import Input from '@/components/elements/Input'
import ModalConfirmationDeleteVisit from '@/components/layouts/dialogs/modal-confirmation-delete-visit'
import ModalConfirmationEndProtocol from '@/components/layouts/dialogs/modal-confirmation-end-protocol.vue'

export default {
  name: 'view-visit',
  mixins: [Mapping, Image],
  components: {
    ModalConfirmationEndProtocol,
    ModalConfirmationDeleteVisit,
    Tabs,
    Link,
    results,
    reports,
    blood,
    receipt,
    survey,
    addVisit,
    BtnGroup,
    Button,
    SimpleInfoBlock,
    ResultsTotal,
    datepicker,
    CompleteVisit,
    Img,
    DaysSupplyDialog,
    QuickEntryForm: () => import('@/components/layouts/dialogs/quick-entry-form.vue'),
    ResultGroupSupplementTotals: () => import('@/components/layouts/dialogs/result-group-supplement-totals.vue'),
    Protocol: () => import('@/components/layouts/dialogs/protocol'),
    PreviousProtocol: () => import('@/components/layouts/dialogs/previous-protocol'),
    DosageControl,
    checkbox,
    Input
  },
  data: () => ({
    showDescriptionStatus: false,
    timerId: 0,
    componentInd: 0,
    tabIndex: 0,
    mockData: [
      {
        id: 1,
        title: 'Five main complaints',
        data: '- klfjkdls\n- fjdkfd'
      },
      {
        id: 2,
        title: 'Notes for Patient',
        data: '- klfjkdls\n- fjdkfd\n- fjdkfd'
      },
      {
        id: 3,
        title: 'Notes for Practitioner',
        data: '- klfjkdls\n- fjdkfd\n- fjdkfd\n- fjdkfd'
      }
    ],
    visitDate: null,
    alertColor: '#7ac1fb',
    alertIsOpen: false,
    message: null,
    timeLinesData: [
      {
        name: 'Survey Sent',
        date: '04/18/2022',
        colorText: '#A0AEC0',
        bgColor: '#EDF2F7',
        isCurrentStatus: false
      },
      {
        name: 'Wait Patient for Submit',
        date: null,
        colorText: '#008C89',
        bgColor: '#79d6d4',
        isCurrentStatus: true
      },
      {
        name: 'Approve',
        date: null,
        colorText: '#FFFFFF',
        bgColor: '#008C89',
        isCurrentStatus: false
      },
      {
        name: 'Complete1',
        date: null,
        colorText: '#FFFFFF',
        bgColor: '#008C89',
        isCurrentStatus: false
      },
      {
        name: 'In Protocol',
        date: null,
        colorText: '#A0AEC0',
        bgColor: '#EDF2F7',
        isCurrentStatus: false
      },
      {
        name: 'Complete',
        date: null,
        colorText: '#A0AEC0',
        bgColor: '#EDF2F7',
        isCurrentStatus: false
      }
    ],
    priceModel: {
      $id: '59',
      args: '',
      code: 'StandardSLP',
      name: 'Standard SLP'
    },
    calcMethod: {
      $id: '69',
      args: '',
      code: 'SP',
      names: 'Specific Question-Based Support - Standard Process*',
    },
    isOpenPriceModel: false,
    hidePricingOnReceipt: false,
    isUpdateResults: false,
    isUpdateSurvey: false,
    isUpdateDaysSupply: false,
    isUpdateVisit: false,
    nowDate: new Date(new Date().setDate(new Date().getDate())).toISOString().slice(0, 10),
    bloodIsFasting: false
  }),
  methods: {
    ...mapActions('visits', ['getVisit', 'updateReschedule', 'updateNotes', 'visitProcess']),
    ...mapActions('results', ['getSupplements', 'recalcResults', 'refreshResults', 'getNotes']),
    ...mapActions('surveys', ['updateStatus', 'finishSurvey', 'approveSurvey', 'getSurveyGroups', 'getDetailsTestComplaints']),
    ...mapMutations('dialogs', ['TOGGLE_DIALOG']),
    ...mapMutations('surveys', ['UPDATE_FIELD_DATA']),
    ...mapActions('patients', ['getPatient']),
    ...mapActions('receipts', ['getReceipts', 'createReceiptPreview']),
    ...mapActions('blood', ['updateBloodTestDate']),

    async processVisit () {
      if (this.getDataAndStylesMainVisitControl[this.visit.status].buttonTitle === 'End Protocol Early / Adjust Protocol') {
        this.TOGGLE_DIALOG(
          {
            name: 'modalConfirmationEndProtocol',
            isOpen: true,
            title: 'End Protocol Early / Adjust Protocol',
          })
      } else {
        const data = {
         visitId: this.visit.id,
         visitStatus: this.getDataAndStylesMainVisitControl[this.visit.status].statusInPayloadRequest,
         addVisit: false,
         date: this.$moment(new Date()).format('YYYY-MM-DDTHH:mm:ss'),
         notes: this.visit.notes
       }
       const res = await this.visitProcess({ data })
       // this.isUpdateVisit = true
       if (res.res === true) {
         const { params } = this.$route
         this.loadData(params?.id)
         switch (data.visitStatus) {
           case 'InProtocol':
             this.changeTab({ title: 'receipt', component: 'receipt' })
             break
           case 'Reviewed':
             this.changeTab({ title: 'results', component: 'results' })
             break
         }
       }
      }
    },
    async requestType (type) {
      const data = {
        visitId: this.visit.id,
        visitStatus: type === 'adjust-protocol' ? 'InProgress' : 'Completed',
        addVisit: type === 'adjust-protocol' ? true : false,
        date: this.$moment(new Date()).format('YYYY-MM-DDTHH:mm:ss'),
        notes: this.visit.notes
      }
      const res = await this.visitProcess({ data })
      this.TOGGLE_DIALOG({ name: 'modalConfirmationEndProtocol', isOpen: false, root: true })
      // this.isUpdateVisit = true
      if (res.res === true) {
        const { params } = this.$route
        this.loadData(params?.id)
        switch (data.visitStatus) {
          case 'InProtocol':
            this.changeTab({ title: 'receipt', component: 'receipt' })
            break
          case 'Reviewed':
            this.changeTab({ title: 'results', component: 'results' })
            break
        }
        if (type === 'adjust-protocol') {
          this.$router.replace({ params: { id: res.data.id }})
          window.location.reload()
        }
      }
    },
    onRecalcResults (visitStatus) {
      if(!['InProtocol', 'Completed', 'Closed', 'NeedProduct', 'ProtocolEnding'].includes(visitStatus)) {
        const { id } = this.$route.params
        this.recalcResults({ id })
        this.$emit('changeTab', { title: 'results', component: 'results' })
      }
    },
    openPatientDetails () {
      this.$router.push({ name: 'View patient', params: { id: this.visit.patient.id }, query: this.$route.query })
    },
    openResult () {
      if (!this.visit.finishedOn || (!['InProtocol', 'NeedProduct', 'Completed', 'ProtocolEnding'].includes(this.visit.status))) {
        this.TOGGLE_DIALOG({ name: 'addResult', isOpen: true, title: 'Add supplement' })
      }
    },
    openShowPreviousProtocol () {
      if (!this.visit.finishedOn || (!['InProtocol', 'NeedProduct', 'Completed', 'ProtocolEnding'].includes(this.visit.status))) {
        this.TOGGLE_DIALOG({ name: 'dialogShowPreviousProtocol', isOpen: true, title: 'Select protocol' })
      }
    },
    openShowProtocol () {
      if (!this.visit.finishedOn || (!['InProtocol', 'NeedProduct', 'Completed', 'ProtocolEnding'].includes(this.visit.status))) {
        this.TOGGLE_DIALOG({ name: 'dialogShowProtocol', isOpen: true, title: 'Select protocol' })
      }
    },

    openModalConfirmationDeleteVisit () {
      this.TOGGLE_DIALOG(
        {
          name: 'modalConfirmationDeleteVisit',
          isOpen: true,
          title: 'You are about to delete an Evaluation and all of its associated data',
          visitId: this.visit.id,
          data: '<p>Click <span style="font-weight: bold;color: #008C89">Confirm</span> to continue or <span style="font-weight: bold;color: #CF4655">Close</span> to cancel this request.</p>'
        })
    },
    updateOrderMethod (val) {
      this.priceModel = val
    },
    updateSurvey (val) {
      this.isUpdateSurvey = val
    },
    updateResult (val, systemParam) {
      if (systemParam) {
        this.isUpdateResults = val
        this.alertIsOpen = true
        if (systemParam.data) {
          this.alertColor = '#7ac1fb'
          this.message = `${systemParam.data} was selected`
        }
        if (systemParam.error) {
          this.alertColor = '#CF4655'
          this.message = `${systemParam.error}`
        }
        const self = this
        setTimeout(function () {
          self.alertIsOpen = false
        }, 3000)
      }
    },
    updateProtocol (val, systemParam) {
      if (systemParam) {
        this.alertIsOpen = true
        if (systemParam.data) {
          this.alertColor = '#7ac1fb'
          this.message = `${systemParam.data} was selected`
        }
        if (systemParam.error) {
          this.alertColor = '#CF4655'
          this.message = `${systemParam.error}`
        }
        const self = this
        setTimeout(function () {
          self.alertIsOpen = false
        }, 3000)
      }
    },
    loadData (id) {
      this.getVisit({id}).then(res => {
        this.calcMethod = this.calcMethodList.find(item => item.code === this.visit.calcMethodTypeCode)
        this.priceModel = this.patientOrderMethods.find(item => item.code === this.visit.receiptOrderTypeCode)
        if (res.status && res.status === 400) {
          this.alertIsOpen = true
          this.alertColor = '#CF4655'
          this.message = `${res.data.message}`
          const self = this
          setTimeout(function () {
            self.alertIsOpen = false
            self.$router.push({name: 'Patients'})
          }, 3000)
        } else {
          this.getNotes(id)
          this.$route.meta.headerTitle = `Visit details - ${this.$moment(this.visit.date).format('MM/DD/YYYY')} - ${this.visit.patient.firstName} ${this.visit.patient.lsatName}`
          this.visitDate = this.visit.date
          document.title = `Visit ${this.$moment(this.visitDate).format('MM/DD')} - Maestro Practitioner Portal`
          this.getPatient({ id: this.visit.patient.id })
        }
      })
    },
    runMethod (name) {
      if (name === 'Approve') {
        this.sendSurvey(this.visit)
        this.loadData(this.$route.params.id)
      }
      if (name === 'Complete') {
        this.openModalCompleteVisit()
      }
    },
    async sendSurvey (visit) {
      if (visit.status === 'NeedReview' || visit.status === 'SurveySent' || visit.status === 'SurveyDue') {
        const res = await this.approveSurvey({ surveyId: this.visit.surveyId, visitId: this.visit.id })
        this.alertIsOpen = true
        this.message = 'Survey was approved. Survey Maestro calculating results based on the Survey data..'
        const self = this
        if (res) {
          setTimeout(function () {
            self.$emit('changeTab', { title: 'results', component: 'results' })
          }, 1300)
        }
      }
    },
    tab ($event) {
      console.log('tab', $event)
      this.componentInd = $event
      this.tabIndex = $event
    },
    edit ($event) {
      $event.preventDefault()
      const { id } = this.visit.patient
      if (id) {
        this.TOGGLE_DIALOG({ id: id, name: 'editVisit', title: 'Reschedule Evalutation', isOpen: true })
      }
    },
    async changeStatus ($event) {
      const { id } = this.$route.params
      await this.updateStatus({ type: this.btns[$event].name, visitId: this.visit.id, surveyId: this.visit.surveyId })
    },
    updateNote ({ notes, data }) {
      const prepareData = {
        apiUrl: data.apiUrl,
        field: data.field,
        visitId: this.visit.id
      }
      if (data.apiUrl === 'survey/complaints') {
        prepareData.dataForSend = {
          complaints: notes.split('\n')
        }
      } else {
        prepareData.dataForSend = {
          notes: notes
        }
      }
      this.updateNotes(prepareData)
    },
    async completeSurvey (data) {
      const res = await this.finishSurvey({ visitId: this.$route.params.id, surveyId: this.visit.surveyId, ...data })
      if (res) {
        this.TOGGLE_DIALOG({ name: 'completeVisit', isOpen: false })
        this.loadData(this.$route.params.id)
        this.alertIsOpen = true
        const self = this
        setTimeout(function () {
          self.tab(2)
        }, 1300)
      }
    },
    openModalCompleteVisit () {
      this.TOGGLE_DIALOG({ name: 'completeVisit', title: ' Complete Evaluation', isOpen: true })
    },
    async updateVisitDate (val) {
      this.getSurveyGroups({ surveyID: this.visit.surveyId, additionalParam: '?includeAllGroups=true' })
      if (val) {
        this.visitDate = val
        document.title = `Visit ${this.$moment(this.visitDate).format('MM/DD')} - Maestro Practitioner Portal`
        const data = {
          id: this.$route.params.id,
          data: {
            id: this.$route.params.id,
            date: val,
            doctorId: this.visit.doctor.id,
            patientId: this.visit.patient.id
          }
        }
        const res = await this.updateReschedule(data)
        this.UPDATE_FIELD_DATA({ key: 'dueDate', value: res.date })
      }
    },
    updateBloodDate(val) {
      this.updateBloodTestDate({ id: this.visit.bloodTestId, params: { bloodTestId: this.visit.bloodTestId, bloodTestDate: val } })
    },
    changeTab (tab) {
      console.log('changeTab', tab)
      this.tabIndex = this.tabs.findIndex(item => item.component === tab.component)
      console.log('this.tabIndex', this.tabIndex)
      this.tab(this.tabIndex)
    },
    async showPopup () {
      this.alertIsOpen = true
      this.message = 'Days Supply was changed!'
      const self = this
      setTimeout(function () {
        self.alertIsOpen = false
      }, 1300)
      this.TOGGLE_DIALOG({ name: 'daysSupplyDialog', isOpen: false })
      this.isUpdateDaysSupply = true
    },
    openChangeDaysSupplModal () {
      if (this.tabs[this.componentInd].component === 'results') {
        if (!this.visit.finishedOn && this.visit.status !== 'Completed' && this.visit.status !== 'InProtocol' && this.visit.status !== 'ProtocolEnding') {
          this.TOGGLE_DIALOG({ name: 'daysSupplyDialog', title: 'Change Evaluation Supply Days', isOpen: true })
        }
      } else {
        this.TOGGLE_DIALOG({ name: 'daysSupplyDialog', title: 'Change Evaluation Supply Days', isOpen: true })
      }
    },
    refreshData () {
      if (this.tabs[this.componentInd].component === 'results') {
        if (!this.visit.finishedOn || (!['InProtocol', 'NeedProduct', 'Completed', 'ProtocolEnding'].includes(this.visit.status))) {
          switch (this.tabs[this.componentInd].component) {
            case 'survey':
              this.getSurveyGroups({ surveyID: this.visit.surveyId, additionalParam: '?includeAllGroups=true' })
              this.getDetailsTestComplaints({ surveyID: this.visit.surveyId })
              break
            case 'results':
              this.refreshResults({ id: this.visit.resultId })
              break
            case 'reports':
              console.log('rep')
              break
          }
        }
      } else {
        switch (this.tabs[this.componentInd].component) {
          case 'survey':
            this.getSurveyGroups({ surveyID: this.visit.surveyId, additionalParam: '?includeAllGroups=true' })
            this.getDetailsTestComplaints({ surveyID: this.visit.surveyId })
            break
          case 'results':
            this.refreshResults({ id: this.visit.resultId })
            break
          case 'reports':
            console.log('rep')
            break
        }
      }
      this.getNotes(this.$route.params.id)
    },
    openQuickEntry (type) {
      console.log('openQuickEntry', type)
      if (!this.visit.finishedOn || (!['InProtocol', 'NeedProduct', 'Completed', 'ProtocolEnding'].includes(this.visit.status))) {
        this.TOGGLE_DIALOG({ name: 'quickEntryDialog', title: 'Quick Entry Form', isOpen: true, data: { type } })
      }
    },
    openGroupSupplementTotals (type) {
      this.TOGGLE_DIALOG({ name: 'resultGroupSupplementTotals', title: `${type} Totals`, isOpen: true, data: { type } })
    },
    showStatusDescription () {
      this.showDescriptionStatus = !this.showDescriptionStatus
      clearTimeout(this.timerId)
      if (this.showDescriptionStatus) {
        const self = this
        this.$nextTick(() => {
          self.timerId = setTimeout(function () {
            self.showDescriptionStatus = false
          }, 3000)
        })
      }
    },
    async updatePriceModel ({ data }, oldValue) {
      const { params } = this.$route
      this.priceModel = this.patientOrderMethods.find(item => item.code === data.code) // data.form.code
      // if(['survey', 'reports'].includes(this.tabs[this.componentInd].component)){
      //   this.getVisit({id: params?.id, orderMethod: this.priceModel.code, calcMethod: this.calcMethod.code})
      // }
      if (oldValue !== data.name) {
        const resultVisit = await this.getVisit({id: params?.id, orderMethod: this.priceModel.code, calcMethod: this.calcMethod.code})
        if (resultVisit) {
          this.isUpdateVisit = true
        }
      }
    },
    async updateCalcMethod ({ data }, oldValue) {
      const { params } = this.$route
      this.calcMethod = this.calcMethodList.find(item => item.code === data.code)
      // if(['survey', 'reports'].includes(this.tabs[this.componentInd].component)) {
      //   this.getVisit({id: params?.id, orderMethod: this.priceModel.code, calcMethod: this.calcMethod.code})
      // }
      if (oldValue !== data.code) {
        const resultVisit = await this.getVisit({id: params?.id, orderMethod: this.priceModel.code, calcMethod: this.calcMethod.code})
        if (resultVisit) {
          this.isUpdateVisit = true
        }
      }
    }
  },
  destroyed () {
    this.tab(0)
  },
  mounted () {
    const { params } = this.$route
    sessionStorage.setItem('selectedPage', this.$route.path)
    if (params?.id) {
      this.loadData(params?.id)
      this.$store.state.visits.visit = null // temp code
    }
  },
  created () {
    const { query } = this.$route
    if (query?.tabIndex) {
      this.tabIndex = query.tabIndex
    }
  },
  computed: {
    ...mapState({
      visit: state => state.visits?.visit,
      patient: state => state.patients.patient,
      status: state => (state.surveys?.status?.visit?.status) ? state.surveys?.status?.visit?.status : state.surveys?.status?.status,
      subscriptionFeatures: state => state.subscription,
      patientOrderMethods: state => state.visits?.visit.orderMethods.$values.map(item => {
        return {
          ...item,
          text: item.name,
          value: item.code,
          id: item.$id
        }
      }),
      calcMethodList: state => state.visits?.visit.calculationMethods.$values.map(item => {
        return {
          ...item,
          text: item.name,
          value: item.code,
          id: item.$id
        }
      }),
      bloodData: state => state.blood.bloodData
    }),
    ...mapState('results', {
      notesForPractitioner (state) {
        return state?.notes?.$values
      }
    }),
    ...mapState('receipts', {
      receiptPreview (state) {
        return state?.receiptsPreview?.record
      },
      receiptPreviewData (state) {
        return state?.receiptsPreview?.record
      }
    }),
    notes () {
      const notes = [
        { id: 1, title: 'Notes for Practitioner', data: this.notesForPractitioner?.map((item, idx) => `${idx !== 0 ? '\n' : ''}${idx + 1 + '. ' + item}`).join().replaceAll(/,\n[0-9]/g, ((a) => {
          const number = a.replaceAll(',\n', '');
          return `.\n${number}`
        })).replaceAll('..', `.`).replaceAll(/[0-9]+\./g, ((a) => {

          return `\n ${a}`
        })), apiUrl: 'doctor-notes', disabled: true }, // field: 'doctorNotes',
        { id: 2, title: 'Notes for Patient', data: this.visit.patientNotes, apiUrl: 'patient-notes', field: 'patientNotes', disabled: ['Completed', 'Closed'].includes(this.visit.status) },
        { id: 3, title: 'Evaluation Notes', data: this.visit.evaluationNotes, apiUrl: 'evaluation-notes', field: 'evaluationNotes', disabled: ['Completed', 'Closed'].includes(this.visit.status) },
        { id: 4, title: 'Five main complaints', data: this.visit.surveyComplaints.$values.join().replaceAll(',', '\n'), apiUrl: 'survey/complaints', field: 'surveyComplaints', disabled: this.visit.finishedOn || ['InProtocol', 'NeedProduct', 'Completed', 'ProtocolEnding'].includes(this.visit.status) }
      ]
      return notes
    },
    tabs () {
      const allTabs = [
        { title: 'survey', component: 'survey', count: this.visit.totalSurveyAnsweredQuestions },
        { title: 'blood', component: 'blood', count: this.visit.totalBloodEnteredValues },
        { title: 'results', component: 'results', count: this.visit.totalSelectedResultSupplements },
        { title: 'reports', component: 'reports' },
        { title: 'invoices', component: 'receipt', count: this.visit.totalInvoices }
      ]
      if (!this.subscriptionFeatures.features.$values.includes('Blood')) { // for Blood
        allTabs.splice(1, 1)
      }
      if (this.subscriptionFeatures.productName.includes('Reports Only')) { // for receipt
        allTabs.splice(3, 1)
      }
      if (!this.subscriptionFeatures.features.$values.includes('Survey')) { // for survey
        allTabs.splice(0, 1)
      }
      return allTabs
    },
    dialogs () {
      return this.$store.state.dialogs
    },
    btns: () => ([{ name: 'Update' }, { name: 'Submit' }, { name: 'Approve' }, { name: 'Finish' }])
  },
  watch: {
    visit (val) {
      console.log({val})
      if (val) {
        console.log('visit', val)
        this.$route.meta.headerTitle = `Visit details - ${this.$moment(val.date).format('MM/DD/YYYY')} - ${val.patient.firstName} ${val.patient.lastName}`
        this.visitDate = val.date
        document.title = `Visit ${this.$moment(this.visitDate).format('MM/DD')} - Maestro Practitioner Portal`
      }

    },
  },
  beforeDestroy () {
    localStorage.removeItem('groupDescription')
  }
}
</script>

<style lang="scss" scoped>
  .view-visit {

    .disabled {
      pointer-events: none;
    }
    //overflow-y: auto;
    ::v-deep .v-card {
      .v-card__text {
        padding: 0!important;
      }
    }
    ::v-deep .tabs {
      .v-window {
        padding: 1px;
        //&__container {
        //  .v-window-item {
        //    .v-card {
        //      box-shadow: 0px 3px 1px -2px rgb(0 0 0 / 20%), 0px 2px 2px 0px rgb(0 0 0 / 14%), 0px 1px 5px 0px rgb(0 0 0 / 12%) !important;
        //    }
        //  }
        //}
      }
    }
    background: #F7FAFC;
    .btn-cost {
      cursor: pointer;
      display: flex;
      align-items: center;
      margin-left: 3px;
      font-weight: 600;
      font-size: 14px;
      color: #425466;
      .days-60 {
        color: $dark-green;
        text-decoration: underline;
      }
      .days-60.disable-days {
        color: red;
      }
    }
    ::v-deep .tabs .v-tabs .v-item-group {
      background: transparent!important;
    }
    ::v-deep .tabs .v-tabs-items {
      background: transparent!important;
    }
    ::v-deep .tabs .v-window .v-window-item .v-card {
      border-radius: 16px!important;
    }
    ::v-deep .v-input {
      &__slot {
        margin: 0;
      }
      .v-messages {
        display: none;
      }
    }
    .v-icon {
      cursor: pointer;
    }
    .wrap-container {
      column-gap: 0.5rem;
    }
    .loading {
      height: 100%;
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
    }
    .green-btn-color-text {
      color: $dark-green;
    }
    .gray-btn-color-text {
      color: #A0AEC0;
    }
    .price-model-select {
      //border: 1px solid #cdcdcd;
      border-radius: 8px;
      padding: 5px;
      .select {
        ::v-deep .v-input {
          height: 36px!important;
          width: 185px!important;
        }
      }
    }
    .input-setting, .checkbox-setting {
      ::v-deep .v-input {
        width: 42px!important;
        &__control {
          .v-text-field__details {
            display: none!important;
          }
          .v-input__slot {
            padding: 0 1px!important;
          }
        }
      }
    }
    .checkbox-setting .checkbox {
      width: 25px;
    }

    &__header-status{
      width: 146px;
      margin: 6px auto 0;
    }
    &__header {
      display: flex;
      flex-wrap: wrap;
      align-items: center;
      column-gap: 0.5rem;
      &-title {
        font-style: normal;
        font-weight: 600;
        font-size: 20px;
        color: #27272E;
        padding-left: 10px;
        margin-top: -20px;
      }
      .time-line-wrap {
        position: relative;
        height: 85px; // 75px;
        padding-top: 14px;
        margin-top: -14px;
        display: flex;
        column-gap: 1rem;
        max-width: calc(100% - 90px);
        width: 87%;
        overflow-x: auto;
        overflow-y: hidden;
        .status-btn {
          border-top-right-radius: 10px;
          border-bottom-right-radius: 10px;
          border-top-left-radius: 10px;
          border-bottom-left-radius: 10px;
          position: relative;
          width: 140px; // 112px;
          display: flex;
          align-items: center;
          flex-wrap: wrap;
          justify-content: center;
          cursor: pointer;
          text-align: center;
          padding: 5px 2px;
          font-family: 'Poppins';
          .status-name {
            font-weight: 500;
            font-size: 12px;
            width: 100%;
          }
          .status-date {
            font-size: 10px;
          }
          .current-status.triangle {
            position: absolute;
            transform: rotate(225deg);
            top: -15px;
            left: 50%;
            box-sizing: border-box;
            width: 10px;
            height: 10px;
            border-top-left-radius: 1px;
            border-right: solid 5px transparent;
            border-bottom: solid 5px transparent;
            border-left: solid 5px $dark-green;
            border-top: solid 5px $dark-green;
          }
          .triangle {
            position: absolute;
            right: -8px;
            transform: rotate(135deg);
            box-sizing: border-box;
            width: 20px;
            height: 20px;
            border-top-left-radius: 5px;
            border-right: solid 10px transparent;
            border-bottom: solid 10px transparent;
          }
          .triangle-left {
            position: absolute;
            left: -20px;
            width: 0;
            height: 0;
            border-top: 31px solid #f7fafc;
            border-right: 31px solid transparent;
            transform: rotate(135deg);
          }
        }
        //.status-btn:before {
        //  content: '';
        //  position: absolute;
        //  right: -25px;
        //  width: 0;
        //  height: 0;
        //  transform: rotate(90deg);
        //  border-bottom: 12px solid #000;
        //  border-left: 20px solid transparent;
        //  border-right: 20px solid transparent;
        //}
        .light-grey-bg {
          background-color: #EDF2F7;
        }
        .light-grey-text {
          color: #A0AEC0;
        }
        .green-light-bg {
          background-color: #79d6d4;
        }
        .green-text {
          color: $dark-green;
        }
        .white-text {
          color: #FFFFFF;
        }
        .green-bg {
          background-color: $dark-green;
        }
        .border-left-light-grey {
          border-left: solid 10px #EDF2F7;
        }
        .border-left-light-green {
          border-left: solid 10px #79d6d4;
        }
        .border-left-green {
          border-left: solid 10px $dark-green;
        }
        .border-top-light-grey {
          border-top: solid 10px #EDF2F7;
        }
        .border-top-light-green {
          border-top: solid 10px #79d6d4;
        }
        .border-top-green {
          border-top: solid 10px $dark-green;
        }
      }
      ::-webkit-scrollbar:horizontal {
        height: 15px;
      }
      ::-webkit-scrollbar-track:horizontal {
        background: #F7FAFC;
      }
      ::-webkit-scrollbar-thumb:horizontal {
        //background: #d1eadd;
        border-radius: 10px;
        -webkit-box-shadow: inset 0 0 6px rgba(0,0,0,.3);
        background-color: #d1eadd;
      }
    }
    .custom-btn-status {
      background-color: #E4EBFE;
      border-radius: 8px;
      padding: 10px 8px;
      cursor: pointer;
    }

    .visit-card-main-data {
      padding: 8px!important;
      border-radius: 16px !important;

      .main-row {
        display: flex;
        flex-wrap: wrap;
        align-items: center;
        column-gap: 0.3rem;
        row-gap: 2rem;

        .full-name-avatar {
          display: flex;
          flex-wrap: wrap;
          align-items: center;
          column-gap: 0.5rem;
          //width: 400px;
          &__avatar {
            cursor: pointer;
          }
          &__full-name {
            width: 160px;
            font-style: normal;
            font-weight: 600;
            font-size: 18px;
            color: #27272E;
            cursor: pointer;
          }
        }
        .last-visit {
          display: flex;
          flex-wrap: wrap;
          align-items: center;
          column-gap: 0.2rem;
          width: 150px;
          &__title {
            font-style: normal;
            font-size: 16px;
            color: #718096;
          }
          &__date {
            .btn-date {
              position: relative;
              display: flex;
              align-items: center;
              column-gap: 0.5rem;
              border: 1px solid $dark-green;
              border-radius: 8px;
              padding: 6px 10px;
              &__text {
                color: #27272E;
                font-weight: 600;
                font-size: 16px;
              }
              .datepicker {
                ::v-deep .v-input {
                  input {
                    user-select: none;
                    cursor: pointer;
                  }
                  }
                }
              }
            .btn-date.disabled {
              cursor: unset;
              pointer-events: none;
            }
          }
        }
        .patient-parameters {
          display: flex;
          flex-wrap: wrap;
          align-items: center;
          column-gap: 0.3rem;
          &__title {
            font-style: normal;
            font-size: 16px;
            color: #718096;
          }
          &__value {
            font-weight: 600;
            color: #27272E;
          }
        }
        .visit-controls {
          justify-content: end;
          width: 310px;
          display: flex;
          flex-wrap: wrap;
          align-items: center;
          column-gap: 0.2rem;
        }
      }
    }
    .visit-controls__supply-days {
      margin-left: 35px;
      display: flex;
      cursor: pointer;
      display: flex;
      align-items: center;
      font-weight: 600;
      font-size: 14px;
      color: #425466;

      .days-60 {
        color: $dark-green;
        text-decoration: underline;
      }

      .days-60.disable-days {
        color: red;
      }
    }

    .visit-controls__main-button {}
    .visit-controls__main-button.in-progress {}
    .visit-controls__main-button.survey-sent {
      ::v-deep .v-btn {
        background-color: #668BFF;
      }
    }
    .visit-controls__main-button.survey-due {
      ::v-deep .v-btn {
        background-color: #F7936F;
      }
    }
    .visit-controls__main-button.need-review {
      ::v-deep .v-btn {
        background-color: #668BFF;
      }
    }
    .visit-controls__main-button.reviewed {}
    .visit-controls__main-button.in-protocol {
      ::v-deep .v-btn {
        background-color: #668BFF;
      }
    }
    .visit-controls__main-button.protocol-ending {
      ::v-deep .v-btn {
        background-color: #FFE9E2;
      }
    }
    .visit-controls__main-button.closed {
      display: none;
    }

    .need-product {
      background: rgba(255, 237, 227, 0.7)!important;
      color: #F7936F!important;
      text-align: center!important;
    }
    .in-active {
      background: #EDF2F7!important;
      color: #A0AEC0!important;
      text-align: center!important;
    }
    .in-progress {
      background: rgba(208, 220, 255, 0.5);
      color: #668BFF;
      text-align: center!important;
    }
    .need-survey {
      background: rgba(255, 237, 227, 0.7)!important;
      color: #F7936F!important;
      text-align: center!important;
    }
    .need-reeval {
      background: rgba(255, 237, 227, 0.7)!important;
      color: #F7936F!important;
      text-align: center!important;
    }
    .survey-sent {
      background: rgba(208, 220, 255, 0.5);
      color: #668BFF;
      text-align: center!important;
    }
    .survey-due {
      background: rgba(255, 230, 228, 0.7)!important;
      color: #F16063!important;
      text-align: center!important;
    }
    .need-review {
      background: rgba(255, 237, 227, 0.7)!important;
      color: #F7936F!important;
      text-align: center!important;
    }
    .reviewed {
      background: rgba(208, 220, 255, 0.5)!important;
      color: #668BFF!important;
      text-align: center!important;
    }
    .in-protocol {
      background: rgba(0, 140, 137, 0.16)!important;
      color: #008C89!important;
      text-align: center!important;
    }
    .protocol-ending {
      background: #FFE9E2!important;
      color: #E69376!important;
      text-align: center!important;
    }
    .completed {
      background: rgba(141, 186, 106, 0.2)!important;
      color: #8DBA6A!important;
      text-align: center!important;
    }
    .custom-alert {
      max-width: 300px;
      position: fixed;
      top: 10px;
      right: 20px;
      z-index: 100;
    }

    .wrapper-blood-control {
      margin-top: 16px;
      display: flex;
      justify-content: space-between;
      align-items: center;
      ::v-deep .v-input {
        margin-top: 0px!important;
        padding-top: 0px!important;
        align-items: center!important;
      }
      &__block {
        display: flex;
        column-gap: 10px;
        .legend {
          width: auto;
          padding: 5px;
          border-radius: 5px;
          display: flex;
          align-items: center;
        }
        .blood-lab {
          background-color: #FFD3D0;
        }
        .blood-optimal {
          background-color: #FFEDBF;
        }
        .legend-text {
          font-size: 13px;

          &.bold {
            font-weight: bold;
          }
        }
      }

      .blood-draw-date {
        display: flex;
        column-gap: 10px;
        align-items: center;
        &__label {
          color: #425466;
          font-weight: 300;
        }
        &__date {
          position: relative;
          background-color: white;
          border-radius: 8px;
          padding: 5px 15px;
          cursor: pointer;
        }
      }
    }
  }
</style>
