<template>
  <div class="actions">
    <v-menu left
      attach
      v-if="computedItems.length > 1 || isMenuList"
      :close-on-content-click="true"><!--items.length > 1-->

      <template v-slot:activator="{ on, attrs }">
        <v-btn icon
          v-bind="attrs"
          v-on="on">
          <v-icon class="dots"
            @click.native="action(item)"
          >mdi-dots-vertical</v-icon>
        </v-btn>

      </template>

      <v-list class="px-0 py-0">
        <template v-for="(action, i) in computedItems">
          <v-list-item
            data-action="action"
            @click="emitAction(action)"
            :key="i">

            <div class="d-flex align-center">
              <div v-if="action.icon" class="pr-2">
                <v-icon :color="action.color">{{action.icon}}</v-icon>
              </div>
              <div v-if="action.name">{{action.name}}</div>
            </div>

          </v-list-item>
        </template>
      </v-list>
    </v-menu>

    <div v-else class="d-flex justify-center">
      <v-icon @click.native="emitAction(computedItems[0])" :color="computedItems[0].color">{{computedItems[0].icon}}</v-icon>
    </div>
  </div>
</template>

<script>
export default {
  name: 'action',
  props: {
    items: {
      type: Array
    },
    item: {
      type: Object
    },
    additionalParams: {
      type: Object
    },
    isMenuList: {
      type: Boolean,
      default: false
    }
  },
  methods: {
    action (item) {
      item.actions = !item.actions
    },
    emitAction (params) {
      this.$emit('action', { params, id: this.item.id, data: this.item, additionalParams: this.additionalParams })
    }
  },
  computed: {
    computedItems () {
      if (!this.additionalParams) {
        return this.items
      } else {
        return this.items.filter(item => item.higherRights)
      }
    }
  }
}
</script>

<style lang="scss" scoped>
  .actions {
    // .v-list {
    //   background-color: transparent;
    //   border-radius: 8px;
    //   .v-icon {
    //     cursor: pointer;
    //   }
    // }
    ::v-deep .v-menu {
      &__content {
        border-radius: 8px;
      }
    }
  }
</style>
