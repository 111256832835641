<template>
  <div class="report-view">
    <!--    <Dialog :scrollable="false">
          <template slot="title">
            <span>
              {{dialog.title}}
            </span>
          </template>
          <template slot="body">
            <WebViewer
              :initialDoc="initialDoc"
            />
          </template>
        </Dialog>-->
    <v-row no-gutters class="justify-space-between align-center pa-5">
      <v-col cols="4">
        <div class="title">
          {{dialog?.title || ''}}
        </div>
      </v-col>
      <v-col v-if="showActionButton" cols="8" class="d-flex justify-end report-buttons align-center">
        <!--        <Button color="#d2d2d2"
                        outlined
                        dark>
                  <v-icon>mdi-send-outline</v-icon>
                  <span class="btn-text">Send to Patient</span>
                </Button>
                <Button color="#d2d2d2"
                        outlined
                        dark>
                  <span class="btn-text">Send to Portal</span>
                </Button>-->
        <div class="action-btn-report">
          <img @click="downloadFile" :src="getImgWithExt(['icons', 'report-download-icon', 'svg'])" alt="icon" />
        </div>
        <div class="action-btn-report">
          <img @click="print" :src="getImgWithExt(['icons', 'report-print-icon', 'svg'])" alt="icon" />
        </div>
        <div class="action-btn-report">
          <img @click="sendToPatientEmail" :src="getImgWithExt(['icons', 'report-send-email-icon', 'svg'])" alt="icon" />
        </div>
        <div class="language-switcher">
      <!--v-show="!disabledSpanish"-->
          <Button
            block
            dark
            :class="{ 'active-language': activeLanguage === 'es' }"
            @click.native="switchLanguage('es')"
            tabindex="3" height="36">
            Spanish
          </Button>
          <Button
            block
            dark
            :class="{ 'active-language': activeLanguage === 'en' }"
            @click.native="switchLanguage('en')"
            tabindex="3" height="36">
            English
          </Button>
        </div>
      </v-col>
      <!--<v-col cols="3">
        <div class="language-switcher">
          <v-row class="d-flex">
            <v-col cols="6" v-if="!disabledSpanish">
              <Button
                block
                dark
                :class="{ 'active-language': activeLanguage === 'es' }"
                @click.native="switchLanguage('es')"
                tabindex="3" height="36">
                Spanish
              </Button>
            </v-col>
            <v-col :cols="disabledSpanish ? 12 : 6">
              <Button
                block
                dark
                :class="{ 'active-language': activeLanguage === 'en' }"
                @click.native="switchLanguage('en')"
                tabindex="3" height="36">
                English
              </Button>
            </v-col>
          </v-row>
        </div>
      </v-col>-->
    </v-row>
    <!--<WebViewer
      :initialDoc="initialDoc"
      @renderWebViewer="renderWebViewer"
    />-->
    <iframe :src="initialDoc"
            width="100%"
            id="pdfDocument"
            :height="frameHeight ? frameHeight : '590'">
    </iframe>
  </div>
</template>

<script>
import { mapState } from 'vuex'
// import WebViewer from '@/components/elements/WebViewer'
import Button from '@/components/elements/Button'
import Image from '@/mixins/Image'
// import Dialog from '@/components/layouts/dialog'

export default {
  props: {
    activeLanguage: String,
    initialDoc: {
      type: String,
      default: null
    },
    frameHeight: {
      type: String,
      default: null
    },
    showActionButton: {
      type: Boolean,
      default: true
    },
    disabledSpanish: {
      type: Boolean,
      default: false
    },
    fromReceiptPrint: {
      type: Boolean,
      default: false
    },
    isMultipleReports: {
      type: Boolean,
      default: false
    }
  },
  components: { Button }, // WebViewer
  name: 'reportView',
  mixins: [Image],
  data: () => ({
    localInstance: null
  }),
  mounted () {
    if (this.fromReceiptPrint) {
      const self = this
      setTimeout(() => {
        self.print()
      }, 4000)
    }
  },
  methods: {
    async downloadFile () {
      // const blob = Object.keys(this.reportsSurvey).length === 0 ? this.receiptReport : this.reportsSurvey
      if (!this.isMultipleReports) {
        const url = this.initialDoc // window.URL.createObjectURL(blob)

        const link = document.createElement('a')
        document.getElementsByTagName('body')[0].appendChild(link)
        link.href = url
        link.download = 'Report.pdf'

        link.click()
        document.getElementsByTagName('body')[0].removeChild(link)
      } else {
        this.$emit('downloadMultipleReports')
      }
    },
    
    switchLanguage (val) {
      this.$emit('switchLanguage', val)
    },
    renderWebViewer (instance) {
      this.localInstance = instance
    },
    sendToPatientEmail () {
      this.$emit('sendSurveyFormToPatient')
    },
    print () {
      // console.log('print', this.localInstance)
      // const { UI } = this.localInstance // Core
      // // const { documentViewer } = Core
      // UI.enableElements('printModal')
      // this.localInstance.UI.print()
      const doc = document.getElementById('pdfDocument')
      doc.contentWindow.print()
    }
  },
  computed: mapState({
    dialog (state) {
      const modalName = this._.findKey(state.dialogs, ['isOpen', true])
      return state.dialogs[modalName]
    },
    doctors: state => state.patients?.doctors?.$values,
    model: state => state.visits?.model,
    reportsSurvey: state => state.reports.reportsSurvey,
    receiptReport: state => state.receipts.receiptReport
  })
}
</script>

<style scoped lang="scss">
.report-view {
  //height: inherit;
  .language-switcher {
    display: flex;
    max-width: 180px;
    background-color: #F7FAFC;
    border-radius: 10px;
    border: 1px solid #f1f1f1;
    padding: 4px;
    margin-bottom: 10px;
    box-shadow: 0px 0px 1px rgba(12, 26, 75, 0.24), 0px 3px 8px -1px rgba(50, 50, 71, 0.05);

    .button {
      ::v-deep .v-btn {
        background-color: rgba(255, 255, 255, 0.64) !important;
        color: black!important;
        font-weight: 600!important;
      }
    }
    .button.active-language {
      ::v-deep .v-btn {
        background-color: $dark-green !important;
        color: white!important;
        font-weight: 600!important;
      }
    }
  }
  .title {
    font-weight: 600;
    font-size: 20px;
    color: #16192C;
  }
  .btn-text {
    font-weight: 600;
    font-size: 12px;
    color: #16192C;
    padding-left: 6px;
  }
  .report-buttons {
    column-gap: 1rem;

    .action-btn-report {
      cursor: pointer;
      margin-top: -10px;
      background-color: #F7FAFC;
      border-radius: 10px;
      padding: 5px;
      height: 28px;
    }
  }
}
</style>
