import Vue from 'vue'

const state = () => ({
  listReceipts: [],
  receiptsPreview: null,
  generatedReceipts: null,
  currentReceiptReport: null,
  receiptReport: {}
})

const getters = {}

const actions = {
  async getReceipts ({ commit }, resultId) {
    const result = await Vue.$http.get(`results/${resultId}/receipts`)
    if (result?.status === 200) {
      commit('SET_DATA', ['listReceipts', result.data])
      return true
    }
  },
  async getReceiptById ({ commit }, params) {
    const { resultId, receiptId } = params
    if (resultId && receiptId) {
      const result = await Vue.$http.get(`results/${resultId}/receipts/${receiptId}/report`)
      if (result?.status === 200) {
        commit('SET_DATA', ['currentReceiptReport', result.data])
      }
    }
  },
  async createReceiptPreview ({ commit }, params) {
    const { form } = params
    if (form.resultId) {
      const result = await Vue.$http.post(`results/${form.resultId}/receipts/create-preview`, { ...form })
      if (result?.status === 200) {
        commit('SET_DATA', ['receiptsPreview', result.data])
        return true
      }
    }
  },
  async generateReceipts ({ commit }, params) {
    const { form } = params
    if (form.resultId) {
      const result = await Vue.$http.post(`results/${form.resultId}/receipts`, { ...form })
      console.log(result)
      if (result?.status === 200) {
        commit('SET_DATA', ['generatedReceipts', result.data])
        return result.data
      }
    }
  },
  async deleteReceipt ({ commit }, params) {
    const { form } = params
    if (form.resultId) {
      const result = await Vue.$http.delete(`results/${form.resultId}/receipts/${form.receiptId}`, { ...form })
      if (result?.status === 200) {
        return result.data.record
      }
    }
  },
  async getResult ({ commit }, id) {
    const result = await Vue.$http.get(`Results/ResultSupplements/${id}/Get`)
    if (result?.status === 200) {
      commit('SET_DATA', ['result', result.data?.record])
    }
  },
  async updateResult ({ commit }, params) {
    const { id, form } = params
    const result = await Vue.$http.put(`Results/ResultSupplements/${id}/Update`, { ...form })
    if (result?.status === 200) {
      commit('dialogs/TOGGLE_DIALOG', { name: 'editResult', isOpen: false }, { root: true })
    }
    return result?.data
  },
  deleteResult ({ _ }, id) {
    return new Promise((resolve) => {
      Vue.$http.delete(`/Results/ResultSupplements/${id}/Delete`).then((result) => {
        if (result?.status === 200) {
          resolve()
          console.log(`Respons: The result was delete; Status: ${result?.status}`)
        }
      })
    })
  },
  async createSupplementProduct ({ commit }, data) {
    const result = await Vue.$http.post('ResultSupplementProducts/Create', { ...data })
    if (result?.status === 201) {
      return result.data?.record
    }
  },
  async removeSupplementProduct ({ commit }, { resultSupplementId, supplementProductId }) {
    const result = await Vue.$http.delete(`ResultSupplementProducts/${supplementProductId}/Delete`)
    if (result?.status === 200) {
      return result.data?.record
      // commit('REMOVE_SUPPLEMENT_PRODUCT', { resultSupplementId, supplementProductId })
    }
  },
  async updateSupplementProduct ({ commit }, { resultSupplementId, supplementProductId, numberOfBottles }) {
    const result = await Vue.$http.put('ResultSupplementProducts/Update', { id: supplementProductId, numberOfBottles })
    if (result?.status === 200) {
      return result.data?.record
      // commit('UPDATE_SUPPLEMENT_PRODUCT', { resultSupplementId, supplementProductId, numberOfBottles })
    }
  },
  async updateNotes ({ commit }, params) {
    const { apiUrl, field, dataForSend } = params
    const result = await Vue.$http.put(`Results/${apiUrl}/Update`, { ...dataForSend })
    if (result?.status === 204 || result?.status === 200) {
      if (field) {
        commit('UPDATE_DATA_POINT', { key: field, value: dataForSend.notes })
        return result?.data.record
      } else {
        commit('UPDATE_STORE_DATA', result?.data.record)
        return result?.data.record
      }
    }
  },
  async getGroupTotals ({ commit }, id) {
    const result = await Vue.$http.get(`Results/${id}/GroupTotals`)
    if (result?.status === 200) {
      commit('SET_DATA', ['groupTotals', result.data?.record])
    }
  },
  async getSupplementTotals ({ commit }, id) {
    const result = await Vue.$http.get(`Results/${id}/SupplementTotals`)
    if (result?.status === 200) {
      commit('SET_DATA', ['supplementTotals', result.data?.record])
    }
  },
  async addProtocol ({ commit }, params) {
    const { id, protocolId } = params
    const result = await Vue.$http.put(`/Results/${id}/AddProtocol?protocolId=${protocolId}`)
    if (result?.status === 200) {
      return true
    }
  },
  async printReceipt ({ commit }, { params, code }) {
    const { resultId, receiptId } = params
    const result = await Vue.$http.post(`results/${resultId}/receipts/${receiptId}/print?orderTypeCode=${code}`, {}, { responseType: 'blob' }) // { responseType: 'arraybuffer' }
    if (result?.status === 200) {
      commit('SET_DATA', ['receiptReport', result.data])
      return result?.data
    }
  },
  async emailToPatient ({ commit }, params) {
    const { resultId, receiptId } = params
    const result = await Vue.$http.post(`results/${resultId}/receipts/${receiptId}/send-email-to-patient`)
    if (result?.status === 200) {
      return result.data
    }
  },
  async emailStandardProcess ({ commit }, params) {
    const { resultId, receiptId } = params
    const result = await Vue.$http.post(`results/${resultId}/receipts/${receiptId}/send-to-dropship`)
    if (result?.status === 200) {
      return result.data
    }
  }
}

const mutations = {
  SET_DATA: (state, payload) => {
    const [property, value] = payload
    Vue.set(state, property, value)
  },
  UPDATE_DATA: (state, payload) => {
    for (const key in payload) {
      state.results.record[key] = payload[key]
    }
  },
  UPDATE_DATA_POINT: (state, payload) => {
    state.results.record[payload.key] = payload.value
  },
  UPDATE_STORE_DATA: (state, payload) => {
    console.log(payload)
    state.results.record.resultSupplements.$values.map((item, index) => {
      if (item.id === payload.resultSupplements.$values[0].id) {
        console.log('current res', item, 'newRes', payload)
        Object.assign(item, { ...payload.resultSupplements.$values[0] })
      }
    })
    state.results.record.dailyPrice = payload.dailyPrice
    state.results.record.daysSupply = payload.daysSupply
    state.results.record.monthlyPrice = payload.monthlyPrice
    state.results.record.totalBottles = payload.totalBottles
    state.results.record.totalDosage = payload.totalDosage
    state.results.record.totalDosage3pm = payload.totalDosage3pm
    state.results.record.totalDosage7pm = payload.totalDosage7pm
    state.results.record.totalDosage10am = payload.totalDosage10am
    state.results.record.totalDosageBeforeSleep = payload.totalDosageBeforeSleep
    state.results.record.totalDosageBreakfast = payload.totalDosageBreakfast
    state.results.record.totalDosageDinner = payload.totalDosageDinner
    state.results.record.totalDosageLunch = payload.totalDosageLunch
    state.results.record.totalDosageUponArising = payload.totalDosageUponArising
    state.results.record.totalPrice = payload.totalPrice
    state.results.record.weeklyPrice = payload.weeklyPrice
  },
  REMOVE_SUPPLEMENT_PRODUCT: (state, payload) => {
    state.results.record.resultSupplements.$values.map(item => {
      if (item.id === payload.resultSupplementId) {
        item.resultProducts.$values = item.resultProducts.$values.filter(product => {
          return product.id !== payload.supplementProductId
        })
      }
    })
  },
  UPDATE_SUPPLEMENT_PRODUCT: (state, payload) => {
    state.results.record.resultSupplements.$values.map(item => {
      if (item.id === payload.resultSupplementId) {
        item.resultProducts.$values.map(product => {
          if (product.id === payload.supplementProductId) {
            product.quantity = payload.numberOfBottles
          }
        })
      }
    })
  }
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}
