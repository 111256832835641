<template>
  <div class="support pl-4">
    <p><b>Systems Survey Maestro</b> has been designed using custom algorithms based on years of clinical experience to carefully evaluate the individual patient nutritional needs. This greatly enhances the efficacy of the recommended supplements and prioritizes the patients support needs.</p>

    <p>It was designed and tested by experts in Whole-Food Nutrition, stress-tested in actual clinic use, and loaded with additional support information to assist you to more accurately evaluate your patient needs.</p>

    <h2>Ease of use</h2>
    <p>Systems Survey Maestro was designed from the ground up, using the latest programming technology, to give you the quickest, easiest to use, and most accurate Systems Survey analysis system available today.</p>

    <b>Program features of Systems Survey Maestro include:</b>
    <ul>
      <li>comprehensive interactive tutorials,</li>
      <li>the use of a Task Bar to quickly select all of the program features,</li>
      <li>full on-line help screens,</li>
      <li>on-line Clinical Reference manual,</li>
      <li>quick access to patient history,</li>
      <li>add your own supplements.</li>
    </ul>

    <h2>Systems Survey Maestro is not limited to any one PC</h2>
    <p>It can be used on an unlimited number of PC's within your office, without the need for special software. Even take it home or on the road with you on your laptop!</p>

    <h2>Our Technical Support Commitment</h2>
    <p>Systems Survey Maestro is designed to stay current in features and product information as new additions to the Standard Process and MediHerb product lines are made. Easy to install software updates will be available to ensure Systems Survey Maestro is up-to-date in features and product information.</p>

    <p>In addition to the proven, excellent support provided by Standard Process directly through their distributors, representatives, customer service staff, internet web site, and technical support people, you can contact Greene Software by telephone or email for specific support on the use of Systems Survey Maestro in your practice and proven techniques to manage and grow your practice with nutrition. Register today to get the support you deserve!</p>
  </div>
</template>

<script>
export default {
  name: 'Support'
}
</script>

<style lang="scss" scoped>
  .support {
    // ..
  }
</style>
