<div>
  <canvas></canvas>
</div>
<script>

import { Line } from 'vue-chartjs'
import Chart from 'chart.js'

export default {
  name: 'chart-line',
  props: {
    dataSet: Object
  },
  extends: Line,
  mounted () {
    Chart.pluginService.register({
      /* beforeDraw: function (chart) {
        const ctx = chart.chart.ctx
        const { data } = chart
        const { width } = chart.chart
        const { bottom, left, right } = chart.chart.chartArea // top
        // scrollbar
        ctx.beginPath()
        ctx.fillStyle = 'lightgrey'
        ctx.rect(left, bottom + 30, width - 30, 15)
        ctx.fill()
        ctx.closePath()

        ctx.beginPath()
        ctx.fillStyle = 'rgba(255, 26, 104, 1)'
        ctx.rect(left, bottom + 30, 15, 15)
        ctx.rect(right - 2, bottom + 30, 15, 15)
        ctx.fill()
        ctx.closePath()
        // movable
        // const min = chart.options.scales.x.min
        const startingPoint = left + 15 + width / data.datasets[0].data.length * 7
        const barWidth = (width - 30) / data.datasets[0].data.length * 7
        ctx.beginPath()
        ctx.fillStyle = 'black'
        ctx.rect(startingPoint, bottom + 30, barWidth, 15)
        ctx.fill()
        ctx.closePath()
      } */
      /* afterDraw: function (chart) {
        function scroller (scroll, chart) {
          console.log(scroll)
          console.log(chart)
          const dataLength = chart.chart.data.labels.length
          console.log('dataLength', dataLength)
          if (scroll.deltaY > 0) {
            if (chart.config.options.scales.x.max >= dataLength) {
              chart.config.options.scales.x.min = dataLength - 7
              chart.config.options.scales.x.max = dataLength
            } else {
              chart.config.options.scales.x.min += 1
              chart.config.options.scales.x.max += 1
            }
          } else if (scroll.deltaY < 0) {
            if (chart.config.options.scales.x.min <= 0) {
              chart.config.options.scales.x.min = 0
              chart.config.options.scales.x.max = 6
            } else {
              chart.config.options.scales.x.min -= 1
              chart.config.options.scales.x.max -= 1
            }
          }
          chart.chart.update()
        }
        chart.canvas.addEventListener('wheel', (e) => {
          if (e.target.id === 'line-chart') {
            scroller(e, chart)
          }
        })
      } */
    })
    this.renderChart(this.dataSet, {
      type: 'line',
      legend: {
        labels: false
      },
      layout: {
        padding: {
          right: 2,
          bottom: 30
        }
      },
      scales: {
        x: {
          min: 0,
          max: 6
        },
        yAxes: [{
          display: true,
          ticks: {
            min: -5,
            max: (Math.round((this.dataSet.datasets[0].data[this.dataSet.datasets[0].data.length - 1] / 10)) * 10) + (Math.round(((this.dataSet.datasets[0].data[this.dataSet.datasets[0].data.length - 1] / 100) * 40) / 10) * 10)
          }
        }]
      }
    })
  },
  watch: {
    'dataSet' (val) {
      this.renderChart(val, {
        type: 'line',
        legend: {
          labels: false
        },
        layout: {
          padding: {
            right: 2,
            bottom: 30
          }
        },
        scales: {
          x: {
            min: 0,
            max: 6
          },
          yAxes: [{
            display: true,
            ticks: {
              min: -5,
              max: (Math.round((this.dataSet.datasets[0].data[this.dataSet.datasets[0].data.length - 1] / 10)) * 10) + (Math.round(((this.dataSet.datasets[0].data[this.dataSet.datasets[0].data.length - 1] / 100) * 40) / 10) * 10)
            }
          }]
        }
      })
    }
  }
}
</script>

<style scoped>

</style>
