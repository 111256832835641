<template>
  <div class="btn-group">
    <v-row class="px-2 pb-2 ma-0">
      <v-btn-toggle v-model="toggle" mandatory group>
        <v-btn v-for="(item, key) in btns" :key="key" small :ripple="false" @click="selectBtn(key)">{{item.name}}</v-btn>
      </v-btn-toggle>
    </v-row>
  </div>
</template>

<script>
export default {
  name: 'btn-group',
  props: {
    btns: {
      type: Array
    }
  },
  data: () => ({
    toggle: +localStorage.getItem('quickFilterIndex') || undefined
  }),
  created () {
    this.toggle = this.qFilterBtns.findIndex(filter => filter.code === this.$route.query.quickFilterCode)
  },
  methods: {
    selectBtn (key) {
      this.$emit('update:btn', key)
    }
  },
  watch: {
    toggle (val) {
      localStorage.setItem('quickFilterIndex', val)
      // this.$emit('update:btn', val)
    }
  },
  computed: {
    qFilterBtns () {
      return this.$store.state.settings?.patients?.quickFilters?.$values
    }
  }
}
</script>

<style lang="scss" scoped>
  .btn-group {
    // ..
  }
</style>
