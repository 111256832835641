<template>
  <div class="blood">
    <Table
      :headers="bloodMarkersHeader"
      @searchByInput="searchMarker"
      @filer:update="filterGetBloodMarkers"
      hideDefaultFooter>

      <template v-slot:body>
        <template v-for="itemGroup in localBloodMarkersItems">
          <tr :key="itemGroup.id" class="group-line">
            <td :colspan="localBloodMarkersItems.length <= 5 ? 5 : localBloodMarkersItems.length">
              <div class="d-flex align-center blood-group" @click="toggleRow(itemGroup.name)">
                <v-icon v-if="!expandedRows.includes(itemGroup.name)" class="dark-green-color">mdi-chevron-down</v-icon>
                <v-icon v-if="expandedRows.includes(itemGroup.name)" class="dark-green-color">mdi-chevron-up</v-icon>
                <span style="font-weight: 600;">{{itemGroup.name}}</span>
                <span style="font-weight: 500;" class="circle dark-green-color">{{itemGroup.markers.length || itemGroup.markers.$values.length}}</span>
              </div>
            </td>
          </tr>
          <template v-for="(marker, markerIndex) in itemGroup.markers.$values || itemGroup.markers">
            <tr :key="marker.id" v-if="expandedRows.includes(bloodGroupingType !== BLOOD_GROUP_TYPE.sortByName ? marker.groupName : itemGroup.name)" style="height: 35px;">
              <td style="text-align: left;">
                <span class="marker-name" :class="{ 'marker-name-bold': !!marker.value }">{{marker.markerName}}</span>
              </td>
              <td style="text-align: center;cursor: pointer; border-left: 1px solid rgba(235,235,235,0.46); border-right: 1px solid rgba(235,235,235,0.46);"
                  class="blood-above-below"
                  :class="{
                    'isLabAboveOrBelow': currentInput?.marker.id !== marker.id && (marker.isLabAbove || marker.isLabBelow),
                    'isOptimalAboveOrBelow': currentInput?.marker.id !== marker.id && (marker.isOptimalAbove || marker.isOptimalBelow)
                  }"
                  @click="renderInputTag(marker, itemGroup, markerIndex)"
              >
                <template v-if="currentInput && currentInput.marker.id === marker.id">
                  <Input outlined
                         autofocus
                         ref="computedInput"
                         height="30px"
                         @keypress="filterInputData($event, { min: 0, max: 2000 }, 'number')"
                         @blur="removeInputFromTable($event.target.value)"
                         @keyup.enter="renderNextInput($event.target.value)"
                         @keyup.tab="renderNextInput($event.target.value)"
                         @shift="renderPrevInput"
                         isDynamicRenderInput
                         :value="marker.value"
                         :style="{'width': '100%'}"
                  >
                    <div slot="append" style="cursor: pointer" @click.stop="removeMarkerValue(marker.id)">
                      <v-icon>mdi-close</v-icon>
                    </div>
                  </Input>
                </template>
                <template v-else>
                  <span class="lab-optimal-value">
                    <template v-if="marker.isLabAbove || marker.isLabBelow">
                      <img v-if="marker.isLabAbove && marker.value >= marker.labRangeMax" :src="getImgWithExt(['icons', 'lab-above', 'svg'])" alt="icon" />
                      <img v-if="marker.isLabBelow && marker.value <= marker.labRangeMin" :src="getImgWithExt(['icons', 'lab-below', 'svg'])" alt="icon" />
                    </template>
                    <template v-else>
                      <img v-if="marker.isOptimalAbove && marker.value >= marker.optimalRangeMax" :src="getImgWithExt(['icons', 'optimal-above', 'svg'])" alt="icon" />
                      <img v-if="marker.isOptimalBelow && marker.value <= marker.optimalRangeMin" :src="getImgWithExt(['icons', 'optimal-below', 'svg'])" alt="icon" />
                    </template>
                  {{marker.value ? parseFloat(marker.value.toFixed(2).replace(/\.?0+$/, ''))  : ''}}
                </span>
                </template>
              </td>
              <td style="text-align: center" class="optimal-range optimal-lab-units">
                <span>{{parseFloat(marker.optimalRangeMin.toFixed(2).replace(/\.?0+$/, ''))}} - {{parseFloat(marker.optimalRangeMax.toFixed(2).replace(/\.?0+$/, ''))}}</span>
              </td>
              <td style="text-align: center" class="optimal-lab-units">
                <span>{{parseFloat(marker.labRangeMin.toFixed(2).replace(/\.?0+$/, ''))}} - {{parseFloat(marker.labRangeMax.toFixed(2).replace(/\.?0+$/, ''))}}</span>
              </td>
              <td style="text-align: center" class="optimal-lab-units units">
                <span>{{marker.units}}</span>
              </td>
            </tr>
          </template>
        </template>
      </template>
    </Table>
  </div>
</template>

<script>
import Input from '@/components/elements/Input'
import Table from '@/components/elements/Table'
import _ from 'lodash'
import { mapActions, mapState } from 'vuex'
import Image from '@/mixins/Image'
import Number from '@/mixins/Number'

export default {
  name: 'blood',
  mixins: [Image, Number],
  components: { Input, Table },
  props: {
    bloodIsFasting: {
      type: Boolean,
      default: false
    },
  },
  data: () => ({
    currentInput: null,
    localBloodMarkersItems: null,
    bloodItems: [
      {
        id: 1,
        group: 'Chemistries',
        marker: 'Glucose, Serum',
        value: 78,
        optimalRange: '75-86',
        labRange: '65-99',
        units: 'mg/dL'
      },
      {
        id: 2,
        group: 'Chemistries',
        marker: 'Hemoglobin A1c',
        value: 5.5,
        optimalRange: '4.6-5.5',
        labRange: '4.8-5.6',
        units: '%'
      },
      {
        id: 3,
        group: 'Chemistries',
        marker: 'Uric Acid, Serum',
        value: 100,
        optimalRange: '75-86',
        labRange: '65-99',
        units: 'mg/dL'
      },
      {
        id: 4,
        group: 'Chemistries',
        marker: 'BUN',
        value: 18,
        optimalRange: '10-16',
        labRange: '6-24',
        units: 'mg/dL'
      },
      {
        id: 5,
        group: 'Lipids',
        marker: 'Cholesterol, Total',
        value: 190,
        optimalRange: '180-220',
        labRange: '100-199',
        units: 'mg/dL'
      },
      {
        id: 6,
        group: 'Lipids',
        marker: 'Triglycerides',
        value: 78,
        optimalRange: '70-80',
        labRange: '0-149',
        units: 'mg/dL'
      },
      {
        id: 7,
        group: 'Lipids',
        marker: 'HDL Cholesterol',
        value: 46,
        optimalRange: '55-70',
        labRange: '40-59',
        units: 'mg/dL'
      }
    ],
    isGrouped: true,
    expandedRows: [],
    BLOOD_GROUP_TYPE: {
      showGroups: 0,
      outOfRange: 1,
      sortByName: 2
    },
    bloodGroupingType: 0
  }),
  mounted () {
    this.getBloodMarkers({ id: this.visit.bloodTestId, groupingType: 0 }).then(res => {
      if (res) {
        this.localBloodMarkersItems = this.bloodMarkersItems
        if (this.bloodData.isReadOnly) {
          this.localBloodMarkersItems = this.localBloodMarkersItems.map(group => {
            return {
              ...group,
              markers: {
                $values: group.markers.$values.filter(marker => marker.value !== null)
              }
            }
          })
        }
        this.expandedRows = this.localBloodMarkersItems.map(itemGroup => itemGroup.name)
      }
    })
    /* this.enableOrDisableBloodFasting({ id: this.visit.bloodTestId }).then(res => {
      if (typeof res === 'boolean') {
        console.log('disableBloodFasting', res)
      } else {
        console.log('error disableBloodFasting', res)
      }
    }) */
  },
  methods: {
    ...mapActions('blood', ['getBloodMarkers', 'updateBloodMarkers', 'enableOrDisableBloodFasting']),
    renderInputTag(marker, itemGroup, markerIndex) {
      if (!this.bloodData.isReadOnly) {
        this.currentInput = {
          marker,
          itemGroup,
          markerIndex
        }
        this.$nextTick(() => {
          this.$refs.computedInput[0].$el.querySelector('input[type="text"]')?.focus()
        })
      }
    },
    removeMarkerValue(markerId) {
      this.updateMarker(markerId, '')
      this.currentInput = null
    },
    renderPrevInput() {
      if (this.currentInput.markerIndex > 0) {
        this.currentInput = {
          marker: this.currentInput.itemGroup.markers.$values[this.currentInput.markerIndex - 1],
          itemGroup: this.currentInput.itemGroup,
          markerIndex: this.currentInput.markerIndex - 1
        }
        this.$nextTick(() => {
          this.$refs.computedInput[0].$el.querySelector('input[type="text"]')?.focus()
        })
      }
    },
    renderNextInput(value) {
      this.updateMarker(this.currentInput.marker.id, value)
      if (this.currentInput.itemGroup.markers.$values.length > (this.currentInput.markerIndex + 1)) {
        this.currentInput = {
          marker: this.currentInput.itemGroup.markers.$values[this.currentInput.markerIndex + 1],
          itemGroup: this.currentInput.itemGroup,
          markerIndex: this.currentInput.markerIndex + 1
        }
        this.$nextTick(() => {
          this.$refs.computedInput[0].$el.querySelector('input[type="text"]')?.focus()
        })
      } else {
        this.currentInput = null
      }
    },
    removeInputFromTable(value) {
      // this.updateMarker(this.currentInput.marker.id, value)
      this.currentInput = null
    },
    async updateMarker(markerId, value) {
      const dataToSend = {
        bloodTestId: this.visit.bloodTestId,
        markerId,
        value
      }
      const { totalBloodEnteredValues } = await this.updateBloodMarkers({id: this.visit.bloodTestId, params: dataToSend })
      await this.$store.dispatch('visits/updateTotalBloodEnteredValues', { totalBloodEnteredValues })
    },
    searchMarker(value) {
      if (!value) {
        this.localBloodMarkersItems = this.bloodMarkersItems
      } else {
        let arr = this.localBloodMarkersItems.map(group => {
          return {
            ...group,
            markers: {
              $values: group.markers.$values.filter(marker => {
                return marker.markerName.toLowerCase().includes(value.toLowerCase())
              })
            }
          }
        })
        this.localBloodMarkersItems = arr
      }
    },
    calculateStatusGreen (item) {
      const optimalMin = +item.optimalRange.split('-')[0]
      const optimalMax = +item.optimalRange.split('-')[1]
      if (item.value >= optimalMin && item.value <= optimalMax) {
        return true
      } else {
        return false
      }
    },
    calculateStatusYellow (item) {
      const optimalMin = +item.optimalRange.split('-')[0]
      const optimalMax = +item.optimalRange.split('-')[1]
      const labMin = +item.labRange.split('-')[0]
      const labMax = +item.labRange.split('-')[1]
      if ((item.value <= optimalMin || item.value >= optimalMax) && (item.value >= labMin && item.value <= labMax)) {
        return true
      } else {
        return false
      }
    },
    calculateStatusRed (item) {
      const labMin = +item.labRange.split('-')[0]
      const labMax = +item.labRange.split('-')[1]
      if (item.value <= labMin || item.value >= labMax) {
        return true
      } else {
        return false
      }
    },
    groupBlood ($event) {
      this.isGrouped = $event
    },
    toggleRow (key) {
      const index = this.expandedRows.indexOf(key)

      if (index !== undefined) {
        if (index > -1) {
          this.expandedRows.splice(index, 1)
        } else {
          this.expandedRows.push(key)
        }
      }
    },
    updateBloodValue ($event, id) {
      this.bloodItems.find(item => {
        return item.id === id
      }).value = $event
    },
    filterGetBloodMarkers(val) {
      this.bloodGroupingType = val
      this.getBloodMarkers({ id: this.visit.bloodTestId, groupingType: +val }).then(res => {
        if (res) {
          this.localBloodMarkersItems = this.bloodMarkersItems
          this.expandedRows = this.localBloodMarkersItems.map(itemGroup => itemGroup.name)
        }
      })
    }
  },
  computed: {
    ...mapState({
      visit: state => state.visits?.visit,
      bloodMarkersItems: state => state.blood.bloodMarkersItems,
      bloodData: state => state.blood.bloodData
    }),
    bloodMarkersHeader () {
      return [
        {
          text: 'MARKER',
          value: 'marker',
          sortable: false,
          isIcon: true,
          icon: 'mdi-magnify',
          isSlotable: true,
          customFilter: true,
          action: true,
          tag: 'input',
          width: '40%'
        },
        {
          text: 'VALUE',
          value: 'value',
          sortable: false,
          isSlotable: true,
          width: '15%'
        },
        {
          text: 'OPTIMAL RANGE',
          value: 'optimalRange',
          sortable: false,
          isSlotable: true,
          width: '15%'
        },
        {
          text: 'LAB RANGE',
          value: 'labRange',
          sortable: false,
          isSlotable: true,
          width: '15%'
        },
        {
          text: 'UNITS',
          value: 'units',
          sortable: false,
          isSlotable: true,
          width: '15%'
        }
      ]
    },
    headersBlood () {
      return [
        {
          text: 'Marker',
          value: 'marker',
          isSlotable: true,
        },
        {
          text: 'Value',
          value: 'value',
          isSlotable: true,
        },
        {
          text: 'Optimal Range',
          value: 'optimalRange',
          isSlotable: true,
        },
        {
          text: 'Lab Range',
          value: 'labRange',
          isSlotable: true,
        },
        {
          text: 'Units',
          value: 'marker',
          isSlotable: true,
        },
        {
          text: 'Status',
          value: 'status',
          isSlotable: true,
        }
      ]
    },
  },
  watch: {
    bloodIsFasting (val) {
      /* if (val && !this.bloodOnlyOutOfRange) {
        const array = this.bloodMarkersItems.map(group => {
          return {
            ...group,
            markers: group.markers.$values.filter(marker => marker.isOptimalAbove || marker.isOptimalBelow)
          }
        })
        this.localBloodMarkersItems = array.filter(group => group.markers.length)
      }
      if (!val && !this.bloodOnlyOutOfRange) {
        this.localBloodMarkersItems = this.bloodMarkersItems
      } */
      this.enableOrDisableBloodFasting({ id: this.visit.bloodTestId, isEnableString: val }).then(res => {
        if (typeof res === 'boolean') {
          console.log('enableBloodFasting', res)
        } else {
          console.log('error enableBloodFasting', res)
        }
      })
    },
    /* bloodOnlyOutOfRange (val) {
      if (val) {
        const array = this.bloodMarkersItems.map(group => {
          const filteredMarkers = group.markers.$values.filter(marker => marker.isLabAbove || marker.isLabBelow || marker.isOptimalAbove || marker.isOptimalBelow)
          return {
            ...group,
            markers: filteredMarkers,
            markersCount: filteredMarkers.length
          }
        })
        this.localBloodMarkersItems = array.filter(group => group.markers.length)
      } else {
        this.localBloodMarkersItems = this.bloodMarkersItems
      }
    } */
  }
}
</script>

<style lang="scss" scoped>
.blood {
  position: relative;
  .table {
    ::v-deep .v-data-table {
      &__wrapper {
        overflow-x: hidden!important;
      }
      .v-data-table-header {
        tr {
          th {
            div {
              width: 100%!important;
              justify-content: center!important;
            }
            div.v-input__slot {
              width: 100%!important;
            }
            div#filter-activator-2 {
              width: 130px!important;
              margin-left: 5px!important;
            }
            .v-input__append-inner {
              width: unset!important;
            }
          }
        }
      }
      tr:not(.grouped-table) {
        td {
          border-bottom: thin solid rgba(0, 0, 0, 0.12);
          padding: 3px 0!important;
          .input {
            width: 90px;
            .v-input {
              .v-input__control {
                .v-text-field__details {
                  display: none!important;
                }
              }
            }
          }
        }
      }
      tr:not(.grouped-table):last-child {
        td {
          border-bottom: unset!important;
        }
      }

      .v-data-table-header tr th .v-input__prepend-inner {
        margin-top: 4px!important;
        width: fit-content!important;
      }
    }
  }
  .green {
    background-color: #4AAE8C;
  }
  .yellow {
    background-color: yellow;
  }
  .red {
    background-color: red;
  }

  .blood-group {
    padding: 5px;
    cursor: pointer;
    color: $dark-green;
  }
  .dark-green-color {
    color: $dark-green;
  }
  .circle {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-left: 10px;
    background-color: rgb(0, 140, 137, 16%);
    width: 26px;
    height: 26px;
    border-radius: 50%;
  }

  .isLabAboveOrBelow {
    background-color: rgb(255, 230, 228, 70%);
  }
  .isOptimalAboveOrBelow {
    background-color: rgb(255, 247, 227, 70%);
  }
  .blood-above-below.isLabAboveOrBelow.isOptimalAboveOrBelow {
    background-color: rgb(255, 230, 228, 70%);
  }
  .marker-name {
    font-weight: 500;
    color: rgb(113, 128, 150, 100%);
    padding-left: 10px;
  }
  .marker-name-bold {
    font-weight: 600;
    color: rgb(39, 39, 46, 100%);
  }
  .lab-optimal-value {
    font-weight: 600;
  }
  .optimal-range {
    background-color: rgb(140, 223, 179, 10%);
  }
  .optimal-lab-units {
    color: rgb(66, 84, 102, 100%);
    font-weight: 500;
  }
  .units {
    padding-right: 10px;
  }

  .group-line {
    background-color: #FAFAFB;
  }
}
</style>
