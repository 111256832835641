 <template>
  <div class="add-visit">
    <Dialog width="600">
      <template slot="title">
        <span>
          {{dialog.title}}
        </span>
      </template>
      <template slot="body">
        <v-form ref="form" v-model="valid"  v-if="model && dialog.data" lazy-validation id="addVisit" class="py-4 px-6">
          <div class="main-row">
            <div class="section__avatar">
              <v-avatar size="80">
                <v-img :src="dialog.data.profileAvatar"></v-img>
              </v-avatar>
            </div>
            <div class="section__info">
              <div class="main-row">
                <div class="label">Maestro Patient Since {{ dialog.data.createdOn ? $moment(dialog.data.createdOn).format('MM/DD/YYYY') : '-' }}</div>
                <div class="name">{{ dialog.data.firstName + ' ' + dialog.data.lastName }}</div>
              </div>
              <div class="main-row mt-2">
                <div class="phone">
                  <div class="label">Phone Number</div>
                  <div class="phone-number">{{dialog.data.number || '-'}}</div>
                </div>
                <div class="email">
                  <div class="label">E-mail</div>
                  <div class="email-value">{{dialog.data.email}}</div>
                </div>
              </div>
            </div>
          </div>
          <br>
          <hr>
          <br>
          <v-row>
            <v-col class="eval-label">Evaluation Type (choose one)</v-col>
          </v-row>
          <v-row class="eval-type">
            <v-col>
              <v-checkbox
                color="#008C89"
                v-model="form.surveyEvaluation"
                :class="{'disabled': form.surveyEvaluation}"
                label="Survey Evaluation"/>
              <v-checkbox
                color="#008C89"
                :disabled="form.nonSurvey || form.sendInvite"
                v-model="form.notSendEmail"
                label="Don't Send Email"/>
            </v-col>
            <v-col class="custom-checkbox">
              <v-checkbox
                color="#008C89"
                v-model="form.nonSurvey"
                :class="{'disabled': form.nonSurvey}"
                label="Non-Survey Evaluation"/>
              <span style="margin-top: 6px;display: block;text-align: end;">{{form.surveyEvaluation && !form.notSendEmail && !form.sendInvite ? '* Survey email will be sent' : form.sendInvite && !form.notSendEmail ? '* Invitation email will be sent' : '' }}</span>
            </v-col>
          </v-row>
          <v-row>
            <v-col class="pb-0" style="position:relative;">
              <div class="label">Evaluation Date</div>
              <PhoneInput
                radius="8"
                :isAutofocus="isAutofocus"
                :mask="'##/##/####'"
                :placeholder="'03/05/2022'"
                :value="form.customDate"
                @input="form.date=$event"
              />
              <v-icon color="#008C89" style="position: absolute;right: 20px;top: 40px;">mdi-calendar-blank</v-icon>
              <datepicker
                style="position: absolute;top: 34px;right: 0;opacity: 0;width: 50%"
                :min="nowDate"
                @date:update="form.date=$moment($event).format('MM/DD/YYYY'), form.customDate=form.date"
                :value="model.date ? $moment(model.date).format('MM/DD/YYYY') : ''" :rules="notEmtpy" />
            </v-col>
          </v-row>
          <v-row>
            <v-col class="pb-0">
              <div class="label">Practitioners</div>
              <Select outlined
                v-if="doctors"
                radius="8"
                itemText="name"
                itemValue="id"
                :items="doctors"
                :value="doctors[0]"
                @change="form.doctorId=$event.id"
              />
            </v-col>
          </v-row>
<!--          <v-row>
            <v-col class="">
              <div class="label mb-1 d-flex justify-space-between">
                <div>Optional Evaluation Notes</div>
                <div class="d-flex align-center" style="cursor: pointer" @click="openPreviewEmail({ isPortal: false })"><v-icon>mdi-email-open</v-icon> Preview</div>
              </div>
              <Textarea outlined
                counter
                color="#fff"
                radius="8"
                height="100"
                background-color="#EDF2F7"
                placeholder="Initial notes to Patients"
                :value="model.notes"
                @input:text-area="form.notes=$event"
              />
            </v-col>
          </v-row>-->
<!--          <v-row class="d-flex align-center justify-space-between">
            <v-col class="pt-0">
              <v-checkbox
                style="margin-top: 12px"
                color="#008C89"
                v-model="model.addSurveyForm"
                label="Add Survey Pdf to the Email"/>
            </v-col>
            <v-col class="pt-0" cols="5">
              <Select outlined
                      radius="8"
                      itemText="name"
                      itemValue="code"
                      :items="settingsForms"
                      :value="form.addSurveyFormCode"
                      @change="form.addSurveyFormCode=$event"
              />
            </v-col>
          </v-row>-->
          <v-row v-if="!model.isPatientPortalExists && subscriptionFeatures.features.$values.includes('Portal')" class="no-have-portal">
            <v-checkbox
              color="#008C89"
              v-model="form.sendInvite"
              label="Patient does NOT have Portal Access. If you want the Patient to complete surveys online or just want them to have Portal Access, check here to Invite Patient to the Portal."/>
          </v-row>
<!--          <v-row class="d-flex align-center justify-space-between">
            <v-col class="pt-0">
              <v-checkbox
                style="margin-top: 0"
                color="#008C89"
                v-model="model.printSurveyForm"
                label="Print Survey Form"/>
            </v-col>
            <v-col class="pt-0" cols="5">
              <Select outlined
                      radius="8"
                      itemText="name"
                      itemValue="code"
                      :items="settingsForms"
                      :value="settingsForms[0]"
                      @change="form.printSurveyFormCode=$event"
              />
            </v-col>
          </v-row>-->
          <v-row>
            <v-col>
              <div class="d-flex justify-end">
                <div v-if="(form.sendInvite || form.surveyEvaluation) && !form.notSendEmail" class="d-flex align-center" style="cursor: pointer;padding-right: 10px" @click="openPreviewEmail({ isPortal: false })"><v-icon>mdi-email-open</v-icon> Preview</div>
                <Button color="#008C89" @click.native="save">
                  <span style="color: white">Schedule</span>
                </Button>
              </div>
            </v-col>
          </v-row>
        </v-form>
        <div v-if="loading" class="loading d-flex justify-center px-2 py-2 align-center">
          <v-progress-circular
            indeterminate
            color="#008C89"
          ></v-progress-circular>
        </div>
      </template>
    </Dialog>
    <preview-email-template v-if="dialog.name === 'previewEmailTemplateDialog'" />
  </div>
</template>

<script>
import { mapState, mapActions, mapMutations } from 'vuex'

import Dialog from '@/components/layouts/dialog'
import Button from '@/components/elements/Button'
import Select from '@/components/elements/Select'
import datepicker from '@/components/elements/datepicker'
// import timepicker from '@/components/elements/timepicker'
import Textarea from '@/components/elements/Textarea'

import Validation from '@/mixins/Validation'
// import Input from '@/components/elements/Input'
import PhoneInput from '@/components/elements/phone-input'
import previewEmailTemplate from '@/components/layouts/dialogs/preview-email-template'

export default {
  name: 'add-visit',
  mixins: [Validation],
  props: {
    query: {
      type: Object
    }
  },
  components: {
    Dialog,
    Button,
    Select,
    datepicker,
    // timepicker,
    // Input,
    PhoneInput,
    Textarea,
    previewEmailTemplate
  },
  data: () => ({
    time: null,
    form: {
      // patientName: '',
      customDate: null,
      date: null,
      doctorId: null,
      patientId: null,
      notes: '',
      addSurveyForm: null,
      addSurveyFormCode: null,
      printSurveyForm: null,
      printSurveyFormCode: null,
      nonSurvey: false,
      notSendEmail: false,
      sendInvite: false,
      surveyEvaluation: true,
    },
    nowDate: new Date(new Date().setDate(new Date().getDate())).toISOString().slice(0, 10),
    loading: false,
    isAutofocus: true
  }),
  async mounted () {
    this.isAutofocus = false
    this.getDoctors()
    const { id } = this.dialog
    const res = await this.getModel(id)
    this.form.date = res.date
    this.form.customDate = this.$moment(res.date).format('MM/DD/YYYY')
    this.form.addSurveyFormCode = res.surveyFormCode
    console.log('dialog.data', this.dialog.data)
    this.form.sendInvite = !this.model.isPatientPortalExists && this.subscriptionFeatures.features.$values.includes('Portal') ? true : false
  },
  methods: {
    ...mapMutations('dialogs', ['TOGGLE_DIALOG']),
    ...mapActions('patients', ['getDoctors', 'getPatients']),
    ...mapActions('visits', ['addVisit', 'getModel', 'getVisit']),
    async save () {
      this.loading = true
      if (this.validate()) {
        const form = Object.assign({}, this.form)
        delete form.customDate
        // form.patientName = this.model.patientName
        form.patientId = this.model.patientId
        form.doctorId = this.model.doctorId
        // form.doctorName = this.model.doctorName
        form.notes = this.form.notes || this.model.notes
        form.date = this.$moment(this.form.date).format('YYYY-MM-DDTHH:mm:ss')
        form.addSurveyForm = this.model.addSurveyForm
        form.addSurveyFormCode = this.form.addSurveyFormCode
        form.printSurveyForm = this.model.printSurveyForm
        form.printSurveyFormCode = this.form.printSurveyFormCode
        form.sendInvite = !this.model.isPatientPortalExists && this.subscriptionFeatures.features.$values.includes('Portal') ? form.sendInvite : false
        delete form.surveyEvaluation
        const res = await this.addVisit(form)
        this.$emit('showPopup', { type: 'add-visit', data: res })
        this.query.PageSize = 15
        await this.getPatients(this.query)
      }
      this.loading = false
    },
    openPreviewEmail ({ isPortal }) {
      this.TOGGLE_DIALOG({
        name: 'previewEmailTemplateDialog',
        isOpen: true,
        title: '',
        data: { ...this.dialog.data, ...this.form, notes: this.form.notes || this.model.notes, nonSurvey: this.form.nonSurvey, notSendEmail: this.form.notSendEmail, sendInvite: this.form.sendInvite },
        isPortal
      })
    }
  },
  computed: mapState({
    dialog (state) {
      const modalName = this._.findKey(state.dialogs, ['isOpen', true])
      return state.dialogs[modalName]
    },
    settingsForms: state => state.settings.patients.forms.$values.map(item => { return { name: item.name.replace('Survey', '').trim(), code: item.code } }),
    doctors: state => state.patients?.doctors?.$values,
    model: state => state.visits?.model,
    subscriptionFeatures: state => state.subscription,
  }),
  watch: {
    'form.surveyEvaluation' (val) {
      if (val) {
        this.form.nonSurvey = false
      }
    },
    'form.nonSurvey' (val) {
      if (val) {
        this.form.surveyEvaluation = false
        this.form.notSendEmail = false
      }
    },
    'form.sendInvite' (val) {
      if (val) {
        this.form.notSendEmail = false
      }
    }
  }
}
</script>

<style lang="scss" scoped>
  .label {
    font-size: 14px;
    font-weight: 500;
    color: #425466;
  }
  .add-visit {
    .eval-label {
      font-weight: 600;
      text-align: center;
      font-size: 16px;
      padding: 0;
    }
    .main-row {
      display: flex;
      flex-wrap: wrap;
      column-gap: 2rem;
      row-gap: 0.5rem;
      .section__avatar {
        display: flex;
        flex-wrap: wrap;
        align-items: center;
        column-gap: 1rem;
        width: fit-content;
      }
      .section__info {
        width: calc(100% - 125px);
      }
      .label {
        font-weight: 600;
        color: #afafaf;
      }
      .name {
        width: 100%;
        font-size: 20px;
        font-weight: bold;
      }
      .phone-number {
        font-weight: 600;
      }
      .email-value {
        font-weight: 600;
      }
    }
    ::v-deep .v-dialog__container {
      .v-card {
        &__title {
          background: #edf2f7;
          padding: 25px 30px 17px 46px !important;
          justify-content: center!important;
          position: relative;
          .v-icon {
            position: absolute;
            right: 15px;
            top: 25px;
          }
        }
        &__text {
          .v-input {
            .v-text-field__details {
              display: none;
            }
          }
        }
      }
    }
    .loading {
      min-height: 100px;
      position: absolute;
      height: 85%;
      width: 98%;
      top: 84px;
      left: 6px;
      background: rgba(213, 213, 213, 0.5);
      border-radius: 16px;
    }
    .no-have-portal {
      ::v-deep .v-label {
        color: red;
      }
      ::v-deep .v-input {
        margin-top: 30px!important;
      }
    }
    .eval-type {
      ::v-deep {
        .v-input {
          margin-top: 0!important;
          .v-input__control {
            .v-messages {
              display: none!important;
            }
          }
          .v-input__slot {
            margin-bottom: 0!important;
          }
        }
        .v-input.disabled {
          pointer-events: none!important;
        }
      }
      .custom-checkbox {
        ::v-deep {
          .v-input .v-input__slot {
            justify-content: flex-end!important;
          }
        }
      }
    }
    .preview-hide {
      visibility: hidden;
      pointer-events: none;
    }
  }
</style>
