<template>
  <div class="tab-settings">
    <div class="wrapper" v-if="settingFilters2 && settingFilters2.length > 0 && Object.keys(form).length">
      <template v-for="setting in settingFilters2">
        <div class="item-setting" :key="setting.id" v-if="currentSubscription.features.$values.concat(['All']).includes(setting.permission)"><!--style="width: 49%;height: fit-content"-->
          <v-card
            :style="{'border-radius': '16px'}"
            elevation="1"
          >
            <v-card-text>
              <div class="main-row">
                <div class="setting-title">{{setting.settingName}}</div>
              </div>
              <div v-for="filter in setting.filters" :key="filter.componentName">
                <component
                  :is="filter.componentName"
                  :title="filter.name"
                  :form="form"
                  v-model="loadParams"></component>
              </div>
            </v-card-text>
          </v-card>
        </div>
      </template>
      <v-row>
        <v-col class="d-flex flex-row justify-end">
          <Button height="45"
                  width="100"
                  btn-bg="dark-green"
                  dark
                  :styles="{ 'border-radius': '8px' }"
                  @click.native="update">Update</Button>
        </v-col>
      </v-row>
    </div>
    <div v-else class="loading d-flex justify-center px-2 py-2 align-center">
      <v-progress-circular
        indeterminate
        color="#008C89"
      ></v-progress-circular>
    </div>
    <div class="custom-alert">
      <v-alert
        color="#7ac1fb"
        dismissible
        type="info"
        v-model="alertIsOpen"
      >
        <span style="font-size: 14px;">{{popupMessage}}</span>
      </v-alert>
    </div>
  </div>
</template>

<script>
import { mapActions, mapState } from 'vuex'
import Button from '@/components/elements/Button'

export default {
  name: 'tabSettings',
  components: {
    Button,
    evaluations: () => import('@/views/settings/tabSettingFilters/evaluations.vue'),
    calculationSettings: () => import('@/views/settings/tabSettingFilters/calculationSettings.vue'),
    followupstatusworkflow: () => import('@/views/settings/tabSettingFilters/followUpStatusWorkflow.vue'),
    surveyduestatus: () => import('@/views/settings/tabSettingFilters/surveyDueStatus.vue'),
    inprotocolstatus: () => import('@/views/settings/tabSettingFilters/inProtocolStatus.vue'),
    patientSchedule: () => import('@/views/settings/tabSettingFilters/patientSchedule.vue'),
    // patientschedule: () => import('@/views/settings/tabSettingFilters/custom-filter.vue'),
    resultreceipt: () => import('@/views/settings/tabSettingFilters/custom-filter.vue'),
    patientReceipt: () => import('@/views/settings/tabSettingFilters/patientReceipt.vue')
    // Resultreceipt: () => import('@/views/settings/tabSettingFilters/patientReceipt.vue')
    /* patientReportPutBelowSignature: () => import('@/views/settings/tabSettingFilters/patientReportPutBelowSignature.vue'),
    visits: () => import('@/views/settings/tabSettingFilters/visits.vue'),
    visitResultCalculation: () => import('@/views/settings/tabSettingFilters/visitResultCalculation.vue'),
    defaultSystemsSurveyCalculationMethod: () => import('@/views/settings/tabSettingFilters/defaultSystemsSurveyCalculationMethod.vue'),
    defaultBloodChemistryCalculationMethod: () => import('@/views/settings/tabSettingFilters/defaultBloodChemistryCalculationMethod.vue'),
    commonUi: () => import('@/views/settings/tabSettingFilters/commonUi.vue') */
  },
  async mounted () {
    this.appSettings()
    const res = await this.getSettingFilters()
    if (res) {
      const { adminSettings, doctorSettings, organizationSettings } = this.settingFilters
      this.form = Object.assign({}, { adminSettings, doctorSettings, organizationSettings })
    }
    this.getCurrentSubscriptionPlan()
  },
  data: () => ({
    settingFilters2: [
      {
        id: 7,
        settingName: 'Evaluations',
        permission: 'All',
        filters: [
          {
            componentName: 'evaluations',
            name: ''
          }
        ]
      },
      {
        id: 8,
        settingName: 'Follow Up (Home Screen and Patient List Filter)',
        permission: 'All',
        filters: [
          {
            componentName: 'followupstatusworkflow',
            name: ''
          },
          {
            componentName: 'surveyduestatus',
            name: 'Survey Due Status'
          },
          {
            componentName: 'inprotocolstatus',
            name: ' In Protocol Status Buffer'
          }
        ]
      },
      {
        id: 2,
        settingName: 'Calculation Settings',
        permission: 'Survey',
        filters: [
          {
            componentName: 'calculationSettings',
            name: ''
          }
        ]
      },
      {
        id: 1,
        settingName: 'Reports',
        permission: 'All',
        filters: [
          {
            componentName: 'patientSchedule',
            name: 'Patient Schedule'
          },
          {
            componentName: 'patientReceipt',
            name: 'Patient Invoices'
          }
          /* {
            componentName: 'patientReportPutBelowSignature',
            name: 'Patient Report - Put Below Signature'
          } */
        ]
      }
      /* {
        id: 2,
        settingName: 'Survey calculations',
        filters: [
          {
            componentName: 'defaultSystemsSurveyCalculationMethod',
            name: 'Default Systems Survey Calculation Method'
          }
        ]
      },
      {
        id: 3,
        settingName: 'Visits',
        filters: [
          {
            componentName: 'visits',
            name: ''
          }
        ]
      },
      {
        id: 4,
        settingName: 'Visit Result Calculation',
        filters: [
          {
            componentName: 'visitResultCalculation',
            name: ''
          }
        ]
      },
      {
        id: 5,
        settingName: 'Default Blood Chemistry Calculation Method',
        filters: [
          {
            componentName: 'defaultBloodChemistryCalculationMethod',
            name: ''
          }
        ]
      },
      {
        id: 6,
        settingName: 'Common UI',
        filters: [
          {
            componentName: 'commonUi',
            name: ''
          }
        ]
      } */
    ],
    loadParams: {},
    form: {},
    popupMessage: null,
    alertIsOpen: false
  }),
  methods: {
    ...mapActions(['appSettings']),
    ...mapActions('organizations', ['getSettingFilters', 'updateSettingFilters']),
    ...mapActions('subscriptions', ['getCurrentSubscriptionPlan']),
    async update () {
      const res = await this.updateSettingFilters(this.form)
      if (res) {
        this.showPopup()
      }
    },
    showPopup () {
      this.alertIsOpen = true
      this.popupMessage = 'Setting was updated successfully.'
    },
  },
  computed: {
    ...mapState({
      settingFilters: state => state.organizations.settingFilters?.record,
      currentSubscription: state => state.subscriptions.currentSubscription
    })
  },
  watch: {
    loadParams (val) {
      console.log('loadParams', val)
      this.form = Object.assign({ ...val })
    },
    alertIsOpen (val) {
      const self = this
      if (val) {
        setTimeout(function () {
          self.alertIsOpen = false
        }, 5000)
      }
    }
  }
}
</script>

<style scoped lang="scss">
.tab-settings {
  padding-bottom: 35px;
  .wrapper {
    /*display: flex;
    column-gap: 1rem;
    row-gap: 1rem;
    flex-wrap: wrap;
    height: auto;*/
    column-count: 2;
    column-gap: 10px;
    .item-setting {
      margin: 10px;
      width: 100%;
      display: inline-block;
      box-sizing: border-box;
    }
  }
  .loading {
    min-height: 65vh;
    ::v-deep .v-progress-circular {
      position: absolute;
      top: 50%;
    }
  }
  ::v-deep .v-card {
    background: white!important;
    flex-wrap: wrap!important;
    width: 100%!important;
  }
  .main-row {
    display: flex;
    flex-wrap: wrap;
    column-gap: 1rem;
    row-gap: 1rem;
    align-items: center;
    .setting-title {
      font-weight: 600;
      font-size: 20px;
      color: #16192C;
    }
  }
  .custom-alert {
    max-width: 300px;
    position: fixed;
    top: 10px;
    right: 20px;
    z-index: 100;
  }
}
</style>
