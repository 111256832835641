<template>
  <div class="hipaa pl-4">
    <h2>What is HIPAA Compliance?</h2>
    <p>
      One of the most commonly asked questions we get is “What is HIPAA compliance?” The Health Insurance Portability and Accountability Act of 1996, commonly known as HIPAA, is a series of regulatory standards that outline the lawful use and disclosure of protected health information (PHI). HIPAA compliance is regulated by the Department of Health and Human Services (HHS) and enforced by the Office for Civil Rights (OCR).
    </p>
    <h2>What is Protected Health Information?</h2>
    <p>Protected health information (PHI) is any demographic information that can be used to identify a patient or client of a HIPAA-beholden entity. Common examples of PHI include names, addresses, phone numbers, Social Security numbers, medical records, financial information, and full facial photos to name a few. PHI transmitted, stored, or accessed electronically also falls under HIPAA regulatory standards and is known as electronic protected health information, or ePHI. ePHI is regulated by the HIPAA Security Rule, which was an addendum to HIPAA regulation enacted to account for changes in medical technology.</p>
  </div>
</template>

<script>
export default {
  name: 'hipaa'
}
</script>

<style lang="scss" scoped>
  .hipaa {
    // ..
  }
</style>
