<template>
  <div class="notes-dialog">
    <Dialog width="450">
      <template slot="title">
        <div class="title">
          {{notesDialog.title}} {{notesDialog.data.supplementName || ''}}
        </div>
      </template>

      <template slot="body">
        <div class="description">
          <v-textarea v-if="notesDialog.title === 'Special Notes'" disabled v-model="notesDialog.data.specialNotes" :auto-grow="true"></v-textarea>
          <v-textarea v-else v-model="notesDialog.data.clinicianNotes" :auto-grow="true"></v-textarea>
        </div>
      </template>

      <template slot="actions">
        <div class="d-flex justify-space-between w-100 pb-4 px-4">
          <Button
            dark
            outlined
            color="grey"
            @click.native="close"
            :styles="{ 'border-radius': '8px' }"
          >
            <span style="color: #425466">Cancel</span>
          </Button>
          <Button
            v-if="notesDialog.title !== 'Special Notes'"
            btn-bg="dark-green"
            @click.native="saveNotes"
            :styles="{ 'border-radius': '8px' }"
          >
            <span style="color: #FFFFFF">Confirm</span>
          </Button>
        </div>
      </template>
    </Dialog>
  </div>
</template>

<script>
import { mapState, mapMutations, mapActions } from 'vuex'
import Dialog from '@/components/layouts/dialog'
import Button from '@/components/elements/Button'
import Image from '@/mixins/Image'

export default {
  name: 'notes-dialog',
  props: {
    query: {
      type: Object
    }
  },
  components: { Dialog, Button },
  mixins: [Image],
  methods: {
    ...mapActions('results', ['updateNotes']),
    ...mapMutations('dialogs', ['TOGGLE_DIALOG']),
    close () {
      this.TOGGLE_DIALOG({ name: 'notesDialog', isOpen: false })
    },
    async saveNotes () {
      const data = {
        apiUrl: this.notesDialog.action,
        dataForSend: {
          resultSupplementId: this.notesDialog.data.id,
          clinicianNotes: this.notesDialog.data.clinicianNotes
        }
      }
      const res = await this.updateNotes(data)
      if (res) {
        this.$emit('showPopup', { res, supplementName: this.notesDialog.data.supplementName })
      }
    }
  },
  mounted () {
    console.log('notes-dialog')
  },
  computed: {
    ...mapState({
      notesDialog: state => state.dialogs.notesDialog
    })
  }
}
</script>

<style lang="scss" scoped>
.w-100 {
  width: 100%;
}
.notes-dialog {
  //::v-deep {
  //  .v-card__title {
  //    position: relative!important;
  //    .v-icon {
  //      position: absolute!important;
  //      right: 15px;
  //      top: 0;
  //    }
  //  }
  //}
  //.title {
  //  width: 100%;
  //  text-align: center;
  //}
  //.description {
  //  padding: 0 16px;
  //}
  ::v-deep .v-card {
    &__title {
      background: #edf2f7;
      padding: 10px 16px!important;
      justify-content: center!important;
      margin-bottom: 10px!important;
    }
  }
  ::v-deep .v-dialog__container {
    .v-card {
      &__title {
        //background: #edf2f7;
        padding: 25px 35px 17px 26px !important;
        justify-content: center!important;
        position: relative;
        .image {
          width: 100%;
          text-align: center;
        }
        .title {
          font-family: 'Poppins';
          font-weight: 600;
          font-size: 16px;
          text-align: center;
          color: #27272E;
        }
        .v-icon {
          display: none;
          position: absolute;
          right: 15px;
          top: 25px;
        }
      }
      &__text {
        .description {
          font-family: 'Poppins';
          padding: 0 35px;
          font-weight: 400;
          font-size: 14px;
          text-align: center;
          color: #425466;
        }
        .v-input {
          .v-text-field__details {
            display: none;
          }
        }
      }
    }
  }
}
</style>
