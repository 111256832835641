<template>
  <div class="app-bar-desktop">
    <v-app-bar
      color="#FFFFFF"
      prominent
      :style="leftMenuIsOpen ? 'left: 180px': 'left: 55px'"
      app
    >

      <div class="doctor-organization">
        <v-list-item-avatar>
          <v-img :src="organization.logoSmall" :alt="organization.practiceName"></v-img>
        </v-list-item-avatar>
<!--        <div class="app-bar-desktop__doctor-title">
          <div class="org-name">
            {{organization.practiceName}}
          </div>
          <div class="org-title">
            {{organization.practiceTitle}}
          </div>
        </div>-->
      </div>

      <div class="active-page">
        <Breadcrumbs/>
      </div>

      <v-spacer></v-spacer>
      <v-icon color="#718096"
              @click.native="goBack"
              class="arrow-go-back">
        mdi-arrow-left-thick
      </v-icon>
      <v-divider
        vertical
      ></v-divider>
<!--      <v-btn icon>
        <v-badge
          color="error"
          overlap
          :value="6"
          :content="6"
        >
          <v-icon color="#718096">mdi-bell-outline</v-icon>
        </v-badge>
      </v-btn>-->
      <div class="right-side-main-info ml-2" @click="toggleMenu">
        <v-list-item-avatar>
          <v-img :src="userProfile.profileIcon" alt="ava"></v-img>
        </v-list-item-avatar>
        <div class="right-side-main-info__full-name">
          {{userProfile.firstName}} {{userProfile.lastName}}
        </div>
      </div>
    </v-app-bar>
  </div>
</template>

<script>
import { mapMutations, mapState } from 'vuex'
// import OrganizationInfo from '@/components/layouts/forms/organizationInfo'
import Image from '@/mixins/Image'
import Breadcrumbs from '@/components/layouts/Breadcrumbs'
// import Img from '@/components/elements/Img'

export default {
  components: { Breadcrumbs },
  props: {
    value: {
      type: Boolean
    },
    leftMenuIsOpen: {
      type: Boolean
    }
  },
  mixins: [Image],
  name: 'App-bar-desktop',
  data: () => ({
    drawerMenu: false
  }),
  mounted () {},
  methods: {
    ...mapMutations('dialogs', ['TOGGLE_DIALOG']),
    toggleMenu () {
      this.drawerMenu = this.value
      this.drawerMenu = !this.drawerMenu
      this.$emit('toggleMenu', this.drawerMenu)
    },
    openOrganizationInfo () {
      this.TOGGLE_DIALOG({ name: 'organizationInfo', isOpen: true })
    },
    switchLocale (locale) {
      if (this.$i18n.locale !== locale) {
        this.$i18n.locale = locale
        localStorage.setItem('locale', this.$i18n.locale)
      }
    },
    goBack () {
      window.history.back()
    }
  },
  computed: {
    ...mapState({
      userProfile: state => state.userProfile,
      organization: state => state.organization
    })
  },
  watch: {
    $route (val) {
      console.log(val)
    }
  }
}
</script>

<style scoped lang="scss">
.app-bar-desktop {
  .active-page {
    display: flex;
    //width: 30%;
    justify-content: end;
    padding-right: 10px;
    font-weight: 700;
  }
  .arrow-go-back {
    cursor: pointer;
    margin-right: 10px;
  }
  .doctor-organization {
    display: contents;
  }
  ::v-deep .v-toolbar--prominent .v-toolbar__content {
    align-items: center !important;
    height: auto !important;
    padding-bottom: 0!important;
    padding-top: 0!important;
  }
  ::v-deep .v-toolbar--prominent {
    height: auto !important;
  }
  ::v-deep .v-toolbar__title {
    display: contents !important;
  }
  &__doctor-title {
    max-width: 260px;
    word-break: break-word;
    color: #718096;
    font-family: Poppins;
    .org-name {
      font-weight: 800;
      font-size: 14px;
    }
    .org-title {
      font-weight: 600;
      font-size: 12px;
    }
  }

  .menu-tabs {
    margin-right: 1px;

    ::v-deep .v-tabs {
      height: 56px!important;
    }
    ::v-deep .v-tabs .v-slide-group {
      height: 56px!important;
    }
    ::v-deep .v-tabs .v-item-group .v-slide-group {
      &__wrapper {}
      &__content {
        .v-tab {
          text-decoration: none!important;
          font-weight: 600;
          font-size: 12px;
          color: #718096;
        }
        .v-tab--active {
          color: #4faa6e!important;
        }
      }
    }
  }

  .right-side-main-info {
    display: flex;
    align-items: center;
    cursor: pointer;
    &__full-name {
      font-weight: 600;
      font-size: 14px;
      color: #16192C;
    }
  }

  @media screen and (max-width: 330px) {
    &__doctor-title {
      display: none;
    }
  }
}
</style>
