<template>
  <div class="forcing-subscription">
    <Dialog width="40%" :scrollable="false" @destroyDialog="onCLoseHandler">

      <template slot="title">
        <span>Forcing subscription</span>
      </template>

      <template slot="body">
        <div class="main-row justify-center">
          To ensure your subscription continues seamlessly, a credit card needs to be on file for your monthly or annual subscription fees. Please add one here.
        </div>
      </template>
      <template slot="actions">
        <div class="d-flex justify-space-between w-100">
          <Button
            dark
            outlined
            color="grey"
            @click.native="close"
            :styles="{ 'border-radius': '8px' }"
          >
            <span style="color: #425466">Cancel</span>
          </Button>
          <Button
            btn-bg="dark-green"
            @click.native="updateSubscription"
            :styles="{ 'border-radius': '8px' }"
          >
            <span style="color: #FFFFFF">Update Subscription</span>
          </Button>
        </div>
      </template>

    </Dialog>
  </div>
</template>

<script>
import Dialog from '@/components/layouts/dialog'
import Image from '@/mixins/Image'
import { mapActions, mapMutations } from 'vuex'
import Button from '@/components/elements/Button.vue'

export default {
  name: 'forcing-subscription',
  components: { Dialog, Button },
  mixins: [Image],
  data: () => ({
    activePaying: 'month'
  }),
  methods: {
    ...mapMutations('dialogs', ['TOGGLE_DIALOG']),
    ...mapActions('subscriptions', ['createCheckoutSession']),
    async updateSubscription () {
      const res = await this.createCheckoutSession({ priceId: this.subscription.productPriceId })
      window.open(res.url, '_self').focus()
    },
    close () {
      this.TOGGLE_DIALOG({ name: 'forcingSubscriptionDialog', isOpen: false })
    },
    onCLoseHandler (val) {
      if (this.subscription.expired) {
        if (this.$route.name !== 'Settings') {
          this.$router.push({ name: 'Settings' })
        }
      }
    }
  },
  computed: {
    subscription () {
      return this.$store.state.subscription
    }
  }
}
</script>

<style lang="scss" scoped>
.forcing-subscription {
  ::v-deep .v-dialog {
    overflow: hidden;
    .v-card {
      position: relative;
      padding: 5px;

      &__title {
        padding: 10px 0 0!important;
        justify-content: center!important;

        .v-icon {
          position: absolute;
          right: 10px;
          top: -100px;
        }
      }
      &__actions {
        display: flex;
      }
    }
  }
  .main-row {
    display: flex;
    flex-wrap: wrap;
    width: 100%;
  }
  .w-100 {
    width: 100%;
  }
}
</style>
