import Vue from 'vue'

const state = () => ({
  patientsChartLine: null,
  topSupplementsChart: null
})

const getters = {}

const actions = {
  async getPatientsChartLineData ({ commit }, params) {
    const { period } = params
    const result = await Vue.$http.get(`/Doctors/dashboard/charts/patients?period=${period || 'month'}`)
    if (result?.status === 200) {
      commit('SET_DATA', ['patientsChartLine', result.data])
    }
  },
  async getTopSupplementsChartData ({ commit }, params) {
    const { period } = params
    const result = await Vue.$http.get(`/Doctors/dashboard/charts/top-supplements?period=${period || 'month'}`)
    if (result?.status === 200) {
      commit('SET_DATA', ['topSupplementsChart', result.data])
    }
  }
}

const mutations = {
  SET_DATA: (state, payload) => {
    const [property, value] = payload
    Vue.set(state, property, value)
  }
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}
